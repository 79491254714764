import { Component } from '@angular/core';
import { GetInspectionResp } from '@xpo-ltl/sdk-inspections';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { shareReplay, take } from 'rxjs';
import { InspectionDetailDialogComponent } from '../../dialogs/inspection-detail-dialog/inspection-detail-dialog.component';
import { InspectionDetailService } from '../../services/api/inspection-detail.service';
import { DialogWrapperService } from '../../services/dialog-wrapper.service';
import { RequestValidator } from '../../validators/request.validator';

@Component({
  selector: 'app-inspection-detail-dialog-column',
  templateUrl: './inspection-detail-dialog-column.component.html',
  styleUrls: ['./inspection-detail-dialog-column.component.scss']
})
export class InspectionDetailDialogColumnComponent implements AgRendererComponent {
  static readonly OPEN_DIALOG = 'Open';
  cellValue: string; // value that will be displayed in column
  value: number; // we're not displaying this in the column but using this to get the data

  constructor(
    private dialogWrapperService: DialogWrapperService,
    private inspectionDetailService: InspectionDetailService
  ) {}

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    if (this.value) {
      this.cellValue = InspectionDetailDialogColumnComponent.OPEN_DIALOG;
    }
  }

  onOpenDialogClicked() {
    RequestValidator.validateNumberIsPositiveInteger(this.value, 'Inspection ID');

    this.inspectionDetailService
      .getInspection(this.value)
      .pipe(take(1), shareReplay(1))
      .subscribe((resp: GetInspectionResp) => {
        this.inspectionDetailService.setInspectionDetails(resp);

        this.dialogWrapperService.alert(
          {
            titleText: `Details for Inspection ID: ${this.value}`,
            contentComponent: InspectionDetailDialogComponent,
            hideDismissButton: true
          },
          {
            disableClose: false,
            minWidth: '95vw'
          }
        );
      });
  }

  /**
   * refresh() is from ICellRenderer(base interface)
   *
   * Get the cell to refresh. Return true if successful. Return false if not (or you don't have refresh logic),
   * then the grid will refresh the cell for you.
   */
  refresh(params: ICellRendererParams): boolean {
    throw new Error('Method not implemented.');
  }
}
