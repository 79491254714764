import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CorrectionRequest, GetInspectionShipmentDetailsResp } from '@xpo-ltl/sdk-inspections';
import { BehaviorSubject, take } from 'rxjs';
import { ProNumber } from '../../../../classes/pronumber';
import { AppNavigationService } from '../../../../services/app-navigation.service';
import { RequestValidator } from '../../../../validators/request.validator';
import { ExternalAppAccessService } from '../../../../services/externalAppAccess.service';
import { DialogWrapperService } from '../../../../services/dialog-wrapper.service';
import { InspectionCorrectionService } from '../../../../components/inspection-corrections/service/inspection-correction.service';
import { InspectionCorrectionsComponent } from '../../../../components/inspection-corrections/inspection-corrections.component';
import { CorrectionReviewStatusText } from '../../../../enums/correction-review-status.enum';
import { CorrectionRequestReviewStatusCd } from '@xpo-ltl/sdk-common';
import { CorrectionInformation } from '../../../../interfaces/correction-information.interface';
import { AppConstantsService } from '../../../../services/app-constants.service';
import { LocationApiWrapperService } from '../../../../services/location/locationApiWrapperService';

@Component({
  selector: 'app-correction-information',
  templateUrl: './correction-information.component.html',
  styleUrls: ['./correction-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorrectionInformationComponent implements OnChanges {
  @Input() correctionRequest: CorrectionRequest;
  @Input() correctionInformation: CorrectionInformation;
  @Input() shipmentDetailsResp: GetInspectionShipmentDetailsResp;
  proNbr$ = new BehaviorSubject<ProNumber>(null);
  @Output() reviewCommentsChanged = new EventEmitter<string>();
  @Output() closeDialog = new EventEmitter<boolean>();
  reviewComments: string;
  region: string;
  isReviewCommentsFieldDisabled$ = new BehaviorSubject<boolean>(true);
  isExpanded = true;
  isDeclined = false;
  showProgressSpinner = true;
  isRulesetAvailable: boolean = false;
  appliedRulesetUrl: string;
  private readonly PRO_NUMBER: string = 'PRO number';
  readonly CorrectionReviewStatusText = CorrectionReviewStatusText;

  get proNbr(): ProNumber {
    return this.proNbr$.value;
  };

  constructor(
    private appNavigation: AppNavigationService,
    private externalAppAccessService: ExternalAppAccessService,
    private dialogWrapperService: DialogWrapperService,
    private inspectionCorrectionService: InspectionCorrectionService,
    public xpoDialog: DialogWrapperService,
    public appConstantsService: AppConstantsService,
    private locationApiWrapperService: LocationApiWrapperService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes['correctionRequest']) {
      this.locationApiWrapperService.listOperationalRegions().pipe(take(1)).subscribe((responseMap: Map<string, string>) => {
        this.region = responseMap.get(this.correctionRequest?.requestorSicCd);
        this.reviewComments = this.correctionRequest?.reviewComment;
        this.isReviewCommentsFieldDisabled$.next(this.correctionRequest?.reviewStatusCd !== CorrectionRequestReviewStatusCd.IN_REVIEW);
        this.proNbr$.next(new ProNumber(this.correctionRequest?.proNbr));
        this.isDeclined = this.correctionRequest?.reviewStatusCd === CorrectionRequestReviewStatusCd.DECLINED;
      })
    }
    if (changes['shipmentDetailsResp']) {
      if (this.shipmentDetailsResp) {
        this.showProgressSpinner = false;
        const proNumber = new ProNumber(this.shipmentDetailsResp?.shipmentDetails?.shipmentId?.proNumber).formatProNumber();
        const pickupDate = this.shipmentDetailsResp?.shipmentDetails?.shipmentId?.pickupDate;
        const appliedRulesetNbr = this.shipmentDetailsResp?.shipmentDetails?.appliedRulesetNbr;
        const appliedAgreementId = this.shipmentDetailsResp?.shipmentDetails?.appliedAgreementId;
        if (proNumber && pickupDate && appliedRulesetNbr && appliedAgreementId) {
          this.isRulesetAvailable = true;
          const queryParams = [];
          queryParams.push('proId=' + proNumber);
          queryParams.push('shipmentPkupDt=' + pickupDate);
          queryParams.push('rsSeq=' + appliedRulesetNbr);
          queryParams.push('docId=' + appliedAgreementId);
          queryParams.push('popUp=Y');
          this.appliedRulesetUrl = this.appConstantsService.getPricingAppEndpoint(['initRulesetView.do'], queryParams);
        }
      }
    }
  }

  onProLinkClicked(mouseEvent: MouseEvent) {
    mouseEvent.preventDefault();
    this.appNavigation.navigateToShipmentDetails(this.proNbr);
    this.closeDialog.emit(true);
  }

  onOpenCorrectionsApp(mouseEvent: MouseEvent) {
    mouseEvent.preventDefault();

    const formattedProNumberString = this.proNbr.formatProNumber();
    RequestValidator.validateStringNotNullOrEmpty(formattedProNumberString, this.PRO_NUMBER);
    const shipmentManagementAppBaseUrl: string = this.externalAppAccessService.buildCorrectionsAppUrl(
      formattedProNumberString
    );

    window.open(shipmentManagementAppBaseUrl);
  }

  onOpenRulesets(mouseEvent: MouseEvent) {
    mouseEvent.preventDefault();
    if (this.appliedRulesetUrl) {
      this.xpoDialog.openExternalWindow(this.appliedRulesetUrl, 'PricingRuleSet');
    }
  }

  onOpenCorrectionRequestDialog(mouseEvent: MouseEvent) {
    mouseEvent.preventDefault();
    this.inspectionCorrectionService.setCorrectionRequestId(+this.correctionRequest?.correctionRequestId);
    this.inspectionCorrectionService.setIsInspectionCorrectionsComponentReadOnly(true);
    this.dialogWrapperService.alert(
      {
        titleText: `Details for Correction Request ID: ${this.correctionRequest?.correctionRequestId}`,
        contentComponent: InspectionCorrectionsComponent,
        hideDismissButton: true
      },
      {
        disableClose: false,
        minWidth: '95vw'
      }
    );
  }

  public openNmftaSite(nmfc: string): void {
    const nmftaSearchUrl = this.appConstantsService.getNmftaSearchUrl(nmfc);
    this.xpoDialog.openExternalWindow(nmftaSearchUrl, 'NMFTA Search');
  }
}
