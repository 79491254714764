import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ListType } from '../../../enums/list-type.enum';
import { ShipmentDetailsService } from '../../../services/api/shipment-details.service';
import { AppNavigationService } from '../../../services/app-navigation.service';
import { AddProOverview } from '../model/add-pro.model';

@Injectable()
export class AddProResolverService implements Resolve<Observable<AddProOverview>> {
  constructor(private shipmentDetailsService: ShipmentDetailsService, private appNavigation: AppNavigationService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AddProOverview> {
    if (!this.shipmentDetailsService.proNbrInputs) {
      this.appNavigation.navigateToList(ListType.SHIPMENT);
      return null;
    } else {
      return this.shipmentDetailsService.listInspectionDetail(this.shipmentDetailsService.proNbrInputs);
    }
  }
}
