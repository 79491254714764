import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { GetInspectionShipmentDetailsResp } from '@xpo-ltl/sdk-inspections';
import { filter, take } from 'rxjs';
import { ProNumber } from '../../classes/pronumber';
import { SicShiftPreference } from '../../classes/sic-shift-preference';
import { InspectionShipmentDetailsService } from '../../services/api/inspection-shipment-details.service';
import { AppConstantsService } from '../../services/app-constants.service';
import { DevTestingService } from '../../services/dev-testing.service';
import { SnackBarHandlingService } from '../../services/snack-bar-handling.service';
import { UserPreferencesService } from '../../services/user-preferences.service';
import { UserService } from '../../services/user.service';

/**
 * ONLY USED IN TEST ENVIRONMENT
 *
 * unread-broadcast-message-dialog.component.ts also has remove/restore ItemInstanceId()
 * as dev only methods
 */
@Component({
  selector: 'app-dev-only',
  templateUrl: './dev-only.component.html',
  styleUrls: ['./dev-only.component.scss']
})
export class DevOnlyComponent implements AfterViewInit {
  @ViewChild('shipmentInstIdSearchInput', { static: true })
  shipmentInstIdSearchInput: ElementRef;

  proInputFormControl: UntypedFormControl;
  scanned: string = '';
  shipmentInstId: string = '';

  constructor(
    public constants: AppConstantsService,
    private userPreferenceService: UserPreferencesService,
    public devTestingService: DevTestingService,
    private userService: UserService,
    private elementRef: ElementRef,
    private inspectionShipmentDetailsService: InspectionShipmentDetailsService,
    private errorHandling: SnackBarHandlingService
  ) {
    this.proInputFormControl = new UntypedFormControl();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.shipmentInstIdSearchInput?.nativeElement?.focus(), 300);
  }

  @HostListener('keypress', ['$event'])
  public proEntered(event) {
    const input = event.key;
    if (this.shipmentInstIdSearchInput.nativeElement !== document.activeElement) {
      if (this.elementRef.nativeElement.contains(event.target)) {
        if (input === 'Enter') {
          event.returnValue = '';
          this.onShipmentInstIdLookup();
        }
        if (input.length === 1) {
          this.scanned += input;
          this.proInputFormControl.setValue(this.scanned);
        }
      }
    } else {
      this.scanned = this.proInputFormControl.value;
    }
  }

  onBeaconApiThrowErrorChanged(changedSlide: MatSlideToggleChange): void {
    this.devTestingService.isBeaconApiThrowingError = changedSlide.checked;
  }

  onDeleteSicShiftUserPreferenceClicked(): void {
    const preference: SicShiftPreference = new SicShiftPreference();
    preference.sic = undefined;
    preference.shift = undefined;
    this.constants.setInspectionContext(
      preference.sic,
      preference.shift,
      this.userService.currentUserFirstName,
      this.userService.currentUserLastName,
      this.userService.currentUserEmployeeId
    );
    this.userPreferenceService.updateSicShiftUserPreference(preference);
  }

  onManagerRolesChanged(changedSlide: MatSlideToggleChange): void {
    this.userService.devOnlyChangeManagerRole(changedSlide.checked);
    this.devTestingService.isManagerRolesChecked = changedSlide.checked;
  }

  onShipmentInstIdLookup() {
    const lookupValue: string = this.proInputFormControl.value?.trim();
    if (lookupValue) {
      if (ProNumber.isValid(lookupValue)) {
        this.inspectionShipmentDetailsService
          .getInspectionShipmentDetails(new ProNumber(lookupValue))
          .pipe(
            filter((resp) => !!resp),
            take(1)
          )
          .subscribe((inspectionShipmentDetailsResp: GetInspectionShipmentDetailsResp) => {
            this.shipmentInstId = inspectionShipmentDetailsResp?.shipmentDetails?.shipmentId?.shipmentInstId;
          });
      } else {
        this.proInputFormControl.reset();
        this.shipmentInstId = '';
        this.errorHandling.showErrorMessage(`Please enter a valid PRO`);
      }
    }
  }
}
