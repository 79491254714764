import { Injectable } from '@angular/core';
import { DocumentManagementService } from '../api/document-management-service';
import { BehaviorSubject } from 'rxjs';
import { Photo } from '../../classes/photos/photo';
import { ProNumber } from '../../classes/pronumber';
import { RequestValidator } from '../../validators/request.validator';
import { take } from 'rxjs/operators';
import { PhotoId } from '../../classes/photos/photo-id';
import { DmsDocType } from '../../enums/dms-doc-type.enum';
import { ShipmentDetailsComponent } from '../../components/shipment-details/shipment-details.component';
import { List } from 'immutable';
import { InspectionHandlingUnit } from '@xpo-ltl/sdk-inspections';

@Injectable({
  providedIn: 'root'
})
export class PhotoDownloadService {
  inspectionPhotosSubject: BehaviorSubject<List<Photo>> = new BehaviorSubject<List<Photo>>(List());
  operationPhotosSubject: BehaviorSubject<List<Photo>> = new BehaviorSubject<List<Photo>>(List());
  documentPhotosSubject: BehaviorSubject<List<Photo>> = new BehaviorSubject<List<Photo>>(List());

  lastLoadedProNumber: ProNumber;

  constructor(private dmsService: DocumentManagementService) {}

  /**
   * Updates
   *         this.documentPhotosSubject.next(List(lDocPhotos));
   *         this.inspectionPhotosSubject.next(List(lInspectionsPhotos));
   *         this.operationPhotosSubject.next(List(lOpsPhotos));
   * @param proNumber
   */
  loadPhotoIdsFromDms(parentPro: ProNumber, handlingUnits: InspectionHandlingUnit[] | undefined) {
    RequestValidator.validateProNumber(parentPro);

    this.dmsService
      .listDocumentIds(parentPro, handlingUnits)
      .pipe(take(1))
      .subscribe((photoIds: PhotoId[]) => {
        const lInspectionsPhotos: Photo[] = [];
        const lDocPhotos: Photo[] = [];
        const lOpsPhotos: Photo[] = [];
        //separate by type
        photoIds.forEach((photoId: PhotoId) => {
          const imageType: DmsDocType = photoId.docClass as DmsDocType;

          if (Object.values(ShipmentDetailsComponent.INSPECTIONS_PHOTOS_DOC_TYPES).includes(imageType)) {
            lInspectionsPhotos.push(new Photo(photoId));
          } else if (Object.values(ShipmentDetailsComponent.OPERATIONS_PHOTOS_DOC_TYPES).includes(imageType)) {
            lOpsPhotos.push(new Photo(photoId));
          } else if (Object.values(ShipmentDetailsComponent.DOCUMENT_DOC_TYPES).includes(imageType)) {
            lDocPhotos.push(new Photo(photoId));
          } else {
            // do nothing, there are more doc types but we're only interested in Inspections related docs
          }
        });

        this.lastLoadedProNumber = parentPro;

        this.documentPhotosSubject.next(List(lDocPhotos));
        this.inspectionPhotosSubject.next(List(lInspectionsPhotos));
        this.operationPhotosSubject.next(List(lOpsPhotos));
      });
  }
}
