import { NgModule } from '@angular/core';
import { MaterialModule } from '../../material.module';
import { LoadingOverlayComponent } from './loading-overlay.component';
import { LoadingOverlayService } from './service/loading-overlay.service';

@NgModule({
  imports: [MaterialModule],
  declarations: [LoadingOverlayComponent],
  exports: [LoadingOverlayComponent],
  providers: [LoadingOverlayService]
})
export class LoadingOverlayModule {}
