import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InspectionError } from '../classes/error/inspection-error';

/**
 * https://stackoverflow.com/questions/72812501/how-can-i-handle-an-error-locally-and-skip-an-angular-http-interceptor
 *
 */
@Injectable()
export class InspectionsDefaultHttpInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.interceptCatchErrorImpl(error);
      })
    );
  }

  private interceptCatchErrorImpl(error: HttpErrorResponse) {
    let errorMsg = '';
    if (error.error instanceof ErrorEvent) {
      console.error('this is client side error', error);
      errorMsg = `Error: ${error.error.message}`;
    } else {
      console.error('this is server side error', error);
      errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
    }
    return throwError(() => error);
  }
}
