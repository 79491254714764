import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { ConfirmCancelData } from '../../classes/confirm-cancel-data';
import { ConfirmCancelComponent } from '../../dialogs/confirm-cancel/confirm-cancel.component';
import { PhotoGalleryData } from './photo-gallery-data';
import { MobileWebBrowserService } from '../../services/hardware/mobile-web-browser-service';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss']
})
export class PhotoGalleryComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  constructor(
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: PhotoGalleryData,
    public mobileWebBrowserService: MobileWebBrowserService,
    public changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    this.subscription = this.data.serviceRef.photoGalleryChanged$.subscribe(() => {
      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public close() {
    this.dialogRef.close();
  }

  public canDeletePhoto(): boolean {
    return this.data.serviceRef.canDeletePhoto();
  }

  public deletePhoto(isConfirmed: boolean = false) {
    if (!isConfirmed) {
      const data = new ConfirmCancelData('Confirm Action', 'Delete Photo?', 'No', 'Yes');
      const dialogRef = this.matDialog.open(ConfirmCancelComponent, {
        data: data
      });
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((resp) => {
          if (resp) {
            this.data.serviceRef.deletePhoto();
          }
        });
      return;
    }
  }

  public previousPhoto() {
    this.data.serviceRef.previousPhoto();
  }

  public nextPhoto() {
    this.data.serviceRef.nextPhoto();
  }
}
