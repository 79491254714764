export enum ListType {
  SHIPMENT = 'Shipment',
  ADD_PROS = 'AddPros',
  LOOKUP = 'Lookup',
  RECOMMENDATION_RULES = 'RecommendationRules',
  CUSTOMER_GUIDELINES = 'CustomerGuidelines',
  BROADCAST_MESSAGE = 'BroadcastMessage',
  PICKUP_REQUEST = 'PickupRequest',
  CORRECTION_REQUESTS = 'CorrectionRequests',
  INSPECTION_CORRECTIONS = 'ListInspectionCorrections'
}
