import { Injectable } from '@angular/core';
import {
  CustomerLocationFunction,
  CustomerApiService as CustomerV2ApiService,
  GetCustomerPath,
  GetCustomerQuery,
  GetCustomerResp
} from '@xpo-ltl-2.0/sdk-customer';
import { AcctId, CustomerAccountTypeCd, CustomerIdTypeCd } from '@xpo-ltl/sdk-common';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { ResponseValidation } from '../classes/ResponseValidation';
import { EntityNotFoundError } from '../classes/error/api/entity-not-found-error';
import { InspectionError } from '../classes/error/inspection-error';
import { ApiUtil } from '../classes/utils/api-util';

@Injectable()
export class CustomerAccountService {
  private static readonly MAD_CODE_CANNOT_BE_EMPTY = 'The MAD Code cannot be empty';
  private static readonly INVALID_MAD_CODE = 'Invalid MAD Code';

  private madCode: string;

  constructor(private customerV2ApiService: CustomerV2ApiService) {}

  /**
   *
   * @param account
   */
  static accountToString(account: AcctId): string {
    return `${account.acctName} (${account.acctMadCd.toUpperCase()})`;
  }

  /**
   *
   * @param madCode
   */
  static formatMadCodeAccount(madCode: string): string {
    return madCode.toUpperCase();
  }

  /**
   *
   * @param madCode
   */
  static validateMadCode(madCode: string): ResponseValidation {
    const regExp = /^[a-z0-9\s]+$/i;
    const madCodeIsValid = new ResponseValidation(true, '');
    if (!madCode) {
      madCodeIsValid.setIsValid(false);
      madCodeIsValid.setMessage(CustomerAccountService.MAD_CODE_CANNOT_BE_EMPTY);
    } else if (madCode.length !== 11) {
      madCodeIsValid.setIsValid(false);
      madCodeIsValid.setMessage('');
    } else if (!madCode.match(regExp)) {
      madCodeIsValid.setIsValid(false);
      madCodeIsValid.setMessage(CustomerAccountService.INVALID_MAD_CODE);
    }
    return madCodeIsValid;
  }

  /**
   * https://github.xpo.com/OneXPO/ltl-api-customer-dist/blob/bbf937b20100b4e8f50cbc676f32a0c0ce829895/2.0/ltl-angular-9x-customer-sdk/projects/xpo-ltl/sdk-customer/src/lib/customer-api.service.ts
   *
   * @param madCode
   */
  getAccountDetails(madCode: string): Observable<GetCustomerResp> {
    const METHOD_NAME: string = 'getAccountDetails';
    const pathParams: GetCustomerPath = new GetCustomerPath();
    const queryParams: GetCustomerQuery = new GetCustomerQuery();
    pathParams.id = madCode.trim();
    queryParams.customerIdTypeCd = CustomerIdTypeCd.MAD_CODE;

    return this.customerV2ApiService.getCustomer(pathParams, queryParams, ApiUtil.DEFAULT_DATA_OPTIONS).pipe(
      take(1),
      catchError((srcError) => {
        if (srcError.code === '404' && srcError.message.contains('not found')) {
          return throwError(new EntityNotFoundError(METHOD_NAME));
        }
        console.error('getAccountDetails unexpected error srcError:', srcError);
        return throwError(srcError);
      })
    );
  }

  /**
   *
   * @param madCode
   */
  getAccountResponseValidation(madCode: string): ResponseValidation {
    this.madCode = madCode.trim();
    const validationAccount = CustomerAccountService.validateMadCode(this.madCode);
    if (validationAccount.getIsValid()) {
      validationAccount.setResponse(
        this.getAccountDetails(this.madCode).pipe(
          map((getCustomerResp: GetCustomerResp) => this.buildAcctIdFromGetCustomerResp(getCustomerResp))
        )
      );
    }
    return validationAccount;
  }

  /**
   *
   * @param getCustomerResp
   */
  private buildAcctIdFromGetCustomerResp(getCustomerResp: GetCustomerResp): AcctId {
    const customerInfo: CustomerLocationFunction = this.findAccountInfoFromGetCustomerResp(getCustomerResp);
    const acctInfo: AcctId = new AcctId();
    if (customerInfo) {
      acctInfo.acctInstId = customerInfo.legacyCisCustomerNbr?.toString();
      acctInfo.acctMadCd = customerInfo.madCd;
      acctInfo.acctName = getCustomerResp.customerLocation?.party1?.partyName;
      acctInfo.acctPartyRole = customerInfo.functionCd;
      acctInfo.creditStatCd = customerInfo.creditStatusCd;
      acctInfo.nationalAccountInd = customerInfo.acctTypeCd === CustomerAccountTypeCd.NATIONAL_ACCOUNT;
    } else {
      throw new InspectionError('Customer not found from madCd into API response');
    }

    return acctInfo;
  }

  private findAccountInfoFromGetCustomerResp(getCustomerResp: GetCustomerResp): CustomerLocationFunction {
    return getCustomerResp?.customerLocation?.customerLocationFunction?.find(
      (customer: CustomerLocationFunction) => customer.madCd === this.madCode
    );
  }
}
