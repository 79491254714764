<xpo-button-group>
  <div class="save-cancel">
    <button
      [disabled]="!(inspectionCorrectionService.isSaveCancelPreviewEnabled$ | async)"
      (click)="onSaveClicked()"
      mat-stroked-button
      xpoSmallButton
      xpoButton
      color="primary"
    >
      Save
    </button>
    <xpo-icon iconName="info" class="info_icon"></xpo-icon>
    <button
      [disabled]="!(inspectionCorrectionService.isSaveCancelPreviewEnabled$ | async)"
      (click)="onCancelClicked()"
      mat-stroked-button
      xpoSmallButton
      xpoButton
      color="primary"
    >
      Cancel
    </button>
    <xpo-icon iconName="info" class="info_icon"></xpo-icon>
  </div>
  <div class="preview">
    <button
      [disabled]="!((inspectionCorrectionService.isSaveCancelPreviewEnabled$ | async) && isBillStatusRated)"
      (click)="onAutoRatePreviewClicked()"
      mat-stroked-button
      xpoSmallButton
      xpoButton
      color="primary"
    >
      Preview Auto Rate
    </button>
    <xpo-icon iconName="info" class="info_icon"></xpo-icon>
  </div>

  <div class="submit">
    <p class="submit-confirmation-message">
      By submitting this correction, I confirm that all required documents will be placed in image and that the
      correction is valid.
    </p>
    <button
      [disabled]="!(inspectionCorrectionService.isSubmitEnabled$ | async)"
      (click)="onSubmitClicked()"
      mat-flat-button
      xpoSmallButton
      xpoButton
      color="primary"
    >
      Submit
    </button>
  </div>
</xpo-button-group>
