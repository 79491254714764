import { InspectionState, toInspectionStateDisplayName } from './inspection-state.enum';

export enum ConfirmMessage {
  INSPECTION_SUBMIT_MESSAGE = 'Are you sure you want to submit this Inspection?',
  INSPECTED_NOT_CORRECTED = 'Are you sure you want to submit as Inspected Not Corrected?',
  INSPECTION_SUBMIT_ERROR = 'Error Inspecting Shipment! Please try again.',
  INSPECTED_CORRECTED = 'Are you sure you want to submit as Corrected?',
  INSPECTION_WILL_BE_DELETED_WARNING = 'This Shipment has an inspection {inEditOrProgress}. Any dimensions, notes and photos not submitted from the inspection page will be deleted.<br><br>Are you sure you want to continue?',
  SHIPMENTS_NOT_MOVED_WARNING = 'Shipment could not be moved from {fromState} to {toState}.',
  SHIPMENTS_ALREADY_IN_STATUS_WARNING = 'Shipment already in {state} status.',
  INSPECTION_ALREADY_SUBMITTED = 'Inspection has already been submitted. Please complete existing Inspection.',
  INSPECTION_SESSION_LOGOUT_MESSAGE = 'You are about to log off from Inspections application.<br><br>Do you wish to continue?',
  INSPECTION_SESSION_LOGOUT_PHOTO_UPLOAD_INPROGRESS_WARNING = 'You are about to log off from the Inspections application and you have photos pending upload.' +
    '<br><br>If you log off the upload process will stop and any remaining photos will not be uploaded until you log back into the application.' +
    '<br><br>Do you wish to continue?'
}

export function buildNotSubmittingInspectionWarningMessage(currentState: string | InspectionState): string {
  switch (currentState) {
    case InspectionState.IN_PROGRESS:
    case InspectionState.EDITING:
      return buildInspectionWillBeDeletedWarning(currentState);
    case InspectionState.RECOMMENDED:
    case InspectionState.FLAGGED:
    case InspectionState.INSPECTED:
    case InspectionState.DISMISSED:
      return ConfirmMessage.INSPECTED_NOT_CORRECTED;
    default:
      return '';
  }
}

export function buildInspectionWillBeDeletedWarning(inEditOrProgressState: string | InspectionState): string {
  const warningMessage: string = ConfirmMessage.INSPECTION_WILL_BE_DELETED_WARNING.toString().replace(
    '{inEditOrProgress}',
    toInspectionStateDisplayName(<InspectionState>inEditOrProgressState)
  );
  return warningMessage;
}

export function buildShipmentsNotMovedWarning(
  fromState: string | InspectionState,
  toState: string | InspectionState
): string {
  const warningMessage: string = ConfirmMessage.SHIPMENTS_NOT_MOVED_WARNING.toString()
    .replace('{fromState}', toInspectionStateDisplayName(<InspectionState>fromState))
    .replace('{toState}', toInspectionStateDisplayName(<InspectionState>toState));
  return warningMessage;
}

export function buildShipmentsAlreadyInStatusWarning(currentState: string | InspectionState): string {
  const warningMessage: string = ConfirmMessage.SHIPMENTS_ALREADY_IN_STATUS_WARNING.toString().replace(
    '{state}',
    toInspectionStateDisplayName(<InspectionState>currentState)
  );
  return warningMessage;
}
