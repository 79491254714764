export class InspectionNotificationEvent {
  private _message: string | undefined;
  private _createDate: Date;

  constructor(msg: string | undefined, createDate: Date) {
    this._message = msg;
    this._createDate = createDate;
  }

  get message(): string {
    return this._message;
  }

  get createDate(): Date {
    return this._createDate;
  }

  logToConsole() {
    console.log('InspectionNotificationEvent->date, message:', this._createDate, this.message);
  }
}
