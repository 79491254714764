import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { GetInspectionStatusResp, UpdateInspectionStatusResp } from '@xpo-ltl/sdk-inspections';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ProNumber } from '../classes/pronumber';
import { InspectionState } from '../enums/inspection-state.enum';
import { DialogWrapperService } from '../services/dialog-wrapper.service';
import { ShipmentDetailsService } from '../services/api/shipment-details.service';
import { InspectionShipmentDetailsService } from '../services/api/inspection-shipment-details.service';

//todo: pg you have to replace me... and remove unnecessary services
@Injectable()
export class ChangeInspectionStatusGuard {
  constructor(
    private shipmentDetailsService: ShipmentDetailsService,
    private dialog: DialogWrapperService,
    private inspectionShipmentDetailsService: InspectionShipmentDetailsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const pro: ProNumber = new ProNumber(route.params['pro']);

    if (!pro) {
      return false;
    }

    return this.shipmentDetailsService.getInspectionStatus(pro).pipe(
      take(1),
      switchMap((statusResp: GetInspectionStatusResp) => {
        if (!statusResp) {
          return of(false);
        }
        if (
          statusResp.inspectionStatusMessage === InspectionState.IN_PROGRESS ||
          statusResp.inspectionStatusMessage === InspectionState.EDITING
        ) {
          return of(true);
        }
        return this.shipmentDetailsService.updateInspectionStatus(pro, InspectionState.IN_PROGRESS).pipe(
          take(1),
          switchMap((changeStatusResp: UpdateInspectionStatusResp) => {
            let validChangeStatus = false;
            if (changeStatusResp) {
              if (
                (!changeStatusResp.validationErrors || changeStatusResp.validationErrors.length === 0) &&
                changeStatusResp.messageValidations &&
                changeStatusResp.messageValidations.length > 0
              ) {
                this.inspectionShipmentDetailsService.inspectionStateSubject.next(InspectionState.IN_PROGRESS);
                validChangeStatus = true;
              }

              if (validChangeStatus) {
                return of(true); // the only valid case
              } else {
                if (changeStatusResp.validationErrors) {
                  this.dialog.showStatusChangeDialog(changeStatusResp);
                }
                return of(false);
              }
            }
          })
        );
      })
    );
  }
}
