import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { GridOptions } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { CustomerGuidelineWithAcct } from '../../../classes/customer-guideline-with-acct';
import { ApiError } from '../../../classes/error/api/api-error';
import { AgGridHelper } from '../../../classes/helpers/ag-grid-helper';
import { ComparatorUtils } from '../../../classes/utils/comparator-utils';
import { CustomerGuidelineDialogComponent } from '../../../dialogs/customer-guideline-dialog/customer-guideline-dialog.component';
import { DialogAction } from '../../../enums/dialog-actions.enum';
import { ListName } from '../../../enums/list-name.enum';
import { AppConstantsService } from '../../../services/app-constants.service';
import { GridSettingsService } from '../../../services/grid-settings/grid-settings.service';
import { HardwareService } from '../../../services/hardware/hardware-service';
import { RefreshDatesService } from '../../../services/refresh-dates.service';
import { SnackBarHandlingService } from '../../../services/snack-bar-handling.service';
import { LoadingOverlayService } from '../../loading-overlay/service/loading-overlay.service';
import { CustomerGuidelinesService } from '../services/customer-guidelines.service';
import { ErrorMessageActions } from '../../../enums/error-message-actions.enum';

@Component({
  selector: 'ig-list-customer-guidelines',
  templateUrl: './list-customer-guidelines.component.html',
  styleUrls: ['./list-customer-guidelines.component.scss'],
  host: { class: 'ig-customerGuidelines' },
  encapsulation: ViewEncapsulation.None
})
export class ListCustomerGuidelinesComponent implements OnInit, OnDestroy {
  public gridOptions: GridOptions;
  public rowData = [];
  public totalCount = 0;
  height: any;
  width: any;

  @Output()
  selectedCountChanged = new EventEmitter<number>();

  @Output()
  listChanged = new EventEmitter<boolean>();

  @Output()
  gridSettingsChanged = new EventEmitter<boolean>();

  private lastRefreshDateSubject = new BehaviorSubject(<string>'');
  public lastRefreshDate$ = this.lastRefreshDateSubject.asObservable();

  constructor(
    private customerGuidelinesService: CustomerGuidelinesService,
    private appConstants: AppConstantsService,
    private gridSettings: GridSettingsService,
    private dialog: MatDialog,
    public refreshDatesService: RefreshDatesService,
    private loadingOverlayService: LoadingOverlayService,
    private snackBarHandlingService: SnackBarHandlingService
  ) {
    this.gridOptions = <GridOptions>{};

    this.gridOptions.defaultColDef = AgGridHelper.getDefaultColDef();

    this.gridOptions.columnDefs = this.gridSettings.buildDefaultCustomerGuidelinesListColumnDefs();
    this.gridOptions.rowSelection = 'single';
    this.gridOptions.suppressMenuHide = HardwareService.isTouchScreenCapable();
    this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.gridOptions.onCellDoubleClicked = this.onCellDoubleClicked.bind(this);
    this.gridOptions.onColumnMoved = this.columnsChanged.bind(this);
    this.gridOptions.onColumnPinned = this.columnsChanged.bind(this);
    this.gridOptions.onColumnResized = this.columnsChanged.bind(this);
    this.gridOptions.onFilterChanged = this.columnsChanged.bind(this);
    this.gridOptions.onColumnVisible = this.columnsChanged.bind(this);
    this.gridOptions.onSortChanged = this.columnsChanged.bind(this);
    this.gridOptions.onRowDataChanged = this.rowDataChanged.bind(this);
    this.gridOptions.icons = GridSettingsService.buildGridIcons();
    this.gridOptions.context = this;
    this.gridOptions.enableCellTextSelection = true;
    this.gridOptions.ensureDomOrder = true;
    this.gridOptions.onGridReady = () => {
      this.setHeightAndWidth();
    };
  }

  public ngOnInit() {
    this.setCustomerGuidelines();
  }

  public setCustomerGuidelines(): void {
    this.loadingOverlayService.setIsLoading(true);
    this.customerGuidelinesService
      .getCustomerGuidelines()
      .pipe(
        take(1),
        catchError((error) => {
          this.loadingOverlayService.setIsLoading(false);
          this.snackBarHandlingService.handleResponseError(error, ErrorMessageActions.GETTING, `Customer Guidelines`);
          throw new ApiError(`Error ${ErrorMessageActions.GETTING} Customer Guidelines`, error);
        })
      )
      .subscribe((customerGuidelines: CustomerGuidelineWithAcct[]) => {
        this.loadingOverlayService.setIsLoading(false);
        customerGuidelines.sort((guideline1, guideline2) => {
          return ComparatorUtils.madStringCompare(guideline1?.acctMadCd, guideline2?.acctMadCd);
        });
        this.rowData = customerGuidelines;
        this.totalCount = customerGuidelines.length;
        this.refreshDatesService.setCustomerGuidelinesListRefreshDate(new Date());
      });
  }

  public ngOnDestroy() {}

  private rowDataChanged($event) {
    this.gridSettings.restoreGridState(ListName.CUSTOMER_GUIDELINES, this.gridOptions);
    this.setHeightAndWidth();
  }

  private columnsChanged($event) {
    this.gridSettings.storeGridState(ListName.CUSTOMER_GUIDELINES, this.gridOptions);
    this.gridSettingsChanged.emit(true);
  }

  onResize($event) {
    this.setHeightAndWidth();
  }

  setHeightAndWidth() {
    this.width = document.getElementsByClassName('app-container-content')[0].clientWidth;
    this.height = document.getElementsByClassName('app-container-content')[0].clientHeight - 50;
  }

  private onSelectionChanged() {
    if (this.gridOptions.api) {
      const selectedRows = this.gridOptions.api.getSelectedRows();
      if (selectedRows) {
        this.selectedCountChanged.emit(selectedRows.length);
      }
    }
  }

  public clearList() {
    this.rowData = [];
    this.totalCount = 0;
  }

  private openCustomerGuidelineDialog(data: Object) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.autoFocus = true;
    matDialogConfig.disableClose = false;
    matDialogConfig.minWidth = '60%';
    matDialogConfig.minHeight = '60%';
    matDialogConfig.width = '70%';
    matDialogConfig.data = {
      title: 'Edit Customer Guideline',
      action: DialogAction.EDIT,
      guideline: data
    };
    const matDialogRef = this.dialog.open(CustomerGuidelineDialogComponent, matDialogConfig);
    matDialogRef.afterClosed().subscribe((reload) => {
      if (reload) {
        this.clearList();
        this.setCustomerGuidelines();
      }
      this.customerGuidelinesService.clear();
    });

    matDialogRef.backdropClick().subscribe(() => {
      this.customerGuidelinesService.clear();
    });
  }

  private onCellDoubleClicked($event) {
    this.unselectAllRows();
    this.openCustomerGuidelineDialog($event.data);
  }

  private unselectAllRows() {
    if (this.gridOptions.api) {
      this.gridOptions.api.deselectAll();
      this.onSelectionChanged();
    }
  }

  public onShowMoreClickedFromGuidelineCell(rowNumber: number): void {
    this.unselectAllRows();
    this.openCustomerGuidelineDialog(this.rowData[rowNumber]);
  }
}
