import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { InspectionShipmentDetails } from '../../../../classes/inspection-shipment-details';
import { NameAddressSection } from '../../../../enums/name-address-section.enum';

@Component({
  selector: 'app-bill-details',
  templateUrl: './bill-details.component.html',
  styleUrls: ['./bill-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillDetailsComponent implements OnChanges {
  constructor() {}

  @Input()
  shipmentDetails: InspectionShipmentDetails;

  NameAddressSection = NameAddressSection;

  shipperSectionExpanded = false;
  consigneeSectionExpanded = false;
  billtoSectionExpanded = false;

  isExpanded = false;

  ngOnChanges() {
    this.shipperSectionExpanded = false;
    this.consigneeSectionExpanded = false;
    this.billtoSectionExpanded = false;
    this.isExpanded = false;
  }

  expandCollapse(section: NameAddressSection) {
    if (section === NameAddressSection.SHIPPER) {
      this.shipperSectionExpanded = !this.shipperSectionExpanded;
    } else if (section === NameAddressSection.CONSIGNEE) {
      this.consigneeSectionExpanded = !this.consigneeSectionExpanded;
    } else {
      this.billtoSectionExpanded = !this.billtoSectionExpanded;
    }
  }
}
