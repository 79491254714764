import { Injectable } from '@angular/core';
import { ShiftCd } from '@xpo-ltl/sdk-common';
import {
  GetInspectionShipmentDetailsPath,
  GetInspectionShipmentDetailsResp,
  InspectionsApiService
} from '@xpo-ltl/sdk-inspections';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, distinctUntilChanged } from 'rxjs/operators';
import { ProNumber } from '../../classes/pronumber';
import { ApiUtil } from '../../classes/utils/api-util';
import { LoadingOverlayService } from '../../components/loading-overlay/service/loading-overlay.service';
import { ErrorMessageActions } from '../../enums/error-message-actions.enum';
import { InspectionState } from '../../enums/inspection-state.enum';
import { RetryStrategyService } from '../../operators/retry-strategy.service';
import { RequestValidator } from '../../validators/request.validator';
import { AppConstantsService } from '../app-constants.service';
import { AppNavigationService } from '../app-navigation.service';
import { SnackBarHandlingService } from '../snack-bar-handling.service';
import { ShipmentDetailsService } from './shipment-details.service';

@Injectable({
  providedIn: 'root'
})
export class InspectionShipmentDetailsService {
  inspectionShipmentDetailsSubject: BehaviorSubject<GetInspectionShipmentDetailsResp> =
    new BehaviorSubject<GetInspectionShipmentDetailsResp>(undefined);
  protected lastInspectionShipmentDetailsProNumber: ProNumber;
  inspectionShipmentDetails$: Observable<GetInspectionShipmentDetailsResp> =
    this.inspectionShipmentDetailsSubject.asObservable();

  get inspectionShipmentDetails(): GetInspectionShipmentDetailsResp {
    return this.inspectionShipmentDetailsSubject.value;
  }

  inspectionStateSubject: BehaviorSubject<InspectionState> = new BehaviorSubject<InspectionState>(
    InspectionState.NO_STATUS
  );

  parentProNumberSubject: BehaviorSubject<ProNumber> = new BehaviorSubject<ProNumber>(undefined);

  constructor(
    private appConstantsService: AppConstantsService,
    private inspectionsApiService: InspectionsApiService,
    private retryStrategyService: RetryStrategyService,
    private appNavigationService: AppNavigationService,
    private snackBarHandlingService: SnackBarHandlingService,
    private loadingOverlayService: LoadingOverlayService
  ) {}

  setInspectionShipmentDetails(getInspectionShipmentDetailsResp: GetInspectionShipmentDetailsResp) {
    if (getInspectionShipmentDetailsResp?.shipmentDetails?.shipmentId?.proNumber) {
      this.parentProNumberSubject.next(
        new ProNumber(getInspectionShipmentDetailsResp.shipmentDetails.shipmentId.proNumber)
      );
    } else {
      // this should not happen, but in case if its happened, we display snackbar with error message.
      const errorMessage: string = this.snackBarHandlingService.buildDisplayErrorMessage(
        ErrorMessageActions.GETTING,
        `PRO`
      );
      this.snackBarHandlingService.showErrorMessage(errorMessage);
      this.parentProNumberSubject.next(undefined);
    }
    if (getInspectionShipmentDetailsResp?.shipmentDetails) {
      this.inspectionStateSubject.next(
        <InspectionState>getInspectionShipmentDetailsResp.shipmentDetails.inspectionStatusCd
      );
    } else {
      this.inspectionStateSubject.next(InspectionState.NO_STATUS);
    }

    this.inspectionShipmentDetailsSubject.next(getInspectionShipmentDetailsResp);
  }

  getInspectionShipmentDetails(proNumber: ProNumber): Observable<GetInspectionShipmentDetailsResp> {
    RequestValidator.validateStringNotNullOrEmpty(
      this.appConstantsService?.inspectionContext?.inspectionSic,
      'Inspection Sic'
    );
    RequestValidator.validateObjectNotUndefinedOrEmpty(proNumber, RequestValidator.PRO_NBR);
    RequestValidator.validateProNumber(proNumber);
    RequestValidator.validateStringNotNullOrEmpty(this.appConstantsService?.inspectionContext?.shiftCd, 'Shift Code');

    const pathParam: GetInspectionShipmentDetailsPath = new GetInspectionShipmentDetailsPath();
    pathParam.inspectionSic = this.appConstantsService.inspectionContext?.inspectionSic;
    pathParam.proNumber = proNumber.formatProNumber();
    pathParam.shiftCd = this.appConstantsService.inspectionContext?.shiftCd as ShiftCd;

    return this.inspectionsApiService.getInspectionShipmentDetails(pathParam, ApiUtil.DEFAULT_DATA_OPTIONS).pipe(
      this.retryStrategyService.retryStrategy(
        ShipmentDetailsService.DEFAULT_GET_TIMEOUT,
        ShipmentDetailsService.DEFAULT_RETRY_DELAY,
        ShipmentDetailsService.DEFAULT_RETRY_COUNT,
        ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
        'GET-INSPECTION-SHIPMENT-DETAILS'
      ),
      distinctUntilChanged(),
      catchError((error) => {
        this.loadingOverlayService.setIsLoading(false);
        console.error('error while getting shipmentDetails for pro:' + proNumber.formatProNumber(), error);
        this.lastInspectionShipmentDetailsProNumber = undefined;
        this.parentProNumberSubject.next(undefined);
        this.inspectionStateSubject.next(undefined);
        this.inspectionShipmentDetailsSubject.next(undefined);
        const useMoreInfoMessage: boolean = true;
        // We need to navigate on an error back to the list page, in case we started from the shipment details already
        this.appNavigationService.navigateToList(); // Navigate back to list

        if (error.code === '500') {
          this.snackBarHandlingService.handleResponseError(
            error,
            ErrorMessageActions.GETTING,
            `shipment details`,
            useMoreInfoMessage
          );
        } else {
          this.snackBarHandlingService.handleResponseError(
            error,
            ErrorMessageActions.GETTING,
            `shipment details`,
            !useMoreInfoMessage
          );
        }

        return EMPTY;
      })
    );
  }

  lastInspectionShipmentDetailsProIs(proNumber: ProNumber): boolean {
    return proNumber?.formatProNumber() === this.lastInspectionShipmentDetailsProNumber?.formatProNumber();
  }
}
