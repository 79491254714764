import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

import { RouterUriComponents } from '../../enums/router-uri-components.enum';

@Component({
  selector: 'app-calculators-menu',
  templateUrl: './tools-menu.component.html',
  styleUrls: ['./tools-menu.component.scss']
})
export class ToolsMenuComponent implements OnInit {
  private static readonly toolsTitle = 'TOOLS';
  public readonly item680Note2bTitle = 'Item 680 Note 2b Calculator';
  public title: string;
  public pro: string;

  constructor(
    private dialogRef: MatDialogRef<ToolsMenuComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = ToolsMenuComponent.toolsTitle;

    // We get the pro if exists
    if (data && data.pro) {
      this.pro = data.pro;
    }
  }

  ngOnInit() {}

  public showCalculator(calculator: string) {
    if (calculator === `item6802B`) {
      // If we have a pro we send is as a parameter to the calculator
      if (this.pro) {
        this.router.navigate([RouterUriComponents.ITEM_680_2B_PAGE, this.pro]);
      } else {
        this.router.navigate([RouterUriComponents.ITEM_680_2B_PAGE]);
      }

      this.close();
    }
  }

  public close(): void {
    this.dialogRef.close(undefined);
  }

  public getMatDialogConfig() {
    // LEI-376: disabled until it can be updated to match the revised rule, was used in ins-context-header.component
    // public openCalculatorsMenu() {
    //   const matDialogConfig = new MatDialogConfig();
    //   matDialogConfig.autoFocus = true;
    //   matDialogConfig.disableClose = false;
    //   matDialogConfig.minWidth = '60%';
    //   matDialogConfig.minHeight = '60%';
    //   matDialogConfig.width = '80%';
    //   matDialogConfig.height = '80%';
    //   matDialogConfig.data = {
    //     pro: this.toolsMenuPro
    //   };
    //   this.dialog.open(ToolsMenuComponent, matDialogConfig);
    // }
  }
}
