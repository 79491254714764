import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ShiftDisplayEnumStringPipe } from './app.shift-code.pipe';
import { ChildProPipe } from './childPro.pipe';
import { CommodityClassPipe } from './commodity-class-pipe';
import { DateMilitaryFormatPipe } from './date-military-format.pipe';
import { DialogActionPipe } from './dialogAction.pipe';
import { FirstInitialLastNamePipe } from './firstInitial-lastName.pipe';
import { ImageTypePipe } from './image-type.pipe';
import { InspectionStatusCodePipe } from './inspection-status-code.pipe';
import { InvertedYesNoPipe } from './inverted-yes-no.pipe';
import { NameAddressPipe } from './name-address.pipe';
import { TimeFormatPipe } from './timeFormat.pipe';
import { ToFormattedDensityPipe } from './to-formatted-density.pipe';
import { ToFormattedVolumePipe } from './to-formatted-volume.pipe';
import { ToMessageFromCorrectionStatus } from './toMessageFromCorrectionStatus.pipe';
import { SpacedStringFromTitleCasePipe } from './toSpacedString.pipe';
import { ToTooltipMessageFromCorrectionStatus } from './toTooltipMessageFromCorrectionStatus.pipe';
import { ToUsCurrency } from './toUsCurrency-pipe';
import { YesNoPipe } from './yes-no.pipe';

const pipes = [
  ShiftDisplayEnumStringPipe,
  YesNoPipe,
  NameAddressPipe,
  InspectionStatusCodePipe,
  ImageTypePipe,
  InvertedYesNoPipe,
  ChildProPipe,
  TimeFormatPipe,
  DialogActionPipe,
  ToMessageFromCorrectionStatus,
  ToTooltipMessageFromCorrectionStatus,
  FirstInitialLastNamePipe,
  SpacedStringFromTitleCasePipe,
  DateMilitaryFormatPipe,
  ToUsCurrency,
  CommodityClassPipe,
  ToFormattedDensityPipe,
  ToFormattedVolumePipe
];

@NgModule({
  imports: [CommonModule],
  declarations: pipes,
  providers: pipes,
  exports: pipes
})
export class XpoPipesModule {}
