import {PhotoEvent} from "./photo-event";
import {Photo} from "../photo";

export class UploadedToDmsPhotoEvent extends PhotoEvent{

  constructor(msg: String, photo: Photo, createdDate: Date) {
    super(msg, photo, createdDate);
  }

  getEventName(): string {
    return 'Uploaded to DMS'
  }


}
