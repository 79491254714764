<div class="listInspectionCorrections_container">
  <app-list-header
    [title]="title"
    [isAddEnabled]="isAddEnabled$ | async"
    [isDateRangeDisplayed]="true"
    [addingObjectName]="addingObjectName"
    [refreshTime]="refreshDatesService.inspectionCorrectionListRefreshTimestamp.stringValue$ | async"
    (refreshTriggered)="onRefreshTriggered()"
    (gridFilterCleared)="onGridFilterCleared()"
    (gridSortCleared)="onGridSortCleared()"
    (gridSettingsSaved)="onGridSettingsSaved()"
    (gridSettingsLoaded)="onGridSettingsLoaded()"
    (gridSettingsReset)="onGridSettingsReset()"
    (formGroupValueChanged)="onHeaderFormGroupValueChanged($event)"
  ></app-list-header>

  <app-list-corrections (listChanged)="onRefreshTriggered()" (gridSettingsChanged)="gridSettingsChanged()">
  </app-list-corrections>
</div>
