import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { ListName } from '../enums/list-name.enum';
import { RouterUriComponents } from '../enums/router-uri-components.enum';
import { RequestValidator } from '../validators/request.validator';
import { InspectionLocalStorageService } from './inspection-local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AppRouteHistoryService {
  private static HISTORY_LIMIT = 20;
  private static NOT_SAVING_HISTORY_LIST = [
    'auth',
    ListName.RECOMMENDED,
    ListName.FLAGGED,
    ListName.INSPECTED,
    ListName.COMPLETED,
    ListName.DISMISSED,
    RouterUriComponents.INSPECT_SHIPMENT_PAGE,
    RouterUriComponents.RECOMMENDATION_RULES,
    RouterUriComponents.PICKUP_REQUESTS,
    RouterUriComponents.CUSTOMER_GUIDELINES,
    RouterUriComponents.BROADCAST_MESSAGES,
    RouterUriComponents.AUTO_RATED_PREVIEW
  ];
  private static LAST_VISITED_LIST_CANDIDATES: ListName[] = [
    ListName.RECOMMENDED,
    ListName.FLAGGED,
    ListName.INSPECTED,
    ListName.COMPLETED,
    ListName.DISMISSED
  ];

  private validate_fieldName_url: string = 'url';

  constructor(private router: Router, private localStorageService: InspectionLocalStorageService) {
    this.localStorageService.setLastVisitedList(this.getLastVisitedList());
  }

  listenRoutingEvt(): void {
    this.router.events
      .pipe(
        distinctUntilChanged(),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(({ url }: NavigationEnd) => {
        this.storeOrResetRoutingHistory(url);
        this.updateLastVisitedShipmentList(url);
      });
  }

  getLastVisitedList(): ListName {
    return this.localStorageService.getLastVisitedList();
  }

  setLastVisitedList(listName: ListName): void {
    this.localStorageService.setLastVisitedList(listName);
  }

  getPrevUrl(): string {
    const histories: string[] = this.localStorageService.getRoutingHistory();

    return histories?.[histories?.length - 2]; // the last history is the current page
  }

  getRouteHistory(): string[] {
    return this.localStorageService.getRoutingHistory();
  }

  popPrevUrl(): string {
    const histories: string[] = this.localStorageService.getRoutingHistory();

    histories.pop(); // the last history is the current page so we can discard it
    const prevUrl: string = histories.pop();
    this.localStorageService.setRoutingHistory(histories);

    return prevUrl;
  }

  resetRouteHistory(): void {
    this.localStorageService.setRoutingHistory([]);
  }

  /**
   * store the url if the history array is less than maximum length
   * if the array is maximum length, then remove the oldest url
   * @param url
   */
  private addNavigatedHistory(url: string): void {
    const histories: string[] = this.localStorageService.getRoutingHistory();

    if (histories.length === AppRouteHistoryService.HISTORY_LIMIT) {
      histories.shift();
    }
    histories.push(url);
    this.localStorageService.setRoutingHistory(histories);
  }

  /**
   * handle url to store or reset the history
   * @param url
   */
  private storeOrResetRoutingHistory(url: string): void {
    RequestValidator.validateStringNotNullOrEmpty(url, this.validate_fieldName_url);
    url[0] === '/' ? (url = url.substring(1)) : (url = url); // remove first '/' if its included so we can call Router.navigate([])
    const histories: string[] = this.localStorageService.getRoutingHistory();

    if (!AppRouteHistoryService.NOT_SAVING_HISTORY_LIST.some((item) => url.includes(item.toString()))) {
      if (url === RouterUriComponents.LIST_SHIPMENTS) {
        // if url is 'list-shipments', then reset the history
        this.resetRouteHistory();
      } else if (
        histories?.[histories?.length - 1]?.startsWith(RouterUriComponents.INSPECT_SHIPMENT_PAGE) &&
        histories?.length >= 2 &&
        histories?.[histories?.length - 2] === url
      ) {
        // if the current page is inspect-shipment/ page and url is same as the previous page, this event came from back arrow button. pop the history
        histories.pop();
        this.localStorageService.setRoutingHistory(histories);
      } else if (histories?.[histories?.length - 1] !== url) {
        // if url is with PRO such as 'list-shipments/123-456789' and not the same as the last history, then add to the history
        // its possible to get the same url as the last history if user refresh the app
        this.addNavigatedHistory(url);
      }
    } else {
      // if url is in NOT_SAVING_HISTORY_LIST, reset the history
      this.resetRouteHistory();
    }
  }

  /**
   * store last visited shipment list so when inspector can go back to the last visited list
   * when they click 'BACK TO LAST VISITED LIST' button
   * @param url
   */
  private updateLastVisitedShipmentList(url: string): void {
    RequestValidator.validateStringNotNullOrEmpty(url, this.validate_fieldName_url);

    if (AppRouteHistoryService.LAST_VISITED_LIST_CANDIDATES.some((item: ListName) => url.includes(item.toString()))) {
      const splittedCurrentUrl: string[] = url.split('/');
      const currentList: ListName = splittedCurrentUrl[splittedCurrentUrl.length - 1] as ListName;

      if (this.getLastVisitedList() !== currentList) {
        this.setLastVisitedList(currentList);
      } else {
        // no need to update since current and last visited lists are the same
      }
    }
  }
}
