import { Component, OnInit } from '@angular/core';
import {
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

import { GeneralPreferences } from '../../classes/general-preferences';
import { ZoomLevel } from '../../enums/zoom-level.enum';
import { UserPreferencesService } from '../../services/user-preferences.service';
import { MobileWebBrowserService } from '../../services/hardware/mobile-web-browser-service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  public zoomLevel: ZoomLevel;
  public title: string;
  public zoomLevelList: Array<ZoomLevel>;
  public generalPreferences: GeneralPreferences;

  constructor(
    private dialogRef: MatDialogRef<SettingsComponent>,
    private mobileWebBrowserService: MobileWebBrowserService,
    private userPreferenceService: UserPreferencesService
  ) {}

  ngOnInit() {
    this.title = 'Settings';

    // We get the values of the enum to display the list in the view
    this.zoomLevelList = Object.keys(ZoomLevel).map((key) => ZoomLevel[key]);

    // We get the current user preferences
    this.generalPreferences = this.getUserPreferences();

    // We set the current zoomlevel value
    this.zoomLevel = this.generalPreferences.getGetacZoomLevel();
  }

  public submit(): void {
    if (this.zoomLevel) {
      // We set the value for the zoomLevel
      this.generalPreferences.setGetacZoomLevel(this.zoomLevel);

      // Then we save user preferences
      this.userPreferenceService.saveGeneralUserPreferences(this.generalPreferences);

      // Finally, we apply the setings
      this.mobileWebBrowserService.setZoomLevel(GeneralPreferences.getZoomLevelValue(this.zoomLevel));
      this.close();
    }
  }

  private getUserPreferences(): GeneralPreferences {
    // We get the current user preferences from the service
    let generalPreferences = this.userPreferenceService.getGeneralPreferences();

    // If there are no preferences for the user we create a new object
    if (!generalPreferences) {
      generalPreferences = new GeneralPreferences();
    }

    return generalPreferences;
  }

  public close(): void {
    this.dialogRef.close(undefined);
  }

  static getMatDialogConfig(): MatDialogConfig {
    const result: MatDialogConfig = new MatDialogConfig();
    result.autoFocus = true;
    result.disableClose = false;
    result.minWidth = '40vw';
    result.minHeight = '40vh';
    result.width = '50vw';

    return result;
  }
}
