import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GetInspectionForCorrectionRequestResp, GetInspectionShipmentDetailsResp } from '@xpo-ltl/sdk-inspections';
import { List } from 'immutable';
import { BehaviorSubject, Observable, startWith, Subject, switchMap, take, takeUntil } from 'rxjs';
import { ConfirmCancelData } from '../../../classes/confirm-cancel-data';
import { Photo } from '../../../classes/photos/photo';
import { PhotoId } from '../../../classes/photos/photo-id';
import { ProNumber } from '../../../classes/pronumber';
import { ConfirmCancelComponent } from '../../../dialogs/confirm-cancel/confirm-cancel.component';
import { MobileWebBrowserService } from '../../../services/hardware/mobile-web-browser-service';
import { PhotoDownloadService } from '../../../services/photos/photo-download.service';
import { SnackBarHandlingService } from '../../../services/snack-bar-handling.service';
import { RequestValidator } from '../../../validators/request.validator';
import { ImageGalleryComponent } from '../../inspect-shipment/components/photos/photos.component';

@Component({
  selector: 'app-inspection-info',
  templateUrl: './inspection-info.component.html',
  styleUrls: ['./inspection-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class InspectionInfoComponent implements OnChanges, OnDestroy {
  @HostBinding('class') hostClass = 'ins-correction-inspection-info';

  @Input()
  formattedProNumberString: string;
  @Input()
  inspectionForCorrectionRequestResp: GetInspectionForCorrectionRequestResp;
  @Input()
  shipmentDetails: GetInspectionShipmentDetailsResp;
  @Input()
  isReadOnlyView: boolean;

  @Output()
  onUpdateButtonState = new EventEmitter<boolean>();

  website: string;
  originalBolInfo: string;
  requesterComment: string;
  inspectionInfoFormGroup: FormGroup;
  maxChars = 4000;
  inspectionPhotosFromDmsSubject$: BehaviorSubject<List<Photo>>;

  private isInspectionInfoFormControlInitializedSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    undefined
  );
  isInspectionInfoFormControlInitialized$: Observable<boolean> =
    this.isInspectionInfoFormControlInitializedSubject$.asObservable();

  private newPhotoCountSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  newPhotoCount$: Observable<number> = this.newPhotoCountSubject$.asObservable();

  private newPhotosSubject$: BehaviorSubject<Photo[]> = new BehaviorSubject<Photo[]>([]);
  newPhotos$: Observable<Photo[]> = this.newPhotosSubject$.asObservable();

  private readonly PHOTO_LIMIT_EXCEEDED_MESSAGE: string = `You have reached photo limit. Only ${ImageGalleryComponent.PHOTO_NUMBER_LIMIT} photos per inspection are allowed.`;
  private unsubscriber$: Subject<void> = new Subject();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public mobileWebBrowserService: MobileWebBrowserService,
    private matDialog: MatDialog,
    private photoDownloadService: PhotoDownloadService,
    private snackBarHandlingService: SnackBarHandlingService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.inspectionForCorrectionRequestResp?.currentValue) {
      this.getInspectionInfoData();
      this.updateNewPhotosSubject(new ProNumber(this.formattedProNumberString));
    }
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  getFormGroup() {
    return this.inspectionInfoFormGroup;
  }

  onBolChanged() {
    this.emitUpdateButtonStateForInspectionInfo();
  }

  onCommentChanged() {
    this.emitUpdateButtonStateForInspectionInfo();
  }

  onDeleteClicked(photo: Photo): void {
    const data = new ConfirmCancelData('Confirm Action', 'Delete Photo?', 'No', 'Yes');
    const dialogRef = this.matDialog.open(ConfirmCancelComponent, {
      data: data
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((resp) => {
        if (resp) {
          this.mobileWebBrowserService
            .deletePhoto(photo.id)
            .pipe(take(1))
            .subscribe(() => {
              this.mobileWebBrowserService.photosUpdated(photo.id?.proNumber);
            });
        }
      });
  }

  onOpenWebsite() {
    let enteredUrl: string = this.inspectionInfoFormGroup.value.website;
    if (!enteredUrl.match(/^https?:\/\//i)) {
      enteredUrl = '//' + enteredUrl;
    }
    window.open(enteredUrl);
  }

  onPhotoSelectedFromFolder(event): void {
    const files: File[] = Array.from(event.target.files);

    if (
      files.length + this.newPhotosSubject$.value.length + this.inspectionPhotosFromDmsSubject$.value.size >
      ImageGalleryComponent.PHOTO_NUMBER_LIMIT
    ) {
      this.snackBarHandlingService.warning(this.PHOTO_LIMIT_EXCEEDED_MESSAGE);
    } else {
      files.forEach((file) => {
        if (this.isValidFilename(file.name)) {
          const reader = new FileReader();

          reader.onload = () => {
            const filename = file.name;
            const base64 = reader.result.toString();
            this.mobileWebBrowserService
              .addPhoto(new ProNumber(this.formattedProNumberString), base64, filename)
              .pipe(takeUntil(this.unsubscriber$))
              .subscribe();
          };
          reader.readAsDataURL(new Blob([file]));
        } else {
          alert('Invalid filename/format for file: ' + file.name.toString());
        }
      });

      this.inspectionInfoFormGroup.markAsDirty();
    }
  }

  showPhotoGallery(photoId: PhotoId): void {
    this.mobileWebBrowserService
      .showPhotoGallery(new ProNumber(this.formattedProNumberString), photoId)
      .pipe(take(1))
      .subscribe(() => {
        this.updateNewPhotosSubject(new ProNumber(this.formattedProNumberString));
      });
  }

  private emitUpdateButtonStateForInspectionInfo() {
    this.onUpdateButtonState.emit(true);
  }

  private getInspectionInfoData() {
    this.inspectionPhotosFromDmsSubject$ = this.photoDownloadService.inspectionPhotosSubject;
    this.website = this.inspectionForCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequest?.website;
    this.originalBolInfo =
      this.inspectionForCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequest?.originalBolInformation;
    this.requesterComment =
      this.inspectionForCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequest?.requestorComment;
    this.initFormGroup();
  }

  private updateNewPhotosSubject(proNumber: ProNumber) {
    RequestValidator.validateProNumber(proNumber);

    this.mobileWebBrowserService.photosUpdated$
      .pipe(
        startWith(this.mobileWebBrowserService.photosUpdated(proNumber)),
        switchMap(() => {
          return this.mobileWebBrowserService.listThumbnails(proNumber).pipe(take(1));
        })
      )
      .subscribe((photos: Photo[]) => {
        this.newPhotosSubject$.next(photos);
      });
  }

  private initFormGroup() {
    this.inspectionInfoFormGroup = this.formBuilder.group({
      website: this.formBuilder.control(this.website),
      originalBolInfo: this.formBuilder.control(this.originalBolInfo, [Validators.required]),
      requesterComment: this.formBuilder.control(this.requesterComment, [Validators.required])
    });
    this.isInspectionInfoFormControlInitializedSubject$.next(true);
  }

  private isValidFilename(fileName: string): boolean {
    if (!fileName) {
      return false;
    }

    const dotIdx = fileName.lastIndexOf('.');
    if (dotIdx < 0) {
      return false;
    }

    const ext = fileName.substring(dotIdx).toUpperCase();
    if (ext === '.JPG' || ext === '.JPEG' || /*ext === '.BMP' || */ ext === '.PNG' || ext === '.GIF') {
      return true;
    } else {
      return false;
    }
  }
}
