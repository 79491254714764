import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { catchError, takeUntil } from 'rxjs/operators';
import { CustomerGuidelinesFormComponent } from '../../components/customer-guidelines/customer-guidelines-form/customer-guidelines-form.component';
import { CustomerGuidelinesService } from '../../components/customer-guidelines/services/customer-guidelines.service';
import { DialogAction, toDialogActionPastTense } from '../../enums/dialog-actions.enum';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-customer-guideline-dialog',
  templateUrl: './customer-guideline-dialog.component.html',
  styleUrls: ['./customer-guideline-dialog.component.scss']
})
export class CustomerGuidelineDialogComponent implements OnInit, OnDestroy {
  @ViewChild(CustomerGuidelinesFormComponent, { static: false })
  public customerGuidelinesForm: CustomerGuidelinesFormComponent;

  isFormValid: boolean;
  action: DialogAction;
  title: string;
  isManagerActionsAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private unsubscriber$: Subject<void> = new Subject();
  isDeleteButtonDisplayed: boolean;
  isGuidelineFormDisplayed: boolean;
  isWarningMessageDisplayed: boolean;
  errorMessage: string;

  constructor(
    private dialogRef: MatDialogRef<CustomerGuidelineDialogComponent>,
    private customerGuidelinesService: CustomerGuidelinesService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.toggleAsGuidelineForm(true);

    if (data) {
      this.setAction(data.action);
      const customerGuidelineId: number = data.guideline?.customerGuidelineId;
      if (customerGuidelineId) {
        if (this.isManagerActionsAvailable$) {
          this.title = `${data.title} ID: ${customerGuidelineId}`;
        } else {
          this.title = `Customer Guideline ID: ${customerGuidelineId}`;
        }
      } else {
        this.title = `${data.title}`;
      }

      this.customerGuidelinesService.setAction(this.action);
    }

    if (this.action === DialogAction.EDIT) {
      // We copy the guideline object to avoid passing it by reference
      const customerGuideline = JSON.parse(JSON.stringify(data.guideline));

      this.customerGuidelinesService.setCustomerGuideline(customerGuideline);
      this.isDeleteButtonDisplayed = true;
    }
    this.isManagerActionsAvailable$ = this.customerGuidelinesService.isManagerActionsAvailable$;
  }

  ngOnInit() {
    this.customerGuidelinesService.updatedSubject$.subscribe(() => {
      this.isFormValid = this.customerGuidelinesService.isGuidelineValidForUpdate();
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  public onSaveClicked() {
    if (this.customerGuidelinesService.isGuidelineValidForUpdate()) {
      this.toggleAsGuidelineForm(false);
    } else {
      this.errorMessage = 'Guideline is not valid for update';
    }
  }

  public onYesButtonClicked() {
    let customerGuidelineActionResp$: Observable<any>;
    let showMessage = '';
    if (this.customerGuidelinesService.getAction() === DialogAction.EDIT) {
      customerGuidelineActionResp$ = this.customerGuidelinesService.updateCustomerGuideline();
    } else if (this.customerGuidelinesService.getAction() === DialogAction.DELETE) {
      customerGuidelineActionResp$ = this.customerGuidelinesService.deleteCustomerGuideline();
    } else {
      customerGuidelineActionResp$ = this.customerGuidelinesService.createCustomerGuideline();
    }

    showMessage = `The guideline ${toDialogActionPastTense(this.action as DialogAction)} successfully`;
    customerGuidelineActionResp$
      .pipe(
        takeUntil(this.unsubscriber$),
        catchError((error) => {
          console.error('error ' + this.action + 'guideline', this.customerGuidelinesService.getCustomerGuideline());
          this.setDialogToFormDisplayed();
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.snackBar.open(showMessage, 'Close', {
          duration: 3000
        });
        this.close(true);
      });
  }

  public onDeleteClicked() {
    if (this.customerGuidelinesService.isGuidelineValidToDelete()) {
      this.customerGuidelinesService.setAction(DialogAction.DELETE);
      this.toggleAsGuidelineForm(false);
    } else {
      this.errorMessage = 'Guideline is not deletable';
    }
  }

  public setDialogToFormDisplayed() {
    this.toggleAsGuidelineForm(true);
    if (this.customerGuidelinesService.getAction() === DialogAction.DELETE) {
      this.customerGuidelinesService.setAction(DialogAction.EDIT);
    }
  }

  public close(dialogCloseResult: boolean): void {
    this.customerGuidelinesService.clear();
    this.dialogRef.close(dialogCloseResult);
  }

  public onClearFormClicked(): void {
    this.customerGuidelinesService.clearGuideline();
    this.customerGuidelinesForm.madCodeResponseValidation.setIsValid(true);
  }

  public getAction(): string {
    return this.customerGuidelinesService.getAction();
  }

  public getWarningFromAction(): string {
    return `Are you sure you want to ${this.getAction()} this Customer Guideline?`;
  }

  protected toggleAsGuidelineForm(asGuidelineForm: boolean) {
    this.isGuidelineFormDisplayed = asGuidelineForm;
    this.isWarningMessageDisplayed = !asGuidelineForm;
    this.updateDeleteButtonDisplayed();
  }

  private setAction(val: DialogAction) {
    this.action = val;
    this.isDeleteButtonDisplayed = this.customerGuidelinesService.hasGuidelineWithId();
  }

  private updateDeleteButtonDisplayed() {
    this.isDeleteButtonDisplayed =
      this.action === DialogAction.EDIT &&
      this.customerGuidelinesService.hasGuidelineWithId() &&
      this.isGuidelineFormDisplayed;
  }
}
