import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../enums/config-manager-properties.enum';

@Injectable({
  providedIn: 'root'
})
export class AppConfigManagerService {
  private static PREPROD = 'TCTSS';

  constructor(private configManagerService: ConfigManagerService) {}

  getAccessEmployeeIds(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.ACCESS_EMPLOYEE_IDS);
  }

  getAccessJobRoleCodes(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.ACCESS_JOB_ROLE_CODES);
  }

  getAccessRoles(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.ACCESS_ROLES);
  }

  getBuildVersion(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.BUILD_VERSION);
  }

  getEmailSubject(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.FEEDBACK_SUBJECT);
  }

  getLoggedInUser(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.LOGGED_IN_USER_ROOT);
  }

  getRegion(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.REGION);
  }

  getReleaseNotesLink(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.RELEASE_NOTE_LINK);
  }

  getTeamEmail(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.FEEDBACK_TO_ADDRESS);
  }

  isElsEnabled(): boolean {
    return this.configManagerService.getSetting<boolean>(ConfigManagerProperties.IS_ELS_AUTOMATED_CORRECTION_AVAILABLE);
  }

  isProduction(): boolean {
    return this.configManagerService.getSetting<boolean>(ConfigManagerProperties.PRODUCTION);
  }

  isPreProd(): boolean {
    return this.getRegion() === AppConfigManagerService.PREPROD;
  }
}
