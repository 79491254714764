import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { GetInspectionShipmentDetailsResp } from '@xpo-ltl/sdk-inspections';
import { MaterialModule } from '../../material.module';

@Component({
  selector: 'app-customer-instructions',
  imports: [MaterialModule, MatExpansionModule,],
  standalone: true,
  templateUrl: './customer-instructions.component.html',
  styleUrls: ['./customer-instructions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerInstructionsComponent {
  constructor() {}

  @Input()
  inspectionShipmentDetails: GetInspectionShipmentDetailsResp;

  isExpanded: boolean = true;
}
