<div class="recommendationRules_container">
  <app-list-header
    [title]="title"
    [isAddEnabled]="isAddEnabled$ | async"
    [addingObjectName]="addingObjectName"
    [refreshTime]="refreshDatesService.recommendationRulesListRefreshTimestamp.stringValue$ | async"
    (refreshTriggered)="onRefreshTriggered()"
    (addButtonClicked)="onAddButtonClicked()"
    (gridFilterCleared)="onGridFilterCleared()"
    (gridSortCleared)="onGridSortCleared()"
    (gridSettingsSaved)="onGridSettingsSaved()"
    (gridSettingsLoaded)="onGridSettingsLoaded()"
    (gridSettingsReset)="onGridSettingsReset()"
  ></app-list-header>

  <app-list-recommended-rules
    (listChanged)="onRefreshTriggered()"
    (gridSettingsChanged)="gridSettingsChanged()"
  ></app-list-recommended-rules>
</div>
