import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toFormattedVolume'
})
export class ToFormattedVolumePipe implements PipeTransform {
  transform(beforeFormattedNumber: number | string): string {
    let formattedVolumeString: number | string = '0.00';

    if (!!beforeFormattedNumber && !isNaN(+beforeFormattedNumber)) {
      formattedVolumeString = beforeFormattedNumber?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      });
    } else {
      formattedVolumeString = beforeFormattedNumber;
    }

    return `${formattedVolumeString}`;
  }
}
