import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { ColDef, GridApi } from 'ag-grid-community';
import { BehaviorSubject, Observable, Subscription, filter } from 'rxjs';
import { InspectionListItemComponent } from '../../../classes/inspection-list-item-component';
import { InspectionState } from '../../../enums/inspection-state.enum';
import { ListName } from '../../../enums/list-name.enum';
import { ShipmentDetailsService } from '../../../services/api/shipment-details.service';
import { AppNavigationService } from '../../../services/app-navigation.service';
import { GridSettingsService } from '../../../services/grid-settings/grid-settings.service';
import { HardwareService } from '../../../services/hardware/hardware-service';
import { LocationService } from '../../../services/location/location.service';

@Component({
  selector: 'app-list-inspections',
  templateUrl: './list-inspected-inspections.component.html',
  styleUrls: ['./list-inspected-inspections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ListInspectionsComponent extends InspectionListItemComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClass = 'listInspectedShipments';
  public inProgressCount: number = 0;
  gridApi: GridApi;

  @Output()
  selectedCountChanged = new EventEmitter<number>();

  @Output()
  listChanged = new EventEmitter<boolean>();

  @Output()
  gridSettingsChanged = new EventEmitter<boolean>();

  private columnDefsSubject$: BehaviorSubject<ColDef[]> = new BehaviorSubject<ColDef[]>(undefined);
  columnDefs$: Observable<ColDef[]> = this.columnDefsSubject$.asObservable();

  constructor(
    private shipmentDetailsService: ShipmentDetailsService,
    private gridSettings: GridSettingsService,
    protected appNavigation: AppNavigationService,
    protected changeDetector: ChangeDetectorRef,
    private locationService: LocationService,
  ) {
    super(changeDetector, appNavigation);

    this.gridOptions.defaultColDef = this.getDefaultColDef();

    this.gridOptions.columnDefs = this.gridSettings.buildDefaultInspectedListColumnDefs();

    this.gridOptions.suppressMenuHide = HardwareService.isTouchScreenCapable();
    this.gridOptions.suppressRowClickSelection = true;
    this.gridOptions.onCellDoubleClicked = this.onCellDoubleClicked.bind(this);
    this.gridOptions.onColumnMoved = this.columnsChanged.bind(this);
    this.gridOptions.onColumnPinned = this.columnsChanged.bind(this);
    this.gridOptions.onColumnResized = this.columnsChanged.bind(this);
    this.gridOptions.onFilterChanged = this.columnsChanged.bind(this);
    this.gridOptions.onColumnVisible = this.columnsChanged.bind(this);
    this.gridOptions.onSortChanged = this.columnsChanged.bind(this);
    this.gridOptions.onRowDataChanged = this.rowDataChanged.bind(this);
    this.gridOptions.icons = GridSettingsService.buildGridIcons();
    this.gridOptions.enableCellTextSelection = true;
    this.gridOptions.ensureDomOrder = true;
    this.gridOptions.onGridReady = () => {
      this.setCurrentWidthAndHeight();
    };
  }

  private subscription = new Subscription();

  public ngOnInit() {
    this.subscription.add(
      this.shipmentDetailsService.inspectedListData$.subscribe((inspectionListItems) => {
        if (inspectionListItems) {
          this.updateRowData(inspectionListItems);
          let inProgressCount: number = 0;
          inspectionListItems.forEach((inspectionShipment) => {
            if (
              inspectionShipment.inspectionStatusCd === <string>InspectionState.IN_PROGRESS ||
              inspectionShipment.inspectionStatusCd === <string>InspectionState.EDITING
            ) {
              inProgressCount++;
            }
          });
          this.inProgressCount = inProgressCount;
        }
      })
    );

    this.subscription.add(
      this.locationService.isCorrectionsFeatureAvailable$
        .pipe(filter((isCorrectionsFeatureAvailable: boolean) => isCorrectionsFeatureAvailable !== undefined))
        .subscribe((isCorrectionsFeatureAvailable: boolean) => {
          this.columnDefsSubject$.next(this.gridSettings.buildDefaultInspectedListColumnDefs());
        })
    );

    // TODO: remove this temporary approach. This should be done by Angular automatically.
    this.setCurrentWidthAndHeight();
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private rowDataChanged($event) {
    this.gridSettings.restoreGridState(ListName.INSPECTED, this.gridOptions);
    this.setCurrentWidthAndHeight();
  }

  private columnsChanged($event) {
    this.gridSettings.storeGridState(ListName.INSPECTED, this.gridOptions);
    this.gridSettingsChanged.emit(true);
  }

  onResize($event) {
    this.setCurrentWidthAndHeight();
  }

  onSelectionChanged() {
    //do nothing
  }
}
