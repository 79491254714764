import { Injectable } from '@angular/core';
import {
  CreateCustomerGuidelineResp,
  CustomerGuideline,
  InspectionCustGuidelines,
  UpdateCustomerGuidelineResp
} from '@xpo-ltl/sdk-inspections';
import { List } from 'immutable';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomerGuidelineWithAcct } from '../../../classes/customer-guideline-with-acct';
import { NotificationData } from '../../../classes/notification-data';
import { DialogAction } from '../../../enums/dialog-actions.enum';
import { InspectionsApiWrapperService } from '../../../services/inspections/inspections-api-wrapper.service';
import { UserService } from '../../../services/user.service';
import { RequestValidator } from '../../../validators/request.validator';

@Injectable()
export class CustomerGuidelinesService {
  private currentCustomerGuidelineWithAcct: CustomerGuidelineWithAcct = new CustomerGuidelineWithAcct();

  private dialogAction: DialogAction;

  public isManagerActionsAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public updatedSubject$: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

  constructor(private inspectionsService: InspectionsApiWrapperService, private userService: UserService) {
    this.isManagerActionsAvailable$.next(this.userService.isCurrentUserManager);
    this.userService.userRoleChanged$.subscribe((isRoleChanged: boolean) => {
      if (isRoleChanged) {
        this.updatedSubject$.next();
        this.isManagerActionsAvailable$.next(this.userService.isCurrentUserManager);
      }
    });
    this.userService.isUserLoggedIn$?.subscribe((loggedIn: boolean) => {
      if (loggedIn) {
        this.isManagerActionsAvailable$.next(this.userService.isCurrentUserManager);
      }
    });
  }

  public clear() {
    this.currentCustomerGuidelineWithAcct = new CustomerGuidelineWithAcct();
  }

  public getAccountName(): string | undefined {
    return this.currentCustomerGuidelineWithAcct?.acctName;
  }

  public setAccountName(accountName: string): void {
    this.currentCustomerGuidelineWithAcct.acctName = accountName;
    this.updatedSubject$.next();
  }

  public getMadCode(): string | undefined {
    return this.currentCustomerGuidelineWithAcct?.acctMadCd;
  }

  public setMadCode(madCode: string) {
    this.currentCustomerGuidelineWithAcct.acctMadCd = madCode;
    this.updatedSubject$.next();
  }

  public getGuidelineTxt(): string {
    return this.currentCustomerGuidelineWithAcct.guideline;
  }

  public setGuidelineTxt(value: string) {
    this.currentCustomerGuidelineWithAcct.guideline = value;
    this.updatedSubject$.next();
  }

  public getAccountId(): string | undefined {
    return this.currentCustomerGuidelineWithAcct?.acctInstId;
  }

  public setAccountId(accountInstId: string) {
    this.currentCustomerGuidelineWithAcct.acctInstId = accountInstId;
    this.updatedSubject$.next();
  }

  public getAction(): DialogAction {
    return this.dialogAction;
  }

  public setAction(action: DialogAction) {
    this.dialogAction = action;
    this.updatedSubject$.next();
  }

  public setCustomerGuideline(guideline: CustomerGuidelineWithAcct) {
    this.currentCustomerGuidelineWithAcct = guideline;
    this.updatedSubject$.next();
  }

  public getCustomerGuideline(): CustomerGuidelineWithAcct {
    return this.currentCustomerGuidelineWithAcct;
  }

  public getCustomerGuidelines(): Observable<CustomerGuidelineWithAcct[]> {
    return this.inspectionsService.listCustomerGuidelines();
  }

  public updateCustomerGuideline(): Observable<UpdateCustomerGuidelineResp> {
    RequestValidator.validateObjectNotUndefinedOrEmpty(
      this.currentCustomerGuidelineWithAcct,
      'currentCustomerGuidelineWithAcct'
    );

    const customerGuidelineForApi: CustomerGuideline = new CustomerGuideline();
    customerGuidelineForApi.customerGuidelineId = this.currentCustomerGuidelineWithAcct.customerGuidelineId;
    customerGuidelineForApi.customerId = Number(this.currentCustomerGuidelineWithAcct.acctInstId);
    customerGuidelineForApi.guideline = this.currentCustomerGuidelineWithAcct.guideline;
    const result: Observable<UpdateCustomerGuidelineResp> = this.inspectionsService.updateCustomerGuideline(
      customerGuidelineForApi,
      this.currentCustomerGuidelineWithAcct?.acctInstId
    );
    this.updatedSubject$.next();
    return result;
  }

  public deleteCustomerGuideline(): Observable<void> {
    RequestValidator.validateStringNotNullOrEmpty(this.currentCustomerGuidelineWithAcct?.acctInstId, 'acctInstId');

    const acctId: string = this.currentCustomerGuidelineWithAcct?.acctInstId;
    return this.inspectionsService.deleteCustomerGuideline(acctId);
  }

  public createCustomerGuideline(): Observable<CreateCustomerGuidelineResp> {
    return this.inspectionsService.createCustomerGuideline(
      this.currentCustomerGuidelineWithAcct.toCustomerGuidelineForApi()
    );
  }

  hasGuidelineWithId(): boolean {
    return this.currentCustomerGuidelineWithAcct?.acctInstId?.length > 0;
  }

  isGuidelineValidToDelete(): boolean {
    return this.hasGuidelineWithId();
  }

  isGuidelineValidForUpdate(): boolean {
    return this.hasGuidelineWithId() && this.isGuidelineWithValidMadCdAndGuidelineTxt();
  }

  clearGuideline() {
    this.currentCustomerGuidelineWithAcct.guideline = undefined;
    this.updatedSubject$.next();
  }

  isGuidelineWithValidMadCdAndGuidelineTxt(): boolean {
    return (
      this.currentCustomerGuidelineWithAcct.guideline?.length > 0 &&
      this.currentCustomerGuidelineWithAcct.acctMadCd?.length > 0
    );
  }

  buildCustomerGuidelineMessagesList(custSpecificInspGuidelines: InspectionCustGuidelines[]): List<string> {
    let guideLineMessageList: List<string> = List();
    custSpecificInspGuidelines?.forEach((guideline: InspectionCustGuidelines) => {
      if (guideline?.customerGuidelines?.note?.trim().length > 0) {
        RequestValidator.validateObjectNotUndefinedOrEmpty(guideline?.customerId, 'customer ID');

        const message: string = `${guideline.customerId.acctName}: ${guideline.customerGuidelines.note}`;
        guideLineMessageList = guideLineMessageList.push(message);
      }
    });

    return guideLineMessageList;
  }

  buildNotificationData(notificationHeader: string, notificationMessageList: List<string>): NotificationData {
    RequestValidator.validateStringNotNullOrEmpty(notificationHeader, 'notification header');
    RequestValidator.validateListNotUndefinedOrEmpty(notificationMessageList, 'notification message list');

    const notificationData: NotificationData = new NotificationData();
    notificationData.header = notificationHeader;
    notificationData.messages = notificationMessageList;

    return notificationData;
  }
}
