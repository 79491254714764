import { Pipe, PipeTransform } from '@angular/core';
import { InspectionsDateUtils } from '../classes/utils/inspections-date-utils';

@Pipe({
  name: 'timeFormatPipe'
})
export class TimeFormatPipe implements PipeTransform {
  transform(eta: number): string {
    if (eta) {
      const current_dateTime = new Date(
        new Date(eta).toLocaleString(InspectionsDateUtils.locale, { timeZone: InspectionsDateUtils.DEFAULT_TIMEZONE })
      );
      return InspectionsDateUtils.getFormattedDateTime(current_dateTime);
    } else {
      return '';
    }
  }
}
