import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PhotoGalleryDataIF } from '../photo-gallery/photo-gallery-data-if';
import { DocumentManagementService } from '../../services/api/document-management-service';
import { PhotoId } from '../../classes/photos/photo-id';
import { Photo } from '../../classes/photos/photo';
import { ProNumber } from '../../classes/pronumber';

export class DocumentListImpl implements PhotoGalleryDataIF {
  constructor(private documentManagement: DocumentManagementService) {}

  public canDeletePhoto(): boolean {
    return false;
  }

  public loadPhoto(photoId: PhotoId, isLoadingOverlayEnabled: boolean): Observable<Photo> {
    return this.documentManagement.getShipmentImagedDocuments(photoId, isLoadingOverlayEnabled);
  }

  public deletePhoto(photoId: PhotoId): Observable<boolean> {
    return new Observable<boolean>();
  }

  public listPhotoIds(proNumber: ProNumber, docTypes: string[]): Observable<PhotoId[]> {
    return this.documentManagement.listDocumentIds(proNumber, undefined).pipe(
      map((photoIds: PhotoId[]) => {
        return photoIds?.filter((photoId) => {
          if (docTypes) {
            return docTypes.indexOf(photoId.docClass) >= 0;
          } else {
            return true;
          }
        });
      })
    );
  }
}
