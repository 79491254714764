import { Injectable } from '@angular/core';

/**
 * ONLY USED IN TEST ENVIRONMENT
 * This service will have methods/properties that will help testing such as
 *  - mock/use dummy API responses
 *  - change user roles to switch app version between Managers and Inspectors and more
 *
 * unread-broadcast-message-dialog.component.ts also has remove/restore ItemInstanceId()
 * as dev only methods
 */

@Injectable({
  providedIn: 'root'
})
export class DevTestingService {
  isManagerRolesChecked: boolean;
  isBeaconApiThrowingError: boolean;

  constructor() {}
}
