import { ColDef } from 'ag-grid-community';
import { AgGridFilterType } from '../../enums/ag-grid-filter-type';
import { ComparatorUtils } from '../utils/comparator-utils';

export class AgGridHelper {
  public static getDefaultColDef(): ColDef {
    return <ColDef>{
      resizable: true,
      sortable: true,
      filter: AgGridFilterType.SET,
      menuTabs: ['filterMenuTab'],
      filterParams: {
        applyMiniFilterWhileTyping: true,
        comparator: (a: string, b: string) => {
          return ComparatorUtils.stringAndNumberCompare(a, b);
        }
      }
    };
  }
}
