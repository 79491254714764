import { Photo } from '../photo';
import { InspectionsDateUtils } from '../../utils/inspections-date-utils';
import { ProNumber } from '../../pronumber';

export abstract class PhotoEvent {
  private _msg: String;
  private _photo: Photo;
  private _createdDate: Date;

  constructor(msg: String, photo: Photo, createdDate: Date) {
    this._msg = msg;
    this._photo = photo;
    this._createdDate = createdDate;
  }

  get msg(): String {
    return this._msg;
  }

  get photo(): Photo {
    return this._photo;
  }

  get createdDate(): Date {
    return this._createdDate;
  }

  abstract getEventName(): string;

  getComponentText(): string {
    let result: string = '';
    result += InspectionsDateUtils.getFormattedDateTime(this.createdDate) + '->';
    result += this.getEventName();
    if (this.msg) {
      result += ' Msg:' + this.msg;
    }
    if (this.photo) {
      result += ' Photo-> ';
      if (this.photo.fileName?.length > 0) {
        result += ' filename:' + this.photo.fileName;
      }
      if (this.isProDisplayedInComponentText()) {
        result += ' pro:' + this.photo.id?.proNumber;
      }
    } else {
      result += ' No Photo data';
    }
    return result;
  }

  getProNumber(): ProNumber | undefined {
    return this.photo?.getProNumber();
  }

  getProNumberTxt(): string | undefined {
    return this.photo?.getProNumber()?.getRawProNumber();
  }

  protected isProDisplayedInComponentText(): boolean {
    return false;
  }
}
