<xpo-card type="data" color="accent">
  <xpo-card-header>
    <xpo-card-title>Correction Request Information </xpo-card-title>
  </xpo-card-header>

  <xpo-card-content>
    <div class="normal_height_row">
      <div class="labels">Requester</div>
      <div [ngClass]="!isReadOnlyView ? 'values' : 'readonly_value'">
        {{ requesterName }}
      </div>
    </div>

    <div class="normal_height_row">
      <div class="labels">Last Update Date / Time</div>
      <div [ngClass]="!isReadOnlyView ? 'values' : 'readonly_value'">
        {{ requestDateTime | dateMilitaryFormat }}
      </div>
    </div>

    <div class="dropdown_row">
      <div class="labels">Correction Type *</div>
      <div class="values">
        <form *ngIf="correctionRequestInfoFormGroup" [formGroup]="correctionRequestInfoFormGroup">
          <div *ngIf="isReadOnlyView; else canModifyCorrectionType" class="readonly_value">
            {{ inspectionForCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequest?.shortDescription }}
          </div>
          <ng-template #canModifyCorrectionType>
            <mat-form-field>
              <mat-select
                formControlName="inspectionCorrectionTypeCd"
                placeholder="Select Correction Type"
                (selectionChange)="onReasonTypeChanged($event.value)"
                aria-modal
              >
                <mat-option
                  *ngFor="let reason of inspectionCorrectionReasons"
                  [value]="reason.inspectionCorrectionTypeCd"
                >
                  {{ reason.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-template>
        </form>
      </div>
    </div>

    <div class="dropdown_row">
      <div class="labels">Force to Auditor</div>
      <div class="values">
        <form *ngIf="correctionRequestInfoFormGroup" [formGroup]="correctionRequestInfoFormGroup">
          <div *ngIf="isReadOnlyView; else canModifyForceAudit" class="readonly_value">
            {{ isForceAudit | yesNoPipe }}
          </div>
          <ng-template #canModifyForceAudit>
            <mat-slide-toggle
              formControlName="forceAuditor"
              [(ngModel)]="isForceAudit"
              (change)="onForceAuditorChanged($event)"
            >
            </mat-slide-toggle>
          </ng-template>
        </form>
      </div>
    </div>

    <div class="normal_height_row">
      <div class="labels">Lineal Foot</div>
      <div class="values">
        {{ linealFootInd | yesNoPipe }}
      </div>
    </div>

    <div class="normal_height_row">
      <div class="labels">Inspection Notes</div>
      <div class="values">
        <textarea rows="2" matInput value="{{ inspectionNotes }}" disabled></textarea>
      </div>
    </div>
  </xpo-card-content>
</xpo-card>
