import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { MatTooltipModule } from '@angular/material/tooltip';
import { ChangeInspectionStatusGuard } from '../../guards/change-inspection-status.guard';
import { UserProfileGuard } from '../../guards/user-profile-guard';
import { MaterialModule } from '../../material.module';
import { XpoPipesModule } from '../../pipes/xpo-pipes.module';
import { DocumentListModule } from '../document-list/document-list.module';
import { LoadingOverlayModule } from '../loading-overlay/loading-overlay.module';
import { PhotoGalleryModule } from '../photo-gallery/photo-gallery.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { DimensionLineComponent } from './components/dimensions/dimension-line/dimension-line.component';
import { DimensionsComponent } from './components/dimensions/dimensions.component';
import { IndicatorsComponent } from './components/indicators/indicators.component';
import { NotesComponent } from './components/notes/notes.component';
import { ImageGalleryComponent } from './components/photos/photos.component';
import { InspectShipmentRoutingModule } from './inspect-shipment-routing.module';
import { InspectShipmentComponent } from './inspect-shipment.component';

@NgModule({
  imports: [
    MaterialModule,
    MatDialogModule,
    InspectShipmentRoutingModule,
    XpoPipesModule,
    ToolbarModule,
    DocumentListModule,
    PhotoGalleryModule,
    MatTooltipModule,
    LoadingOverlayModule
  ],
  declarations: [
    InspectShipmentComponent,
    NotesComponent,
    DimensionsComponent,
    DimensionLineComponent,
    ImageGalleryComponent,
    IndicatorsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [UserProfileGuard, ChangeInspectionStatusGuard]
})
export class InspectShipmentModule {}
