import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { InspectionCorrectionTypeCd } from '@xpo-ltl/sdk-common';
import {
  Correction,
  CorrectionRequest,
  GetInspectionForCorrectionRequestResp,
  InspectionContext,
  InspectionCorrectionReason
} from '@xpo-ltl/sdk-inspections';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { DateMilitaryFormatPipe } from '../../../pipes/date-military-format.pipe';
import { AppConstantsService } from '../../../services/app-constants.service';
import { DialogWrapperService } from '../../../services/dialog-wrapper.service';
import { RequestValidator } from '../../../validators/request.validator';

@Component({
  selector: 'app-correction-request-info',
  templateUrl: './correction-request-info.component.html',
  styleUrls: ['./correction-request-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CorrectionRequestInfoComponent implements OnChanges {
  @HostBinding('class') hostClass = 'ins-correction-request-info';

  @Input()
  inspectionForCorrectionRequestResp: GetInspectionForCorrectionRequestResp;

  @Input()
  isReadOnlyView: boolean;

  @Output()
  onUpdateButtonState = new EventEmitter<boolean>();

  requesterName: string;
  requestDateTime: Date;
  isForceAudit: boolean = false;
  linealFootInd: boolean;
  inspectionNotes: string;
  selectedReasonCd: string;
  selectedInspectionCorrectionReason: InspectionCorrectionReason;
  inspectionCorrectionReasons: InspectionCorrectionReason[];
  correctionRequestInfoFormGroup: FormGroup;

  readonly BILL_STATUS_NOT_RATED: string =
    'Bill is not rated. A Correction Request can be Created, Saved or Cancelled but Preview and Submit are not available until the bill is rated.';

  private correctionTypeDescription: string;
  private correctionTypeShortDescription: string;
  private userId: string;
  private readonly CORRECTION_REQUEST: string = 'Correction Request';
  private readonly CORRECTION: string = 'Correction';
  private readonly SUBMITTED: string = 'submitted';
  private readonly CREATED: string = 'created';
  private readonly CORRECTION_ID_OR_CORRECTION_REQUEST_ID: string = 'Correction ID or Correction Request ID';
  private readonly CANNOT_BE_EMPTY: string = 'cannot be empty';
  private readonly INSPECTION_CORRECTION_TYPE_CODE: string = 'inspectionCorrectionTypeCd';
  private readonly FORCE_AUDITOR: string = 'forceAuditor';
  private readonly USER_ID: string = 'userId';
  private readonly REQUESTED_TIMESTAMP: string = 'requestedTimestamp';
  private readonly CORRECTION_TYPE_DESCRIPTION: string = 'description';
  private readonly CORRECTION_TYPE_SHORT_DESCRIPTION: string = 'shortDescription';
  private readonly NEW_LINE: string = '<br/>';
  private readonly LINEAL_FOOT_CONFIRMATION_MESSAGE: string =
    'The Inspection supporting this Correction Request is marked as Lineal Foot but the Correction Type is not for Lineal Foot.';
  private readonly ELS_CONFIRMATION_MESSAGE: string =
    'This Correction Request is for Excessive Length (ELS). ELS corrections are auto-applied through the Inspection process.';
  private readonly DO_YOU_WISH_TO_CONTINUE: string = 'Do you wish to continue?';

  private isCorrectionRequestInfoFormControlInitializedSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(undefined);
  isCorrectionRequestInfoFormControlInitialized$: Observable<boolean> =
    this.isCorrectionRequestInfoFormControlInitializedSubject$.asObservable();

  constructor(
    private appConstantsService: AppConstantsService,
    private formBuilder: UntypedFormBuilder,
    private dialogWrapperService: DialogWrapperService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.inspectionForCorrectionRequestResp?.currentValue) {
      this.getGeneralInfoData();
    }
  }

  getFormGroup() {
    return this.correctionRequestInfoFormGroup;
  }

  onForceAuditorChanged(changedSlide: MatSlideToggleChange) {
    this.isForceAudit = changedSlide?.checked;
    this.inspectionForCorrectionRequestResp.inspectionCorrectionRequest.correctionRequest.forceAuditInd =
      this.isForceAudit;
  }

  onReasonTypeChanged(selectedCorrectionType: string) {
    let message: string = '';
    const existingCorrections: Correction[] = this.inspectionForCorrectionRequestResp?.existingCorrections;
    const isLinealFootInspection: boolean = this.inspectionForCorrectionRequestResp?.inspectionLinealFootInd;
    const isSelectedCorrectionTypeLinealFoot: boolean =
      selectedCorrectionType === InspectionCorrectionTypeCd.LINEAL_FOOT;
    const isSelectedCorrectionTypeEls: boolean = selectedCorrectionType === InspectionCorrectionTypeCd.ELS;
    this.setDescriptionsFromCorrectionType(selectedCorrectionType);
    this.correctionRequestInfoFormGroup.get(this.CORRECTION_TYPE_DESCRIPTION).setValue(this.correctionTypeDescription);
    this.correctionRequestInfoFormGroup
      .get(this.CORRECTION_TYPE_SHORT_DESCRIPTION)
      .setValue(this.correctionTypeShortDescription);

    if (existingCorrections?.length > 0) {
      message = message?.concat(this.getExistedCorrectionDataMessageIfAny(selectedCorrectionType, existingCorrections));
    }

    if (isLinealFootInspection && !isSelectedCorrectionTypeLinealFoot) {
      message = this.addNewLinesIfMessageExist(message);
      message = message?.concat(this.LINEAL_FOOT_CONFIRMATION_MESSAGE);
    }

    if (isSelectedCorrectionTypeEls) {
      message = this.addNewLinesIfMessageExist(message);
      message = message?.concat(this.ELS_CONFIRMATION_MESSAGE);
    }

    if (message?.trim().length > 0) {
      message = this.addNewLinesIfMessageExist(message);
      message = message?.concat(this.DO_YOU_WISH_TO_CONTINUE);

      this.openConfirmCancelDialog(message)
        .pipe(take(1))
        .subscribe((response: boolean) => {
          if (response) {
            // do nothing, continue adding/modifying the correction request.
          } else {
            // reset the correction type code
            this.correctionRequestInfoFormGroup.get(this.INSPECTION_CORRECTION_TYPE_CODE).setValue('');
            this.correctionRequestInfoFormGroup.get(this.CORRECTION_TYPE_DESCRIPTION).setValue('');
            this.correctionRequestInfoFormGroup.get(this.CORRECTION_TYPE_SHORT_DESCRIPTION).setValue('');
            this.correctionRequestInfoFormGroup.get(this.INSPECTION_CORRECTION_TYPE_CODE).markAsDirty();
            this.emitUpdateButtonStateForGeneralInfo();
          }
        });
    }

    this.emitUpdateButtonStateForGeneralInfo();
  }

  private addNewLinesIfMessageExist(message: string): string {
    if (message?.trim().length > 0) {
      return message?.concat(`${this.NEW_LINE} ${this.NEW_LINE} ${this.NEW_LINE}`);
    } else {
      return message;
    }
  }

  private getExistedCorrectionDataMessageIfAny(
    selectedCorrectionType: string,
    existingCorrections: Correction[]
  ): string {
    let existedCorrectionDataMessage: string;
    const existingCorrection: Correction = existingCorrections.find(
      (correction: Correction) => correction.correctionTypeCd === selectedCorrectionType
    );

    if (existingCorrection) {
      const correctionTypeDescription: InspectionCorrectionReason =
        this.inspectionForCorrectionRequestResp?.inspectionCorrectionReasons?.find(
          (reason: InspectionCorrectionReason) =>
            reason.inspectionCorrectionTypeCd === existingCorrection?.correctionTypeCd
        );

      let dataSource: string;
      let submittedOrCreated: string;
      if (existingCorrection?.corCorrectionRequestId > 0) {
        // if corCorrectionRequestId ID has value, this is a COR_CORECTION_RQST data so correction data.
        dataSource = this.CORRECTION;
        submittedOrCreated = this.SUBMITTED;
      } else if (existingCorrection?.parentCorrectionRequestId > 0) {
        // if parentCorrectionRequestId ID has value, this is a INS_CORRECTION_REQUEST data so correction request data.
        dataSource = this.CORRECTION_REQUEST;
        submittedOrCreated = this.CREATED;
      } else {
        // we should not get here.
        RequestValidator.throwError(this.CANNOT_BE_EMPTY, this.CORRECTION_ID_OR_CORRECTION_REQUEST_ID);
      }

      existedCorrectionDataMessage = this.getExistedCorrectionDataMessage(
        dataSource,
        submittedOrCreated,
        correctionTypeDescription.shortDescription,
        existingCorrection
      );
    }

    existedCorrectionDataMessage = existedCorrectionDataMessage?.trim().length > 0 ? existedCorrectionDataMessage : '';

    return existedCorrectionDataMessage;
  }

  private getExistedCorrectionDataMessage(
    dataSource: string,
    submittedOrCreated: string,
    correctionTypeShortDescription: string,
    existingCorrection: Correction
  ): string {
    const dateMilitaryFormatPipe: DateMilitaryFormatPipe = new DateMilitaryFormatPipe();
    const introMessage: string = `A ${dataSource} has already been ${submittedOrCreated} for this shipment for "${correctionTypeShortDescription}". Additional information: ${this.NEW_LINE} ${this.NEW_LINE}`;
    const correctionType: string = `Correction Type: ${correctionTypeShortDescription} ${this.NEW_LINE}`;
    const inspectorFullName: string = `Requestor: ${existingCorrection.requestorFullName} ${this.NEW_LINE}`;
    const sicCd: string = `SIC: ${existingCorrection.sicCd} ${this.NEW_LINE}`;
    const status: string = `${dataSource} Status: ${existingCorrection.statusCd} ${this.NEW_LINE}`;
    const lastUpdatedDate: string = `Last Update: ${dateMilitaryFormatPipe.transform(
      +existingCorrection.auditInfo?.updatedTimestamp
    )}`;

    return `${introMessage} ${correctionType} ${inspectorFullName} ${sicCd} ${status}  ${lastUpdatedDate}`;
  }

  private emitUpdateButtonStateForGeneralInfo() {
    this.onUpdateButtonState.emit(true);
  }

  private initFormGroup() {
    this.correctionRequestInfoFormGroup = this.formBuilder.group({
      [this.INSPECTION_CORRECTION_TYPE_CODE]: this.formBuilder.control(this.selectedReasonCd, [Validators.required]),
      [this.FORCE_AUDITOR]: this.formBuilder.control(this.isForceAudit),
      [this.USER_ID]: this.formBuilder.control(this.userId),
      [this.REQUESTED_TIMESTAMP]: this.formBuilder.control(this.requestDateTime),
      [this.CORRECTION_TYPE_DESCRIPTION]: this.formBuilder.control(this.correctionTypeDescription),
      [this.CORRECTION_TYPE_SHORT_DESCRIPTION]: this.formBuilder.control(this.correctionTypeShortDescription)
    });
    this.emitUpdateButtonStateForGeneralInfo();
    this.isCorrectionRequestInfoFormControlInitializedSubject$.next(true);
  }

  private getGeneralInfoData() {
    this.appConstantsService.inspectionContext$.pipe(take(1)).subscribe((context: InspectionContext) => {
      const correctionRequest: CorrectionRequest =
        this.inspectionForCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequest;
      const savedRequestorFullName: string = correctionRequest?.requestorFullName;
      const savedUserId: string = correctionRequest?.requestorEmplid;

      this.requesterName = savedRequestorFullName
        ? savedRequestorFullName
        : `${context.inspectorFirstNm} ${context.inspectorLastNm}`;
      this.userId = savedUserId ? savedUserId : context.inspectorEmployeeId;
      this.requestDateTime = correctionRequest?.auditInfo?.updatedTimestamp;
      this.selectedReasonCd = correctionRequest?.correctionTypeCd;
      this.inspectionCorrectionReasons = this.inspectionForCorrectionRequestResp?.inspectionCorrectionReasons;
      this.inspectionNotes = this.inspectionForCorrectionRequestResp?.inspectionNote?.note;
      this.isForceAudit = correctionRequest?.forceAuditInd;
      this.linealFootInd = this.inspectionForCorrectionRequestResp?.inspectionLinealFootInd;
      if (!this.isReadOnlyView) {
        this.setDescriptionsFromCorrectionType(this.selectedReasonCd);
      }

      this.initFormGroup();
    });
  }

  private setDescriptionsFromCorrectionType(correctionTypeCd: string) {
    const reason: InspectionCorrectionReason = this.inspectionCorrectionReasons.find(
      (reason: InspectionCorrectionReason) => reason.inspectionCorrectionTypeCd === correctionTypeCd
    );

    this.correctionTypeDescription = reason?.description;
    this.correctionTypeShortDescription = reason?.shortDescription;
  }

  private openConfirmCancelDialog(messageBody: string): Observable<boolean> {
    return this.dialogWrapperService.showConfirmCancelDialog(messageBody).pipe(take(1));
  }
}
