import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MobileWebBrowserService } from '../../services/hardware/mobile-web-browser-service';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<NotAuthorizedComponent>,
    public mobileWebBrowserService: MobileWebBrowserService
  ) {}

  ngOnInit() {}

  public close(): void {
    this.mobileWebBrowserService.exitApplication();
    this.dialogRef.close();
  }
}
