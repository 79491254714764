import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { List } from 'immutable';
import { MultipleNotificationData } from '../../classes/multiple-notification-data';
import { NotificationData } from '../../classes/notification-data';
import { RequestValidator } from '../../validators/request.validator';

@Component({
  selector: 'app-customer-instructions-dialog',
  templateUrl: './customer-instructions-dialog.component.html',
  styleUrls: ['./customer-instructions-dialog.component.scss']
})
export class CustomerInstructionsAndPalletPricingRatedDialogComponent implements OnInit {
  notificationDataList: List<NotificationData> = List([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: MultipleNotificationData,
    private dialogRef: MatDialogRef<CustomerInstructionsAndPalletPricingRatedDialogComponent>
  ) {}

  ngOnInit() {
    this.initializeData(this.data?.notificationDataList);
  }

  private initializeData(notificationDataList: List<NotificationData>) {
    RequestValidator.validateObjectNotUndefinedOrEmpty(notificationDataList, 'Notification Data');

    this.notificationDataList = notificationDataList;
  }

  close() {
    this.dialogRef.close();
  }
}
