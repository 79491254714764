import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { CorrectionDetails, InspectionShipmentDetails } from '../../classes/inspection-shipment-details';
import { MaterialModule } from '../../material.module';
import { XpoPipesModule } from '../../pipes/xpo-pipes.module';
import { DialogWrapperService } from '../../services/dialog-wrapper.service';
import { InspectionCorrectionsComponent } from '../inspection-corrections/inspection-corrections.component';
import { InspectionCorrectionService } from '../inspection-corrections/service/inspection-correction.service';

@Component({
  selector: 'app-inspection-corrections-card',
  templateUrl: './inspection-corrections-card.component.html',
  styleUrls: ['./inspection-corrections-card.component.scss'],
  standalone: true,
  imports: [MaterialModule, MatExpansionModule, XpoPipesModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class InspectionCorrectionsCardComponent implements OnChanges {
  @HostBinding('class') hostClass = 'ins-corrections-card';

  @Input()
  shipmentDetails: InspectionShipmentDetails;

  isExpanded = true;
  dataSource: CorrectionDetails[] = [];

  readonly CORRECTION_REQUEST_DIALOG_OPEN = 'correctionRequestDialogOpen';
  readonly SHORT_DESCRIPTION = 'shortDescription';
  readonly STATUS_CODE = 'statusCd';
  readonly AUTO_CORRECTION_IND = 'autoCorrectionInd';
  readonly REVENUE_IMPACT_AMOUNT = 'revenueImpactAmount';
  readonly REQUESTER_FULL_NAME = 'requestorFullName';
  readonly SIC_CODE = 'sicCd';
  readonly CREATED_TIMESTAMP = 'createdTimestamp';
  readonly LAST_UPDATED_TIMESTAMP = 'updatedTimestamp';

  displayedColumnsForSubmittedCorrections: string[] = [
    this.CORRECTION_REQUEST_DIALOG_OPEN,
    this.SHORT_DESCRIPTION,
    this.STATUS_CODE,
    this.AUTO_CORRECTION_IND,
    this.REVENUE_IMPACT_AMOUNT,
    this.REQUESTER_FULL_NAME,
    this.SIC_CODE,
    this.CREATED_TIMESTAMP,
    this.LAST_UPDATED_TIMESTAMP
  ];

  displayedColumnsForInProgressCorrections: string[] = [
    this.SHORT_DESCRIPTION,
    this.STATUS_CODE,
    this.AUTO_CORRECTION_IND,
    this.REQUESTER_FULL_NAME,
    this.SIC_CODE,
    this.LAST_UPDATED_TIMESTAMP
  ];

  constructor(
    private dialogWrapperService: DialogWrapperService,
    private inspectionCorrectionService: InspectionCorrectionService
  ) {
  }

  ngOnChanges() {
    if (this.shipmentDetails) {
      this.sortToAscOrderByCorrectionCreatedTimestamp();
      this.dataSource = this.shipmentDetails.corrections?.toArray();
    }
  }

  onOpenCorrectionRequestDetailClicked(correctionDetails: CorrectionDetails) {
    this.inspectionCorrectionService.setCorrectionRequestId(correctionDetails.parentCorrectionRequestId);
    this.inspectionCorrectionService.setIsInspectionCorrectionsComponentReadOnly(true);
    this.dialogWrapperService.alert(
      {
        titleText: `Details for Correction Request ID: ${correctionDetails.parentCorrectionRequestId}`,
        contentComponent: InspectionCorrectionsComponent,
        hideDismissButton: true
      },
      {
        disableClose: false,
        minWidth: '95vw'
      }
    );
  }

  private sortToAscOrderByCorrectionCreatedTimestamp() {
    this.shipmentDetails.corrections?.sort(
      (a, b) =>
        new Date(a?.auditInfo?.createdTimestamp)?.getTime() - new Date(b?.auditInfo?.createdTimestamp)?.getTime()
    );
  }
}
