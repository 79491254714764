import { Pipe, PipeTransform } from '@angular/core';
import { InspectionsDateUtils } from '../classes/utils/inspections-date-utils';

@Pipe({
  name: 'dateMilitaryFormat'
})
export class DateMilitaryFormatPipe implements PipeTransform {
  transform(dateNumber: number): string {
    if (dateNumber && !isNaN(dateNumber)) {
      return InspectionsDateUtils.getFormatDate(
        new Date(dateNumber),
        InspectionsDateUtils.DATE_TIME_MILITARY_FORMAT_STRING
      );
    } else {
      return '';
    }
  }
}
