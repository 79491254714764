import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MaterialModule } from '../../material.module';
import { XpoPipesModule } from '../../pipes/xpo-pipes.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { PhotosStatusComponent } from './photos-status.component';

@NgModule({
  imports: [MaterialModule, XpoPipesModule, ToolbarModule, MatTooltipModule],
  declarations: [PhotosStatusComponent]
})
export class PhotosStatusModule {}
