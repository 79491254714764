import { PhotoEvent } from './photo-event';
import { Photo } from '../photo';
import { PhotoId } from '../photo-id';

export class DeletedFromLocalStoragePhotoEvent extends PhotoEvent {
  constructor(photoId: PhotoId, createdDate: Date) {
    super(undefined, new Photo(photoId), createdDate);
  }

  getEventName(): string {
    return 'Deleted from local storage';
  }
}
