<div class="document-list">
  <mat-expansion-panel [(expanded)]="isExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title class="section-label">{{ title }} ({{ photos?.size || '0' }})</mat-panel-title>
    </mat-expansion-panel-header>
    <div class="document-list__container">
      <div *ngIf="!photos || photos.size === 0; else showPhotos" fxLayoutAlign="start center">
        No {{ title }} Found
      </div>
      <ng-template #showPhotos>
        <div fxLayout="row wrap" fxLayoutGap="10px">
          <div *ngFor="let photo of photos" fxLayout="column">
            <div fxFlex>
              <mat-icon
                class="document-list__documentIcon"
                svgIcon="{{ photo.svgIconValue }}"
                (click)="openPhotoGallery(photo.id)"
                [matTooltip]="photo.toolTip"
              >
              </mat-icon>
            </div>
          </div>
        </div>
      </ng-template>
      <div *ngIf="showTrailerPhotoAppLink">
        <button (click)="openTrailerPhotoApp()" mat-button color="primary" class="document-list__trailer-photo-link">
          TRAILER PHOTOS
        </button>
      </div>
      <div></div>
    </div>
  </mat-expansion-panel>
</div>
