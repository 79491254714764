<app-loading-overlay></app-loading-overlay>
<div class="without-overlay">
  <div class="ins-correction__container">
    <div class="back-button_and_title">
      <mat-icon
        *ngIf="!isReadOnlyView"
        (click)="onBackButtonClicked()"
        class="back-icon"
        svgIcon="back-button-icon"
      ></mat-icon>
      <div class="title">{{ formattedProNumberString }} - Correction Request</div>
    </div>

    <div class="first-row">
      <app-correction-request-info
        [inspectionForCorrectionRequestResp]="inspectionForCorrectionRequestResp"
        [isReadOnlyView]="isReadOnlyView"
        (onUpdateButtonState)="onUpdateButtonState($event)"
      ></app-correction-request-info>
      <app-general-info
        [formattedProNumberString]="formattedProNumberString"
        [inspectionForCorrectionRequestResp]="inspectionForCorrectionRequestResp"
        [isReadOnlyView]="isReadOnlyView"
        [shipmentDetailsResponse]="shipmentDetails"
        (onUpdateButtonState)="onUpdateButtonState($event)"
      ></app-general-info>
    </div>

    <app-inspection-info
      [formattedProNumberString]="formattedProNumberString"
      [inspectionForCorrectionRequestResp]="inspectionForCorrectionRequestResp"
      [isReadOnlyView]="isReadOnlyView"
      [shipmentDetails]="shipmentDetails"
      (onUpdateButtonState)="onUpdateButtonState($event)"
    ></app-inspection-info>
    <app-commodities
      [inspectionForCorrectionRequestResp]="inspectionForCorrectionRequestResp"
      [isReadOnlyView]="isReadOnlyView"
      [shipmentDetails]="shipmentDetails"
      (onUpdateButtonState)="onUpdateButtonState($event)"
    ></app-commodities>
  </div>
  <div *ngIf="!isReadOnlyView">
    <ins-corrections-footer
      [shipmentDetails]="shipmentDetails"
      (onSaveButtonClicked)="onSaveButtonClicked($event)"
      (onCancelButtonClicked)="onCancelButtonClicked($event)"
      (onPreviewButtonClicked)="onPreviewButtonClicked($event)"
      (onSubmitButtonClicked)="onSubmitButtonClicked($event)"
    ></ins-corrections-footer>
  </div>
</div>
