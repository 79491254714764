<mat-expansion-panel expanded="true">
  <mat-expansion-panel-header>
    <mat-panel-title class="section-label"> Add Indicators </mat-panel-title>
  </mat-expansion-panel-header>

  <form *ngIf="indicatorsForm" [formGroup]="indicatorsForm">
    <xpo-checkbox-group class="add_indicators__checkboxGroup">
      <mat-checkbox
        *ngFor="let indicator of indicatorLabels"
        [formControlName]="indicator"
        (change)="onIndicatorChanged(indicatorsForm)"
        class="checkbox"
        >{{ indicator | spacedStringFromTitleCase }}</mat-checkbox
      >
    </xpo-checkbox-group>
  </form>
</mat-expansion-panel>
