import { Component, Input, OnChanges } from '@angular/core';
import { InspectionShipment, PieceDimensions } from '@xpo-ltl/sdk-inspections';
import { ProNumber } from '../../../../classes/pronumber';
import { AppConstantsService } from '../../../../services/app-constants.service';
import { InspectionLocalStorageService } from '../../../../services/inspection-local-storage.service';
import { ShipmentDetailsService } from '../../../../services/api/shipment-details.service';
import { Item680AppStorageService } from '../../../tools/item680calculator/services/item680-app-storage.service';
import { InspectShipmentComponent } from '../../inspect-shipment.component';

@Component({
  selector: 'app-dimensions',
  templateUrl: './dimensions.component.html',
  styleUrls: ['./dimensions.component.scss']
})
export class DimensionsComponent implements OnChanges {
  constructor(
    public shipmentDetailsService: ShipmentDetailsService,
    public constants: AppConstantsService,
    private inspectionLocalStorage: InspectionLocalStorageService,
    public item680StorageService: Item680AppStorageService
  ) {}

  @Input()
  inspectionDetails: InspectionShipment;

  @Input()
  dimensionErrors: string[];

  public totalVolume = 0;
  public totalDensity = 0;
  public totalPieces = 0;

  /**
   * we receive the inspectionDetails after the component init, so we have to check for that change.
   * We probably should have the inputs as observables and async though but didnt want to refactor (yet :))
   */
  ngOnChanges() {
    if (this.inspectionDetails) {
      this.dimensionsChanged();
      this.setDimensionsFromCalculator();

      this.inspectionLocalStorage.setInspectionWeight(
        new ProNumber(this.inspectionDetails.shipmentId.proNumber),
        this.inspectionDetails.totGrossWeight
      );
    }
  }

  public dimensionsChanged() {
    if (this.inspectionDetails?.inspectorPieceDimensions) {
      const pieceDimensionsForLocalStorage = new Array<PieceDimensions>();
      this.inspectionDetails?.inspectorPieceDimensions?.forEach((inspectionPieceDimension) => {
        let isValid = true;
        if (!this.isValidValue(inspectionPieceDimension.pieceCnt, 9999)) {
          inspectionPieceDimension.pieceCnt = undefined;
          isValid = false;
        }
        if (!this.isValidValue(inspectionPieceDimension.dimensions.length, 999.5)) {
          inspectionPieceDimension.dimensions.length = undefined;
          isValid = false;
        }
        if (!this.isValidValue(inspectionPieceDimension.dimensions.width, 999.5)) {
          inspectionPieceDimension.dimensions.width = undefined;
          isValid = false;
        }
        if (!this.isValidValue(inspectionPieceDimension.dimensions.height, 999.5)) {
          inspectionPieceDimension.dimensions.height = undefined;
          isValid = false;
        }

        if (inspectionPieceDimension.pieceCnt) {
          inspectionPieceDimension.pieceCnt = Math.floor(+inspectionPieceDimension.pieceCnt);
        }

        if (isValid) {
          pieceDimensionsForLocalStorage.push(inspectionPieceDimension);
        }
      });

      const totalWeight = this.inspectionDetails.totGrossWeight;
      this.totalVolume = this.calculateTotalVolume();
      this.totalPieces = this.calculateTotalPieces();
      this.totalDensity = AppConstantsService.calculateDensity(totalWeight, this.totalVolume);

      this.inspectionLocalStorage.setInspectionDimensions(
        new ProNumber(this.inspectionDetails.shipmentId.proNumber),
        pieceDimensionsForLocalStorage
      );
      if (this.needsNewRow()) {
        this.addRow();
      }
    }
  }

  private addRow() {
    InspectShipmentComponent.addRowToInspectionDetails(this.inspectionDetails);
  }

  public calculateTotalVolume(): number {
    let totalVolume = 0;
    this.inspectionDetails.inspectorPieceDimensions.forEach((pieceDimension) => {
      const volume = this.calculateVolume(pieceDimension);
      totalVolume = parseFloat((totalVolume + volume).toFixed(2));
    });

    return totalVolume;
  }

  public calculateTotalPieces(): number {
    let totalPieces = 0;
    this.inspectionDetails.inspectorPieceDimensions.forEach((pieceDimension) => {
      if (this.isValidPieceDimension(pieceDimension)) {
        const pieceCount = +pieceDimension.pieceCnt;
        totalPieces = parseFloat((totalPieces + pieceCount).toFixed(0));
      }
    });

    return totalPieces;
  }

  /**
   * Calculates the total volume in cubic feet and density for the entire shipment.
   */
  calculateVolume(pieceDimensions: PieceDimensions) {
    let volume = 0;
    // var newDensity = 0;
    if (this.isValidPieceDimension(pieceDimensions)) {
      const dimensions = pieceDimensions.dimensions;
      const cDivisor = 12; // Conversion Divisor: Entries are in inches - calculations result in feet
      volume =
        pieceDimensions.pieceCnt *
        (dimensions.length / cDivisor) *
        (dimensions.width / cDivisor) *
        (dimensions.height / cDivisor);
      volume = parseFloat(volume.toFixed(2));
    }
    return volume;
  }

  private isValidValue(value: number, maxValue: number): boolean {
    return !Number.isNaN(value) && value > 0 && value <= maxValue;
  }

  public isValidPieceDimension(pieceDimensions: PieceDimensions): boolean {
    if (pieceDimensions && pieceDimensions.dimensions) {
      return (
        pieceDimensions.pieceCnt > 0 &&
        pieceDimensions.dimensions.length > 0 &&
        pieceDimensions.dimensions.width > 0 &&
        pieceDimensions.dimensions.height > 0
      );
    }
    return false;
  }

  public needsNewRow(): boolean {
    if (
      !this.inspectionDetails.inspectorPieceDimensions ||
      this.inspectionDetails.inspectorPieceDimensions.length == 0
    ) {
      return true;
    } else {
      const lastLine =
        this.inspectionDetails.inspectorPieceDimensions[this.inspectionDetails.inspectorPieceDimensions.length - 1];
      return (
        lastLine.pieceCnt !== undefined ||
        lastLine.dimensions.height !== undefined ||
        lastLine.dimensions.length !== undefined ||
        lastLine.dimensions.width !== undefined
      );
    }
  }

  public setDimensionsFromCalculator(): void {
    if (this.inspectionDetails) {
      const proNumber = new ProNumber(this.inspectionDetails.shipmentId.proNumber);
      const dimensionsCalculator = this.item680StorageService.getItem6802bDimensions(proNumber);
      const inspectionDimensions = this.inspectionLocalStorage.getInspectionDimensions(proNumber);
      const noInspectionDimensions = !(inspectionDimensions && inspectionDimensions.length);
      const dimensionIsValid = dimensionsCalculator && dimensionsCalculator.length;

      if (dimensionIsValid && noInspectionDimensions) {
        this.inspectionDetails.inspectorPieceDimensions = dimensionsCalculator;
        this.dimensionsChanged();
      }
    }
  }
}
