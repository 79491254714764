import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FormatValidationService } from '@xpo-ltl/common-services';
import { ShiftCd } from '@xpo-ltl/sdk-common';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppConstantsService } from '../../../services/app-constants.service';
import { AppNavigationService } from '../../../services/app-navigation.service';
import { SnackBarHandlingService } from '../../../services/snack-bar-handling.service';
import { ShipmentDetailsService } from '../../../services/api/shipment-details.service';
import { TrailerDetail } from '../model/trailer-detail.model';
import { LoadingOverlayService } from '../../loading-overlay/service/loading-overlay.service';

@Injectable()
export class TrailerDetailResolver implements Resolve<TrailerDetail> {
  constructor(
    private validationService: FormatValidationService,
    private errorHandling: SnackBarHandlingService,
    private constantsService: AppConstantsService,
    private shipmentDetailsService: ShipmentDetailsService,
    private appNavigation: AppNavigationService,
    private loadingOverlayService: LoadingOverlayService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TrailerDetail> | Observable<never> {
    const shift: ShiftCd = this.constantsService.shiftCode as ShiftCd;
    const sic: string = this.constantsService.sicCode;
    const input = route.paramMap.get('trailer');
    let trailerId;
    let doorId;
    if (this.validationService.isValidTrailerNumber(input)) {
      trailerId = input;
    } else if (this.validationService.isValidDoorNumber(input)) {
      doorId = input;
    }

    this.loadingOverlayService.setIsLoading(true);
    return this.shipmentDetailsService.listTrailerManifestForInspection(shift, sic, doorId, trailerId).pipe(
      catchError(() => {
        this.appNavigation.clearLookupValue();
        if (doorId) {
          this.errorHandling.showErrorMessage(`No Trailer Found at the Door #${doorId}`);
          return throwError(`No Trailer Found at the Door #${doorId}`);
        } else {
          this.errorHandling.showErrorMessage(`Trailer: ${trailerId} Not Found!`);
          return throwError(`Trailer: ${trailerId} Not Found!`);
        }
      })
    );
  }
}
