import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CorrectionsApiWrapperService {
  static BILL_DETAILS = 'content';
  static CORRECTION_HISTORY = 'history';

  constructor() {}
}
