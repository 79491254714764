import { ErrorPhotoEvent } from './error-photo-event';
import { Photo } from '../../photo';

export class FailedGetNextPhotoEvent extends ErrorPhotoEvent {
  constructor(msg: String, photo: Photo, createdDate: Date, error: Error) {
    super(msg, photo, createdDate, error);
  }

  getEventName(): string {
    return 'Failed to get next photo from storage';
  }
}
