import { ErrorPhotoEvent } from './error-photo-event';
import { Photo } from '../../photo';

export class FailedLoadLocalStorage extends ErrorPhotoEvent {
  constructor(photo: Photo, error: Error, createdDate: Date) {
    super(undefined, photo, createdDate, error);
  }

  getEventName(): string {
    return 'Failed to load local storage';
  }
}
