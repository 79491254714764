import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { GetInspectionResp, InspectionShipment } from '@xpo-ltl/sdk-inspections';
import { Subject, filter, takeUntil } from 'rxjs';
import { InspectionDetailService } from '../../services/api/inspection-detail.service';

@Component({
  selector: 'app-inspection-detail-dialog',
  templateUrl: './inspection-detail-dialog.component.html',
  styleUrls: ['./inspection-detail-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class InspectionDetailDialogComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClass = 'ins-inspectionDetailDialog';

  inspectionDetailsResp: GetInspectionResp;
  displayedColumns: string[] = ['pieceCnt', 'length', 'width', 'height'];

  private unsubscriber$: Subject<void> = new Subject();
  private readonly CUBIC_INCH_IN_CUBIC_FEET: number = 1728;

  constructor(private inspectionDetailService: InspectionDetailService) {}

  ngOnInit(): void {
    this.inspectionDetailService.inspectionDetails$
      .pipe(
        filter((details: GetInspectionResp) => !!details),
        takeUntil(this.unsubscriber$)
      )
      .subscribe((details: GetInspectionResp) => {
        this.inspectionDetailsResp = details;
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  calculateDensity(inspectionDetails: InspectionShipment): number {
    let density: number = 0;
    if (!isNaN(inspectionDetails?.totGrossWeight) && !isNaN(inspectionDetails?.totGrossVolume)) {
      density = inspectionDetails.totGrossWeight / inspectionDetails.totGrossVolume;
    }

    return density;
  }
}
