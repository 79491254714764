<div class="overall">
  <div class="close">
    <button (click)="close()" class="close" matTooltip="Click to close or Escape or Click outside the dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div *ngFor="let notificationData of notificationDataList; let i = index">
    <h1 mat-dialog-title>{{ notificationData.header }}</h1>

    <mat-dialog-content>
      <p *ngFor="let message of notificationData.messages">
        {{ message }}
      </p>
    </mat-dialog-content>
    <hr *ngIf="!notificationDataList.isEmpty() && i !== notificationDataList?.size - 1" />
  </div>
</div>
