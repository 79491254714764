<div class="app-header-toolbar" fxLayout="column" fxLayoutAlign="start start">
  <div
    fxLayout="row"
    fxFlex=""
    fxFlexAlign="stretch start"
    class=""
    style="max-width: 100%; max-height: 100%;"
    (window:resize)="onResize($event)"
  >
    <ag-grid-angular
      style="max-width: 100%; max-height: 100%; position: fixed;"
      #agGrid
      id="myGrid"
      [style.width.px]="width"
      [style.height.px]="height"
      class="ag-theme-material"
      [headerHeight]="56"
      (gridReady)="onGridReady($event)"
      [rowMultiSelectWithClick]="true"
      [domLayout]="'normal'"
      [gridOptions]="gridOptions"
      [rowData]="rowData"
    ></ag-grid-angular>
  </div>
</div>
