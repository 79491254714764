import { InspectionCorrectionIneligibleReasonCd } from '@xpo-ltl/sdk-common';
import { InsCreateCorrectionStatusCdEnum } from './create-correction-status-code.enum';

export enum ElsSubmissionMessageEnum {
  CORRECTION_SUBMITTED = 'ELS correction has been submitted.',
  CORRECTION_APPROVED = 'ELS correction has been submitted and approved.',
  CORRECTION_PENDING = 'ELS correction has been submitted and is Pending for auditor review.',
  CORRECTION_DISABLED = 'ELS correction process is currently disabled. ELS will be reevaluated at final delivery.',
  CORRECTION_DECLINED = 'ELS correction has been submitted and was declined.',
  ERROR_PROCESS_AT_FINAL_DELIVERY = 'ELS correction was submitted but a process error occurred. Pro will be reprocessed for ELS at final delivery.',
  ERROR_NO_DIMENSIONS = 'Pro is not eligible for ELS correction because no dimensions were submitted. Pro will be reprocessed for ELS at final delivery.',
  ERROR_ELS_ALREADY_APPLIED = 'Pro is not eligible for ELS correction. ELS is already applied.', // Should not get this, handled by Eligibility
  ERROR_XLF_ALREADY_APPLIED = 'Pro is not eligible for ELS correction. Lineal foot was applied which excludes ELS application.',
  ERROR_CORRECTION_EXISTS = 'Pro is not eligible for ELS correction. ELS correction was previously submitted.', // Should not get this, handled by Eligibility
  ERROR_BILL_CLASS_INVALID = 'Pro is not eligible for ELS correction. Bill Class for Pro is not valid for ELS (i.e. Company Business and other Bill Classes).', // Should not get this, handled by Eligibility
  ERROR_SHIPMENT_NOT_RATED = 'Pro is not eligible for ELS Correction at this time.  Pro is not yet rated but will be evaluated for ELS at Final Delivery.', // Should not get this, handled by Eligibility
  ERROR_MOTORIZED_TOTAL_PIECES = 'Pro is not eligible for ELS correction. Motorized pieces are greater than total pieces.',
  NO_REVENUE_CHANGE = 'Pro is not eligible for ELS correction. There was no revenue impact when adding ELS.',
  ANOTHER_INSPECTION_NEEDED = 'Do you need a copy of the Inspection for another correction?',
  ERROR_500 = 'ELS correction was submitted but a process error occurred. Pro will be reprocessed for ELS at final delivery.',
  LINEAL_FOOT_INSPECTION_TYPE = 'Pro is not eligible for ELS correction. Supporting inspection is for a Lineal Foot Correction.'
}

export enum ElsSubmissionOverviewCardMessageEnum {
  CORRECTION_SUBMITTED = 'ELS Correction Submitted',
  CORRECTION_APPROVED = 'ELS Correction Approved',
  CORRECTION_PENDING = 'ELS Correction Pending',
  CORRECTION_DISABLED = 'ELS Correction Disabled',
  CORRECTION_DECLINED = 'ELS Correction Declined',
  CORRECTION_ERROR = 'ELS Correction Error',
  NOT_ELIGIBLE_FOR_ELS = 'Not Eligible for ELS',
  ERROR_ELS_ALREADY_APPLIED = 'ELS already applied',
  ERROR_XLF_ALREADY_APPLIED = 'Lineal Foot applied',
  ERROR_CORRECTION_EXISTS = 'ELS correction already submitted',
  ERROR_BILL_CLASS_INVALID = 'Non-revenue shipment',
  PRO_NOT_RATED = 'Pro not rated',
  ERROR_MOTORIZED_TOTAL_PIECES = 'Motorized pieces > total pieces',
  NO_REVENUE_CHANGE = 'No revenue impact',
  WILL_BE_PROCESSED_AT_FINAL_DELIVERY = 'Will be reprocessed at final delivery',
  ANOTHER_INSPECTION_NEEDED = 'Do you need a copy of the Inspection for another correction?',
  LINEAL_FOOT_INSPECTION_TYPE = 'Inspection is for a Lineal Foot Correction'
}

export function getDisplayMessageFromCorrectionStatusCd(
  statusCd: InsCreateCorrectionStatusCdEnum | string,
  isQuestionNeeded: boolean
): string {
  let message: string = '';
  switch (statusCd) {
    case InsCreateCorrectionStatusCdEnum.CORRECTION_INVALID:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_LOCKED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_SAVED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_UNKNOWN:
      message = ElsSubmissionMessageEnum.CORRECTION_SUBMITTED;
      break;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_APPROVED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_APPLIED:
      message = ElsSubmissionMessageEnum.CORRECTION_APPROVED;
      break;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_PENDING:
      message = ElsSubmissionMessageEnum.CORRECTION_PENDING;
      break;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_DISABLED:
      message = ElsSubmissionMessageEnum.CORRECTION_DISABLED;
      break;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_DECLINED:
      message = ElsSubmissionMessageEnum.CORRECTION_DECLINED;
      break;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_PROXY_FAILURE:
    case InsCreateCorrectionStatusCdEnum.ERROR_UNKNOWN:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_ORIGINAL_CHARGES:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_EMPLOYEE:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_PREVIEW_CHARGES:
      message = ElsSubmissionMessageEnum.ERROR_PROCESS_AT_FINAL_DELIVERY;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_DIMENSIONS:
      message = ElsSubmissionMessageEnum.ERROR_NO_DIMENSIONS;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_ELS_ALREADY_APPLIED:
      message = ElsSubmissionMessageEnum.ERROR_ELS_ALREADY_APPLIED;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_XLF_ALREADY_APPLIED:
      message = ElsSubmissionMessageEnum.ERROR_XLF_ALREADY_APPLIED;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_CORRECTION_EXISTS:
      message = ElsSubmissionMessageEnum.ERROR_CORRECTION_EXISTS;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_BILL_CLASS_INVALID:
      message = ElsSubmissionMessageEnum.ERROR_BILL_CLASS_INVALID;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_SHIPMENT_NOT_RATED:
      message = ElsSubmissionMessageEnum.ERROR_SHIPMENT_NOT_RATED;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_MOTORIZED_TOTAL_PIECES:
      message = ElsSubmissionMessageEnum.ERROR_MOTORIZED_TOTAL_PIECES;
      break;
    case InsCreateCorrectionStatusCdEnum.NO_REVENUE_CHANGE:
      message = ElsSubmissionMessageEnum.NO_REVENUE_CHANGE;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_LINEAL_FOOT_INSPECTION_TYPE:
      message = ElsSubmissionMessageEnum.LINEAL_FOOT_INSPECTION_TYPE;
      break;
    default:
      message = '';
      break;
  }

  if (isQuestionNeeded) {
    message = message.concat(`<br /><br/> ${ElsSubmissionMessageEnum.ANOTHER_INSPECTION_NEEDED}`);
  }

  return message;
}

export function getDisplayMessageFromIneligibleReasonCd(reasonCd: InspectionCorrectionIneligibleReasonCd): string {
  const notEligible: string = `Pro is not eligible for ELS correction.`;
  const alreadySubmitted: string = `correction has been submitted for this shipment.`;

  switch (reasonCd) {
    case InspectionCorrectionIneligibleReasonCd.SHIPMENT_NOT_FOUND:
      return `Shipment not found.`;
    case InspectionCorrectionIneligibleReasonCd.SHIPMENT_NOT_RATED:
      return `${notEligible} Pro is not rated. ELS will be evaluated at final delivery.`;
    case InspectionCorrectionIneligibleReasonCd.BILL_CLASS_INVALID:
      return `${notEligible} Pro is non-revenue.`;
    case InspectionCorrectionIneligibleReasonCd.EXISTING_INSPECTION_CORRECTION:
    case InspectionCorrectionIneligibleReasonCd.EXISTING_ELS_CORRECTION:
      return `${notEligible} ELS correction has already been submitted.`;
    case InspectionCorrectionIneligibleReasonCd.EXISTING_XLF_CORRECTION:
      return `${notEligible} Lineal Foot ${alreadySubmitted}`;
    case InspectionCorrectionIneligibleReasonCd.EXISTING_XCC_CORRECTION:
      return `${notEligible} Cubic Capacity ${alreadySubmitted}`;
    case InspectionCorrectionIneligibleReasonCd.EXISTING_ELS_ACCESSORIAL:
      return `${notEligible} ELS is already applied.`;
    case InspectionCorrectionIneligibleReasonCd.EXISTING_XLF_ACCESSORIAL:
      return `${notEligible} Lineal Foot charge is applied.`;
    case InspectionCorrectionIneligibleReasonCd.EXISTING_XCC_ACCESSORIAL:
      return `${notEligible} Cubic Capacity charge is applied.`;
    case InspectionCorrectionIneligibleReasonCd.LINEAL_FOOT_INSPECTION_TYPE:
      return `${notEligible} Supporting inspection is for a Lineal Foot Correction.`;
    case InspectionCorrectionIneligibleReasonCd.NO_DIMENSIONS: // skip displaying snackbar message
    case InspectionCorrectionIneligibleReasonCd.LARGEST_DIMS_UNDER_ELS_THRESHOLD: // skip displaying snackbar message
    case InspectionCorrectionIneligibleReasonCd.PREVIOUS_INSPECTION: // skip displaying snackbar message
      return undefined;
    default:
      return reasonCd; // display the reason code so we can see which cases we're not implementing.
  }
}

export function getOverviewMessageFromCorrectionStatusCd(statusCd: InsCreateCorrectionStatusCdEnum | string) {
  switch (statusCd) {
    case InsCreateCorrectionStatusCdEnum.CORRECTION_INVALID:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_LOCKED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_SAVED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_UNKNOWN:
      return ElsSubmissionOverviewCardMessageEnum.CORRECTION_SUBMITTED;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_APPROVED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_APPLIED:
      return ElsSubmissionOverviewCardMessageEnum.CORRECTION_APPROVED;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_PENDING:
      return ElsSubmissionOverviewCardMessageEnum.CORRECTION_PENDING;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_DISABLED:
      return ElsSubmissionOverviewCardMessageEnum.CORRECTION_DISABLED;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_DECLINED:
      return ElsSubmissionOverviewCardMessageEnum.CORRECTION_DECLINED;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_PROXY_FAILURE:
    case InsCreateCorrectionStatusCdEnum.ERROR_UNKNOWN:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_ORIGINAL_CHARGES:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_DIMENSIONS:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_EMPLOYEE:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_PREVIEW_CHARGES:
    case InsCreateCorrectionStatusCdEnum.ERROR_MOTORIZED_TOTAL_PIECES:
      return ElsSubmissionOverviewCardMessageEnum.CORRECTION_ERROR;
    case InsCreateCorrectionStatusCdEnum.ERROR_ELS_ALREADY_APPLIED:
    case InsCreateCorrectionStatusCdEnum.ERROR_XLF_ALREADY_APPLIED:
    case InsCreateCorrectionStatusCdEnum.ERROR_XCC_ALREADY_APPLIED:
    case InsCreateCorrectionStatusCdEnum.ERROR_CORRECTION_EXISTS:
    case InsCreateCorrectionStatusCdEnum.ERROR_BILL_CLASS_INVALID:
    case InsCreateCorrectionStatusCdEnum.NO_REVENUE_CHANGE:
    case InsCreateCorrectionStatusCdEnum.ERROR_LINEAL_FOOT_INSPECTION_TYPE:
      return ElsSubmissionOverviewCardMessageEnum.NOT_ELIGIBLE_FOR_ELS;
    case InsCreateCorrectionStatusCdEnum.ERROR_SHIPMENT_NOT_RATED:
      return ElsSubmissionOverviewCardMessageEnum.PRO_NOT_RATED;
    default:
      return statusCd; // display status code so we can see which code we haven't implemented
  }
}

export function getTooltipMessageFromCorrectionStatusCd(statusCd: InsCreateCorrectionStatusCdEnum | string) {
  const sameMessageStatuses: InsCreateCorrectionStatusCdEnum[] = [
    InsCreateCorrectionStatusCdEnum.CORRECTION_INVALID,
    InsCreateCorrectionStatusCdEnum.CORRECTION_LOCKED,
    InsCreateCorrectionStatusCdEnum.CORRECTION_SAVED,
    InsCreateCorrectionStatusCdEnum.CORRECTION_APPROVED,
    InsCreateCorrectionStatusCdEnum.CORRECTION_APPLIED,
    InsCreateCorrectionStatusCdEnum.CORRECTION_PENDING,
    InsCreateCorrectionStatusCdEnum.CORRECTION_DISABLED,
    InsCreateCorrectionStatusCdEnum.CORRECTION_DECLINED,
    InsCreateCorrectionStatusCdEnum.CORRECTION_UNKNOWN
  ];

  if (sameMessageStatuses.indexOf(statusCd as InsCreateCorrectionStatusCdEnum) > -1) {
    return getOverviewMessageFromCorrectionStatusCd(statusCd);
  } else {
    switch (statusCd) {
      case InsCreateCorrectionStatusCdEnum.CORRECTION_PROXY_FAILURE:
      case InsCreateCorrectionStatusCdEnum.ERROR_UNKNOWN:
      case InsCreateCorrectionStatusCdEnum.ERROR_NO_ORIGINAL_CHARGES:
      case InsCreateCorrectionStatusCdEnum.ERROR_NO_DIMENSIONS:
      case InsCreateCorrectionStatusCdEnum.ERROR_NO_EMPLOYEE:
      case InsCreateCorrectionStatusCdEnum.ERROR_NO_PREVIEW_CHARGES:
      case InsCreateCorrectionStatusCdEnum.ERROR_SHIPMENT_NOT_RATED:
        return ElsSubmissionOverviewCardMessageEnum.WILL_BE_PROCESSED_AT_FINAL_DELIVERY;
      case InsCreateCorrectionStatusCdEnum.ERROR_ELS_ALREADY_APPLIED:
        return ElsSubmissionOverviewCardMessageEnum.ERROR_ELS_ALREADY_APPLIED;
      case InsCreateCorrectionStatusCdEnum.ERROR_XLF_ALREADY_APPLIED:
        return ElsSubmissionOverviewCardMessageEnum.ERROR_XLF_ALREADY_APPLIED;
      case InsCreateCorrectionStatusCdEnum.ERROR_CORRECTION_EXISTS:
        return ElsSubmissionOverviewCardMessageEnum.ERROR_CORRECTION_EXISTS;
      case InsCreateCorrectionStatusCdEnum.ERROR_BILL_CLASS_INVALID:
        return ElsSubmissionOverviewCardMessageEnum.ERROR_BILL_CLASS_INVALID;
      case InsCreateCorrectionStatusCdEnum.ERROR_MOTORIZED_TOTAL_PIECES:
        return ElsSubmissionOverviewCardMessageEnum.ERROR_MOTORIZED_TOTAL_PIECES;
      case InsCreateCorrectionStatusCdEnum.NO_REVENUE_CHANGE:
        return ElsSubmissionOverviewCardMessageEnum.NO_REVENUE_CHANGE;
      case InsCreateCorrectionStatusCdEnum.ERROR_LINEAL_FOOT_INSPECTION_TYPE:
        return ElsSubmissionOverviewCardMessageEnum.LINEAL_FOOT_INSPECTION_TYPE;
    }
  }
}

export function isAnotherInspectionQuestionNeeded(statusCd: InsCreateCorrectionStatusCdEnum | string): boolean {
  switch (statusCd) {
    case InsCreateCorrectionStatusCdEnum.CORRECTION_INVALID:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_SAVED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_APPROVED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_APPLIED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_PENDING:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_DECLINED:
      return true;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_LOCKED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_DISABLED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_UNKNOWN:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_PROXY_FAILURE:
    case InsCreateCorrectionStatusCdEnum.ERROR_UNKNOWN:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_ORIGINAL_CHARGES:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_DIMENSIONS:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_EMPLOYEE:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_PREVIEW_CHARGES:
    case InsCreateCorrectionStatusCdEnum.ERROR_ELS_ALREADY_APPLIED:
    case InsCreateCorrectionStatusCdEnum.ERROR_XLF_ALREADY_APPLIED:
    case InsCreateCorrectionStatusCdEnum.ERROR_CORRECTION_EXISTS:
    case InsCreateCorrectionStatusCdEnum.ERROR_BILL_CLASS_INVALID:
    case InsCreateCorrectionStatusCdEnum.ERROR_SHIPMENT_NOT_RATED:
    case InsCreateCorrectionStatusCdEnum.ERROR_MOTORIZED_TOTAL_PIECES:
    case InsCreateCorrectionStatusCdEnum.NO_REVENUE_CHANGE:
    case InsCreateCorrectionStatusCdEnum.ERROR_LINEAL_FOOT_INSPECTION_TYPE:
      return false;
  }
}
