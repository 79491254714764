import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RecommendationRuleDialogComponent } from '../../dialogs/recommendation-rule-dialog/recommendation-rule-dialog.component';
import { DialogAction } from '../../enums/dialog-actions.enum';
import { AddFeatureName, ListName } from '../../enums/list-name.enum';
import { RouteLabels } from '../../enums/router-uri-components.enum';
import { AppNavigationService } from '../../services/app-navigation.service';
import { GridSettingsService } from '../../services/grid-settings/grid-settings.service';
import { RefreshDatesService } from '../../services/refresh-dates.service';
import { ListHeaderComponent } from '../list-header/list-header.component';
import { ListRecommendedRulesComponent } from './list-recommended-rules/list-recommended-rules.component';
import { RecommendedRulesService } from './services/recommended-rules.service';

@Component({
  selector: 'app-recommendation-rules',
  templateUrl: './recommendation-rules.component.html',
  styleUrls: ['./recommendation-rules.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RecommendationsRulesComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClass = 'recommendationRules';

  selectedListName = undefined;
  selectedCount = 0;
  listName = ListName;
  dateRange = '';
  columnsSorted: boolean;
  columnsFiltered: boolean;
  title: string;
  addingObjectName: string;
  private isAddEnabledSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isAddEnabled$: Observable<boolean> = this.isAddEnabledSubject.asObservable();

  @ViewChild(ListRecommendedRulesComponent, { static: true })
  public recommendedRulesList: ListRecommendedRulesComponent;

  @ViewChild(ListHeaderComponent, { static: true })
  public listHeaderComponent: ListHeaderComponent;

  public toolbarTitle: string;
  private unsubscriber = new Subject<void>();

  constructor(
    public router: Router,
    private gridSettings: GridSettingsService,
    private appNavigation: AppNavigationService,
    private changeDetector: ChangeDetectorRef,
    public recommendedRulesService: RecommendedRulesService,
    private dialog: MatDialog,
    public refreshDatesService: RefreshDatesService
  ) {}

  ngOnInit() {
    this.selectedListName = RouteLabels.RECOMMENDATION_RULES;
    this.columnsFiltered = this.gridSettings.hasFilterGridSettings(this.selectedListName);
    this.columnsSorted = this.gridSettings.hasSortGridSettings(this.selectedListName);
    this.title = this.selectedListName;
    this.addingObjectName = AddFeatureName.RECOMMENDATION_RULE;

    // NOTE: This timeout is to force the table to resize correctly when rendered (after becoming visible).
    setTimeout(() => {
      this.resetRowHeights();
    });
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  gridSettingsChanged() {
    this.columnsFiltered = this.gridSettings.hasFilterGridSettings(this.selectedListName);
    this.columnsSorted = this.gridSettings.hasSortGridSettings(this.selectedListName);
    this.updateGridSettingForListHeader();
    this.changeDetector.detectChanges();
  }

  onGridFilterCleared() {
    this.gridSettings.clearFilterGridSettings(this.selectedListName, this.recommendedRulesList.gridOptions);
  }

  onGridSortCleared() {
    this.gridSettings.clearSortGridSettings(this.selectedListName, this.recommendedRulesList.gridOptions);
  }

  onGridSettingsReset() {
    this.gridSettings.resetGridSettings(this.selectedListName, this.recommendedRulesList.gridOptions);
  }

  onGridSettingsLoaded() {
    this.gridSettings.loadListGridSettings(this.selectedListName, this.recommendedRulesList.gridOptions);
  }

  onGridSettingsSaved() {
    const list = this.getSelectedList();
    if (list && list.gridOptions) {
      this.gridSettings.saveListGridSettings(this.selectedListName);
    }
  }

  public getSelectedList(): any {
    return this.recommendedRulesList;
  }

  public resetRowHeights() {
    const list = this.getSelectedList();
    if (list && list.gridOptions && list.gridOptions.api) {
      list.gridOptions.api.resetRowHeights();
    }
  }

  /**
   *
   * @param value
   */
  public listShipmentsTabChanged(value: string) {
    this.appNavigation.setListShipmentsTab(<ListName>value);
  }

  onAddButtonClicked() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.autoFocus = true;
    matDialogConfig.disableClose = false;
    matDialogConfig.minWidth = '90%';
    matDialogConfig.minHeight = '90%';
    matDialogConfig.width = '90%';
    matDialogConfig.height = '90%';
    matDialogConfig.data = {
      title: 'Add Recommendation Rule',
      action: DialogAction.CREATE,
      isNew: true,
      rule: {}
    };

    const matDialogRef = this.dialog.open(RecommendationRuleDialogComponent, matDialogConfig);

    matDialogRef.afterClosed().subscribe((reload) => {
      if (reload) {
        this.recommendedRulesList.clearList();
        this.recommendedRulesList.getInformation();
      }
      this.recommendedRulesService.clear();
    });

    matDialogRef.backdropClick().subscribe(() => {
      this.recommendedRulesService.clear();
    });
  }

  onRefreshTriggered() {
    this.recommendedRulesList.clearList();
    this.recommendedRulesList.getInformation();
  }

  updateGridSettingForListHeader() {
    this.listHeaderComponent.gridSettingsChanged();
  }
}
