import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'firstInitialLastNamePipe'
})
export class FirstInitialLastNamePipe implements PipeTransform {
  transform(fullName: string): string {
    const splittedName: string[] = fullName?.split(' ');
    let displayName: string = '';

    if (splittedName?.length < 2) {
      displayName = fullName;
    } else {
      for (let i = 0; i < splittedName?.length; i++) {
        if (i === splittedName?.length - 1) {
          displayName = displayName.concat(` ${splittedName?.[i]}`);
        } else {
          displayName = displayName.concat(` ${splittedName?.[i][0]}`);
        }
      }
    }

    return displayName.trim();
  }
}
