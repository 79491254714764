import { Component } from '@angular/core';
import { LoadingOverlayService } from './service/loading-overlay.service';

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent {
  constructor(public loadingOverlayService: LoadingOverlayService) {}
}
