import { InspectionNotificationEvent } from './inspection-notification-event';
import { Photo } from '../photos/photo';

export class LocalStoragePhotoDeletedTooOld extends InspectionNotificationEvent {
  private _photo: Photo;

  constructor(photo: Photo, createDate: Date, msg: string | undefined) {
    super(msg, createDate);
    this._photo = photo;
  }

  get photo(): Photo {
    return this._photo;
  }

  /**
   * Override
   */
  logToConsole() {
    console.log('InspectionNotificationEvent->date, message, photo:', this.createDate, this.message, this.photo);
  }
}
