import { Beacon } from '@xpo-ltl/sdk-beacon';
import { BeaconStatusCd } from '@xpo-ltl/sdk-common';
import { BroadcastMessage as InspectionBroadcastMessage } from '@xpo-ltl/sdk-inspections';
import {
  BroadcastMessageBeaconSource,
  BroadcastMessageBeaconType,
  BroadcastMessagePriority
} from '../../constants/broadcast-messages/broadcast-message-beacon.const';
import { InspectionsDateUtils } from '../utils/inspections-date-utils';

export class BroadcastMessage {
  messageId: number;
  message: string;
  messageType: string;
  messageStatus: string;
  effectiveDate: Date;
  expirationDate: Date;
  correlationId: string;
  // #region Response
  itemInstId: number;
  response: string;
  // #endregion

  // #region Audit info
  /**
   * EmployeeId
   */
  createdBy: string;
  createdAt: string;
  /**
   * EmployeeId
   */
  updatedBy: string;
  updatedAt: string;
  // #endregion

  constructor(broadcastMessage?: InspectionBroadcastMessage) {
    if (broadcastMessage) {
      this.messageId = broadcastMessage.messageId;
      this.message = broadcastMessage.message;
      this.messageType = broadcastMessage.messageTypeCd;
      this.messageStatus = broadcastMessage.messageStatusCd;
      this.effectiveDate = new Date(broadcastMessage.effectiveDate);
      this.correlationId = broadcastMessage.correlationId;
      this.expirationDate = new Date(broadcastMessage.expirationDate);
      if (broadcastMessage.auditInfo) {
        this.createdBy = broadcastMessage.auditInfo.createdById;
        this.createdAt = broadcastMessage.auditInfo.createdTimestamp?.toString();
        this.updatedBy = broadcastMessage.auditInfo.updateById;
        this.updatedAt = broadcastMessage.auditInfo.updatedTimestamp?.toString();
      }
      this.itemInstId = broadcastMessage.externalId;
    }
  }

  private getBeacon(): Beacon {
    const beacon = new Beacon();
    beacon.typeCd = BroadcastMessageBeaconType;
    beacon.sourceCd = BroadcastMessageBeaconSource;
    beacon.priorityCd = BroadcastMessagePriority;
    return beacon;
  }

  /**
   * Time period in minutes after which message automatically expires.
   * 0 means that message never expires and can only be resolved by a person or a system
   */
  private getMinutesToExpiry(): number {
    if (!this.expirationDate) {
      return null;
    }
    return InspectionsDateUtils.getDifferenceInMinutes(this.effectiveDate, this.expirationDate);
  }

  getDisplayedStatus(): string {
    // TODO: When beacon implement the expiry date, this should change
    if (this.messageStatus === BeaconStatusCd.EXPIRED) {
      return 'Deleted';
    }
    if (this.messageStatus === BeaconStatusCd.RESOLVED) {
      return 'Sent';
    }
    return this.messageStatus;
  }

  isDeleted(): boolean {
    return this.messageStatus === BeaconStatusCd.EXPIRED;
  }

  getCreateMessage(): InspectionBroadcastMessage {
    const message = new InspectionBroadcastMessage();
    message.message = this.message;
    message.correlationId = this.correlationId;
    message.effectiveDate = InspectionsDateUtils.getFormatDate(this.effectiveDate, 'YYYY/MM/DD');
    if (this.expirationDate) {
      message.expirationDate = InspectionsDateUtils.getFormatDate(this.expirationDate, 'YYYY/MM/DD');
    }
    return message;
  }

  getSendMessage(): InspectionBroadcastMessage {
    const message = new InspectionBroadcastMessage();
    message.messageId = this.messageId;
    return message;
  }

  getUpdateBeacon(): Beacon {
    const beacon = this.getBeacon();
    beacon.message = this.message;
    beacon.messageId = this.messageId;
    beacon.statusCd = this.messageStatus as BeaconStatusCd;
    beacon.activeDateTime = this.effectiveDate;
    beacon.expiresInMinutes = this.getMinutesToExpiry();
    return beacon;
  }

  getCancelBeacon(): Beacon {
    const beacon = this.getBeacon();
    beacon.statusCd = BeaconStatusCd.EXPIRED;
    beacon.activeDateTime = this.effectiveDate;
    beacon.message = this.message;
    beacon.messageId = this.messageId;
    beacon.expiresInMinutes = this.getMinutesToExpiry();
    return beacon;
  }

  getContextBeacon(): Beacon {
    const beacon = this.getBeacon();
    beacon.message = this.message;
    beacon.messageId = this.messageId;
    beacon.correlationId = this.correlationId;
    return beacon;
  }
}
