import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InspectionCorrection, ListInspectionCorrectionsResp } from '@xpo-ltl/sdk-inspections';
import { GridOptions } from 'ag-grid-community';
import { List } from 'immutable';
import { Subject, catchError, map, takeUntil } from 'rxjs';
import { InspectionError } from '../../../classes/error/inspection-error';
import { AgGridHelper } from '../../../classes/helpers/ag-grid-helper';
import { InspectionCorrectionListItem } from '../../../classes/inspection-correction-list-item';
import { InspectionsConstants } from '../../../classes/inspections-constants';
import { ProNumber } from '../../../classes/pronumber';
import { ListName } from '../../../enums/list-name.enum';
import { ShipmentDetailsService } from '../../../services/api/shipment-details.service';
import { AppConstantsService } from '../../../services/app-constants.service';
import { AppNavigationService } from '../../../services/app-navigation.service';
import { GridSettingsService } from '../../../services/grid-settings/grid-settings.service';
import { HardwareService } from '../../../services/hardware/hardware-service';
import { RequestValidator } from '../../../validators/request.validator';
import { LoadingOverlayService } from '../../loading-overlay/service/loading-overlay.service';

@Component({
  selector: 'app-list-corrections',
  templateUrl: './list-corrections.component.html',
  styleUrls: ['./list-corrections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ListCorrectionsComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClass = 'list_InspectionCorrections';

  gridOptions: GridOptions;
  rowData: List<InspectionCorrectionListItem> = List();
  height: number;
  width: number;
  totalCount = 0;

  private unsubscriber: Subject<void> = new Subject();

  @Output()
  gridSettingsChanged = new EventEmitter<boolean>();

  constructor(
    private gridSettings: GridSettingsService,
    private dialog: MatDialog,
    private shipmentDetailsService: ShipmentDetailsService,
    protected changeDetector: ChangeDetectorRef,
    private constantsService: AppConstantsService,
    protected appNavigationService: AppNavigationService,
    private loadingOverlayService: LoadingOverlayService
  ) {
    this.gridOptions = <GridOptions>{};

    this.gridOptions.defaultColDef = AgGridHelper.getDefaultColDef();

    this.gridOptions.columnDefs = this.gridSettings.buildDefaultCorrectionsListColumnDefs();
    this.gridOptions.rowSelection = 'single';
    this.gridOptions.suppressMenuHide = HardwareService.isTouchScreenCapable();
    this.gridOptions.onCellDoubleClicked = this.onCellDoubleClicked.bind(this);
    this.gridOptions.onColumnMoved = this.columnsChanged.bind(this);
    this.gridOptions.onColumnPinned = this.columnsChanged.bind(this);
    this.gridOptions.onColumnResized = this.columnsChanged.bind(this);
    this.gridOptions.onFilterChanged = this.columnsChanged.bind(this);
    this.gridOptions.onColumnVisible = this.columnsChanged.bind(this);
    this.gridOptions.onSortChanged = this.columnsChanged.bind(this);
    this.gridOptions.onRowDataChanged = this.rowDataChanged.bind(this);
    this.gridOptions.icons = GridSettingsService.buildGridIcons();
    this.gridOptions.enableCellTextSelection = true;
    this.gridOptions.ensureDomOrder = true;
    this.gridOptions.onGridReady = () => {
      this.setHeightAndWidth();
    };
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  onResize($event): void {
    this.setHeightAndWidth();
  }

  setHeightAndWidth(): void {
    this.width = document.getElementsByClassName('app-container-content')[0].clientWidth;
    this.height = document.getElementsByClassName('app-container-content')[0].clientHeight - 50;
  }

  clearList(): void {
    this.rowData = List();
    this.totalCount = 0;
  }

  getInformation(startDate: Date, endDate: Date, isSearchForSic: boolean): void {
    const sic: string = this.constantsService.sicCode;
    RequestValidator.validateStringNotNullOrEmpty(sic, 'SIC');

    const filterAutoDensityInd: boolean = false;
    const filterAutoElsInd: boolean = false;

    if (!endDate) {
      endDate = new Date();
    }

    if (!startDate) {
      const today: Date = new Date();
      startDate = new Date(
        today.setDate(today.getDate() - InspectionsConstants.DEFAULT_MINUS_START_DATE_OFFSET)
      );
    }

    if (endDate < startDate) {
      const errorMessage: string = RequestValidator.END_START_DATE_ERROR_MESSAGE;
      throw new InspectionError(errorMessage);
    }

    this.loadingOverlayService.setIsLoading(true);
    this.shipmentDetailsService
      .listInspectionCorrections(sic, startDate, endDate, isSearchForSic, filterAutoDensityInd, filterAutoElsInd)
      .pipe(
        map((listInspectionCorrectionsResp: ListInspectionCorrectionsResp) => {
          let inspectionCorrectionItemList: List<InspectionCorrectionListItem> = List();
          if (listInspectionCorrectionsResp?.inspectionCorrections?.length > 0) {
            listInspectionCorrectionsResp.inspectionCorrections?.forEach((insCorrection: InspectionCorrection) => {
              inspectionCorrectionItemList = inspectionCorrectionItemList.push(
                new InspectionCorrectionListItem(insCorrection)
              );
            });
          }
          return inspectionCorrectionItemList;
        }),
        catchError((error) => {
          this.loadingOverlayService.setIsLoading(false);
          throw error;
        }),
        takeUntil(this.unsubscriber)
      )
      .subscribe((ListOfInspectionCorrectionListItem: List<InspectionCorrectionListItem>) => {
        this.rowData = List(ListOfInspectionCorrectionListItem);
        this.totalCount = this.rowData.size;
        this.loadingOverlayService.setIsLoading(false);
        this.changeDetector.detectChanges();
      });
  }

  private columnsChanged($event): void {
    this.gridSettings.storeGridState(ListName.INSPECTION_CORRECTIONS, this.gridOptions);
    this.gridSettingsChanged.emit(true);
  }

  protected navigateToShipmentDetails(data: InspectionCorrectionListItem) {
    this.appNavigationService.navigateToShipmentDetails(new ProNumber(data.proNbr));
  }

  private onCellDoubleClicked($event) {
    this.navigateToShipmentDetails($event.data);
  }

  private rowDataChanged($event): void {
    this.gridSettings.restoreGridState(ListName.INSPECTION_CORRECTIONS, this.gridOptions);
    this.setHeightAndWidth();
  }
}
