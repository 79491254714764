import { ShipmentListItemComponent } from './shipment-list-item-component';
import { ChangeDetectorRef } from '@angular/core';
import { AppNavigationService } from '../services/app-navigation.service';
import { InspectionListItem } from './inspection-list-item';

export abstract class InspectionListItemComponent extends ShipmentListItemComponent<InspectionListItem> {
  constructor(protected changeDetector: ChangeDetectorRef, protected appNavigation: AppNavigationService) {
    super(changeDetector, appNavigation);
  }
}
