import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { GetInspectionShipmentDetailsResp } from '@xpo-ltl/sdk-inspections';
import { Observable, take } from 'rxjs';
import { InspectionShipmentDetails } from '../../../classes/inspection-shipment-details';
import { DialogWrapperService } from '../../../services/dialog-wrapper.service';
import { InspectionCorrectionService } from '../service/inspection-correction.service';

@Component({
  selector: 'ins-corrections-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnChanges {
  @HostBinding('class') hostClass = 'insCorrection-footer';

  @Input()
  shipmentDetails: GetInspectionShipmentDetailsResp;

  @Output()
  onSaveButtonClicked = new EventEmitter<boolean>();
  @Output()
  onCancelButtonClicked = new EventEmitter<boolean>();
  @Output()
  onPreviewButtonClicked = new EventEmitter<boolean>();
  @Output()
  onSubmitButtonClicked = new EventEmitter<boolean>();

  isBillStatusRated: boolean;

  private readonly REPLACE_STRING_FOR_ACTION: string = '{action}';
  private readonly ACTION_CONFIRMATION_MESSAGE: string = `Do you want to ${this.REPLACE_STRING_FOR_ACTION} this correction request?`;
  private readonly PROCEED_CANCEL_CANNOT_EDIT: string = `If you Cancel you will no longer be able to edit or submit the Correction Request.  But can still create a new Correction Request in the future if needed.`;

  constructor(
    private dialogWrapperService: DialogWrapperService,
    public inspectionCorrectionService: InspectionCorrectionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shipmentDetails?.currentValue) {
      this.initData();
    }
  }

  onSaveClicked() {
    const messageBody: string = this.getMessageBody('save');

    return this.openConfirmCancelDialog(messageBody)
      .pipe(take(1))
      .subscribe((resp: boolean) => {
        this.onSaveButtonClicked.emit(resp);
      });
  }

  onCancelClicked() {
    let messageBody: string = this.getMessageBody('cancel');
    messageBody = messageBody.concat(this.PROCEED_CANCEL_CANNOT_EDIT);

    return this.openConfirmCancelDialog(messageBody)
      .pipe(take(1))
      .subscribe((response: boolean) => {
        this.onCancelButtonClicked.emit(response);
      });
  }

  onAutoRatePreviewClicked() {
    this.onPreviewButtonClicked.emit(true);
  }

  onSubmitClicked() {
    const messageBody: string = this.getMessageBody('submit');

    return this.openConfirmCancelDialog(messageBody)
      .pipe(take(1))
      .subscribe((response: boolean) => {
        this.onSubmitButtonClicked.emit(response);
      });
  }

  private getMessageBody(actionString: string): string {
    return this.ACTION_CONFIRMATION_MESSAGE.replace(this.REPLACE_STRING_FOR_ACTION, actionString);
  }

  private initData() {
    if (this.shipmentDetails?.shipmentDetails) {
      const inspectionShipmentDetails: InspectionShipmentDetails = new InspectionShipmentDetails(
        this.shipmentDetails.shipmentDetails
      );
      this.isBillStatusRated = inspectionShipmentDetails.isRated;
    }
  }

  private openConfirmCancelDialog(messageBody: string): Observable<boolean> {
    return this.dialogWrapperService.showConfirmCancelDialog(messageBody).pipe(take(1));
  }
}
