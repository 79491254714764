import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingOverlayService {
  private isLoadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  isLoading$: Observable<boolean> = this.isLoadingSubject$.asObservable();

  setIsLoading(isLoading: boolean) {
    this.isLoadingSubject$.next(isLoading);
  }
}
