export enum InspectionErrorCdEnum {
  INSPECTION_MAPPING_ERROR = 'INSN999-005E',
  PICKUP_REQUEST_MAPPING_ERROR = 'INSN999-003E',
  RCMND_SHPMT_CREATE = 'INSN040-001E',
  SHIPMENT_MAPPING_ERROR = 'INSN999-002E',
  TRAILER_MANIFEST_MAPPING_ERROR = 'INSN999-004E',
  UNEXPECTED_EXCEPTION = 'INSN999-999E',
  UNHANDLED_SERVICE_EXCEPTION = 'INSN999-001E',

  CUST_GUIDELINE_NOT_FOUND = 'INSN010-009E',
  DENSITY_NOT_FOUND = 'INSN010-003E',
  INSPECTION_DIMENSIONS_MISSING = 'INSN010-005E',
  INSPECTION_RECORD_MISSING = 'INSN010-004E',
  INSPECTION_SUBMIT_MSNG_SHPMT = 'INSN010-006E',
  REC_RULE_ACCT_INVALID = 'INSN010-008E',
  REC_RULE_NOT_FOUND = 'INSN010-007E',
  SHIPMENT_INSPECTION_NOT_FOUND = 'INSN010-001E',
  SHIPMENT_NOT_FOUND = 'INSN010-002E',

  FIELD_MISSING_OR_BLANK = 'INSN020-001E',
  INSPECTION_STATE_CHANGE = 'INSN021-008E',
  INSPECTION_STATE_CONFIRM_RQRD = 'INSN021-007E',
  INSPECTION_STATE_IDENTICAL = 'INSN021-006E',
  INSPECTION_STATUS_INVALID = 'INSN021-009E',
  PLANNING_STATUS_INVALID = 'INSN021-010E',
  PRO_BLANK = 'INSN021-019E',
  PRO_CHECK_DIGIT = 'INSN021-020E',
  PRO_FORMAT_INVALID = 'INSN021-018E',
  REC_RULE_ACCT_BILLTO = 'INSN021-016E',
  REC_RULE_ACCT_IDENTICAL = 'INSN021-015E',
  REC_RULE_ACCT_PND = 'INSN021-017E',
  REC_RULE_CONFLICT = 'INSN021-013E',
  REC_RULE_EXISTS = 'INSN021-014E',
  REC_RULE_IDENTICAL = 'INSN021-012E',
  SHIPMENT_CMDY_MISSING = 'INSN021-002E',
  SHIPMENT_CMDY_NMFC_MISSING = 'INSN021-003E',
  SHIPMENT_CMDY_VOL_MISSING = 'INSN021-004E',
  SHIPMENT_CMDY_WGHT_MISSING = 'INSN021-005E',
  SHIPMENT_PENDING = 'INSN021-011E',
  VALIDATION_ERRORS_FOUND = 'INSN021-001E'
}
