<div class="pdf-viewer">
  <div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="sticky">
    <xpo-ltl-dialog-title [options]="{ moveable: false }"> 
     <ng-container [ngTemplateOutlet]="headerContent"></ng-container>
    </xpo-ltl-dialog-title> 
  </div>
  <div class="pdf-body">
    <xpo-responsive-loading-spinner *ngIf="loading$ | async"></xpo-responsive-loading-spinner>
    <pdf-viewer [src]="combinedPdf" [zoom]="zoom"></pdf-viewer>
  </div>
  <ng-template #headerContent>
    <span class="appointmentDetailChangeloDialog__header">
      Inspection Details
      <button mat-button (click)="incrementZoom(-0.25)">
        <mat-icon class="zoom-icon">zoom_out</mat-icon>
      </button>
      <button mat-button (click)="incrementZoom(0.25)">
        <mat-icon class="zoom-icon">zoom_in</mat-icon>
      </button>
    </span>
  </ng-template>
</div>
