import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';

export abstract class XpoSnackBarService {
  public static readonly DEFAULT_DURATION: number = 5_000;
  public static readonly DEFAULT_ERROR_DURATION: number = 10_000;

  constructor(protected xpoSnackBar: XpoSnackBar) {}

  error(e: any, snackBarOpenDurationMillis?: number): void {
    console.error('error:', e);
    this.xpoSnackBar.open({
      message: this.getErrorMessage(e),
      status: 'error',
      matConfig: {
        duration: this.getSnackBarDuration(snackBarOpenDurationMillis)
      }
    });
  }

  warning(e: any, snackBarOpenDurationMillis?: number): void {
    console.warn('error:', e);
    this.xpoSnackBar.open({
      message: e.error?.message ?? e.message ?? e,
      status: 'warn',
      matConfig: {
        duration: this.getSnackBarDuration(snackBarOpenDurationMillis)
      }
    });
  }

  success(e: any, snackBarOpenDurationMillis?: number): void {
    this.xpoSnackBar.open({
      message: e.error?.message ?? e.message ?? e,
      status: 'success',
      matConfig: {
        duration: this.getSnackBarDuration(snackBarOpenDurationMillis)
      }
    });
  }

  /**
   * milliseconds
   *
   * @param snackBarOpenDurationMs
   * @private
   * @param durationIfNotSpecifiedMs
   * @return {number}
   */
  private getSnackBarDuration(snackBarOpenDurationMs: number, durationIfNotSpecifiedMs?: number): number {
    let result: number = XpoSnackBarService.DEFAULT_DURATION;
    if (!isNaN(snackBarOpenDurationMs)) {
      result = snackBarOpenDurationMs;
    } else if (!isNaN(durationIfNotSpecifiedMs)) {
      result = durationIfNotSpecifiedMs;
    }
    return result;
  }

  info(e: any, snackBarOpenDurationMillis?: number): void {
    this.xpoSnackBar.open({
      message: e.error?.message ?? e.message ?? e,
      status: 'success',
      matConfig: {
        duration: this.getSnackBarDuration(snackBarOpenDurationMillis)
      }
    });
  }

  private getErrorMessage(e: any): string {
    const result: string = e.message ?? e.error?.message ?? e.error?.error?.message ?? e;
    return result;
  }
}
