<div class="inspectionDetails" *ngIf="inspectionDetailsResp?.inspection; let detail">
  <div class="inspection-id">PRO: {{ detail.shipmentId.proNumber }}</div>
  <div class="first-row">
    <span class="header-label__timestamp">
      {{ detail.inspectionDateTime | dateMilitaryFormat }}
    </span>
    <span class="header-label">
      by {{ detail.inspectionContext?.inspectorFirstNm }} {{ detail.inspectionContext?.inspectorLastNm }}
    </span>
    <span class="header-label__sic-status"> at {{ detail.inspectionContext?.inspectionSic }} </span>
    <span class="header-label__sic-status"> Status: {{ detail.inspectionStatusCd | inspectionStatusCode }} </span>
    <span *ngIf="detail.inspectionCorrectionTypeCd" class="header-label">
      Correction Type: {{ detail.inspectionCorrectionTypeCd | spacedStringFromTitleCase }}
    </span>
  </div>

  <div class="second-row">
    <div class="left">
      <div class="summary">
        <div class="header-label">
          Weight: <span class="value">{{ detail.totGrossWeight | number }}</span>
        </div>
        <div class="header-label">
          Cubic Feet: <span class="value">{{ detail.totGrossVolume | toFormattedVolume }}</span>
        </div>
        <div class="header-label">
          Density (PCF): <span class="value">{{ calculateDensity(detail) | toFormattedDensity }}</span>
        </div>
      </div>

      <table mat-table [dataSource]="detail.inspectorPieceDimensions">
        <ng-container matColumnDef="pieceCnt">
          <th class="header-label" mat-header-cell *matHeaderCellDef>Pieces</th>
          <td mat-cell *matCellDef="let element">{{ element.pieceCnt }}</td>
        </ng-container>

        <ng-container matColumnDef="length">
          <th class="header-label" mat-header-cell *matHeaderCellDef>Length(in)</th>
          <td mat-cell *matCellDef="let element">{{ element.dimensions.length }}</td>
        </ng-container>

        <ng-container matColumnDef="width">
          <th class="header-label" mat-header-cell *matHeaderCellDef>Width(in)</th>
          <td mat-cell *matCellDef="let element">{{ element.dimensions.width }}</td>
        </ng-container>

        <ng-container matColumnDef="height">
          <th class="header-label" mat-header-cell *matHeaderCellDef>Height(in)</th>
          <td mat-cell *matCellDef="let element">{{ element.dimensions.height }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <div class="horizontal-divider"></div>

    <div class="right">
      <div class="header-label">Notes:</div>
      <div>{{ detail.inspectionNote?.note }}</div>
    </div>
  </div>
</div>
