import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { InspectionContext } from '@xpo-ltl/sdk-inspections';
import { Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { GeneralPreferences } from '../classes/general-preferences';
import { SicShiftPreference } from '../classes/sic-shift-preference';
import { ChangeLocationComponent } from '../dialogs/change-location/change-location.component';
import { ErrorMessageActions } from '../enums/error-message-actions.enum';
import { AppConstantsService } from '../services/app-constants.service';
import { DialogWrapperService } from '../services/dialog-wrapper.service';
import { MobileWebBrowserService } from '../services/hardware/mobile-web-browser-service';
import { LocationService } from '../services/location/location.service';
import { SnackBarHandlingService } from '../services/snack-bar-handling.service';
import { UserPreferencesService } from '../services/user-preferences.service';
import { UserService } from '../services/user.service';

@Injectable()
export class UserProfileGuard implements CanActivate {
  constructor(
    private appConstantsService: AppConstantsService,
    private dialogManager: DialogWrapperService,
    private mobileWebBrowserService: MobileWebBrowserService,
    private userPreferenceService: UserPreferencesService,
    private userService: UserService,
    private snackBarHandlingService: SnackBarHandlingService,
    private locationService: LocationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    // Load the user settings
    if (!this.appConstantsService.isGeneralUserPreferencesLoaded) {
      this.loadGeneralUserPreferences();
    }

    if (this.appConstantsService.sicCode && this.appConstantsService.shiftCode) {
      if (!this.locationService.isCorrectionsFeatureLoaded) {
        this.setFeatureFlagValue();
      }
      return true;
    } else {
      return this.userPreferenceService.loadSicShiftUserPreference().pipe(
        take(1),
        map((preference: SicShiftPreference) => {
          if (preference?.sic && preference?.shift) {
            this.appConstantsService.setInspectionContext(
              preference.sic,
              preference.shift,
              this.userService.currentUserFirstName,
              this.userService.currentUserLastName,
              this.userService.currentUserEmployeeId
            );
            if (!this.locationService.isCorrectionsFeatureLoaded) {
              this.setFeatureFlagValue();
            }

            return true;
          } else {
            this.showSicShiftUserPreferenceSetupDialog();
          }
        })
      );
    }
  }

  private showSicShiftUserPreferenceSetupDialog() {
    this.dialogManager.alert(
      {
        titleText: 'Please setup your Home SIC and Shift',
        contentComponent: ChangeLocationComponent,
        injectedData: of({ isSuccess: false }),
        hideDismissButton: true
      },
      {
        disableClose: true,
        width: '600'
      }
    );
  }

  private loadGeneralUserPreferences() {
    this.appConstantsService.isGeneralUserPreferencesLoaded = true;
    this.userPreferenceService
      .loadGeneralUserPreferences()
      .pipe(take(1))
      .subscribe(
        (response: string) => {
          if (response) {
            // We parse the value of general user preferences
            const preference = JSON.parse(response);

            if (this.mobileWebBrowserService.canSetZoomLevel() && preference.getacZoomlevel) {
              // We set the getac zoom level preference
              const zoomLevel = GeneralPreferences.getZoomLevelValue(preference.getacZoomlevel);
              this.mobileWebBrowserService.setZoomLevel(zoomLevel);
            }
          }
        },
        (error) => {
          this.snackBarHandlingService.handleResponseError(
            error,
            ErrorMessageActions.LOADING,
            UserPreferencesService.SOURCE_NAME
          );
        }
      );
  }

  private setFeatureFlagValue() {
    const employeeId: string = this.appConstantsService.inspectionContext.inspectorEmployeeId;
    const sicCd: string = this.appConstantsService.inspectionContext.inspectionSic;

    this.locationService.setIsCorrectionsFeatureAvailable(employeeId, sicCd);
  }
}
