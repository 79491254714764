import { InspectionCorrection } from '@xpo-ltl/sdk-inspections';
import { CorrectionType } from '../enums/correction-type.enum';
import { RequestValidator } from '../validators/request.validator';
import { ProNumber } from './pronumber';

export class InspectionCorrectionListItem {
  static readonly NOT_AVAILABLE = 'N/A Auto-ELS';

  proNbr: string;
  inspectionId: number;
  correctionRequestIdOrCellValue: string; // We use this as cellRendererFramework value and some shipments will have 'N/A Auto-ELS' value instead of ID.
  correctionId: number;
  correctionTypeCd: string;
  correctionStatusCd: string;
  inspectionInspectorFullName: string;
  correctionInspectorFullName: string;
  revenueImpact: number;
  updatedTimestamp: Date;
  shortDescription: string;
  autoCorrectionInd: string; // Display 'Yes' if its true and don’t display anything if its false/undefined/null

  constructor(inspectionCorrection: InspectionCorrection) {
    RequestValidator.validateObjectNotUndefinedOrEmpty(inspectionCorrection, 'InspectionCorrection');

    this.proNbr = inspectionCorrection.proNbr ? new ProNumber(inspectionCorrection.proNbr).formatProNumber() : '';
    this.inspectionId = inspectionCorrection.inspectionId;
    this.correctionRequestIdOrCellValue = this.getCorrectionRequestIdOrCellValue(inspectionCorrection);
    this.correctionId = inspectionCorrection.correctionId;
    this.correctionTypeCd = inspectionCorrection.correctionTypeCd;
    this.correctionStatusCd = inspectionCorrection.correctionStatusCd;
    this.correctionInspectorFullName = inspectionCorrection.correctionRequestId
      ? inspectionCorrection.correctionInspectorFullName
      : '';
    this.inspectionInspectorFullName = inspectionCorrection.inspectionId
      ? inspectionCorrection.inspectionInspectorFullName
      : '';
    inspectionCorrection.revenueImpact
      ? (this.revenueImpact = inspectionCorrection.revenueImpact)
      : (this.revenueImpact = 0);
    this.updatedTimestamp = inspectionCorrection.updatedTimestamp;
    this.shortDescription = inspectionCorrection.shortDescription;
    this.autoCorrectionInd = inspectionCorrection.autoCorrectionInd ? 'Yes' : ''; // Display 'Yes' if its true and don’t display anything if its false/undefined/null
  }

  private getCorrectionRequestIdOrCellValue(inspectionCorrection: InspectionCorrection): string {
    let result = '';

    if (!isNaN(inspectionCorrection.correctionRequestId)) {
      result = inspectionCorrection.correctionRequestId.toString();
    } else if (inspectionCorrection.autoCorrectionInd && inspectionCorrection.correctionTypeCd === CorrectionType.ELS) {
      result = InspectionCorrectionListItem.NOT_AVAILABLE;
    }

    return result;
  }
}
