<mat-expansion-panel style="margin-bottom: 8px" [(expanded)]="isExpanded">
  <mat-expansion-panel-header>
    <mat-panel-title class="section-label"
      >Previous Inspections ({{ previousInspections?.length || '0' }})</mat-panel-title
    >
  </mat-expansion-panel-header>

  <div *ngIf="!previousInspections || previousInspections.length == 0" fxLayoutAlign="start center">
    No Previous Inspections
  </div>

  <ng-container *ngFor="let prev of previousInspections">
    <mat-expansion-panel style="margin-bottom: 3px">
      <mat-expansion-panel-header>
        <mat-panel-title class="section-sub-label">
          {{ prev.lastUpdateDateTime | dateMilitaryFormat }}
          <span class="header-label" style="margin-left: 10px">
            by {{ prev.inspectionContext.inspectorFirstNm }} {{ prev.inspectionContext.inspectorLastNm }}
          </span>
          <span class="header-label" style="margin-left: 3px"> at {{ prev.inspectionContext.inspectionSic }} </span>
          <span class="header-label" style="margin-left: 10px">
            Status: {{ prev.inspectionStatusCd | inspectionStatusCode }}
          </span>
          <span *ngIf="prev.inspectionCorrectionTypeCd" class="header-label" style="margin-left: 10px">
            Correction Type: {{ prev.inspectionCorrectionTypeCd | spacedStringFromTitleCase }}
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxFlex="35">
        <div class="first_row">
          <div fxLayout="column" fxFlex="33">
            <div class="header-label">
              Weight: <span class="value">{{ calculateWeight(prev) | number }}</span>
            </div>
          </div>
          <div fxLayout="column" fxFlex="33">
            <div class="header-label">
              Cubic Feet: <span class="value">{{ calculateCuFt(prev) | number }}</span>
            </div>
          </div>
          <div fxLayout="column" fxFlex="33">
            <div class="header-label">
              Density (PCF): <span class="value">{{ calculatePCF(prev) | number }}</span>
            </div>
          </div>
        </div>
        <table mat-table [dataSource]="prev.inspectorPieceDimensions">
          <ng-container matColumnDef="pieceCnt">
            <th class="header-label" mat-header-cell *matHeaderCellDef>Pieces</th>
            <td mat-cell *matCellDef="let element">{{ element.pieceCnt }}</td>
          </ng-container>

          <ng-container matColumnDef="length">
            <th class="header-label" mat-header-cell *matHeaderCellDef>Length(in)</th>
            <td mat-cell *matCellDef="let element">{{ element.dimensions.length }}</td>
          </ng-container>

          <ng-container matColumnDef="width">
            <th class="header-label" mat-header-cell *matHeaderCellDef>Width(in)</th>
            <td mat-cell *matCellDef="let element">{{ element.dimensions.width }}</td>
          </ng-container>

          <ng-container matColumnDef="height">
            <th class="header-label" mat-header-cell *matHeaderCellDef>Height(in)</th>
            <td mat-cell *matCellDef="let element">{{ element.dimensions.height }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <div class="horizontal-divider" fxFlex="2"></div>
      <div fxLayout="column" fxFlex="63">
        <div class="header-label">Notes:</div>
        <div>{{ prev.inspectionNote?.note }}</div>
      </div>
    </mat-expansion-panel>
  </ng-container>
</mat-expansion-panel>
