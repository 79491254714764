import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ListName } from '../../enums/list-name.enum';
import { AppNavigationService } from '../../services/app-navigation.service';
import { GridSettingsService } from '../../services/grid-settings/grid-settings.service';
import { RefreshDatesService } from '../../services/refresh-dates.service';
import { ListHeaderComponent } from '../list-header/list-header.component';
import { ListCorrectionsComponent } from './list-corrections/list-corrections.component';

@Component({
  selector: 'app-list-inspection-corrections',
  templateUrl: './list-inspection-corrections.component.html',
  styleUrls: ['./list-inspection-corrections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ListInspectionCorrectionsComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClass = 'listInspectionCorrections';

  selectedListName: ListName;
  listName = ListName;
  dateRange = '';
  columnsSorted: boolean;
  columnsFiltered: boolean;
  title: string;
  addingObjectName: string;
  private isAddEnabledSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isAddEnabled$: Observable<boolean> = this.isAddEnabledSubject.asObservable();
  private unsubscriber: Subject<void> = new Subject();

  @ViewChild(ListCorrectionsComponent, { static: true })
  public listCorrectionsComponent: ListCorrectionsComponent;

  @ViewChild(ListHeaderComponent, { static: true })
  public listHeaderComponent: ListHeaderComponent;

  constructor(
    private gridSettings: GridSettingsService,
    protected appNavigation: AppNavigationService,
    protected changeDetector: ChangeDetectorRef,
    public refreshDatesService: RefreshDatesService
  ) {
    this.gridSettings.setDefaultFilterForInspectionCorrectionList();
  }

  private subscription = new Subscription();

  ngOnInit() {
    this.selectedListName = ListName.INSPECTION_CORRECTIONS;
    this.columnsFiltered = this.gridSettings.hasFilterGridSettings(this.selectedListName);
    this.columnsSorted = this.gridSettings.hasSortGridSettings(this.selectedListName);
    this.title = this.selectedListName;
    this.addingObjectName = undefined; // no add feature for inspection corrections list
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  gridSettingsChanged() {
    this.columnsFiltered = this.gridSettings.hasFilterGridSettings(this.selectedListName);
    this.columnsSorted = this.gridSettings.hasSortGridSettings(this.selectedListName);
    this.updateGridSettingForListHeader();
    this.changeDetector.detectChanges();
  }

  onRefreshTriggered() {
    this.listCorrectionsComponent.clearList();
    this.listCorrectionsComponent.getInformation(
      this.listHeaderComponent.startDate,
      this.listHeaderComponent.endDate,
      this.listHeaderComponent.isSearchForSic
    );
  }

  onGridFilterCleared() {
    this.gridSettings.clearFilterGridSettings(this.selectedListName, this.listCorrectionsComponent.gridOptions);
  }

  onGridSortCleared() {
    this.gridSettings.clearSortGridSettings(this.selectedListName, this.listCorrectionsComponent.gridOptions);
  }

  onGridSettingsSaved() {
    const list = this.getSelectedList();
    if (list && list.gridOptions) {
      this.gridSettings.saveListGridSettings(this.selectedListName);
    }
  }

  getSelectedList(): any {
    return this.listCorrectionsComponent;
  }

  onGridSettingsLoaded() {
    this.gridSettings.loadListGridSettings(this.selectedListName, this.listCorrectionsComponent.gridOptions);
  }

  onGridSettingsReset() {
    this.gridSettings.resetGridSettings(this.selectedListName, this.listCorrectionsComponent.gridOptions);
  }

  updateGridSettingForListHeader() {
    this.listHeaderComponent.gridSettingsChanged();
  }

  onHeaderFormGroupValueChanged(event) {
    if (event) {
      this.listCorrectionsComponent.getInformation(event.startDate, event.endDate, event.isSearchForSic);
    }
  }
}
