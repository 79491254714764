export enum DocumentTypeIconName {
  BLAT = `document-type-BLAT`,
  BL = 'document-type-BL',
  CUST = 'document-type-CUST',
  WRFO = 'document-type-WRFO',
  NCIC = 'document-type-NCIC',
  WI = 'document-type-WI',
  PDFO = 'document-type-PDFO',
  DDFO = 'document-type-DDFO',
  UNKNOWN = 'document-type-UNKNOWN',
  BLANK = 'document-type-BLANK'
}
