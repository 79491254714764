/**
 * Generic class for custom errors
 */
export class InspectionError extends Error {
  protected cause: any;
  constructor(aMessage: string, aCause?: any) {
    super(aMessage); // call the parent constructor
    this.name = 'InspectionError'; // set the name property
    this.cause = aCause;
  }
}
