export enum InsCreateCorrectionStatusCdEnum {
  CORRECTION_INVALID = 'CorrectionNullStatus',
  CORRECTION_LOCKED = 'CorrectionLockedStatus',
  CORRECTION_SAVED = 'CorrectionSavedStatus',
  CORRECTION_APPROVED = 'CorrectionApprovedStatus',
  CORRECTION_APPLIED = 'CorrectionAppliedStatus',
  CORRECTION_PENDING = 'CorrectionPendingStatus',
  CORRECTION_DISABLED = 'CorrectionDisabledStatus',
  CORRECTION_DECLINED = 'CorrectionDeclinedStatus',
  CORRECTION_UNKNOWN = 'CorrectionUnknownStatus',
  CORRECTION_PROXY_FAILURE = 'CorrectionProxyFailure',
  ERROR_BILL_CORRECTION_SYSTEM = 'ErrorBillCorrectionSystem',
  ERROR_UNKNOWN = 'ErrorUnknown',
  ERROR_NO_ORIGINAL_CHARGES = 'ErrorNoOriginalCharges',
  ERROR_NO_DIMENSIONS = 'ErrorNoDimensions',
  ERROR_NO_EMPLOYEE = 'ErrorNoEmployee',
  ERROR_NO_PREVIEW_CHARGES = 'ErrorNoPreviewCharges',
  ERROR_ELS_ALREADY_APPLIED = 'ErrorElsAlreadyApplied',
  ERROR_XLF_ALREADY_APPLIED = 'ErrorXlfAlreadyApplied',
  ERROR_XCC_ALREADY_APPLIED = 'ErrorXccAlreadyApplied',
  ERROR_CORRECTION_EXISTS = 'ErrorCorrectionExists',
  ERROR_BILL_CLASS_INVALID = 'ErrorBillClassInvalid',
  ERROR_SHIPMENT_NOT_RATED = 'ErrorShipmentNotRated',
  ERROR_MOTORIZED_TOTAL_PIECES = 'ErrorMotorizedGreaterThanTotalPieces',
  ERROR_LINEAL_FOOT_INSPECTION_TYPE = 'ErrorLinealFootInspectionType',
  NO_REVENUE_CHANGE = 'NoRevenueChange'
}
