/**
 * Might be refactored using DocType from
 * import {DocType} from "@xpo-ltl/sdk-dms";
 */
export enum DmsDocType {
  /**
   * Bill of Landing.
   * Category: Document.
   */
  BILL_OF_LANDING = 'BL',
  /**
   * Bill of Landing Attachment.
   * Category: Document.
   */
  BILL_OF_LANDING_ATTACHMENT = 'BLAT',
  /**
   * Customs.
   * Category: Document.
   */
  CUSTOMS = 'CUST',
  /**
   * Inspection Certificate.
   * Category: Document.
   */
  INSPECTION_CERTIFICATE = 'NCIC',
  /**
   * Reweigh Certificate.
   * Category: Document.
   */
  REWEIGH_CERTIFICATE = 'WI',
  /**
   * Dock Dimensioner Foto.
   * Category: Inspection Photos.
   */
  DOCK_DIMENSIONER_FOTO = 'DDFO',
  /**
   * Dimensioner Photo.
   * Category: Inspection Photos.
   */
  DIMENSION_PHOTO = 'DMNR',
  /**
   * Inspection Photo.
   * Category: Operations Photos.
   */
  INSPECTION_PHOTO = 'WRFO',
  /**
   * Trailer Photo.
   * Category: Operations Photos.
   */
  TRAILER_PHOTO = 'TRFO',
  /**
   * Pickup Dimensioner Photo.
   * Category: Operations Photos.
   */
  PICKUP_DIMENSIONER_PHOTO = 'PDFO',
  /**
   * Cubiscan Dimensioner Photo.
   * Category: Operations Photos.
   */
  CUBIC_SCAN_DIMENSIONER_PHOTO = 'DDFO',
  /**
   * Other Photo/Document.
   */
  OTHER = 'OTHER'
}
