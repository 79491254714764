export enum ZoomLevel {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

export const ZoomLevelValues = new Map<ZoomLevel, number>([
  [ZoomLevel.SMALL, 0],
  [ZoomLevel.MEDIUM, 1.25],
  [ZoomLevel.LARGE, 2.25]
]);
