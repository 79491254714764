import { Injectable } from '@angular/core';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { ErrorMessageActions } from '../enums/error-message-actions.enum';
import { XpoSnackBarService } from './xpo-snack-bar-service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService extends XpoSnackBarService {
  constructor(protected xpoSnackBar: XpoSnackBar) {
    super(xpoSnackBar);
  }

  public buildResponseErrorMessage(message: string, srcError: any): string {
    let lError: any = srcError;
    if (typeof srcError === 'function') {
      const calledSrcErrorRes = srcError.call(undefined);
      if (calledSrcErrorRes) {
        lError = calledSrcErrorRes;
      }
    }

    if (lError?.error?.message) {
      message = `${message} ${lError.error.message}`;
    } else if (lError?.message) {
      message = `${message}  ${lError.message}`;
    } else if (lError?.constructor?.name === 'ProgressEvent') {
      message = `${message} Communication Error!`;
    } else if (lError) {
      message = `${message} ${lError}`;
    }

    return message;
  }

  public buildGeneralErrorMessage(message: string, error: any = null): string {
    if (error?.error?.message) {
      message = `${message} ${error.error.message}`;
    } else if (error?.error) {
      message = `${message} ${error.error}`;
    } else if (error?.message) {
      message = `${message} ${error.message}`;
    } else if (error) {
      message = `${message} ${error}`;
    }

    return message;
  }

  public buildDisplayErrorMessage(action: string, sourceName: string): string {
    return `Error ${action} ${sourceName}!`;
  }

  public showErrorMessage(message: string, reason?: string) {
    if (reason) {
      this.error(reason + ': ' + message);
    } else {
      this.error(message);
    }
  }

  public handleResponseError(
    error: any,
    action: ErrorMessageActions,
    sourceName: string,
    isMoreInfoError: boolean = false
  ): void {
    const errorLocation: string = this.buildDisplayErrorMessage(action, sourceName);
    let snackBarMessage: string;

    if (isMoreInfoError) {
      snackBarMessage = this.buildResponseErrorMessage(errorLocation, error?.error?.moreInfo?.[0]?.message);
    } else {
      snackBarMessage = this.buildResponseErrorMessage(errorLocation, error);
    }

    this.error(snackBarMessage);
  }

  handleError(msg: string, error: Error) {
    console.error(this.buildResponseErrorMessage(msg, error));
    const snackBarMessage = this.buildResponseErrorMessage(msg, error);
    this.showErrorMessage(snackBarMessage);
  }
}
