import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { SnackBarHandlingService } from './snack-bar-handling.service';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * Dont use this, this is only as default error handler, error handling should be handled by SnackBarHandlingService class
 */
@Injectable({
  providedIn: 'root'
})
export class InspectionDefaultErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(srcError: any): void {
    const snackBarHandlingService: SnackBarHandlingService = this.injector.get(SnackBarHandlingService);
    let lError: any = srcError;
    if (typeof srcError === 'function') {
      const calledSrcErrorRes = srcError.call(undefined);
      if (calledSrcErrorRes) {
        lError = calledSrcErrorRes;
      }
    }
    console.error('Default error handler->handleError: src, transformed:', srcError, lError);

    //already logged below
    snackBarHandlingService.error(lError);
  }
}
