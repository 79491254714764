<div class="dimensions app-inspect-dimensions">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title class="section-label"> Add Dimensions </mat-panel-title>
    </mat-expansion-panel-header>
    <div style="width: 100%;display: inline-flex;">
      <div style="width: 60%">
        <div *ngFor="let inspectionPieceDimension of inspectionDetails?.inspectorPieceDimensions; let i = index">
          <app-dimension-line
            [inspectionPieceDimension]="inspectionPieceDimension"
            (change)="dimensionsChanged()"
            [dimensionError]="dimensionErrors == null ? '' : dimensionErrors[i]"
          ></app-dimension-line>
        </div>
      </div>
      <div style="width: 40%; border-left: 1px solid grey; padding-left: 35px;">
        <div class="dimensions__right_panel_textbox">
          <div class="dimensions__label">Total Weight</div>
          <div class="value">{{ inspectionDetails?.totGrossWeight }}</div>
        </div>
        <div style="display: inline-flex;width: 100%;" class="dimensions__right_panel_textbox">
          <div class="dimensions__pieces_detail">
            <div class="dimensions__label">Total Pieces</div>
            <div class="value">{{ inspectionDetails?.totPieceCnt }}</div>
          </div>
          <div class="dimensions__pieces_detail">
            <div class="dimensions__label">Motor Moves</div>
            <div class="value">{{ inspectionDetails?.motorizedPiecesCnt }}</div>
          </div>
          <div class="dimensions__pieces_detail">
            <div class="dimensions__label">Loose Pieces</div>
            <div class="value">{{ inspectionDetails?.loosePiecesCnt }}</div>
          </div>
        </div>
        <div style="width: 100%;" class="dimensions__right_panel_textbox">
          <div style="display: inline-flex;">
            <div class="dimensions__cubic-feet-container">
              <div class="dimensions__label">Cubic Feet</div>
              <div class="value">{{ totalVolume }}</div>
            </div>
            <div class="dimensions__label dimensions__unit-label">ft3</div>
          </div>
        </div>
        <div style="width: 100%;" class="dimensions__right_panel_textbox">
          <div style="display: inline-flex;">
            <div class="dimensions__density-container">
              <div class="dimensions__label">Density (PCF)</div>
              <div class="value">{{ totalDensity }}</div>
            </div>
            <div class="dimensions__label dimensions__unit-label">lbs/ft3</div>
          </div>
        </div>
        <div style="width: 100%;" class="dimensions__right_panel_textbox">
          <div style="display: inline-flex;">
            <div class="dimensions__pieces-container">
              <div class="dimensions__label">Measured Pieces</div>
              <div class="value">{{ totalPieces }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
