<div id="camera-delay-overlay"></div>

<div id="main-container">
  <div class="toolbar">
    <div class="toolbar-left">
      <button mat-button (click)="onClose()">
        <mat-icon class="camera-icon" svgIcon="back-button-icon"></mat-icon>
        {{ this.pro?.formatProNumber() }}
      </button>
    </div>
    <div class="toolbar-right" *ngIf="!(cameraState?.cameraStatus === 'error')">
      <button mat-button (click)="toggleTorch()" *ngIf="!this.torch">
        <mat-icon class="camera-icon" svgIcon="torch-off"></mat-icon>
      </button>
      <button mat-button (click)="toggleTorch()" *ngIf="this.torch && !(cameraState?.cameraStatus === 'error')">
        <mat-icon class="camera-icon" svgIcon="torch-on"></mat-icon>
      </button>
      <button mat-button (click)="toggleFlash()" *ngIf="this.flash === 'flash'">
        <mat-icon class="camera-icon">flash_on</mat-icon>
      </button>
      <button mat-button (click)="toggleFlash()" *ngIf="this.flash === 'off'">
        <mat-icon class="camera-icon">flash_off</mat-icon>
      </button>
    </div>
  </div>
  <div class="camera-error-container" *ngIf="cameraState?.cameraStatus === 'error'">
    <div class="camera-error-message">
      {{ errorMessage }}
    </div>
    <div class="camera-error-image">
      <img class="camera-error-icon" src="assets/images/camera-error.png"/>
    </div>
  </div>
  <video id="camera-stream" autoplay (click)="onSnapshot()" [src]="this.imageCapture">
    <audio id="camera-shutter" src="assets/sounds/Click.wav"></audio>
  </video>
  <img class="pip" [src]="previewImage" (click)="this.showPhotoGallery()" *ngIf="previewImage !== undefined"/>
</div>
