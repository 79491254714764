import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { InspectionShipment } from '@xpo-ltl/sdk-inspections';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { InspectionsDateUtils } from '../../classes/utils/inspections-date-utils';
import { Photo } from '../../classes/photos/photo';
import { PhotoId } from '../../classes/photos/photo-id';
import { ProNumber } from '../../classes/pronumber';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { DmsDocType } from '../../enums/dms-doc-type.enum';
import { DocumentListTitles } from '../../enums/document-list-titles.enum';
import { DocumentManagementService } from '../../services/api/document-management-service';
import { PhotoGalleryService } from '../photo-gallery/photo-gallery.service';
import { DocumentListImpl } from './document-list-impl';
import { List } from 'immutable';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentListComponent implements OnChanges, OnInit {
  constructor(
    private configManagerService: ConfigManagerService,
    private documentManagementService: DocumentManagementService,
    private photoGalleryService: PhotoGalleryService
  ) {
    this.archivedDocumentsGalleryData = new DocumentListImpl(this.documentManagementService);
  }

  @Input()
  shipmentDetails: InspectionShipment;

  @Input()
  docTypes: DmsDocType[];

  @Input()
  title: DocumentListTitles;

  @Input()
  photos: List<Photo>; // this should stay Photo[], not PhotoId[] because we use Photo's method in template

  readonly DocTypes = DmsDocType;
  readonly DocumentListTitles = DocumentListTitles;
  proNumber: ProNumber;
  isExpanded: boolean = false;
  showTrailerPhotoAppLink: boolean = false;
  private archivedDocumentsGalleryData: DocumentListImpl;
  private lastProNumberLoaded: string;
  private unsubscriber$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.showTrailerPhotoAppLink = this.title === DocumentListTitles.OPERATIONS_PHOTOS;
    // LEI-1902

    //todo pg: move this!!! but this should not be replaced as it should be passed as the right one as observable, and that's it!
    // todo pg: merged with develop and having doubt if the below code should be there or not
    // initially, we have incomplete info of photos[] in this component level.
    // photo info will be loaded fully when opening photo gallery.
    // once loaded, photo gallery service will emit the updated photos so we update it here
    // let i: number = 0;
    // this.photoGalleryService.loadedPhotos$.pipe(takeUntil(this.unsubscriber$)).subscribe((loadedPhotos: List<Photo>) => {
    //   loadedPhotos.forEach((currentPhoto) =>{
    //     const photoInLoadedPhotos: Photo = loadedPhotos.find(
    //       (loadedPhoto: Photo) => loadedPhoto.id.id === currentPhoto.id.id
    //     );
    //     if (photoInLoadedPhotos) {
    //       loadedPhotos.set(currentPhotoIndex, photoInLoadedPhotos);
    //     }
    //     i++
    //   }, i)
    //   for (let i = 0; i < this.photos?.size; i++) {
    //     const photoInLoadedPhotos: Photo = loadedPhotos.find(
    //       (loadedPhoto: Photo) => loadedPhoto.id.id === this.photos[i].id.id
    //     );
    //     if (photoInLoadedPhotos) {
    //       this.photos[i] = photoInLoadedPhotos;
    //     }
    //   }
    // });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['shipmentDetails']) {
      this.onShipmentDetailsChanged();
    }
  }

  private onShipmentDetailsChanged() {
    if (this.shipmentDetails?.shipmentId) {
      const proNumber = this.shipmentDetails.shipmentId.proNumber;

      if (this.lastProNumberLoaded !== proNumber) {
        this.proNumber = new ProNumber(proNumber);
        this.lastProNumberLoaded = proNumber;
      }
    }
  }

  openPhotoGallery(currentPhotoId: PhotoId) {
    this.photoGalleryService
      .openPhotoGalleryDialog(this.archivedDocumentsGalleryData, this.docTypes, currentPhotoId, this.photos)
      .pipe(take(1))
      .subscribe();
  }

  openTrailerPhotoApp() {
    let imageviewerAuthAppRootUri = this.configManagerService.getSetting<string>(
      ConfigManagerProperties.IMAGEVIEWER_AUTH_APP_ROOT_URL
    );
    if (imageviewerAuthAppRootUri && imageviewerAuthAppRootUri[imageviewerAuthAppRootUri.length - 1] !== '/') {
      imageviewerAuthAppRootUri += '/';
    }
    const trailerPhotoAppBaseUrl = `${imageviewerAuthAppRootUri}image-search`;
    const proNumberParam = `searchKey=${this.lastProNumberLoaded}`;
    const today = new Date();
    const yesterday = InspectionsDateUtils.getDayBefore(today);
    const beginDateParam = `beginDate=${InspectionsDateUtils.getFormatDate(yesterday, 'MM-DD-YYYY')}`;
    const endDateParam = `endDate=${InspectionsDateUtils.getFormatDate(today, 'MM-DD-YYYY')}`;
    const url = `${trailerPhotoAppBaseUrl}?${proNumberParam}&${beginDateParam}&${endDateParam}`;
    window.open(url);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
