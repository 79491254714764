import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { BroadcastMessage } from '../../classes/broadcast-messages/broadcast-message';
import { AppConfigManagerService } from '../../services/app-config-manager.service';
import { BroadcastMessagesService } from '../../services/broadcast-messages/broadcast-messages.service';
import { DevTestingService } from '../../services/dev-testing.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-unread-broadcast-message-dialog',
  templateUrl: './unread-broadcast-message-dialog.component.html',
  styleUrls: ['./unread-broadcast-message-dialog.component.scss']
})
export class UnreadBroadcastMessageDialogComponent implements OnInit, OnDestroy {
  title: string;
  unreadMessages: BroadcastMessage[];
  employeeId: string;
  isAcknowledgeLaterButtonDisplayed: boolean = false;
  isProduction: boolean;

  private unsubscriber$: Subject<void> = new Subject();

  constructor(
    private appConfigManagerService: AppConfigManagerService,
    private broadcastMessagesService: BroadcastMessagesService,
    private dialogRef: MatDialogRef<UnreadBroadcastMessageDialogComponent>,
    private devTestingService: DevTestingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  /**
   * Init properties from MAT_DIALOG_DATA
   */
  private initData(): void {
    this.isProduction = this.appConfigManagerService.isProduction();
    this.title = this.data.title;
    this.unreadMessages = this.data.unreadMessages;
    this.employeeId = this.data.employeeId;

    this.checkForDisplayAcknowledgeLaterButton();
  }

  onResponseMessage(messageResponse: BroadcastMessage): void {
    this.broadcastMessagesService
      .responseMessage(messageResponse)
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscriber$))
      .subscribe((broadcastMessageResponse) => {
        if (broadcastMessageResponse) {
          this.removeRespondedMessage(messageResponse);
          this.closeDialog();
        }
      });
  }

  /**
   * Close the dialog if there are not unread messages
   */
  private closeDialog(): void {
    if (!this.unreadMessages.length) {
      this.dialogRef.close(true);
    }
  }

  /**
   * Remove an unread message when it was replayed.
   */
  private removeRespondedMessage(messageResponse: BroadcastMessage): void {
    const readMessageIndex = this.unreadMessages.findIndex(
      (message) => message.messageId === messageResponse.messageId
    );
    this.unreadMessages.splice(readMessageIndex, 1);
  }

  /**
   * Check for displaying Acknowledge Later Button
   * @param unreadMessages unread broadcast messages
   */
  private checkForDisplayAcknowledgeLaterButton(): void {
    if (this.unreadMessages?.length > 0) {
      this.unreadMessages.forEach((unreadMessage: BroadcastMessage) => {
        if (!unreadMessage.itemInstId) {
          this.isAcknowledgeLaterButtonDisplayed = true;
        }
      });
    }
  }

  /**
   * Force close the dialog when user clicked Acknowledge Later button
   */
  onAcknowledgeLaterClicked(): void {
    this.broadcastMessagesService.isMissingItemInstanceId = true;
    this.dialogRef.close(true);
  }

  // /**
  //  * ONLY USED IN TEST ENVIRONMENT: Remove Item Instance ID
  //  * @param changedSlide
  //  */
  // onRemoveItemInstanceIdChanged(changedSlide: MatSlideToggleChange): void {
  //   const isItemInstanceIdRemoved: boolean = changedSlide.checked;
  //   if (this.unreadMessages?.length > 0) {
  //     this.unreadMessages = this.devTestingService.removeOrRestoreBeaconItemInstanceId(
  //       isItemInstanceIdRemoved,
  //       this.unreadMessages
  //     );
  //     this.isAcknowledgeLaterButtonDisplayed = isItemInstanceIdRemoved;
  //     this.broadcastMessagesService.isMissingItemInstanceId = isItemInstanceIdRemoved;
  //   } else {
  //     this.isAcknowledgeLaterButtonDisplayed = false;
  //     this.broadcastMessagesService.isMissingItemInstanceId = false;
  //   }
  // }
}
