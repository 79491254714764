<xpo-contextual-header>
  <xpo-contextual-header-item>
    <mat-form-field>
      <input
        #searchInput
        matInput
        autocomplete="off"
        placeholder="Scan or enter a PRO, Door, or Trailer #"
        [formControl]="lookupInput"
        (keyup.enter)="onLookup()"
        type="text"
        pattern="[a-zA-Z0-9\-]+"
      />
      <mat-icon matSuffix class="search_icon" (click)="onLookup()">search</mat-icon>
    </mat-form-field>
  </xpo-contextual-header-item>

  <div class="text-display-items">
    <xpo-contextual-header-item *ngIf="!appConfigManagerService.isProduction()" class="dev-only">
      <button class="dev-only" mat-button (click)="onDevOnlyClicked()">Dev Only</button>
    </xpo-contextual-header-item>
    <xpo-contextual-header-item
      (click)="onSicClicked()"
      class="text-display-items_SIC"
      matTooltip="Click to change SIC"
      >{{ sic$ | async }}</xpo-contextual-header-item
    >
    <xpo-contextual-header-item
      (click)="onShiftClicked()"
      class="text-display-items_Shift"
      matTooltip="Click to change Shift"
      >{{ shift$ | async }}</xpo-contextual-header-item
    >
    <xpo-contextual-header-item matTooltip="hh:mm:ss ago, Click on refresh recommendations if needed">
      Recommendations Last Refreshed: {{ refreshDatesService.lastRefreshRecommendationTimestamp.stringValue$ | async }}
      ago
    </xpo-contextual-header-item>
  </div>

  <button xpo-action-button [matMenuTriggerFor]="moreIconMenu">
    <mat-icon>more_horiz</mat-icon>
  </button>

  <mat-menu #moreIconMenu>
    <div class="overlay-more-menu">
      <div class="overlay-more-menu_button">
        <button mat-button (click)="onRefreshRecommendationsClicked()">Refresh Recommendations</button>
        <button mat-button (click)="onAddProsClicked()">Add PROs</button>
        <!-- LEI-376: disabled until it can be updated to match the revised rule -->
        <!-- <div class="app-header-sidenav-addpros">
        <button mat-button (click)="openCalculatorsMenu()">Tools</button>
        </div> -->
        <button mat-button (click)="onChangeSicShiftClicked()">Change SIC / Shift</button>
        <div *ngIf="canSetAppSettings()">
          <button mat-button (click)="onSettingsClicked()">Settings</button>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="overlay-more-menu_stored-items">
        <ul class="photos">
          <li>
            <a
              (click)="onPhotoComponentShowClicked($event)"
              matTooltip="Locally stored photos (Click open photo details)"
              class="photos"
              >Stored photos: {{ storedPhotoCount$ | async }} (<span
                matTooltip="Number of photos associated with submitted inspections, pending for upload to DMS"
                >{{ mobileWebBrowserService.pendingPhotoCount$ | async }} queued</span
              >)</a
            >
          </li>
          <li>Used storage: {{ spaceUsedMB$ | async }} MB of {{ AppStorageService.PHOTO_STORAGE_MAX_MB }} MB</li>
        </ul>
      </div>
    </div>
  </mat-menu>
</xpo-contextual-header>
