<form *ngIf="correctionFormGroup" [formGroup]="correctionFormGroup">
  <mat-dialog-actions>
    <div class="content-left">
      <h2 mat-dialog-title>{{ title }}</h2>
    </div>
  </mat-dialog-actions>
  <br />
  <mat-divider></mat-divider>
  <mat-dialog-content>
    <div *ngIf="!(isConfirmMessageDisplayed$ | async)">
      <app-correction-information (closeDialog)="close(false)" [correctionRequest]="correctionRequest$ | async" [correctionInformation]="correctionInformation$ | async" [shipmentDetailsResp]="inspectionShipmentDetailsRespSubject | async" (reviewCommentsChanged)="reviewCommentsChanged($event)"></app-correction-information>
      <div *ngIf="locationService.isCorrectionsFeatureAvailable$ | async">
        <app-inspection-corrections-card [shipmentDetails]="shipmentDetailsSubject | async"></app-inspection-corrections-card>
      </div>
      <app-customer-instructions [inspectionShipmentDetails]="inspectionShipmentDetailsRespSubject | async"></app-customer-instructions>
      <app-dimensioner-inspection-detail-list [dimensionerInspectionDetailListInput]="dimensionerInspectionDetails$ | async" [correctionRequest]="correctionRequest$ | async"></app-dimensioner-inspection-detail-list>
    </div>
    <div *ngIf="(isConfirmMessageDisplayed$| async) && (isDecline$ | async)">
      <div style="padding-top: 40px; padding-left: 70px;">
        <mat-form-field style="width: 500px;">
          <mat-select
            formControlName="declineReason"
            placeholder="Select Decline Reason"
            (selectionChange)="onReasonTypeChanged($event.value)"
            aria-modal>
            <mat-option
              *ngFor="let reason  of declineReasons"
              [value]="reason.reasonCd">
              {{ reason.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="showOther" class="input">
          <textarea
          matInput
          placeholder="Comments *"
          maxlength="4000"
          formControlName="other"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="100"
          ></textarea>
        </div>
      </div>
    </div>
    <div *ngIf="(isConfirmMessageDisplayed$ | async) && !(isDecline$ | async)">
      <div class="warning">
        <p>{{ warningActionLabel$ | async }}</p>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="content-left">
      <ng-container *ngIf="!(isConfirmMessageDisplayed$ | async) && (isReviewCorrectionActionExecutable$ | async)">
        <button mat-flat-button color="warn" (click)="onSelectAction(DialogAction.DECLINE)">Decline</button>
      </ng-container>
    </div>
    <div class="content-right">
      <ng-container *ngIf="!(isConfirmMessageDisplayed$ | async) && (isReviewCorrectionActionExecutable$ | async)">
        <button mat-button (click)="close(false)">Cancel</button>
        <ng-container *ngIf="(is75RuleDisplayed$ | async)">
          <button mat-button
          color="primary" (click)="on75Action()">Apply 75 rule</button>
        </ng-container>
        <ng-container *ngIf="(isUnapply75RuleDisplayed$ | async)">
          <button mat-button
          color="primary" (click)="off75Action()">Unapply 75 rule</button>
        </ng-container>
        <button
          mat-button
          color="primary"
          (click)="onSelectAction(DialogAction.SAVE)"
        >
          Save
        </button>
        <button
          mat-button
          color="primary"
          (click)="onSelectAction(DialogAction.APPROVE)"
        >
          Approve
        </button>
      </ng-container>
      <ng-container *ngIf="(isConfirmMessageDisplayed$| async) && (isDecline$| async)">
        <button mat-button (click)="onNoButtonClicked()">Cancel</button>
        <button
        mat-button
        color="primary"
        [disabled]="!correctionFormGroup.valid"
        (click)="onYesButtonClicked()"
        >
          Save
        </button>
      </ng-container>
      <!--    yes/no buttons are displayed when save clicked as intermediate screen-->
      <ng-container *ngIf="(isConfirmMessageDisplayed$| async) && !(isDecline$| async)">
        <button (click)="onNoButtonClicked()" mat-button>No</button>
        <button (click)="onYesButtonClicked()" mat-button color="primary">Yes</button>
      </ng-container>
      <!-- If the review status is InReview we cannot take any action  -->
      <button mat-button *ngIf="!(isReviewCorrectionActionExecutable$ | async)" (click)="close(false)">Close</button>
    </div>
  </mat-dialog-actions>
</form>