import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { InspectionHandlingUnit } from '@xpo-ltl/sdk-inspections';
import { InspectionShipmentDetails } from '../../../../classes/inspection-shipment-details';
import { LocationBreakdown, LocationUtil } from '../../../../classes/utils/location-utils';
import { RouterUriComponents } from '../../../../enums/router-uri-components.enum';

export class ShipmentLocationInfo {
  pltProNumber?: string;
  locationBreakdown: LocationBreakdown;
  trailerStatus: string;
}
@Component({
  selector: 'app-location-movement',
  templateUrl: './location-movement.component.html',
  styleUrls: ['./location-movement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationMovementComponent implements OnChanges {
  @Input()
  shipmentDetails: InspectionShipmentDetails;

  isExpanded: boolean;
  showChildPro: boolean = false;
  routerLink: string;
  shipmentLocations: ShipmentLocationInfo[] = [];
  isLinkApplied: boolean;

  constructor() {
    this.routerLink = `/${RouterUriComponents.TRAILER_DETAIL_PAGE}`;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isExpanded = true;
    this.showChildPro = !!this.shipmentDetails?.inspectionHandlingUnits?.size;

    if (this.showChildPro) {
      // get plt PROs' location breakdown
      this.shipmentLocations = [];
      this.shipmentDetails?.inspectionHandlingUnits?.forEach((handlingUnit: InspectionHandlingUnit) => {
        const shipmentLocation = new ShipmentLocationInfo();
        shipmentLocation.pltProNumber = handlingUnit.handlingUnitProNbr;
        shipmentLocation.locationBreakdown = LocationUtil.getLocationBreakdownForPLT(handlingUnit.currentLocation);
        shipmentLocation.trailerStatus = handlingUnit.scheduleStatusCd
          ? handlingUnit.scheduleStatusCd
          : handlingUnit.trailerStatusCd;
        this.shipmentLocations.push(shipmentLocation);
      });
    } else {
      // get Legacy PRO's location breakdown
      this.shipmentLocations = [new ShipmentLocationInfo()];
      this.shipmentLocations[0].locationBreakdown = LocationUtil.buildLocationBreakdown(
        this.shipmentDetails?.shipmentLocation
      );
      this.shipmentLocations[0].locationBreakdown.trailerNumber = this.shipmentDetails?.trailerNbr;
      this.shipmentLocations[0].trailerStatus = this.shipmentDetails?.scheduleStatusCd
        ? this.shipmentDetails?.scheduleStatusCd
        : this.shipmentDetails?.trailerStatusCd;
    }

    this.updateIsLinkApplied();
  }

  private updateIsLinkApplied(): void {
    if (isNaN(+this.shipmentDetails?.breakDoor) && !this.shipmentDetails?.breakDoor?.includes('-')) {
      this.isLinkApplied = false;
    } else {
      this.isLinkApplied = true;
    }
  }
}
