<div class="listHeader-left">
  <h2 class="listHeader-left__title">{{ title }}</h2>

  <div class="listHeader-left__refresh" (click)="refreshList()" matTooltip="Click to refresh the lists">
    <mat-icon class="listHeader-left__refresh-icon" svgIcon="refresh-icon"></mat-icon>
    <div class="listHeader-left__refresh-time">{{ refreshTime }} ago</div>
  </div>
</div>

<div class="listHeader-right">
  <button *ngIf="isAddEnabled && addingObjectName" mat-button color="primary" (click)="openAddObject()">
    Add {{ addingObjectName }}
  </button>

  <div *ngIf="listHeaderFormGroup">
    <form [formGroup]="listHeaderFormGroup">
      <mat-slide-toggle
        *ngIf="title === listName.INSPECTION_CORRECTIONS"
        formControlName="searchForSicFormControl"
        (change)="onFormGroupValueChanged()"
        value="[searchForSicFormControl.value]"
        >All inspectors at {{ sic }}
      </mat-slide-toggle>
      <mat-slide-toggle
        *ngIf="title === listName.AUTO_CORRECTION_APPROVAL"
        formControlName="inReviewStatusToggleFormControl"
        (change)="onFormGroupValueChanged()"
        value="[inReviewStatusToggleFormControl.value]"
        >Only Corrections In review
      </mat-slide-toggle>
      <mat-form-field *ngIf="isDateRangeDisplayed" class="date-range" color="primary">
        <mat-date-range-input [rangePicker]="rangePicker" (click)="rangePicker.open()">
          <input matStartDate readonly value="[startDateFormControl.value]" formControlName="startDateFormControl" />
          <input matEndDate readonly value="[endDateFormControl.value]" formControlName="endDateFormControl" />
        </mat-date-range-input>
        <mat-date-range-picker #rangePicker>
          <mat-date-range-picker-actions>
            <button mat-button matDateRangePickerCancel>Cancel</button>
            <button mat-raised-button color="primary" matDateRangePickerApply (click)="onFormGroupValueChanged()">
              Apply
            </button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-form-field>
    </form>
  </div>

  <button class="icon-button" mat-button [matMenuTriggerFor]="columnMenu" *ngIf="columnsFiltered || columnsSorted">
    <mat-icon svgIcon="filter" class="filterIconRed" *ngIf="columnsFiltered || columnsSorted"></mat-icon>
    <mat-icon svgIcon="filter" *ngIf="!columnsFiltered && !columnsSorted"></mat-icon>
  </button>

  <mat-menu #columnMenu="matMenu">
    <button (click)="clearGridFilters()" mat-menu-item *ngIf="columnsFiltered">Clear Filters</button>
    <button (click)="clearGridSorts()" mat-menu-item *ngIf="columnsSorted">Clear Sorting</button>
    <button (click)="clearGridSortAndFilters()" mat-menu-item *ngIf="columnsFiltered && columnsSorted">
      Clear Both
    </button>
  </mat-menu>

  <button class="icon-button" mat-button [matMenuTriggerFor]="menu">
    <mat-icon svgIcon="gear-icon"></mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button (click)="saveGridSettings()" mat-menu-item>Save Column Settings</button>
    <button (click)="loadGridSettings()" mat-menu-item>Load Column Settings</button>
    <button (click)="resetGridSettings()" mat-menu-item>Reset Column Settings</button>
  </mat-menu>
</div>
