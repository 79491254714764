<xpo-card type="data" color="accent">
  <xpo-card-header>
    <xpo-card-title>Commodities</xpo-card-title>
  </xpo-card-header>

  <xpo-card-content *ngIf="inspectionForCorrectionRequestResp" class="commodity-container">
    <div class="commodity-container_left">
      <form *ngIf="commodityFormGroup" [formGroup]="commodityFormGroup">
        <table>
          <tr>
            <th class="narrow_column">Commodity Line</th>
            <th class="narrow_column">Piece Count</th>
            <th>Package</th>
            <th>HazMat</th>
            <th>Description</th>
            <th>Weight</th>
            <th class="nmfc_input">NMFC</th>
            <th *ngIf="!isReadOnlyView">NMFC Link</th>
            <th *ngIf="!isReadOnlyView"></th>
            <th class="class_input">Class *</th>
            <th *ngIf="!isReadOnlyView || isDimensionAssociated">Cubic Feet</th>
            <th *ngIf="!isReadOnlyView || isDimensionAssociated">Density</th>
            <th *ngIf="!isReadOnlyView || isDimensionAssociated">Dimensions</th>
            <th *ngIf="!isReadOnlyView" class="narrow_column">Copy NMFC and Class</th>
          </tr>

          <tbody>
            <ng-container formArrayName="commodityFormArray">
              <ng-container *ngFor="let commodity of commodityFormArray.controls; let i = index">
                <tr [formGroupName]="i">
                  <td class="narrow_column">{{ i + 1 }}</td>
                  <td class="narrow_column__right">{{ commodity.get(PIECE_COUNT).value }}</td>
                  <td class="center">{{ commodity.get(PACKAGE_CODE).value }}</td>
                  <td class="center">{{ commodity.get(HAZMAT_IND).value | yesNoPipe }}</td>
                  <td class="center">{{ commodity.get(DESCRIPTION).value }}</td>
                  <td class="right-justify">{{ commodity.get(WEIGHT_LBS).value | number : '1.0' : 'en-US' }}</td>
                  <!-- NMFC input-->
                  <td *ngIf="!isReadOnlyView; else displayReadOnlyNmfc" class="nmfc_input">
                    <mat-form-field>
                      <input
                        [maxlength]="maxCharForNmfc"
                        matInput
                        formControlName="nmfcItemCd"
                        [matAutocomplete]="nmfcAutocomplete"
                      />
                      <mat-autocomplete
                        autoActiveFirstOption
                        #nmfcAutocomplete="matAutocomplete"
                        (optionSelected)="onNmfcSelect($event.option.value, commodity, i)"
                        (closed)="onNmfcDropdownClosed(commodity, i)"
                      >
                        <div *ngIf="(nmfcDropdownChangedIndexNumber$ | async) === i">
                          <mat-option
                            *ngFor="let nmfcDropdownValue of commodity.get(NMFC_DROPDOWN).value"
                            [value]="nmfcDropdownValue"
                          >
                            {{ nmfcDropdownValue.id }}
                          </mat-option>
                        </div>
                      </mat-autocomplete>
                      <mat-error *ngIf="commodity.get(NMFC_ITEM_CODE).hasError('invalidNmfcForm')">
                        {{ NMFC_INVALID_INPUT_ERROR_MESSAGE }}
                      </mat-error>
                    </mat-form-field>
                  </td>
                  <ng-template #displayReadOnlyNmfc>
                    <div *ngIf="commodity.get(NMFC_ITEM_CODE).value?.trim().length > 0" class="center">
                      <a xpo-regular-link (click)="onOpenNmfcLinkClicked(commodity.get(NMFC_ITEM_CODE).value)">{{
                        commodity.get(NMFC_ITEM_CODE).value
                      }}</a>
                    </div>
                  </ng-template>
                  <!-- NMFC link -->
                  <td
                    *ngIf="!isReadOnlyView && commodity?.get(NMFC_DROPDOWN)?.value?.length === 1; else displayEmpty"
                    class="center"
                  >
                    <a
                      xpo-regular-link
                      (click)="onOpenNmfcLinkClicked(commodity.get(NMFC_DROPDOWN)?.value?.[0]?.id)"
                      >{{ commodity.get(NMFC_DROPDOWN)?.value?.[0]?.id }}</a
                    >
                  </td>
                  <!-- NMFC Undo button -->
                  <td *ngIf="!isReadOnlyView" class="center">
                    <div
                      *ngIf="isUndoShouldDisplayed(i, commodity?.get(NMFC_ITEM_CODE)?.value); else displayEmptyIconSize"
                      (click)="onNmfcUndoClicked(commodity, i)"
                    >
                      <xpo-icon
                        iconName="undo"
                        matTooltip="Use original NMFC: {{ inspectionForCorrectionRequestResp?.inspectedShipmentCommodities?.[i]?.nmfcItemCd }}"
                      ></xpo-icon>
                    </div>
                  </td>
                  <!-- Class -->
                  <td class="class_input">
                    <mat-form-field *ngIf="isClassTypeEditable(commodity) && !isReadOnlyView; else readonlyClassType">
                      <mat-select formControlName="classType" (selectionChange)="onClassTypeSelect($event)">
                        <mat-option
                          *ngFor="let class of inspectionForCorrectionRequestResp?.commodityClasses"
                          [value]="class.commodityClassCd"
                        >
                          {{ class.commodityClassCd }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <ng-template #readonlyClassType>
                      {{ commodity.get(CLASS_TYPE).value }}
                    </ng-template>
                  </td>
                  <!-- Cubit Feet -->
                  <td *ngIf="!isReadOnlyView || isDimensionAssociated" class="right-justify">
                    <div
                      *ngIf="
                        commodity.get(DIMENSIONS_DRAG_LIST_NAME).value.dimensions.length > 0;
                        else displayEmptyMediumSize
                      "
                    >
                      {{ commodity.get(CUBIC_FEET).value | toFormattedVolume }}
                    </div>
                  </td>
                  <!-- Density -->
                  <td *ngIf="!isReadOnlyView || isDimensionAssociated" class="right-justify">
                    <div
                      *ngIf="
                        commodity.get(DIMENSIONS_DRAG_LIST_NAME).value.dimensions.length > 0;
                        else displayEmptyMediumSize
                      "
                    >
                      {{ commodity.get(DENSITY).value | toFormattedDensity }}
                    </div>
                  </td>
                  <!-- Dimensions card for commodity -->
                  <td *ngIf="!isReadOnlyView || isDimensionAssociated" class="center">
                    <div *ngIf="!isReadOnlyView; else displayReadOnlyDimensions">
                      <div class="dimension-container">
                        <div
                          cdkDropList
                          [id]="commodity.get(DIMENSIONS_DRAG_LIST_NAME).value.id"
                          [cdkDropListData]="commodity.get(DIMENSIONS_DRAG_LIST_NAME).value.dimensions"
                          [cdkDropListConnectedTo]="commodity.get(DIMENSIONS_DRAG_LIST_NAME).value.connectedList"
                          class="dimension-list"
                          (cdkDropListDropped)="drop($event)"
                        >
                          <div
                            *ngIf="commodity.get(DIMENSIONS_DRAG_LIST_NAME).value.dimensions.length > 0; else emptyCard"
                          >
                            <div
                              class="dimension-card"
                              *ngFor="let item of commodity.get(DIMENSIONS_DRAG_LIST_NAME).value.dimensions"
                              cdkDrag
                            >
                              {{ item.value }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <ng-template #displayReadOnlyDimensions>
                      <div
                        *ngIf="commodity.get(DIMENSIONS_DRAG_LIST_NAME).value.dimensions.length > 0; else noDimsMessage"
                      >
                        <div *ngFor="let item of commodity.get(DIMENSIONS_DRAG_LIST_NAME)?.value?.dimensions">
                          {{ item?.value }}
                        </div>
                      </div>
                    </ng-template>

                    <ng-template #noDimsMessage> No dimensions </ng-template>
                  </td>
                  <!-- Copy NMFC and Class from the first commodity line button -->
                  <td *ngIf="!isReadOnlyView" class="narrow_column">
                    <div
                      *ngIf="i === 0 && commodityFormArray.controls.length > 1; else displayEmptyIconSize"
                      (click)="onNmfcAndClassCopyClicked()"
                    >
                      <xpo-icon iconName="copy" matTooltip="{{ copyIconMatTooltipMessage }}"></xpo-icon>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>

    <div *ngIf="!isReadOnlyView || !isDimensionAssociated" class="vertical-line"></div>

    <div *ngIf="!isReadOnlyView; else displayReadOnlyDimensionCards" class="commodity-container_right">
      <div class="dimension-label">Dimension Cards</div>
      <form *ngIf="commodityFormGroup" [formGroup]="dimensionsFormControl" class="dimension-item">
        <div class="dimension-container">
          <div
            cdkDropList
            [id]="dimensionsFormControl.value.id"
            [cdkDropListData]="dimensionsFormControl.value.dimensions"
            [cdkDropListConnectedTo]="dimensionsFormControl.value.connectedList"
            class="dimension-list"
            (cdkDropListDropped)="drop($event)"
            [cdkDragDisabled]="true"
          >
            <div *ngIf="dimensionsFormControl.value.dimensions.length > 0; else emptyCard">
              <div class="dimension-card" *ngFor="let item of dimensionsFormControl.value.dimensions" cdkDrag>
                {{ item?.value }}
              </div>
            </div>
          </div>

          <div
            *ngIf="getInitialDimensionArray()?.length === dimensionsFormControl.value.dimensions.length"
            class="dimension-summary"
          >
            <div class="dimension-summary_value">
              Total Weight: {{ getTotalWeightFromInspectedShipmentCommodity() | number : '1.0' : 'en-US' }}
            </div>
            <div class="dimension-summary_value">CuFt: {{ dimensionsFormControl.value.cubicFeet }}</div>
            <div class="dimension-summary_value">Density: {{ dimensionsFormControl.value.density }}</div>
          </div>
        </div>
      </form>
    </div>

    <ng-template #displayReadOnlyDimensionCards class="commodity-container_right">
      <div *ngIf="!isDimensionAssociated">
        <div class="dimension-label">Dimension Cards</div>
        <div class="dimension-item" *ngFor="let item of dimensionCardDataArray">
          {{ item?.value }}
        </div>

        <div class="dimension-summary">
          <div class="dimension-summary_value">
            Total Weight: {{ getTotalWeightFromInspectedShipmentCommodity() | number : '1.0' : 'en-US' }}
          </div>
          <div class="dimension-summary_value">CuFt: {{ dimensionsFormControl.value.cubicFeet }}</div>
          <div class="dimension-summary_value">Density: {{ dimensionsFormControl.value.density }}</div>
        </div>
      </div>
    </ng-template>
  </xpo-card-content>
</xpo-card>

<ng-template #emptyCard>
  <div class="dimension-card">Drag and drop dimensions here</div>
</ng-template>

<ng-template #displayEmpty>
  <div></div>
</ng-template>

<ng-template #displayEmptyMediumSize>
  <div class="commodity-value_items__medium"></div>
</ng-template>

<ng-template #displayEmptyIconSize>
  <div class="commodity-value_items__icon"></div>
</ng-template>

<ng-template #readonlyEmptyMediumSize>
  <div class="medium"></div>
</ng-template>
