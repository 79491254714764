import { ShipmentStatusEnum } from '../../enums/shipment-status.enum';

export class LocationBreakdown {
  shipmentLocation: string;
  locationPriority?: number; // this is not used yet, we're saving this value in case the business want it back in location column
  shipmentStatus: string;
  trailerNumber: string;
  breakDoorNumber?: number;
}

export class LocationUtil {
  static buildLocationBreakdown(location: string): LocationBreakdown {
    const locationBreakdown = new LocationBreakdown();
    if (location) {
      if (!Number.isNaN(+location[0])) {
        locationBreakdown.locationPriority = +location[0];
        const locationWithoutPriority: string = location.substr(2);
        LocationUtil.setLocationFields(locationWithoutPriority, locationBreakdown);
      } else {
        // returning the original value so we can see the value and fix if needed
        locationBreakdown.shipmentLocation = location;
      }
    }
    return locationBreakdown;
  }

  private static setLocationFields(locationWithoutPriority: string, locationBreakdown: LocationBreakdown): void {
    let lTrailerNumber: string;
    let lShipmentStatus: string;
    let lShipmentLocation: string;
    const locationSubstrings: string[] = locationWithoutPriority.split(' ');

    if (locationWithoutPriority.toLowerCase().includes('dock')) {
      // if its in dock, only location is available
      lShipmentLocation = locationWithoutPriority;
    } else if (locationSubstrings?.length === 3) {
      // original format: <status> <location> <trailer>
      lShipmentStatus = locationSubstrings[0];
      lShipmentLocation = locationSubstrings[1];
      lTrailerNumber = locationSubstrings[2];
    } else if (locationSubstrings?.length === 2) {
      // original format: <status> <location>
      lShipmentStatus = locationSubstrings[0];
      lShipmentLocation = locationSubstrings[1];
    } else if (locationSubstrings?.length === 1) {
      // original format: <status> OR <location>
      if (Object.values(ShipmentStatusEnum).includes(locationWithoutPriority as ShipmentStatusEnum)) {
        lShipmentStatus = locationWithoutPriority;
      } else {
        lShipmentLocation = locationWithoutPriority;
      }
    }
    locationBreakdown.trailerNumber = lTrailerNumber;
    locationBreakdown.shipmentStatus = lShipmentStatus;
    LocationUtil.setShipmentLocation(lShipmentLocation, locationBreakdown);
  }

  static getLocationBreakdownForPLT(location: string): LocationBreakdown {
    const locationBreakdown = new LocationBreakdown();
    LocationUtil.setLocationFieldsForPLT(location, locationBreakdown);

    return locationBreakdown;
  }

  private static setLocationFieldsForPLT(location: string, locationBreakdown: LocationBreakdown): void {
    if (
      location === 'Final Delivered' || // <'Final Delivered'>
      location?.toLowerCase().includes('dock') || // <dock name> ---example: 'DOCK FINL' '13-DOCK' 'WI-DOCK' or <'On Dock'>
      location?.length === 3 // <sic>
    ) {
      locationBreakdown.shipmentLocation = location;
    } else {
      let lTrailerNumber: string;
      let lShipmentStatus: string;
      let lShipmentLocation: string;
      const substrings: string[] = location?.split(' ');

      if (substrings?.length === 3) {
        // original format: <trailer> <status> <location>
        lTrailerNumber = substrings[0];
        lShipmentStatus = substrings[1];
        lShipmentLocation = substrings[2];
      } else if (substrings?.length === 2) {
        // original format: <status> <location>
        lShipmentStatus = substrings[0];
        lShipmentLocation = substrings[1];
      } else {
        // returning the original value so we can see the value and fix if needed
        lShipmentLocation = location;
      }

      locationBreakdown.shipmentStatus = lShipmentStatus;
      locationBreakdown.trailerNumber = lTrailerNumber;
      LocationUtil.setShipmentLocation(lShipmentLocation, locationBreakdown);
    }
    locationBreakdown.breakDoorNumber = this.getDoorNumber(locationBreakdown.shipmentLocation);
  }

  private static getDoorNumber(location: string): number {
    if (!isNaN(+location) && +location > 0) {
      // if location is a number that is more than 0, its a door number
      return +location;
    } else {
      return undefined;
    }
  }

  private static setShipmentLocation(shipmentLocation: string, locationBreakdown: LocationBreakdown): void {
    const doorNumber: number = this.getDoorNumber(shipmentLocation);
    // if doorNumber has value, location is door number. otherwise its SIC or dock.
    if (!!doorNumber) {
      locationBreakdown.shipmentLocation = doorNumber.toString();
      locationBreakdown.breakDoorNumber = doorNumber;
    } else {
      locationBreakdown.shipmentLocation = shipmentLocation;
    }
  }
}
