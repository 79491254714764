import { Injectable } from '@angular/core';
import { FeatureSetting, GetNextBusinessDayResp, ListLocationFeaturesResp } from '@xpo-ltl-2.0/sdk-location';
import { NextBusinessDayOperationCd } from '@xpo-ltl/sdk-common';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of, switchMap, take } from 'rxjs';
import { LocationFeatureCode } from '../../enums/location-feature-code.enum';
import { RequestValidator } from '../../validators/request.validator';
import { LocationApiWrapperService } from './locationApiWrapperService';
@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private isCorrectionsFeatureAvailableSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  isCorrectionsFeatureAvailable$ = this.isCorrectionsFeatureAvailableSubject$.asObservable();

  isCorrectionsFeatureLoaded: boolean = false;

  constructor(private locationApiWrapperService: LocationApiWrapperService) {}

  get isCorrectionsFeatureAvailable() {
    return this.isCorrectionsFeatureAvailableSubject$.value;
  }

  setIsCorrectionsFeatureAvailable(employeeId: string, sicCd: string) {
    RequestValidator.validateStringNotNullOrEmpty(employeeId, 'Employee ID');
    RequestValidator.validateStringNotNullOrEmpty(sicCd, 'SIC Code');

    this.locationApiWrapperService
      .listLocationFeatures(LocationFeatureCode.EDGE_INSPECTION_CORRECTIONS)
      .pipe(take(1))
      .subscribe((resp: ListLocationFeaturesResp) => {
        const featureSettingForEveryone: FeatureSetting = resp.locationFeatures.find(
          (featureSetting: FeatureSetting) => !featureSetting.employeeId && !featureSetting.sicCd
        );

        if (featureSettingForEveryone) {
          this.isCorrectionsFeatureLoaded = true;
          this.isCorrectionsFeatureAvailableSubject$.next(!!featureSettingForEveryone);
        } else {
          const matchedFeatureSettings: FeatureSetting[] = resp.locationFeatures.filter(
            (featureSetting: FeatureSetting) =>
              featureSetting.employeeId === employeeId || featureSetting.sicCd === sicCd
          );

          this.isCorrectionsFeatureLoaded = true;
          this.isCorrectionsFeatureAvailableSubject$.next(matchedFeatureSettings.length > 0);
        }
      });
  }

  /**
   * we're calling getNextBusinessDay since we don't have getLastBusinessDay api
   */
  getLastBusinessDay(
    sicCd: string,
    offsetDaysCount: number,
    nextBusinessDayOperationCd?: NextBusinessDayOperationCd
  ): Observable<GetNextBusinessDayResp> {
    const today: Date = new Date();
    const possibleLastBusinessDay: Date = this.getPotentialLastBusinessDay(today);
    const startDateFormattedString: string = moment(possibleLastBusinessDay).format('YYYY-MM-DD');

    return this.callGetNextBusinessDayToGetLastBusinessDay(
      sicCd,
      startDateFormattedString,
      offsetDaysCount,
      nextBusinessDayOperationCd
    ).pipe(take(1));
  }

  callGetNextBusinessDayToGetLastBusinessDay(
    sicCd: string,
    startDateFormattedString: string,
    offsetDaysCount: number,
    nextBusinessDayOperationCd?: NextBusinessDayOperationCd
  ): Observable<GetNextBusinessDayResp> {
    return this.locationApiWrapperService
      .getNextBusinessDay(sicCd, startDateFormattedString, offsetDaysCount, nextBusinessDayOperationCd)
      .pipe(
        switchMap((resp: GetNextBusinessDayResp) => {
          if (resp.startDate === resp.nextBusinessDay) {
            return of(resp);
          } else {
            const formattedStartDate: string = resp.startDate.replace('-', '/');
            const checkedStartDate: Date = new Date(formattedStartDate);
            const possibleLastBusinessDay = this.getPotentialLastBusinessDay(checkedStartDate);
            startDateFormattedString = moment(possibleLastBusinessDay).format('YYYY-MM-DD');

            return this.callGetNextBusinessDayToGetLastBusinessDay(
              sicCd,
              startDateFormattedString,
              offsetDaysCount,
              nextBusinessDayOperationCd
            );
          }
        })
      );
  }

  private getPotentialLastBusinessDay(baseDate: Date) {
    // getDay() returns number 0 - 6(Sunday - Saturday)
    if (baseDate.getDay() === 1) {
      // if today is Monday, check if Friday is the last business day
      return this.getMinusDateFromNumber(baseDate, 3);
    } else {
      return this.getMinusDateFromNumber(baseDate, 1);
    }
  }

  private getMinusDateFromNumber(today: Date, minusDayNumber: number) {
    return new Date(today.setDate(today.getDate() - minusDayNumber));
  }
}
