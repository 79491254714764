import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { MaterialModule } from '../../material.module';
import { ListHeaderModule } from '../list-header/list-header.module';
import { ListCorrectionsComponent } from './list-corrections/list-corrections.component';
import { ListInspectionCorrectionsComponent } from './list-inspection-corrections.component';

@NgModule({
  declarations: [ListInspectionCorrectionsComponent, ListCorrectionsComponent],
  imports: [AgGridModule, MaterialModule, ListHeaderModule],
  providers: [],
  schemas: []
})
export class ListInspectionCorrectionsModule {}
