import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { InspectionListItem } from '../../../classes/inspection-list-item';
import { InspectionLocalStorageService } from '../../../services/inspection-local-storage.service';

export interface RouterLinkParam {
  routerLink?: string;
}

@Component({
  selector: 'app-router-link-column',
  templateUrl: './router-link-column.component.html',
  styleUrls: ['./router-link-column.component.scss']
})
export class RouterLinkColumnComponent implements AgRendererComponent {
  cellValue: string;
  routerLink: string;
  routerParam: string;
  params: any;
  isLinkApplied: boolean;

  constructor(
    private localStorageService: InspectionLocalStorageService,
  ) {}

  agInit(params: ICellRendererParams & RouterLinkParam): void {
    this.params = params;
    this.cellValue = params?.value;
    this.routerLink = params?.routerLink;

    this.updateIsLinkApplied();
    this.setRouterParam(params);
  }

  /**
   * refresh() is from ICellRenderer(base interface)
   *
   * Get the cell to refresh. Return true if successful. Return false if not (or you don't have refresh logic),
   * then the grid will refresh the cell for you.
   */
  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  private setRouterParam(params: ICellRendererParams & RouterLinkParam) {
    if (this.cellValue === InspectionListItem.OPEN_DIALOG) {
      this.routerParam = params?.data?.proNumber;
    } else {
      this.routerParam = params?.value;
    }
  }

  handleClickEvent() {
    this.localStorageService.setLastProIndexClick(this.params?.node?.rowIndex);
  } 

  private updateIsLinkApplied(): void {
    const isDoorOrTrailerNumber: boolean = !isNaN(+this.cellValue) || this.cellValue?.includes('-');
    const isOpenString: boolean = this.cellValue === InspectionListItem.OPEN_DIALOG;

    this.isLinkApplied = isDoorOrTrailerNumber || isOpenString;
  }
}
