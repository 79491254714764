import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { FormatValidationService } from '@xpo-ltl/common-services';
import { GetInspectionShipmentDetailsResp, InspectionContext } from '@xpo-ltl/sdk-inspections';
import { List } from 'immutable';
import { Observable, of, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { InspectionsBehaviorSubjectObservable } from '../../classes/inspections-behavior-subject-observable';
import { ProNumber } from '../../classes/pronumber';
import { SuccessErrorData } from '../../classes/success-error-data';
import { AddProComponent } from '../../dialogs/add-pro/add-pro.component';
import { ChangeLocationComponent } from '../../dialogs/change-location/change-location.component';
import { DevOnlyComponent } from '../../dialogs/dev-only/dev-only.component';
import { SettingsComponent } from '../../dialogs/settings/settings.component';
import { ListName } from '../../enums/list-name.enum';
import { ListType } from '../../enums/list-type.enum';
import { ShiftCode, toShiftCd } from '../../enums/shift-code.enum';
import { InspectionShipmentDetailsService } from '../../services/api/inspection-shipment-details.service';
import { ShipmentDetailsService } from '../../services/api/shipment-details.service';
import { AppConfigManagerService } from '../../services/app-config-manager.service';
import { AppConstantsService } from '../../services/app-constants.service';
import { AppNavigationService } from '../../services/app-navigation.service';
import { AppStorageService, IDBSpace } from '../../services/app-storage.service';
import { DialogWrapperService } from '../../services/dialog-wrapper.service';
import { GridSettingsService } from '../../services/grid-settings/grid-settings.service';
import { MobileWebBrowserService } from '../../services/hardware/mobile-web-browser-service';
import { RefreshDatesService } from '../../services/refresh-dates.service';
import { SnackBarHandlingService } from '../../services/snack-bar-handling.service';
import { LoadingOverlayService } from '../loading-overlay/service/loading-overlay.service';
import { PhotosStatusComponent } from '../photos-status/photos-status.component';

@Component({
  selector: 'app-ins-context-header',
  templateUrl: './ins-context-header.component.html',
  styleUrls: ['./ins-context-header.component.scss']
})
export class InsContextHeaderComponent implements OnInit, OnDestroy {
  readonly AppStorageService = AppStorageService;

  @ViewChild('searchInput', { static: true })
  searchInput: ElementRef;

  sic$: Observable<string>;
  shift$: Observable<string>;
  scanned: string = '';
  lookupInput: UntypedFormControl;

  protected spaceUsedMbBs: InspectionsBehaviorSubjectObservable<number> =
    new InspectionsBehaviorSubjectObservable<number>(0);
  spaceUsedMB$: Observable<number> = this.spaceUsedMbBs.observable$;

  protected storedPhotoCountBs: InspectionsBehaviorSubjectObservable<number> =
    new InspectionsBehaviorSubjectObservable<number>(0);
  storedPhotoCount$: Observable<number> = this.storedPhotoCountBs.observable$;

  private unsubscriber$: Subject<void> = new Subject();

  constructor(
    public appConstants: AppConstantsService,
    private dialogManager: DialogWrapperService,
    private errorHandling: SnackBarHandlingService,
    private gridSettings: GridSettingsService,
    private validationService: FormatValidationService,
    private appNavigation: AppNavigationService,
    private shipmentDetailService: ShipmentDetailsService,
    public mobileWebBrowserService: MobileWebBrowserService,
    private storageService: AppStorageService,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    public appConfigManagerService: AppConfigManagerService,
    public refreshDatesService: RefreshDatesService,
    private errorHandlingSevice: SnackBarHandlingService,
    private inspectionShipmentDetailsService: InspectionShipmentDetailsService,
    public loadingOverlayService: LoadingOverlayService
  ) {
    this.lookupInput = new UntypedFormControl();
  }

  private static parseAddPro(proString: string): string[] {
    if (!proString) {
      return undefined;
    }
    const spaceRegex = / /g;
    const proStringNoSpace = proString.replace(spaceRegex, '');
    const delimiterRegex = /[;\n|]/gi;
    const parsedProString = proStringNoSpace.replace(delimiterRegex, ',');
    return parsedProString.split(',').filter((pro) => pro.length > 0);
  }

  private static validateAddPro(pros: string[]): SuccessErrorData {
    const goodPros = [];
    const badPros = [];
    const successError = new SuccessErrorData();
    if (pros) {
      pros.forEach((pro) => {
        if (ProNumber.isValid(pro)) {
          goodPros.push(pro);
          successError.success = goodPros;
        } else {
          badPros.push(pro);
          successError.error = badPros;
        }
      });
    }
    return successError;
  }

  ngOnInit(): void {
    this.sic$ = this.appConstants.inspectionContext$.pipe(map((context: InspectionContext) => context?.inspectionSic));
    this.shift$ = this.appConstants.inspectionContext$.pipe(
      map((context: InspectionContext) => {
        return toShiftCd(context?.shiftCd as ShiftCode);
      })
    );

    //todo shoud be done by serviceWorker or event recalculating space
    this.storageService
      .updateAvailableSpace()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((space: IDBSpace) => {
        const lUsed: number = parseFloat((space.usedKB / 1000).toFixed(2));
        this.spaceUsedMbBs.next(lUsed);
      });
    //todo should be done another way as well
    this.storageService
      .updateStoredPhotosCount$()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((photoCount) => {
        this.storedPhotoCountBs.next(photoCount);
      });

    // This is from the previous header. Saving this just in case.
    // We check if the current route is the inspection page in order
    // to add the pro as a parameter for the tools menu modal
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     if (event.url && event.url.includes(RouterUriComponents.INSPECT_SHIPMENT_PAGE)) {
    //       this.toolsMenuPro = event.url.split('/').pop();
    //     } else {
    //       this.toolsMenuPro = undefined;
    //     }
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: any) {
    if (this.elementRef?.nativeElement?.contains(event.target)) {
      return;
    }
  }

  @HostListener('keypress', ['$event'])
  public itemScanned(event) {
    const input = event.key;
    if (this.searchInput.nativeElement !== document.activeElement) {
      if (this.elementRef.nativeElement.contains(event.target)) {
        if (input === 'Enter') {
          event.returnValue = '';
          this.onLookup();
        }
        if (input.length === 1) {
          this.scanned += input;
          this.lookupInput.setValue(this.scanned);
        }
      }
    } else {
      this.scanned = this.lookupInput.value;
    }
  }

  onLookup(): void {
    let lookupValue = this.lookupInput.value;
    if (lookupValue) {
      // Trim (primarily for trailer/door lookup, ProNumber already trims)
      lookupValue = lookupValue.trim();
      if (ProNumber.isValid(lookupValue)) {
        this.inspectionShipmentDetailsService
          .getInspectionShipmentDetails(new ProNumber(lookupValue))
          .pipe(
            filter((resp) => !!resp),
            take(1)
          )
          .subscribe((inspectionShipmentDetails: GetInspectionShipmentDetailsResp) => {
            this.inspectionShipmentDetailsService.setInspectionShipmentDetails(inspectionShipmentDetails);
            // once we get response, then navigate to the shipment detail page
            this.appNavigation.navigateToShipmentDetails(new ProNumber(lookupValue));
          });
      }
      // Lookup for trailer/door
      else if (
        this.validationService.isValidDoorNumber(lookupValue) ||
        this.validationService.isValidTrailerNumber(lookupValue)
      ) {
        this.gridSettings.clearFilterGridSettings(ListName.LOOKUP);
        this.appNavigation.navigateToList(ListType.LOOKUP, lookupValue);
      } else {
        this.errorHandling.showErrorMessage(`Please enter a valid PRO, Door, or Trailer #`);
      }
    }
    this.lookupInput.reset();
    // need to set timeout to wait a tick
    setTimeout(() => this.searchInput.nativeElement.blur());
  }

  onRefreshRecommendationsClicked(): void {
    this.loadingOverlayService.setIsLoading(true);
    this.shipmentDetailService
      .refreshRecommendations()
      .pipe(take(1))
      .subscribe(
        () => {
          this.loadingOverlayService.setIsLoading(false);
          this.refreshDatesService.lastRefreshRecommendationTimestamp.next(new Date());
          this.shipmentDetailService.loadAllLists(true);
          this.appNavigation.navigateToList(ListType.SHIPMENT, null);
        },
        (error) => {
          this.loadingOverlayService.setIsLoading(false);
          this.errorHandlingSevice.handleError('getPickUpRequests', error);
        }
      );
  }

  onAddProsClicked() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.autoFocus = true;
    matDialogConfig.disableClose = true;
    matDialogConfig.minWidth = '28em';
    matDialogConfig.minHeight = '32em';
    matDialogConfig.width = '40em';
    matDialogConfig.height = '36em';

    const matDialogRef = this.dialog.open(AddProComponent, matDialogConfig);
    matDialogRef
      .afterClosed()
      .pipe(
        filter((pro) => !!pro),
        map((proString: string) => InsContextHeaderComponent.parseAddPro(proString))
      )
      .subscribe((enteredValue: string[]) => {
        this.gridSettings.clearFilterGridSettings(ListName.ADD_PROS);
        this.shipmentDetailService.proNbrInputs = List(enteredValue);
        this.appNavigation.navigateToList(ListType.ADD_PROS);
      });
  }

  /**
   * this will be called when user select Change SIC/Shift from the right top corner menu
   */
  onChangeSicShiftClicked(): void {
    this.openChangeLocationDialog();
  }

  /**
   * this will be called when user double click SIC on the header
   */
  onSicClicked() {
    this.openChangeLocationDialog(ChangeLocationComponent.SIC);
  }

  /**
   * this will be called when user double click Shift on the header
   */
  onShiftClicked() {
    this.openChangeLocationDialog(ChangeLocationComponent.SHIFT);
  }

  // ------- using only in test environment
  onDevOnlyClicked(): void {
    this.dialogManager.alert(
      {
        titleText: 'Dev Only Tools',
        contentComponent: DevOnlyComponent,
        injectedData: of({ isSuccess: false }),
        hideDismissButton: true
      },
      {
        disableClose: false,
        width: '600px'
      }
    );
  }

  // This will check if the device can change any value or
  // save settings. We only have one check at the moment
  canSetAppSettings(): boolean {
    return this.mobileWebBrowserService.canSetZoomLevel();
  }

  onSettingsClicked() {
    this.dialog.open(SettingsComponent, SettingsComponent.getMatDialogConfig());
  }

  private openChangeLocationDialog(clickedFrom?: string): void {
    this.dialogManager.alert(
      {
        titleText: 'Change Home SIC and Shift',
        contentComponent: ChangeLocationComponent,
        injectedData: of(clickedFrom),
        hideDismissButton: true
      },
      {
        disableClose: true,
        width: '40em',
        autoFocus: false
      }
    );
  }

  onPhotoComponentShowClicked($event: MouseEvent) {
    this.dialog.open(PhotosStatusComponent, PhotosStatusComponent.getMatDialogConfig());
  }
}
