<div [ngSwitch]="inspectionState$ | async">
  <div class="toolbar-status-fix" *ngSwitchCase="InspectionState.IN_PROGRESS">
    <app-toolbar
      [showBackButton]="true"
      (onBackButtonClicked)="onBackButtonClicked()"
      [title]="proNumber + ' - In Progress'"
      [actionButton2Label]="InspectionStatusChangeMessage.INSPECT"
      (actionButton2Clicked)="inspectShipment()"
      [actionButton2IsMain]="true"
      [actionButton3Label]="InspectionStatusChangeMessage.INSPECTED_NOT_CORRECTED"
      (actionButton3Clicked)="markInspectedNotCorrected()"
      [actionButton3IsMain]="true"
      [actionButton4Label]="InspectionStatusChangeMessage.SEND_TO_RECOMMENDED_LIST"
      (actionButton4Clicked)="sendToRecommendedList()"
      [actionButton4IsMain]="false"
      [actionButton5Label]="InspectionStatusChangeMessage.SEND_TO_FLAGGED_LIST"
      (actionButton5Clicked)="sendToFlaggedList()"
      [actionButton5IsMain]="false"
      [actionButton6Label]="InspectionStatusChangeMessage.DISMISS"
      (actionButton6Clicked)="dismissShipment()"
      [actionButton6IsMain]="false"
    ></app-toolbar>
  </div>

  <div class="toolbar-status-fix" *ngSwitchCase="InspectionState.EDITING">
    <app-toolbar
      [showBackButton]="true"
      (onBackButtonClicked)="onBackButtonClicked()"
      [title]="proNumber + ' - In Edit'"
      [actionButton2Label]="InspectionStatusChangeMessage.INSPECT"
      (actionButton2Clicked)="inspectShipment()"
      [actionButton2IsMain]="true"
      [actionButton3Label]="InspectionStatusChangeMessage.INSPECTED_NOT_CORRECTED"
      (actionButton3Clicked)="markInspectedNotCorrected()"
      [actionButton3IsMain]="true"
    ></app-toolbar>
  </div>

  <div class="toolbar-status-fix" *ngSwitchCase="InspectionState.INSPECTED">
    <div *ngIf="locationService.isCorrectionsFeatureAvailable$ | async; else withoutCorrections">
      <app-toolbar
        *ngIf="
          shipmentDetails?.correctionRequestsForShipment?.correctionRequestAllowedInd;
          else correctionRequestNotAllowed
        "
        [showBackButton]="true"
        (onBackButtonClicked)="onBackButtonClicked()"
        [title]="
          proNumber +
          ' - Inspected &nbsp; &nbsp; &nbsp; &nbsp; Correction Request Status - ' +
          (correctionRequestStatus | spacedStringFromTitleCase)
        "
        [actionButton1Label]="actionForCorrectionRequest + ' CORRECTION REQUEST'"
        (actionButton1Clicked)="openInspectionCorrection()"
        [actionButton1IsMain]="true"
        [actionButton2Label]="InspectionStatusChangeMessage.INSPECTED_NOT_CORRECTED"
        (actionButton2Clicked)="markInspectedNotCorrected()"
        [actionButton2IsMain]="true"
        [actionButton3Label]="InspectionStatusChangeMessage.EDIT_INSPECTION"
        (actionButton3Clicked)="editInspection()"
        [actionButton3IsMain]="false"
      ></app-toolbar>
    </div>
  </div>

  <div class="toolbar-status-fix" *ngSwitchCase="InspectionState.RECOMMENDED">
    <app-toolbar
      [showBackButton]="true"
      (onBackButtonClicked)="onBackButtonClicked()"
      [title]="proNumber + ' - Recommended' + (isRevoked ? ' (Revoked)' : '')"
      [actionButton1Label]="InspectionStatusChangeMessage.KEEP"
      (actionButton1Clicked)="keepShipment()"
      [actionButton1IsMain]="true"
      [actionButton1Show]="isRevoked"
      [actionButton2Label]="InspectionStatusChangeMessage.INSPECT"
      (actionButton2Clicked)="inspectShipment()"
      [actionButton2IsMain]="true"
      [actionButton3Label]="InspectionStatusChangeMessage.SEND_TO_FLAGGED_LIST"
      (actionButton3Clicked)="sendToFlaggedList()"
      [actionButton3IsMain]="false"
      [actionButton4Label]="InspectionStatusChangeMessage.DISMISS"
      (actionButton4Clicked)="dismissShipment()"
      [actionButton4IsMain]="false"
      [actionButton5Label]="InspectionStatusChangeMessage.INSPECTED_NOT_CORRECTED"
      (actionButton5Clicked)="markInspectedNotCorrected()"
      [actionButton5IsMain]="false"
    ></app-toolbar>
  </div>

  <div class="toolbar-status-fix" *ngSwitchCase="InspectionState.FLAGGED">
    <app-toolbar
      [showBackButton]="true"
      (onBackButtonClicked)="onBackButtonClicked()"
      [title]="proNumber + ' - Flagged' + (isRevoked ? ' (Revoked)' : '')"
      [actionButton1Label]="InspectionStatusChangeMessage.KEEP"
      (actionButton1Clicked)="keepShipment()"
      [actionButton1IsMain]="true"
      [actionButton1Show]="isRevoked"
      [actionButton2Label]="InspectionStatusChangeMessage.INSPECT"
      (actionButton2Clicked)="inspectShipment()"
      [actionButton2IsMain]="true"
      [actionButton3Label]="InspectionStatusChangeMessage.SEND_TO_RECOMMENDED_LIST"
      (actionButton3Clicked)="sendToRecommendedList()"
      [actionButton3IsMain]="false"
      [actionButton4Label]="InspectionStatusChangeMessage.DISMISS"
      (actionButton4Clicked)="dismissShipment()"
      [actionButton4IsMain]="false"
      [actionButton5Label]="InspectionStatusChangeMessage.INSPECTED_NOT_CORRECTED"
      (actionButton5Clicked)="markInspectedNotCorrected()"
      [actionButton5IsMain]="false"
    ></app-toolbar>
  </div>

  <div class="toolbar-status-fix" *ngSwitchCase="InspectionState.DISMISSED">
    <app-toolbar
      [showBackButton]="true"
      (onBackButtonClicked)="onBackButtonClicked()"
      [title]="proNumber + ' - Dismissed' + (isRevoked ? ' (Revoked)' : '')"
      [actionButton1Label]="InspectionStatusChangeMessage.KEEP"
      (actionButton1Clicked)="keepShipment()"
      [actionButton1IsMain]="true"
      [actionButton1Show]="isRevoked"
      [actionButton2Label]="InspectionStatusChangeMessage.INSPECT"
      (actionButton2Clicked)="inspectShipment()"
      [actionButton2IsMain]="true"
      [actionButton3Label]="InspectionStatusChangeMessage.SEND_TO_RECOMMENDED_LIST"
      (actionButton3Clicked)="sendToRecommendedList()"
      [actionButton3IsMain]="false"
      [actionButton4Label]="InspectionStatusChangeMessage.SEND_TO_FLAGGED_LIST"
      (actionButton4Clicked)="sendToFlaggedList()"
      [actionButton4IsMain]="false"
      [actionButton5Label]="InspectionStatusChangeMessage.INSPECTED_NOT_CORRECTED"
      (actionButton5Clicked)="markInspectedNotCorrected()"
      [actionButton5IsMain]="false"
    ></app-toolbar>
  </div>

  <div class="toolbar-status-fix" *ngSwitchCase="InspectionState.CORRECTION_SUBMITTED">
    <app-toolbar
      [showBackButton]="true"
      (onBackButtonClicked)="onBackButtonClicked()"
      [title]="proNumber + ' - Correction Submitted'"
      [actionButton2Label]="InspectionStatusChangeMessage.INSPECT"
      (actionButton2Clicked)="inspectShipment()"
      [actionButton2IsMain]="true"
      [actionButton3Label]="InspectionStatusChangeMessage.SEND_TO_RECOMMENDED_LIST"
      (actionButton3Clicked)="sendToRecommendedList()"
      [actionButton3IsMain]="false"
      [actionButton4Label]="InspectionStatusChangeMessage.SEND_TO_FLAGGED_LIST"
      (actionButton4Clicked)="sendToFlaggedList()"
      [actionButton4IsMain]="false"
    ></app-toolbar>
  </div>

  <div class="toolbar-status-fix" *ngSwitchCase="InspectionState.INSPECTED_NOT_CORRECTED">
    <app-toolbar
      [showBackButton]="true"
      (onBackButtonClicked)="onBackButtonClicked()"
      [title]="proNumber + ' - Inspected Not Corrected'"
      [actionButton2Label]="InspectionStatusChangeMessage.INSPECT"
      (actionButton2Clicked)="inspectShipment()"
      [actionButton2IsMain]="true"
      [actionButton3Label]="InspectionStatusChangeMessage.SEND_TO_RECOMMENDED_LIST"
      (actionButton3Clicked)="sendToRecommendedList()"
      [actionButton3IsMain]="false"
      [actionButton4Label]="InspectionStatusChangeMessage.SEND_TO_FLAGGED_LIST"
      (actionButton4Clicked)="sendToFlaggedList()"
      [actionButton4IsMain]="false"
    ></app-toolbar>
  </div>

  <div class="toolbar-status-fix" *ngSwitchCase="InspectionState.NO_STATUS">
    <app-toolbar
      [showBackButton]="true"
      (onBackButtonClicked)="onBackButtonClicked()"
      [title]="proNumber + ' - No Status'"
      [actionButton2Label]="InspectionStatusChangeMessage.INSPECT"
      (actionButton2Clicked)="inspectShipment()"
      [actionButton2IsMain]="true"
      [actionButton3Label]="InspectionStatusChangeMessage.SEND_TO_RECOMMENDED_LIST"
      (actionButton3Clicked)="sendToRecommendedList()"
      [actionButton3IsMain]="false"
      [actionButton4Label]="InspectionStatusChangeMessage.SEND_TO_FLAGGED_LIST"
      (actionButton4Clicked)="sendToFlaggedList()"
      [actionButton4IsMain]="false"
      [actionButton5Label]="InspectionStatusChangeMessage.INSPECTED_NOT_CORRECTED"
      (actionButton5Clicked)="markInspectedNotCorrected()"
      [actionButton5IsMain]="false"
    ></app-toolbar>
  </div>
</div>

<ng-template #correctionRequestNotAllowed>
  <app-toolbar
    [showBackButton]="true"
    (onBackButtonClicked)="onBackButtonClicked()"
    [title]="proNumber + ' - Inspected'"
    [actionButton2Label]="InspectionStatusChangeMessage.INSPECTED_NOT_CORRECTED"
    (actionButton2Clicked)="markInspectedNotCorrected()"
    [actionButton2IsMain]="true"
    [actionButton3Label]="InspectionStatusChangeMessage.EDIT_INSPECTION"
    (actionButton3Clicked)="editInspection()"
    [actionButton3IsMain]="true"
  ></app-toolbar>
</ng-template>

<ng-template #withoutCorrections>
  <app-toolbar
    [showBackButton]="true"
    (onBackButtonClicked)="onBackButtonClicked()"
    [title]="proNumber + ' - Inspected'"
    [actionButton2Label]="InspectionStatusChangeMessage.INSPECTED_NOT_CORRECTED"
    (actionButton2Clicked)="markInspectedNotCorrected()"
    [actionButton2IsMain]="true"
    [actionButton3Label]="InspectionStatusChangeMessage.MARK_CORRECTED"
    (actionButton3Clicked)="markCorrected()"
    [actionButton3IsMain]="true"
    [actionButton4Label]="InspectionStatusChangeMessage.EDIT_INSPECTION"
    (actionButton4Clicked)="editInspection()"
    [actionButton4IsMain]="false"
  ></app-toolbar>
</ng-template>
