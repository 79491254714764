<div class="overall">
  <div class="close">
    <button
      (click)="close()"
      class="close"
      matTooltip="Click to close or Escape or Click outside the dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <h1 mat-dialog-title class="alert-wrapper__title">
    {{ data.titleText || 'NO TITLE PROVIDED' }}
  </h1>
  <div mat-dialog-content class="alert-wrapper__content">
    <ng-template appDialogTemplateInjector></ng-template>
    <div *ngIf="data.contentText">{{ data.contentText }}</div>
  </div>
</div>
