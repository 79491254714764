import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { interval } from 'rxjs';
import { InspectionsConstants } from '../classes/inspections-constants';
import { TimestampBehaviorSubject } from '../classes/timestamp-behavior-subject';

@Injectable({
  providedIn: 'root'
})
export class RefreshDatesService {
  lastRefreshRecommendationTimestamp: TimestampBehaviorSubject = new TimestampBehaviorSubject();
  lastShipmentListRefreshTimestamp: TimestampBehaviorSubject = new TimestampBehaviorSubject();
  recommendationRulesListRefreshTimestamp: TimestampBehaviorSubject = new TimestampBehaviorSubject();
  pickupRequestsListRefreshTimestamp: TimestampBehaviorSubject = new TimestampBehaviorSubject();
  correctionApprovalListRefreshTimestamp: TimestampBehaviorSubject = new TimestampBehaviorSubject();
  customerGuidelineListRefreshTimestamp: TimestampBehaviorSubject = new TimestampBehaviorSubject();
  broadcastMessagesListRefreshTimestamp: TimestampBehaviorSubject = new TimestampBehaviorSubject();
  inspectionCorrectionListRefreshTimestamp: TimestampBehaviorSubject = new TimestampBehaviorSubject();

  protected toRefreshList: List<TimestampBehaviorSubject> = List([
    this.lastRefreshRecommendationTimestamp,
    this.lastShipmentListRefreshTimestamp,
    this.recommendationRulesListRefreshTimestamp,
    this.pickupRequestsListRefreshTimestamp,
    this.correctionApprovalListRefreshTimestamp,
    this.broadcastMessagesListRefreshTimestamp,
    this.customerGuidelineListRefreshTimestamp,
    this.inspectionCorrectionListRefreshTimestamp
  ]);

  constructor() {
    interval(InspectionsConstants.REFRESH_DATES_TIMEOUT_MS).subscribe(() => {
      this.refreshDateAndTimes();
    });
  }

  setLastRefreshRecommendationsDate(aDate: Date) {
    this.lastRefreshRecommendationTimestamp.next(aDate);
  }

  setShipmentListRefreshDate(aDate: Date) {
    this.lastShipmentListRefreshTimestamp.next(aDate);
  }

  setRecommendationRuleListRefreshDate(aDate: Date) {
    this.recommendationRulesListRefreshTimestamp.next(aDate);
  }

  setPickupRequestListRefreshDate(aDate: Date) {
    this.pickupRequestsListRefreshTimestamp.next(aDate);
  }
  
  setCorrectionApprovalListRefreshDate(aDate: Date) {
    this.correctionApprovalListRefreshTimestamp.next(aDate);
  }

  setCustomerGuidelinesListRefreshDate(aDate: Date) {
    this.customerGuidelineListRefreshTimestamp.next(aDate);
  }

  setBroadcastMessagesListRefreshTimestamp(aDate: Date) {
    this.broadcastMessagesListRefreshTimestamp.next(aDate);
  }

  setInspectionCorrectionListRefreshTimestamp(aDate: Date) {
    this.inspectionCorrectionListRefreshTimestamp.next(aDate);
  }

  refreshDateAndTimes() {
    this.toRefreshList.forEach((toRefresh) => {
      toRefresh.refreshStringValue();
    });
  }
}
