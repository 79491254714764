import { PhotoGalleryDataIF } from '../../../photo-gallery/photo-gallery-data-if';
import { Observable } from 'rxjs';
import { Photo } from '../../../../classes/photos/photo';
import { ProNumber } from '../../../../classes/pronumber';
import { PhotoId } from '../../../../classes/photos/photo-id';
import { MobileWebBrowserService } from '../../../../services/hardware/mobile-web-browser-service';

export class PhotoGalleryDataImpl implements PhotoGalleryDataIF {
  constructor(private mobileWebBrowserService: MobileWebBrowserService) {}

  public canDeletePhoto(): boolean {
    return true;
  }

  public loadPhoto(photoId: PhotoId): Observable<Photo> {
    return this.mobileWebBrowserService.getImage(photoId);
  }

  public deletePhoto(photoId: PhotoId): Observable<boolean> {
    return this.mobileWebBrowserService.deletePhoto(photoId);
  }

  public listPhotoIds(proNumber: ProNumber): Observable<PhotoId[]> {
    return this.mobileWebBrowserService.listPhotoIds(proNumber);
  }
}
