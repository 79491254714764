import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InspectionShipment } from '@xpo-ltl/sdk-inspections';
import { ProNumber } from '../../../../classes/pronumber';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { InspectionLocalStorageService } from '../../../../services/inspection-local-storage.service';
import { Router } from '@angular/router';
import { RouterUriComponents } from '../../../../enums/router-uri-components.enum';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnDestroy {
  constructor(public inspectionLocalStorage: InspectionLocalStorageService, private router: Router) {}

  private debounceSubject$ = new Subject<void>();
  private debounceObservable = this.debounceSubject$.asObservable();
  private subscription = new Subscription();

  @Input()
  inspectionDetails: InspectionShipment;

  ngOnInit() {
    this.subscription = this.debounceObservable.pipe(debounceTime(1000)).subscribe(() => {
      this.inspectionLocalStorage.setInspectionNotes(
        new ProNumber(this.inspectionDetails.shipmentId.proNumber),
        this.inspectionDetails.inspectionNote
      );
    });
  }

  public showCalculator() {
    this.router.navigate([RouterUriComponents.ITEM_680_2B_PAGE, this.inspectionDetails.shipmentId.proNumber]);
  }

  public notesChanged() {
    this.debounceSubject$.next();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
