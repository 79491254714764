import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toUsCurrency'
})
export class ToUsCurrency implements PipeTransform {
  transform(beforeFormattedNumber: number | string): string {
    let twoDigitNumString: string = '0.00';
    beforeFormattedNumber = +beforeFormattedNumber;

    if (!!beforeFormattedNumber) {
      twoDigitNumString = beforeFormattedNumber?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      });
    }

    return `$${twoDigitNumString}`;
  }
}
