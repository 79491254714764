import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { DialogWrapperService } from '../services/dialog-wrapper.service';

@Injectable()
export class AutoCorrectionRoleCheckGuard implements CanActivate {
  constructor(private userService: UserService, private dialogManager: DialogWrapperService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.userService.isUserLoggedIn$.pipe(
      map(() => {
        const checkManagerRole: boolean = false;
        const checkAutoCorrectionReviewerRole: boolean = true;
        const isAuthorized: boolean = this.userService.isCurrentUserAuthorizedToAccess(checkManagerRole, checkAutoCorrectionReviewerRole);
        if (!isAuthorized) {
          this.userService.logNotAuthorizedUser();
          this.dialogManager.showNotAuthorizedDialog();
        }

        return isAuthorized;
      })
    );
  }
}
