import { IllegalArgumentError } from '../classes/error/illegal-argument-error';
import { InsCreateCorrectionStatusCdEnum } from './create-correction-status-code.enum';
import { ElsSubmissionOverviewCardMessageEnum } from './els-submission-message.enum';

export enum GeneralCorrectionSubmissionMessageEnum {
  // for general correction
  CORRECTION_SUBMITTED = 'Correction has been submitted.',
  CORRECTION_APPROVED = 'Correction has been submitted and approved.',
  CORRECTION_PENDING = 'Correction has been submitted and is Pending for auditor review.',
  CORRECTION_DECLINED = 'Correction has been submitted and was declined.',
  ERROR_MOTORIZED_TOTAL_PIECES = 'Pro is not eligible for correction. Motorized pieces are greater than total pieces.',
  ERROR_500 = 'An error occurred in the Bill Correction System. Please try again later. If the error persists use Feedback to request support.',
  ERROR_BILL_CORRECTION_SYSTEM = 'An error occurred in the Bill Correction System. Please try again later. If the error persists use Feedback to request support.',

  // this can be for both auto-els and manual correction
  ERROR_UNKNOWN = 'Correction was submitted but a process error occurred. If Auto_ELS, correction will be reprocessed for ELS at final delivery otherwise use Feedback to request support.'
}

export enum GeneralSubmissionInspectionCardMessageEnum {
  CORRECTION_SUBMITTED = 'Correction Submitted',
  CORRECTION_APPROVED = 'Correction Approved',
  CORRECTION_PENDING = 'Correction Pending',
  CORRECTION_DISABLED = 'Correction Disabled',
  CORRECTION_DECLINED = 'Correction Declined',
  CORRECTION_ERROR = 'Correction Error',
  WILL_BE_PROCESSED_AT_FINAL_DELIVERY = 'Will be reprocessed at final delivery',
  ERROR_MOTORIZED_TOTAL_PIECES = 'Motorized pieces > total pieces',
  ERROR_UNKNOWN = 'If ELS, correction be reprocessed at final delivery',
  ELS_ALREADY_APPLIED = 'ELS already applied',
  XLF_ALREADY_APPLIED = 'Lineal Foot already applied',
  XCC_ALREADY_APPLIED = 'Cubic Capacity already applied',
  EXISTING_ELS_CORRECTION = 'ELS correction already submitted',
  NO_REVENUE_SHIPMENT = 'Non-revenue shipment',
  NO_REVENUE_CHANGE = 'No revenue impact',
  LINEAL_FOOT_INSPECTION_TYPE = 'Inspection is for a Lineal Foot Correction'
}

export function getGeneralCorrectionSubmissionMessageFromCorrectionStatusCd(
  statusCd: InsCreateCorrectionStatusCdEnum | string
): string {
  let message: string = '';
  switch (statusCd) {
    case InsCreateCorrectionStatusCdEnum.CORRECTION_INVALID:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_LOCKED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_SAVED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_UNKNOWN:
      message = GeneralCorrectionSubmissionMessageEnum.CORRECTION_SUBMITTED;
      break;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_APPROVED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_APPLIED:
      message = GeneralCorrectionSubmissionMessageEnum.CORRECTION_APPROVED;
      break;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_PENDING:
      message = GeneralCorrectionSubmissionMessageEnum.CORRECTION_PENDING;
      break;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_DECLINED:
      message = GeneralCorrectionSubmissionMessageEnum.CORRECTION_DECLINED;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_UNKNOWN:
      message = GeneralCorrectionSubmissionMessageEnum.ERROR_UNKNOWN;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_MOTORIZED_TOTAL_PIECES:
      message = GeneralCorrectionSubmissionMessageEnum.ERROR_MOTORIZED_TOTAL_PIECES;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_BILL_CORRECTION_SYSTEM:
      message = GeneralCorrectionSubmissionMessageEnum.ERROR_BILL_CORRECTION_SYSTEM;
      break;
    default:
      message = statusCd;
      throw new IllegalArgumentError(
        `Unknown Correction Status Code: ${statusCd}. Please use Feedback to report the issue.`
      );
  }

  return message;
}

export function getGeneralCorrectionStatusFromCorrectionStatusCd(
  statusCd: InsCreateCorrectionStatusCdEnum | string
): string {
  let message: string = '';
  switch (statusCd) {
    case InsCreateCorrectionStatusCdEnum.CORRECTION_INVALID:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_LOCKED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_SAVED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_UNKNOWN:
      message = GeneralSubmissionInspectionCardMessageEnum.CORRECTION_SUBMITTED;
      break;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_APPROVED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_APPLIED:
      message = GeneralSubmissionInspectionCardMessageEnum.CORRECTION_APPROVED;
      break;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_PENDING:
      message = GeneralSubmissionInspectionCardMessageEnum.CORRECTION_PENDING;
      break;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_DISABLED:
      message = GeneralSubmissionInspectionCardMessageEnum.CORRECTION_DISABLED;
      break;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_DECLINED:
      message = GeneralSubmissionInspectionCardMessageEnum.CORRECTION_DECLINED;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_UNKNOWN:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_ORIGINAL_CHARGES:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_DIMENSIONS:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_EMPLOYEE:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_PREVIEW_CHARGES:
    case InsCreateCorrectionStatusCdEnum.ERROR_MOTORIZED_TOTAL_PIECES:
    case InsCreateCorrectionStatusCdEnum.ERROR_BILL_CORRECTION_SYSTEM:
      message = GeneralSubmissionInspectionCardMessageEnum.CORRECTION_ERROR;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_ELS_ALREADY_APPLIED:
    case InsCreateCorrectionStatusCdEnum.ERROR_XLF_ALREADY_APPLIED:
    case InsCreateCorrectionStatusCdEnum.ERROR_XCC_ALREADY_APPLIED:
    case InsCreateCorrectionStatusCdEnum.ERROR_CORRECTION_EXISTS:
    case InsCreateCorrectionStatusCdEnum.ERROR_BILL_CLASS_INVALID:
    case InsCreateCorrectionStatusCdEnum.NO_REVENUE_CHANGE:
    case InsCreateCorrectionStatusCdEnum.ERROR_LINEAL_FOOT_INSPECTION_TYPE:
      message = ElsSubmissionOverviewCardMessageEnum.NOT_ELIGIBLE_FOR_ELS;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_SHIPMENT_NOT_RATED:
      message = ElsSubmissionOverviewCardMessageEnum.PRO_NOT_RATED;
      break;
    default:
      message = statusCd;
      throw new IllegalArgumentError(
        `Unknown Correction Status Code: ${statusCd}. Please use Feedback to report the issue.`
      );
  }

  return message;
}

export function getGeneralCorrectionStatusFromCorrectionStatusCdForInspectionCardTooltip(
  statusCd: InsCreateCorrectionStatusCdEnum | string
): string {
  let message: string = '';
  switch (statusCd) {
    case InsCreateCorrectionStatusCdEnum.CORRECTION_INVALID:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_LOCKED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_SAVED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_UNKNOWN:
      message = GeneralSubmissionInspectionCardMessageEnum.CORRECTION_SUBMITTED;
      break;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_APPROVED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_APPLIED:
      message = GeneralSubmissionInspectionCardMessageEnum.CORRECTION_APPROVED;
      break;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_PENDING:
      message = GeneralSubmissionInspectionCardMessageEnum.CORRECTION_PENDING;
      break;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_DISABLED:
      message = GeneralSubmissionInspectionCardMessageEnum.CORRECTION_DISABLED;
      break;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_DECLINED:
      message = GeneralSubmissionInspectionCardMessageEnum.CORRECTION_DECLINED;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_UNKNOWN:
      message = GeneralSubmissionInspectionCardMessageEnum.ERROR_UNKNOWN;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_ORIGINAL_CHARGES:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_DIMENSIONS:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_EMPLOYEE:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_PREVIEW_CHARGES:
    case InsCreateCorrectionStatusCdEnum.ERROR_SHIPMENT_NOT_RATED:
      message = GeneralSubmissionInspectionCardMessageEnum.WILL_BE_PROCESSED_AT_FINAL_DELIVERY;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_ELS_ALREADY_APPLIED:
      message = GeneralSubmissionInspectionCardMessageEnum.ELS_ALREADY_APPLIED;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_XLF_ALREADY_APPLIED:
      message = GeneralSubmissionInspectionCardMessageEnum.XLF_ALREADY_APPLIED;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_XCC_ALREADY_APPLIED:
      message = GeneralSubmissionInspectionCardMessageEnum.XCC_ALREADY_APPLIED;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_CORRECTION_EXISTS:
      message = GeneralSubmissionInspectionCardMessageEnum.EXISTING_ELS_CORRECTION;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_BILL_CLASS_INVALID:
      message = GeneralSubmissionInspectionCardMessageEnum.NO_REVENUE_SHIPMENT;
      break;
    case InsCreateCorrectionStatusCdEnum.NO_REVENUE_CHANGE:
      message = GeneralSubmissionInspectionCardMessageEnum.NO_REVENUE_CHANGE;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_LINEAL_FOOT_INSPECTION_TYPE:
      message = ElsSubmissionOverviewCardMessageEnum.LINEAL_FOOT_INSPECTION_TYPE;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_BILL_CORRECTION_SYSTEM:
      message = ElsSubmissionOverviewCardMessageEnum.CORRECTION_ERROR;
      break;
    case InsCreateCorrectionStatusCdEnum.ERROR_MOTORIZED_TOTAL_PIECES:
      message = ElsSubmissionOverviewCardMessageEnum.ERROR_MOTORIZED_TOTAL_PIECES;
      break;
    default:
      message = statusCd;
      throw new IllegalArgumentError(
        `Unknown Correction Status Code: ${statusCd}. Please use Feedback to report the issue.`
      );
  }

  return message;
}

export function isStatusError(statusCd: InsCreateCorrectionStatusCdEnum | string): boolean {
  switch (statusCd) {
    case InsCreateCorrectionStatusCdEnum.CORRECTION_INVALID:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_LOCKED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_SAVED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_UNKNOWN:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_APPROVED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_APPLIED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_PENDING:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_DISABLED:
    case InsCreateCorrectionStatusCdEnum.CORRECTION_DECLINED:
    case InsCreateCorrectionStatusCdEnum.NO_REVENUE_CHANGE:
      return false;
    case InsCreateCorrectionStatusCdEnum.CORRECTION_PROXY_FAILURE:
    case InsCreateCorrectionStatusCdEnum.ERROR_UNKNOWN:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_ORIGINAL_CHARGES:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_EMPLOYEE:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_PREVIEW_CHARGES:
    case InsCreateCorrectionStatusCdEnum.ERROR_NO_DIMENSIONS:
    case InsCreateCorrectionStatusCdEnum.ERROR_ELS_ALREADY_APPLIED:
    case InsCreateCorrectionStatusCdEnum.ERROR_XLF_ALREADY_APPLIED:
    case InsCreateCorrectionStatusCdEnum.ERROR_CORRECTION_EXISTS:
    case InsCreateCorrectionStatusCdEnum.ERROR_BILL_CLASS_INVALID:
    case InsCreateCorrectionStatusCdEnum.ERROR_SHIPMENT_NOT_RATED:
    case InsCreateCorrectionStatusCdEnum.ERROR_MOTORIZED_TOTAL_PIECES:
    case InsCreateCorrectionStatusCdEnum.ERROR_LINEAL_FOOT_INSPECTION_TYPE:
    case InsCreateCorrectionStatusCdEnum.ERROR_BILL_CORRECTION_SYSTEM:
      return true;
    default:
      throw new IllegalArgumentError(
        `Unknown Correction Status Code: ${statusCd}. Please use Feedback to report the issue.`
      );
  }
}
