import { Pipe, PipeTransform } from '@angular/core';
import { DmsDocType } from '../enums/dms-doc-type.enum';

@Pipe({
  name: 'imageTypePipe'
})
export class ImageTypePipe implements PipeTransform {
  transform(imageType: string): string {
    if (!imageType) {
      return '';
    }

    switch (imageType) {
      case DmsDocType.BILL_OF_LANDING:
        return 'Bill Of Lading';
      case DmsDocType.BILL_OF_LANDING_ATTACHMENT:
        return 'Bill Of Lading Attachment';
      case DmsDocType.CUSTOMS:
        return 'Customs Document';
      case DmsDocType.INSPECTION_CERTIFICATE:
        return 'NMFC Inspection Certificate';
      case DmsDocType.REWEIGH_CERTIFICATE:
        return 'WI Inspection Certificate';
      case DmsDocType.INSPECTION_PHOTO:
        return 'WI Inspection Photo';
      case DmsDocType.TRAILER_PHOTO:
        return 'Trailer Photo';
      case DmsDocType.DIMENSION_PHOTO:
        return 'In Motion Dimensioner Photo';
      case DmsDocType.PICKUP_DIMENSIONER_PHOTO:
        return 'Dimensioner Photo';
      case DmsDocType.CUBIC_SCAN_DIMENSIONER_PHOTO:
        return 'Cubiscan Dimensioner Photo';
      default:
        return '';
    }
  }
}
