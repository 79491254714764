import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { DataValidationError } from '@xpo-ltl/sdk-common';
import { List } from 'immutable';
import { BehaviorSubject } from 'rxjs';
import { InspectionErrorCdEnum } from '../../../enums/inspection-error-code.enum';
import { DataValidationErrorInterface } from '../../../interfaces/data-validation-error.interface';

@Component({
  selector: 'app-invalid-pro-number',
  templateUrl: './invalid-pro-number.component.html',
  styleUrls: ['./invalid-pro-number.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InvalidProNumberComponent implements OnInit {
  private invalidProsSubject: BehaviorSubject<List<DataValidationError>> = new BehaviorSubject<
    List<DataValidationError>
  >(undefined);
  invalidPros$ = this.invalidProsSubject.asObservable();

  private mapErrorProsSubject: BehaviorSubject<List<DataValidationError>> = new BehaviorSubject<
    List<DataValidationError>
  >(undefined);
  mapErrorPros$ = this.mapErrorProsSubject.asObservable();

  static readonly INVALID_PROS_MESSAGE =
    'The following requested Pros did not have a valid Pro number format and are not included in the results. Note that Child Pros are not valid for this feature, only Parent Pros are allowed.';
  static readonly MAP_ERROR_PROS_MESSAGE =
    'There was an error retrieving shipment information for the Pros below. Data available is included but other data may be missing.';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataValidationErrorInterface,
    private dialogRef: MatDialogRef<InvalidProNumberComponent>
  ) {}

  ngOnInit() {
    const invalidProsTemp: DataValidationError[] = [];
    const mapErrorProsTemp: DataValidationError[] = [];
    this.data?.invalidPros?.forEach((invalidPro: DataValidationError) => {
      if (invalidPro.errorCd === InspectionErrorCdEnum.PRO_FORMAT_INVALID) {
        invalidProsTemp.push(invalidPro);
      } else if (invalidPro.errorCd === InspectionErrorCdEnum.SHIPMENT_MAPPING_ERROR) {
        mapErrorProsTemp.push(invalidPro);
      }
    });
    this.invalidProsSubject.next(List(invalidProsTemp));
    this.mapErrorProsSubject.next(List(mapErrorProsTemp));
  }

  get invalidProsMessage() {
    return InvalidProNumberComponent.INVALID_PROS_MESSAGE;
  }

  get mapErrorProsMessage() {
    return InvalidProNumberComponent.MAP_ERROR_PROS_MESSAGE;
  }

  close(): void {
    this.dialogRef.close();
  }
}
