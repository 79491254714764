import { CustomerGuideline, EnrichedCustomerGuideline } from '@xpo-ltl/sdk-inspections';

/**
 * There exist already a customerGuideline class in sdk this should be named differently, its confusing in plural
 */
export class CustomerGuidelineWithAcct {
  public static readonly GUIDELINE_TXT_LENGTH_MIN: number = 10;
  public static readonly GUIDELINE_TXT_LENGTH_MAX: number = 4_000;

  public customerGuidelineId: number;
  public acctName: string;
  public acctInstId: string;
  public acctMadCd: string;
  public guideline: string;
  public createByName: string;
  public createdTimestamp: string;
  public updateByName: string;
  public updatedTimestamp: string;

  public setFromEnrichedCustomerGuideline(item: EnrichedCustomerGuideline) {
    this.guideline = item?.guideline;
    this.createByName = item?.auditInfo?.createdById;
    this.createdTimestamp = item?.auditInfo?.createdTimestamp?.toString();
    this.updateByName = item?.auditInfo?.updateById;
    this.updatedTimestamp = item?.auditInfo?.updatedTimestamp?.toString();
    this.acctName = item?.customer?.acctName;
    this.acctMadCd = item?.customer?.acctMadCd;
    this.acctInstId = item?.customer?.acctInstId;
    this.customerGuidelineId = item?.customerGuidelineId;
  }

  toCustomerGuidelineForApi(): CustomerGuideline {
    const customerGuidelineForApi: CustomerGuideline = new CustomerGuideline();
    customerGuidelineForApi.customerGuidelineId = this.customerGuidelineId;
    customerGuidelineForApi.customerId = Number(this.acctInstId);
    customerGuidelineForApi.guideline = this.guideline;
    return customerGuidelineForApi;
  }
}
