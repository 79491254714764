import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Comment,
  Dimensions,
  InspectionCorrectionIneligibleReasonCd,
  InspectionCorrectionTypeCd
} from '@xpo-ltl/sdk-common';
import {
  CreateInspectionCorrectionResp,
  EligibleInspectionCorrection,
  GetInspectionDetailsResp,
  GetInspectionShipmentDetailsResp,
  InspectionCommodityLine,
  InspectionShipment,
  ListEligibleInspectionCorrectionsResp,
  PieceDimensions
} from '@xpo-ltl/sdk-inspections';
import { List } from 'immutable';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { ApiError } from '../../classes/error/api/api-error';
import { InspectionError } from '../../classes/error/inspection-error';
import { Photo } from '../../classes/photos/photo';
import { ProNumber } from '../../classes/pronumber';
import { ConfirmMessage } from '../../enums/confirm-message.enum';
import { InsCreateCorrectionStatusCdEnum } from '../../enums/create-correction-status-code.enum';
import { DmsDocType } from '../../enums/dms-doc-type.enum';
import { DocumentListTitles } from '../../enums/document-list-titles.enum';
import {
  ElsSubmissionMessageEnum,
  getDisplayMessageFromCorrectionStatusCd,
  getDisplayMessageFromIneligibleReasonCd,
  isAnotherInspectionQuestionNeeded
} from '../../enums/els-submission-message.enum';
import { ErrorMessageActions } from '../../enums/error-message-actions.enum';
import { InspectionState, toInspectionStateDisplayName } from '../../enums/inspection-state.enum';
import { InspectionDetailService } from '../../services/api/inspection-detail.service';
import { InspectionShipmentDetailsService } from '../../services/api/inspection-shipment-details.service';
import { ShipmentDetailsService } from '../../services/api/shipment-details.service';
import { AppConfigManagerService } from '../../services/app-config-manager.service';
import { AppConstantsService } from '../../services/app-constants.service';
import { AppNavigationService } from '../../services/app-navigation.service';
import { DialogWrapperService } from '../../services/dialog-wrapper.service';
import { MobileWebBrowserService } from '../../services/hardware/mobile-web-browser-service';
import { InspectionLocalStorageService } from '../../services/inspection-local-storage.service';
import { PhotoDownloadService } from '../../services/photos/photo-download.service';
import { SnackBarHandlingService } from '../../services/snack-bar-handling.service';
import { LoadingOverlayService } from '../loading-overlay/service/loading-overlay.service';

// This might change based on LEI-1889 and LEI-1808
export class EligibilityAndCorrectionState {
  static readonly ELS_MESSAGE_DURATION: number = 5000;

  typeCd: InspectionCorrectionTypeCd; // correction type. example: ELS
  allowed: boolean; // if its allowed for correction submission
  eligible: boolean; // if PRO is eligible for correction
  eligibilityReasonCd: InspectionCorrectionIneligibleReasonCd; // reason when allowed is false
  revenue: number;
  correctionStatusCd: InsCreateCorrectionStatusCdEnum;
  isQuestionNeeded: boolean; // if its true, ask question if another inspection submission is needed or not
  displayMessageFromCorrectionStatusCd: string; // snackbar message based on correctionStatusCd
}

@Component({
  selector: 'app-inspect-shipment',
  templateUrl: './inspect-shipment.component.html',
  styleUrls: ['./inspect-shipment.component.scss']
})
export class InspectShipmentComponent implements OnInit, OnDestroy {
  proNumber: ProNumber;
  shipmentDetails: InspectionShipment;
  inspectionDetails: InspectionShipment;
  dimensionErrors: string[] = [];
  DocumentListTitles = DocumentListTitles;
  readonly photoDocTypes = [DmsDocType.INSPECTION_PHOTO];

  private inspectionDetailsSubject = new BehaviorSubject<InspectionShipment>(undefined);
  inspectionDetails$ = this.inspectionDetailsSubject.asObservable();

  private statusSubject = new BehaviorSubject<string>(undefined);
  status$ = this.statusSubject.asObservable();

  private unsubscriber$: Subject<void> = new Subject();
  private numberOfPhotos = 0;
  private newInspectionShipment: InspectionShipment = new InspectionShipment();
  private exceedsWidth: boolean = false;
  private exceedsHeight: boolean = false;
  private exceedsLength: boolean = false;
  inspectionPhotos$: Observable<List<Photo>>;
  private insPhotosSubscription: Subscription;
  private inspectionShipmentDetailsUnsubscriber$: Subject<void> = new Subject<void>();
  private inspectionStateUnsubscriber$: Subject<void> = new Subject<void>();
  private readonly REPLACE_STRING: string = '{replaceString}';
  private readonly CREATE_INSPECTION_ERROR_MESSAGE: string = `Inspection already in '${this.REPLACE_STRING}' state.`;

  constructor(
    public appConstantsService: AppConstantsService,
    public shipmentDetailsService: ShipmentDetailsService,
    public router: Router,
    private route: ActivatedRoute,
    public constantsService: AppConstantsService,
    private dialogWrapperService: DialogWrapperService,
    private mobileWebBrowserService: MobileWebBrowserService,
    private inspectionLocalStorageService: InspectionLocalStorageService,
    private appNavigationService: AppNavigationService,
    private matDialog: MatDialog,
    private snackBarHandlingService: SnackBarHandlingService,
    private configManagerService: AppConfigManagerService,
    private photoDownloadService: PhotoDownloadService,
    private inspectionShipmentDetailsService: InspectionShipmentDetailsService,
    public changeDetector: ChangeDetectorRef,
    private titleService: Title,
    private inspectionDetailService: InspectionDetailService,
    public loadingOverlayService: LoadingOverlayService
  ) {}

  static addRowToInspectionDetails(inspectionShipment: InspectionShipment) {
    if (!inspectionShipment) {
      throw Error('param should not be null or undefined');
    }
    const inspectionPieceDimensions = new PieceDimensions();
    inspectionPieceDimensions.dimensions = new Dimensions();
    if (!inspectionShipment.inspectorPieceDimensions) {
      inspectionShipment.inspectorPieceDimensions = [];
    }
    inspectionShipment.inspectorPieceDimensions.push(inspectionPieceDimensions);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  ngOnInit() {
    this.route.params.subscribe((routeParam) => {
      const proParamS: string = routeParam['pro'];

      if (!ProNumber.isValid(proParamS) || this.appConstantsService.notHasSicCode()) {
        const msg: string = 'Pro from route is invalid or invalid sicCode set in appConstants';
        console.error(msg, this.route.params, this.appConstantsService.getSicCodeOrUndefined());
        this.snackBarHandlingService.showErrorMessage(msg);
        this.appNavigationService.navigateToList();
      } else {
        const proParam: ProNumber = new ProNumber(proParamS);
        this.titleService.setTitle('Inspect Shipment for pro:' + proParamS);
        this.inspectionStateUnsubscriber$ = new Subject<void>();
        this.inspectionShipmentDetailsUnsubscriber$ = new Subject<void>();
        this.loadComponentDataByPro(proParam);
      }
    });
  }

  onBackButtonClicked() {
    this.appNavigationService.navigateToShipmentDetails(this.proNumber);
  }

  onInspectedNotCorrectedClicked() {
    this.inspectionDetails.inspectionStatusCd = InspectionState.INSPECTED_NOT_CORRECTED;
    this.buildAndCreateInspection(this.inspectionDetails);
    const messageBody: string = this.buildConfirmationMessage();
    this.openConfirmCancelDialog(messageBody).subscribe((isReadyToSubmit: boolean) => {
      if (isReadyToSubmit) {
        this.submitInspection(this.newInspectionShipment);
      } else {
        // do nothing. go back to inspection page
      }
    });
  }

  onSubmitInspectionClicked() {
    if (this.validateInspection(this.inspectionDetails, true)) {
      this.inspectionDetails.inspectionStatusCd = InspectionState.INSPECTED;
      this.buildAndCreateInspection(this.inspectionDetails);
      const messageBody: string = this.buildConfirmationMessage();
      this.openConfirmCancelDialog(messageBody).subscribe((isReadyToSubmit: boolean) => {
        if (isReadyToSubmit) {
          this.submitInspection(this.newInspectionShipment);
        } else {
          // do nothing. go back to inspection page
        }
      });
    }
  }

  selectedCorrectionIndChanged(selectedCorrectionIndicators) {
    const hasTrue: boolean = !!Object.values(selectedCorrectionIndicators).find((value) => value);

    if (hasTrue) {
      Object.keys(selectedCorrectionIndicators).forEach((key) => {
        if (selectedCorrectionIndicators[key]) {
          this.inspectionDetails.inspectionCorrectionTypeCd = key as InspectionCorrectionTypeCd;
          this.inspectionLocalStorageService.setInspectionCorrectionTypeCd(
            new ProNumber(this.inspectionDetails.shipmentId.proNumber),
            [this.inspectionDetails.inspectionCorrectionTypeCd]
          );
        }
      });
    } else {
      this.inspectionDetails.inspectionCorrectionTypeCd = null;

      // remove from local storage
      this.inspectionLocalStorageService.removeInspectionCorrectionTypeCd(
        new ProNumber(this.inspectionDetails.shipmentId.proNumber)
      );
    }
  }

  setNumberOfPhotos(aNumberOfPhotos: number) {
    this.numberOfPhotos = aNumberOfPhotos;
  }

  private buildAndCreateInspection(inspectionDetails: InspectionShipment) {
    const DEFAULT_PIECES_COUNT: number = 1;
    const DEFAULT_SEQUENCE_NUMBER: number = 1;
    if (inspectionDetails) {
      // before submitting.. Validate that things are in the correct state to submit
      // Must not be in Inspected Status to continue.
      // Validate Max Length and copy newPieceDimensions into new array
      const newPieceDimensions = new Array<PieceDimensions>();

      inspectionDetails.inspectorPieceDimensions?.forEach((pieceDimension: PieceDimensions) => {
        if (
          pieceDimension?.pieceCnt > 0 &&
          pieceDimension?.dimensions?.width > 0 &&
          pieceDimension?.dimensions?.length > 0 &&
          pieceDimension?.dimensions?.height > 0
        ) {
          this.exceedsWidth = pieceDimension?.dimensions?.width > this.constantsService?.maximumCommodityDimensionWidth;
          this.exceedsLength =
            pieceDimension?.dimensions?.length > this.constantsService?.maximumCommodityDimensionLength;
          this.exceedsHeight =
            pieceDimension?.dimensions?.height > this.constantsService?.maximumCommodityDimensionHeight;

          pieceDimension.seqNbr = newPieceDimensions.length + 1;
          newPieceDimensions.push(pieceDimension);
        }
      });
      this.newInspectionShipment.shipmentId = inspectionDetails.shipmentId;
      this.newInspectionShipment.inspectionStatusCd = inspectionDetails.inspectionStatusCd;

      this.newInspectionShipment.commodity = new Array<InspectionCommodityLine>();
      const commodityLine = new InspectionCommodityLine();
      commodityLine.pieceCnt = DEFAULT_PIECES_COUNT;
      commodityLine.seq = DEFAULT_SEQUENCE_NUMBER;
      commodityLine.totGrossWeight = inspectionDetails.totGrossWeight;
      this.newInspectionShipment.commodity.push(commodityLine);

      this.newInspectionShipment.inspectorPieceDimensions = newPieceDimensions;
      this.newInspectionShipment.inspectionNote = new Comment();
      this.newInspectionShipment.inspectionNote.note = inspectionDetails.inspectionNote?.note;

      this.newInspectionShipment.inspectionCorrectionTypeCd = inspectionDetails.inspectionCorrectionTypeCd;
    } else {
      throw new InspectionError('Inspection Shipment cannot be null/undefined.');
    }
  }

  private buildConfirmationMessage(): string {
    const inspectionState: string = this.newInspectionShipment.inspectionStatusCd;
    let messageBody: string = '';
    if (this.exceedsLength) {
      messageBody += 'Length Exceeds ' + this.constantsService.maximumCommodityDimensionLength + ' Inches. <br />';
    }
    if (this.exceedsWidth) {
      messageBody += 'Width Exceeds ' + this.constantsService.maximumCommodityDimensionWidth + ' Inches. <br />';
    }
    if (this.exceedsHeight) {
      messageBody += 'Height Exceeds ' + this.constantsService.maximumCommodityDimensionHeight + ' Inches. <br />';
    }

    if (messageBody.length > 0) {
      messageBody = 'Warning: Dimension Value Exceeded<br /><br />' + messageBody + '<br />';
    }

    if (inspectionState === InspectionState.INSPECTED_NOT_CORRECTED) {
      messageBody += '<br />' + ConfirmMessage.INSPECTED_NOT_CORRECTED;
    } else {
      // Only if Submitting an Inspection, check for 3 photos
      if (this.numberOfPhotos < 3) {
        messageBody += 'Less than 3 photos taken.<br>';
      }
      messageBody += '<br />' + ConfirmMessage.INSPECTION_SUBMIT_MESSAGE;
    }

    return messageBody;
  }

  private buildInspectionDetails(
    inspectionDetailsTarget: InspectionShipment,
    shipmentDetailsSrc: InspectionShipment
  ): InspectionShipment {
    if (!inspectionDetailsTarget) {
      inspectionDetailsTarget = new InspectionShipment();
      inspectionDetailsTarget.shipmentId = shipmentDetailsSrc.shipmentId;
    }

    inspectionDetailsTarget.totPieceCnt = shipmentDetailsSrc.totPieceCnt;
    inspectionDetailsTarget.totGrossWeight = shipmentDetailsSrc.totGrossWeight;
    inspectionDetailsTarget.motorizedPiecesCnt = shipmentDetailsSrc.motorizedPiecesCnt;
    inspectionDetailsTarget.loosePiecesCnt = shipmentDetailsSrc.loosePiecesCnt;

    if (!inspectionDetailsTarget.inspectorPieceDimensions) {
      inspectionDetailsTarget.inspectorPieceDimensions = shipmentDetailsSrc.inspectorPieceDimensions;
    }

    if (!inspectionDetailsTarget.inspectionNote) {
      inspectionDetailsTarget.inspectionNote = new Comment();
    }

    InspectShipmentComponent.addRowToInspectionDetails(inspectionDetailsTarget);

    return inspectionDetailsTarget;
  }

  private correctionState$(
    elsEligibility: EligibleInspectionCorrection,
    inspectionShipment: InspectionShipment
  ): Observable<EligibilityAndCorrectionState> {
    const correctionState: EligibilityAndCorrectionState = new EligibilityAndCorrectionState();

    if (elsEligibility?.allowedInd) {
      return this.shipmentDetailsService
        .createInspectionCorrection(inspectionShipment, InspectionCorrectionTypeCd.ELS)
        .pipe(
          take(1),
          switchMap((correctionStatus: CreateInspectionCorrectionResp) => {
            correctionState.allowed = elsEligibility.allowedInd;
            correctionState.eligible = elsEligibility.eligibleInd;
            correctionState.eligibilityReasonCd = elsEligibility.reasonCd;
            correctionState.typeCd = elsEligibility.typeCd;
            correctionState.revenue = correctionStatus.amountUsd;
            correctionState.correctionStatusCd = correctionStatus.statusCd as InsCreateCorrectionStatusCdEnum;
            correctionState.isQuestionNeeded = isAnotherInspectionQuestionNeeded(correctionStatus.statusCd);
            correctionState.displayMessageFromCorrectionStatusCd = getDisplayMessageFromCorrectionStatusCd(
              correctionStatus.statusCd,
              correctionState.isQuestionNeeded
            );
            return of(correctionState);
          }),
          catchError((error) => {
            if (error.code === '500') {
              this.snackBarHandlingService.showErrorMessage(ElsSubmissionMessageEnum.ERROR_500);
            } else {
              this.snackBarHandlingService.handleResponseError(
                error,
                ErrorMessageActions.CREATING,
                `ELS Inspection Correction`
              );
            }
            return of(correctionState);
          })
        );
    } else {
      correctionState.allowed = elsEligibility?.allowedInd;
      correctionState.eligible = elsEligibility.eligibleInd;
      correctionState.eligibilityReasonCd = elsEligibility?.reasonCd;
      correctionState.typeCd = elsEligibility?.typeCd;
      return of(correctionState);
    }
  }

  private createInspection$(inspectionShipment: InspectionShipment, message?: string): Observable<void> {
    return this.shipmentDetailsService.createInspection(inspectionShipment).pipe(
      take(1),
      catchError((error) => {
        this.loadingOverlayService.setIsLoading(false);
        let errorMessage: string = '';
        if (error.code === '400' && error.error?.message?.startsWith('Inspection already in ')) {
          const errorMessageFromBE: string = error.error?.message;
          const statusCode: InspectionState = <InspectionState>errorMessageFromBE?.slice(23, 24);
          const status: string = toInspectionStateDisplayName(statusCode);
          errorMessage = this.CREATE_INSPECTION_ERROR_MESSAGE.replace(this.REPLACE_STRING, status);
        } else {
          errorMessage = this.snackBarHandlingService.buildDisplayErrorMessage(
            ErrorMessageActions.CREATING,
            `Inspection`
          );
          errorMessage = this.snackBarHandlingService.buildResponseErrorMessage(errorMessage, error);
        }

        throw new ApiError(errorMessage);
      })
    );
  }

  private elsEligibility$(inspectionShipment: InspectionShipment): Observable<EligibleInspectionCorrection> {
    return this.shipmentDetailsService
      .listEligibleInspectionCorrections(inspectionShipment.shipmentId.shipmentInstId)
      .pipe(
        take(1),
        map((resp: ListEligibleInspectionCorrectionsResp) => {
          return resp.eligibleInspectionCorrections.find(
            (eligibleCorrection: EligibleInspectionCorrection) =>
              eligibleCorrection.typeCd === InspectionCorrectionTypeCd.ELS
          );
        }),
        catchError((error) => {
          this.snackBarHandlingService.handleResponseError(error, ErrorMessageActions.LOADING, `ELS Eligibility`);

          throw new ApiError(`Error ${ErrorMessageActions.LOADING} ELS Eligibility`, error);
        })
      );
  }

  private isValidDimension(value: number): boolean {
    if (Number.isNaN(value) || value <= 0) {
      return false;
    }
    const testValue = (Math.ceil((value * 10) / 5) * 5) / 10;
    if (testValue !== value) {
      return false;
    }
    return true;
  }

  private openConfirmCancelDialog(messageBody: string): Observable<boolean> {
    return this.dialogWrapperService.showConfirmCancelDialog(messageBody).pipe(take(1));
  }

  private operateAfterSubmission(inspectionShipment: InspectionShipment, message?: string): void {
    // remove PieceDimensions from Local Storage on Create Inspection
    this.inspectionLocalStorageService.clearInspectionData(new ProNumber(this.inspectionDetails.shipmentId.proNumber));

    this.mobileWebBrowserService.submitInspectionPhotos(this.proNumber);

    this.appNavigationService.navigateToList(
      null,
      null,
      message,
      null,
      EligibilityAndCorrectionState.ELS_MESSAGE_DURATION
    );
  }

  /**
   * 1. call createInspection() to update DB
   * 2. once #1 is completed, call eligibleInspectionCorrection
   * 3. based on #2's response, call createInspectionCorrection
   * 4. ELS correction previewed or submitted
   */
  private submitInspection(inspectionShipment: InspectionShipment): void {
    if (!this.configManagerService.isElsEnabled()) {
      this.submitInspectionWithoutELS(inspectionShipment);
    } else {
      this.submitInspectionWithELS(inspectionShipment);
    }
  }

  private submitInspectionWithELS(inspectionShipment: InspectionShipment) {
    let reasonMessage: string;
    this.loadingOverlayService.setIsLoading(true);
    // step 1. call createInspection() to update DB
    this.createInspection$(inspectionShipment)
      .pipe(
        switchMap(() => {
          // step 2. once #1 is completed, call eligibleInspectionCorrection
          return this.elsEligibility$(inspectionShipment).pipe(
            switchMap((elsEligibility: EligibleInspectionCorrection) => {
              // step 3. based on #2's response, call createInspectionCorrection
              return this.correctionState$(elsEligibility, inspectionShipment).pipe(
                take(1),
                switchMap((correctionState: EligibilityAndCorrectionState) => {
                  // step 4. ELS correction submitted
                  if (correctionState.isQuestionNeeded) {
                    // Open dialog to ask if another inspection is needed.
                    return this.openConfirmCancelDialog(correctionState.displayMessageFromCorrectionStatusCd).pipe(
                      take(1),
                      switchMap((isAnotherInspectionRequired: boolean) => {
                        if (isAnotherInspectionRequired) {
                          return this.createInspection$(inspectionShipment);
                        } else {
                          return of(false);
                        }
                      })
                    );
                  } else {
                    // step 4. Correction reviewed and not submitted, update message to display reason
                    if (correctionState.eligibilityReasonCd?.trim().length > 0) {
                      reasonMessage = getDisplayMessageFromIneligibleReasonCd(correctionState.eligibilityReasonCd);
                    } else {
                      reasonMessage = correctionState.displayMessageFromCorrectionStatusCd;
                    }

                    return of(false);
                  }
                })
              );
            }),
            takeUntil(this.unsubscriber$)
          );
        })
      ) // error is handled in createInspection$
      .subscribe(() => {
        this.loadingOverlayService.setIsLoading(false);
        if (reasonMessage?.length > 0) {
          this.operateAfterSubmission(inspectionShipment, reasonMessage);
        } else {
          this.operateAfterSubmission(inspectionShipment);
        }
      });
  }

  private submitInspectionWithoutELS(inspectionShipment: InspectionShipment) {
    this.loadingOverlayService.setIsLoading(true);
    this.createInspection$(inspectionShipment)
      .pipe(take(1)) // error is handled in createInspection$
      .subscribe(() => {
        this.loadingOverlayService.setIsLoading(false);
        this.operateAfterSubmission(inspectionShipment);
      });
  }

  private validateInspection(inspectionDetails: InspectionShipment, dimensionsRequired: boolean): boolean {
    this.dimensionErrors = new Array<string>();
    let validCount = 0;
    let hasError = false;
    for (let idx = 0; idx < inspectionDetails.inspectorPieceDimensions.length; idx++) {
      const pieceDimension = inspectionDetails.inspectorPieceDimensions[idx];
      const pieceCount: number = pieceDimension.pieceCnt;
      const width = pieceDimension.dimensions.width;
      const length = pieceDimension.dimensions.length;
      const height = pieceDimension.dimensions.height;
      let errorMessage: string;

      if (pieceCount || width || length || height) {
        if (pieceCount && width && length && height) {
          if (!Number.isInteger(+pieceCount)) {
            errorMessage = 'Invalid Piece Count!';
          } else if (!this.isValidDimension(+width)) {
            errorMessage = 'Invalid Width! Must be > 0 and in .5 increments.';
          } else if (!this.isValidDimension(+length)) {
            errorMessage = 'Invalid Length! Must be > 0 and in .5 increments.';
          } else if (!this.isValidDimension(+height)) {
            errorMessage = 'Invalid Height! Must be > 0 and in .5 increments.';
          } else {
            validCount++;
          }
        } else {
          errorMessage = 'Missing Data in Row!';
        }
      }

      if (errorMessage) {
        hasError = true;
      }

      this.dimensionErrors.push(errorMessage);
    }

    if (hasError) {
      return false;
    }

    if (dimensionsRequired && validCount === 0) {
      this.dimensionErrors[0] = 'At least One Dimension is Required to Submit Inspection!';
      return false;
    }
    return true;
  }

  private loadComponentDataByPro(aProNumber: ProNumber) {
    this.dimensionErrors = new Array<string>();
    this.inspectionPhotos$ = this.photoDownloadService.inspectionPhotosSubject.asObservable();

    combineLatest([
      this.inspectionDetailService.getInspectionDetails(aProNumber.formatProNumber()),
      this.inspectionShipmentDetailsService.inspectionShipmentDetailsSubject
    ])
      .pipe(
        filter(
          ([inspectionDetailsResp, inspectionShipmentDetailsResp]) =>
            !!inspectionDetailsResp && !!inspectionShipmentDetailsResp
        ),
        distinctUntilChanged(),
        take(1)
      )
      .subscribe(([inspectionDetailsResp, inspectionShipmentDetailsResp]) => {
        if (inspectionDetailsResp.inspectionDetails?.length > 0) {
          console.debug(
            'inspectionShipmentDetailsSubject->inspectionShipmentDetails received with pro:' +
              inspectionShipmentDetailsResp?.shipmentDetails?.shipmentId?.proNumber,
            inspectionShipmentDetailsResp
          );

          this.statusSubject.next(
            toInspectionStateDisplayName(
              <InspectionState>inspectionDetailsResp?.inspectionDetails?.[0].inspectionStatusCd
            )
          );
          this.setInspectionAndShipmentDetails(inspectionDetailsResp, inspectionShipmentDetailsResp, aProNumber);
          this.inspectionShipmentDetailsUnsubscriber$.next();
        }
      });

    this.inspectionShipmentDetailsService
      .getInspectionShipmentDetails(aProNumber)
      .pipe(take(1))
      .subscribe((resp: GetInspectionShipmentDetailsResp) => {
        this.inspectionShipmentDetailsService.setInspectionShipmentDetails(resp);
      });

    this.mobileWebBrowserService.removePendingPhotos(); //not sure why, Think that should not be done only
  }

  private setInspectionAndShipmentDetails(
    inspectionDetailsResp: GetInspectionDetailsResp,
    inspectionShipmentDetails: GetInspectionShipmentDetailsResp,
    aProNumber: ProNumber
  ) {
    this.shipmentDetails = inspectionShipmentDetails.shipmentDetails;
    this.proNumber = new ProNumber(this.shipmentDetails.shipmentId.proNumber);
    if (!this.proNumber.equals(aProNumber)) {
      this.snackBarHandlingService.handleResponseError(
        new Error(
          'pros are not the same from path then from inspectionShipmentDetails, shipmentIdPro:' +
            this.proNumber +
            ' received pro:' +
            aProNumber
        ),
        ErrorMessageActions.VALIDATING,
        'onInit',
        false
      );
    }
    this.inspectionDetails = this.buildInspectionDetails(
      inspectionDetailsResp.inspectionDetails?.[0],
      this.shipmentDetails
    );

    /// get photoIds from previous inspections for this shipment if existed
    this.photoDownloadService.loadPhotoIdsFromDms(aProNumber, this.shipmentDetails?.inspectionHandlingUnits);

    //inspection notes saved in local storage
    const inspectionNotes: Comment = this.inspectionLocalStorageService.getInspectionNotes(aProNumber);
    if (inspectionNotes) {
      this.inspectionDetails.inspectionNote = inspectionNotes;
    }

    //inspection dimensions saved in local storage
    const pieceDimensions: PieceDimensions[] | undefined =
      this.inspectionLocalStorageService.getInspectionDimensions(aProNumber);
    if (pieceDimensions?.length > 0) {
      this.inspectionDetails.inspectorPieceDimensions = pieceDimensions;
    }

    const correctionTypeCds: InspectionCorrectionTypeCd[] =
      this.inspectionLocalStorageService.getInspectionCorrectionTypeCd(
        new ProNumber(this.inspectionDetails?.shipmentId.proNumber)
      );
    if (correctionTypeCds?.length > 0) {
      this.inspectionDetails.inspectionCorrectionTypeCd = correctionTypeCds[0];
    }

    this.inspectionDetailsSubject.next(this.inspectionDetails);
  }
} //end class
