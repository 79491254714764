/**
 * CUSTOMER_GUIDELINES - can add/update/delete Customer Guidelines
 * MESSAGES - can send broadcast messages
 */
export enum ManagerRoles {
  CUSTOMER_GUIDELINES = 'LTL_INSPECTOR_CUSTOMER_GUIDELINES',
  MESSAGES = 'LTL_INSPECTOR_MESSAGES',
}

export enum AutoCorrectionsRoles {
  AUTO_CORRECTION_REVIEW_PROD = 'LTL_WI_AutoCorr_Review',
  AUTO_CORRECTION_REVIEW_TEST = 'TST_LTL_WI_AutoCorr_Review'
}
