import { List } from 'immutable';
import { MultipleNotificationDataInterface } from '../interfaces/multiple-notifications-data.interface';
import { NotificationData } from './notification-data';

export class MultipleNotificationData implements MultipleNotificationDataInterface {
  notificationDataList: List<NotificationData>;

  constructor(notificationData: List<NotificationData>) {
    this.notificationDataList = notificationData;
  }
}
