/**
 * https://www.ag-grid.com/angular-data-grid/filtering-overview/
 *
 * https://www.ag-grid.com/javascript-data-grid/filtering/#column-filter-types
 */
export enum AgGridFilterType {
  NUMBER = 'agNumberColumnFilter',
  SET = 'agSetColumnFilter',
  TEXT = 'agTextColumnFilter',
  DATE = 'agDateColumnFilter',
  MULTICOLUMN = 'agMultiColumnFilter'
}
