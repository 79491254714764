<div class="overview">
  <mat-expansion-panel [(expanded)]="isExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title class="section-label">
        <span class="overview-title"> Overview </span>
        <button
          (click)="openShipmentDetailsManagementApp($event)"
          mat-button
          color="primary"
          matTooltip="Open Edge Shipment Management in new tab"
        >
          Edge Shipment Management
        </button>
        <button
          *ngIf="locationService.isCorrectionsFeatureAvailable$ | async"
          (click)="openBillDetailsOnCorrectionsApp($event)"
          mat-button
          color="primary"
          matTooltip="Open Edge Corrections in new tab"
        >
          Edge Corrections
        </button>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="overview__row1">
      <div class="overview__row1-col1">
        <div>
          <div class="overview__row1-col1-total-pieces">
            <div class="label">Total Pieces</div>
            <div class="value">
              {{ inspectionShipmentDetails?.shipmentDetails?.totPieceCnt }}
            </div>
          </div>
          <div class="overview__row1-col1-motor-moves">
            <div class="label">Motor Moves</div>
            <div class="value">
              {{ inspectionShipmentDetails?.shipmentDetails?.motorizedPiecesCnt }}
            </div>
          </div>
          <div class="overview__row1-col1-loose-pieces">
            <div class="label">Loose Pieces</div>
            <div class="value">
              {{ inspectionShipmentDetails?.shipmentDetails?.loosePiecesCnt }}
            </div>
          </div>
        </div>
        <div>
          <div class="overview__row1-col1-total-weight">
            <div class="label">Total Weight</div>
            <div class="value">
              {{ inspectionShipmentDetails?.shipmentDetails?.totGrossWeight }}
            </div>
          </div>
          <div class="overview__row1-col1-bill-class">
            <div class="label">Cu Ft</div>
            <div class="value">
              {{ inspectionShipmentDetails?.shipmentDetails?.totGrossVolume }}
            </div>
          </div>
          <div class="overview__row1-col1-bill-status">
            <div class="label">PCF</div>
            <div class="value">
              {{ inspectionShipmentDetails?.shipmentDetails?.totDensity }}
            </div>
          </div>
        </div>
        <div>
          <div class="overview__row1-col1-bill-class">
            <div class="label">Bill Class</div>
            <div class="value">
              {{ inspectionShipmentDetails?.shipmentDetails?.billClassCd }}
            </div>
          </div>
          <div class="overview__row1-col1-bill-status">
            <div class="label">Bill Status</div>
            <div class="value">
              {{ inspectionShipmentDetails?.shipmentDetails?.billStatusCd }}
            </div>
          </div>
          <div class="overview__row1-col1-bill-status"></div>
        </div>
      </div>
      <div class="overview__row1-col2">
        <div>
          <div class="overview__row1-col2-fak">
            <div class="label">FAK</div>
            <div *ngFor="let fakText of inspectionShipmentDetails?.shipmentDetails?.agreementFakText">
              <div class="value">{{ fakText }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="horizontal-divider" style="width: 2%; margin-bottom: 16px"></div>
      <div class="overview__row1-col3">
        <div class="label">History</div>
        <div
          class="overview__row1-col3-history"
          *ngFor="let detail of inspectionShipmentDetails?.previousInspectionDetails; let i = index"
        >
          <div fxLayout="row">
            <i class="material-icons overview__row1-col3-history-check">check_circle_outline</i>
            <div fxLayout="column">
              <div class="overview__row1-col3-history-status" data-testid="inspection-status">
                {{ detail?.inspectionStatusCd | inspectionStatusCode }}
              </div>
              <div class="label" data-testid="inspector-timestamp">
                {{ detail?.inspectionContext?.inspectorFirstNm?.[0] }} {{ detail?.inspectionContext?.inspectorLastNm }},
                {{ detail?.inspectionContext?.inspectionSic }},
                {{ detail?.lastUpdateDateTime | dateMilitaryFormat }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <table class="overview_table">
      <tr>
        <th class="label">Pieces</th>
        <th class="label">Packaging</th>
        <th class="label">Weight</th>
        <th class="label">NMFC</th>
        <th class="label">Class</th>
        <th class="label">Commodity Description</th>
      </tr>

      <tbody>
        <tr *ngFor="let commodity of inspectionShipmentDetails?.shipmentDetails?.commodity; let i = index">
          <td class="value">{{ commodity.pieceCnt }}</td>
          <td class="value">{{ commodity.packageCd }}</td>
          <td class="value">{{ commodity.totGrossWeight }}</td>
          <td class="value">
            <div class="nmfc-search-link">
              <a (click)="openNmftaSite(commodity.nmfcItemCd)">
                {{ commodity.nmfcItemCd }}
              </a>
            </div>
          </td>

          <td class="value">{{ commodity.nmfcClass }}</td>
          <td class="value">{{ commodity.desc }}</td>
        </tr>
      </tbody>
    </table>
  </mat-expansion-panel>
</div>
