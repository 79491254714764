import { ShiftCd } from '@xpo-ltl/sdk-common';

export enum ShiftCode {
  IN_BOUND = 'Inbound',
  OUT_BOUND = 'Outbound',
  NIGHT_FAC = 'NightFAC',
  DAY_RESHIP = 'DayReship'
}

export const ShiftCodeDisplayMap = new Map<ShiftCode, string>([
  [ShiftCode.OUT_BOUND, 'Outbound'],
  [ShiftCode.IN_BOUND, 'Inbound'],
  [ShiftCode.NIGHT_FAC, 'Night FAC'],
  [ShiftCode.DAY_RESHIP, 'Day Reship']
]);

export function toShiftCode(shiftCodeValue: string): ShiftCode {
  if (shiftCodeValue) {
    for (const key in ShiftCode) {
      if (ShiftCode[key] === shiftCodeValue) {
        return ShiftCode[key.toString()];
      }
    }
  }
  return null;
}

export function toShiftCd(shiftCode: ShiftCode): ShiftCd {
  return ShiftCodeDisplayMap.get(shiftCode) as ShiftCd;
}
