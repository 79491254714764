<mat-dialog-actions>
  <div class="content-left">
    <h2 mat-dialog-title>{{ title }}</h2>
  </div>
  <div class="content-right">
    <button mat-button (click)="onClearFormClicked()" *ngIf="isManagerActionsAvailable$ | async">Clear Form</button>
    <button mat-button (click)="close(false)">Close</button>
  </div>
</mat-dialog-actions>
<br />
<mat-divider></mat-divider>
<mat-dialog-content>
  <div *ngIf="isGuidelineFormDisplayed">
    <app-customer-guidelines-form></app-customer-guidelines-form>
  </div>

  <div *ngIf="(isManagerActionsAvailable$ | async) && isWarningMessageDisplayed">
    <br />
    <br />
    <div class="warning">
      <p>{{ getWarningFromAction() }}</p>
    </div>
  </div>
  <div class="warning" *ngIf="errorMessage?.length > 0">
    <p>{{ errorMessage }}</p>
  </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="isManagerActionsAvailable$ | async">
  <div class="content-left">
    <button mat-flat-button color="warn" (click)="onDeleteClicked()" *ngIf="isDeleteButtonDisplayed">Delete</button>
  </div>
  <div class="content-right">
    <button mat-button (click)="close(false)" *ngIf="isGuidelineFormDisplayed">Cancel</button>
    <button
      mat-button
      color="primary"
      (click)="onSaveClicked()"
      [disabled]="!isFormValid"
      *ngIf="isGuidelineFormDisplayed"
    >
      Save
    </button>
    <!--    yes/no buttons are displayed when save clicked as intermediate screen-->
    <button mat-button (click)="setDialogToFormDisplayed()" *ngIf="isWarningMessageDisplayed">No</button>
    <button mat-button color="primary" (click)="onYesButtonClicked()" *ngIf="isWarningMessageDisplayed">Yes</button>
  </div>
</mat-dialog-actions>
