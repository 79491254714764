<div class="errorMessage">
  <div class="close-button">
    <button (click)="close()" class="close" matTooltip="Click to close or Escape or Click outside the dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="errorMessage-container" *ngIf="invalidPros$ | async as invalidPros">
    <div class="errorMessage-title" *ngIf="invalidPros.size > 0">
      {{ invalidProsMessage }}
      <div class="errorMessage-data">
        <p *ngFor="let invalidPro of invalidPros">
          {{ invalidPro.fieldValue }}
        </p>
      </div>
    </div>
  </div>
  <div class="errorMessage-container" *ngIf="mapErrorPros$ | async as mapErrorPros">
    <div class="errorMessage-title" *ngIf="mapErrorPros.size > 0">
      {{ mapErrorProsMessage }}
      <div class="errorMessage-data">
        <p *ngFor="let mapErrorPro of mapErrorPros">
          {{ mapErrorPro.fieldValue }}
        </p>
      </div>
    </div>
  </div>
</div>
