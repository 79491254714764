import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ProNumber } from '../classes/pronumber';
import { ListName } from '../enums/list-name.enum';
import { ListType } from '../enums/list-type.enum';
import { getRouterUriComponentsFromListType, RouterUriComponents } from '../enums/router-uri-components.enum';
import { AppRouteHistoryService } from './app-route-history.service';

@Injectable()
export class AppNavigationService {
  // This needs to be an observable as the ListPage updates the Tab Accordingly
  private listShipmentsTabSubject = new BehaviorSubject(ListName.RECOMMENDED);
  listShipmentsTab$ = this.listShipmentsTabSubject.asObservable();

  private listType = ListType.SHIPMENT;
  private lookupValue: string;

  setListShipmentsTab(listShipmentsTab: ListName) {
    if (listShipmentsTab) {
      this.listShipmentsTabSubject.next(listShipmentsTab);
      this.router.navigate([RouterUriComponents.LIST_SHIPMENTS, listShipmentsTab]);
    }
  }

  constructor(
    private router: Router,
    private routeHistoryService: AppRouteHistoryService,
    private snackBar: MatSnackBar
  ) {}

  clearLookupValue() {
    this.lookupValue = null;
  }

  /**
   * MSH 2/7/2019
   * If a list type is passed in, then just go to that list
   * If a list type is a lookup and the lookupInput is passed in, then save it
   * if it's not passed in, then use what's saved
   * if Lookup is requested, but no new lookup value, nor is any saved, then
   * go to the Shipment List
   * Any other Lists, make sure to clear the Lookup Value
   *
   * @param listType
   * @param lookupInput
   * @returns
   */
  navigateToList(
    listType: ListType = null,
    lookupInput: string = null,
    message?: string,
    isBackToPrevPageEvent?: boolean,
    duration?: number
  ) {
    if (listType) {
      this.listType = listType;
    }

    if (this.listType === ListType.LOOKUP) {
      if (lookupInput) {
        this.lookupValue = lookupInput;
      }
      if (!this.lookupValue) {
        this.listType = ListType.SHIPMENT;
      }
    } else {
      this.lookupValue = null;
    }

    if (isBackToPrevPageEvent) {
      const previousUrl = this.routeHistoryService.popPrevUrl();
      if (previousUrl) {
        this.router.navigate([previousUrl]);
        return;
      } else {
        // do nothing, move on to the following logic
      }
    }

    const routerUriComponents: string = getRouterUriComponentsFromListType(this.listType);
    if (this.listType === ListType.LOOKUP) {
      this.router.navigate([routerUriComponents, this.lookupValue]);
    } else if (this.listType === ListType.SHIPMENT || this.listType === null) {
      this.router
        .navigate([routerUriComponents, this.listShipmentsTabSubject.getValue()])
        .then((isNavigated: boolean) => {
          if (isNavigated && message?.trim().length > 0) {
            this.displayMessageOnSnackBar(message, duration);
          }
        });
    } else {
      this.router.navigate([routerUriComponents]).then((isNavigated: boolean) => {
        if (isNavigated && message?.trim().length > 0) {
          this.displayMessageOnSnackBar(message, duration);
        }
      });
    }
  }

  navigateToShipmentDetails(proNumber: ProNumber): void {
    const pro: string = proNumber.formatProNumber();
    this.router.navigate([RouterUriComponents.SHIPMENT_DETAILS_PAGE, pro]);
  }

  navigateToInspection(proNumber: ProNumber): void {
    const pro: string = proNumber.formatProNumber();
    this.router.navigate([RouterUriComponents.INSPECT_SHIPMENT_PAGE, pro]);
  }

  navigateToInspectionCorrection(proNumber: string): void {
    this.router.navigate([RouterUriComponents.INSPECTION_CORRECTIONS, proNumber]);
  }

  setListType(newListType: ListType) {
    this.listType = newListType;
  }

  setLookupValue(newLookupValue: string) {
    this.lookupValue = newLookupValue;
  }

  private displayMessageOnSnackBar(message: string, duration?: number): void {
    this.snackBar.open(message, 'OK', { duration: duration });
  }
}
