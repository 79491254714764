import { InspectionCorrectionTypeCd } from '@xpo-ltl/sdk-common';

export enum CorrectionType {
  ELS = 'ELS',
  ELS_NON_INSP = 'ELS Non Inspection',
  DENSITY = 'Density',
  ITEM_15 = 'Item 15',
  LINEAL_FOOT = 'Lineal Foot',
  CUBIC_CAPACITY = 'Cubic Capacity',
  DENSITY_CUST = 'Density Customer',
  DENSITY_BILL = 'Density Business',
  NMFC_CUST = 'NMFC Customer',
  NMFC_BILL = 'NMFC Business'
}

export function toCorrectionReasonCd(correctionTypeCd: InspectionCorrectionTypeCd | string): string {
  switch (correctionTypeCd) {
    case InspectionCorrectionTypeCd.ELS:
      return '95';
    case InspectionCorrectionTypeCd.ELS_NON_INSP:
      return '98';
    case InspectionCorrectionTypeCd.CUBIC_CAPACITY:
      return '3005';
    case InspectionCorrectionTypeCd.LINEAL_FOOT:
      return '96';
    case InspectionCorrectionTypeCd.ITEM_15:
      return '94';
    case InspectionCorrectionTypeCd.DENSITY_CUST:
      return '91';
    case InspectionCorrectionTypeCd.DENSITY_BILL:
      return '3003';
    case InspectionCorrectionTypeCd.NMFC_CUST:
      return '97';
    case InspectionCorrectionTypeCd.NMFC_BILL:
      return '120';
    default:
      return '';
  }
}

export function toCorrectionType(reasonCd: string): string {
  switch (reasonCd) {
    case '95':
      return InspectionCorrectionTypeCd.ELS;
    case '98':
      return InspectionCorrectionTypeCd.ELS_NON_INSP;
    case '3005':
      return InspectionCorrectionTypeCd.CUBIC_CAPACITY;
    case '96':
      return InspectionCorrectionTypeCd.LINEAL_FOOT;
    case '94':
      return InspectionCorrectionTypeCd.ITEM_15;
    case '91':
      return InspectionCorrectionTypeCd.DENSITY_CUST;
    case '3003':
      return InspectionCorrectionTypeCd.DENSITY_BILL;
    case '97':
      return InspectionCorrectionTypeCd.NMFC_CUST;
    case '120':
      return InspectionCorrectionTypeCd.NMFC_BILL;
    default:
      return '';
  }
}
