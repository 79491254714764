import { ZoomLevel, ZoomLevelValues } from '../enums/zoom-level.enum';

export class GeneralPreferences {
  private getacZoomlevel: ZoomLevel;

  public static getZoomLevelValue(zoomLevel: ZoomLevel): number {
    return ZoomLevelValues.get(zoomLevel);
  }

  public getGetacZoomLevel(): ZoomLevel {
    // If getacZoomlevel is not set, we set it to small
    if (!this.getacZoomlevel) {
      this.getacZoomlevel = ZoomLevel.SMALL;
    }

    return this.getacZoomlevel;
  }

  public setGetacZoomLevel(zoomLevel: ZoomLevel) {
    this.getacZoomlevel = zoomLevel;
  }

  public setPreferencesFromJson(jsonProperties: string): void {
    const properties = JSON.parse(jsonProperties);

    for (const property in properties) {
      if (properties.hasOwnProperty(property)) {
        this[property] = properties[property];
      }
    }
  }

  public toJson(): string {
    return JSON.stringify(this);
  }
}
