import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoLtlAuthFirestoreGuard } from '@xpo-ltl/ngx-ltl-auth/firestore';

import { RouterUriComponents } from '../../enums/router-uri-components.enum';
import { RoleCheckGuard } from '../../guards/role-check-guard';
import { SequentialGuard } from '../../guards/sequential.guard';
import { UserProfileGuard } from '../../guards/user-profile-guard';
import { ShipmentDetailsComponent } from './shipment-details.component';

const routes: Routes = [
  {
    path: `${RouterUriComponents.SHIPMENT_DETAILS_PAGE}/:pro`,
    component: ShipmentDetailsComponent,
    canActivate: [SequentialGuard],
    data: {
      sequentialGuards: [XpoLtlAuthFirestoreGuard, RoleCheckGuard, UserProfileGuard]
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShipmentDetailsRoutingModule {}
