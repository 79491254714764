import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { GetInspectionShipmentDetailsResp, InspectionShipment } from '@xpo-ltl/sdk-inspections';
import { ProNumber } from '../../../../classes/pronumber';
import { InspectionState } from '../../../../enums/inspection-state.enum';
import { AppConstantsService } from '../../../../services/app-constants.service';
import { DialogWrapperService } from '../../../../services/dialog-wrapper.service';
import { ExternalAppAccessService } from '../../../../services/externalAppAccess.service';
import { RequestValidator } from '../../../../validators/request.validator';
import { EligibilityAndCorrectionState } from '../../../inspect-shipment/inspect-shipment.component';
import { LocationService } from '../../../../services/location/location.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: { touchendHideDelay: EligibilityAndCorrectionState.ELS_MESSAGE_DURATION }
    }
  ]
})
export class OverviewComponent implements OnChanges {
  @Input()
  inspectionShipmentDetails: GetInspectionShipmentDetailsResp;

  constructor(
    public constants: AppConstantsService,
    private xpoDialog: DialogWrapperService,
    private externalAppAccessService: ExternalAppAccessService,
    public locationService: LocationService
  ) {}

  isExpanded = true;

  ngOnChanges() {
    this.isExpanded = true;
  }

  openNmftaSite(nmfc: string): void {
    const nmftaSearchUrl = this.constants.getNmftaSearchUrl(nmfc);
    this.xpoDialog.openExternalWindow(nmftaSearchUrl, 'NMFTA Search');
  }

  openShipmentDetailsManagementApp(mouseEvent: MouseEvent): void {
    mouseEvent.stopPropagation();
    const shipmentId: string = this.inspectionShipmentDetails?.shipmentDetails?.shipmentId?.shipmentInstId;
    RequestValidator.validateStringNotNullOrEmpty(shipmentId, 'Shipment ID');

    const shipmentManagementAppBaseUrl = this.externalAppAccessService.buildShipmentManagementAppUrl(shipmentId);
    window.open(shipmentManagementAppBaseUrl);
  }

  openBillDetailsOnCorrectionsApp(mouseEvent: MouseEvent): void {
    mouseEvent.stopPropagation();
    const proNbrString: string = this.inspectionShipmentDetails?.shipmentDetails?.shipmentId?.proNumber;
    RequestValidator.validateStringNotNullOrEmpty(proNbrString, 'PRO number');

    const shipmentManagementAppBaseUrl: string = this.externalAppAccessService.buildCorrectionsAppUrl(proNbrString);
    window.open(shipmentManagementAppBaseUrl);
  }
}
