import { BehaviorSubject, Observable } from 'rxjs';
import { InspectionsDateUtils } from './utils/inspections-date-utils';

export class TimestampBehaviorSubject {
  protected behaviorSubject: BehaviorSubject<string> = new BehaviorSubject('None');
  protected currentDate: Date = undefined;

  public stringValue$: Observable<string> = this.behaviorSubject.asObservable();

  protected setNextFromDate(aBehaviorSubject: BehaviorSubject<string>, aDate: Date) {
    this.currentDate = aDate;
    if (!aDate) {
      // console.debug('setNextFromDate none!!', this.currentDate);
      aBehaviorSubject.next('None');
    } else {
      const nextVal: string = InspectionsDateUtils.getPassedTimeStringSince(this.currentDate);
      // console.debug('setNextFromDate:', nextVal);
      aBehaviorSubject.next(nextVal);
    }
  }

  next(aDate: Date) {
    const lDate: Date = InspectionsDateUtils.toDateIfNumberOrDate(aDate);
    // console.debug('TimestampBehaviorSubject, next:', lDate);
    this.setNextFromDate(this.behaviorSubject, lDate);
  }

  refreshStringValue() {
    this.setNextFromDate(this.behaviorSubject, this.currentDate);
  }
}
