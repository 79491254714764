import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toFormattedDensity'
})
export class ToFormattedDensityPipe implements PipeTransform {
  transform(beforeFormattedNumber: number | string): string {
    let formattedDensityString: number | string = '0.000';

    if (!!beforeFormattedNumber && !isNaN(+beforeFormattedNumber)) {
      formattedDensityString = beforeFormattedNumber?.toLocaleString(undefined, {
        maximumFractionDigits: 3,
        minimumFractionDigits: 3
      });
    } else {
      formattedDensityString = beforeFormattedNumber;
    }

    return `${formattedDensityString}`;
  }
}
