import { AcctId } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';

export class ResponseValidation {
  private isValid: boolean;
  private message: string;
  private response: Observable<AcctId>;
  private errorMessage: string;

  constructor(isValid: boolean, message: string, response: any = null) {
    this.isValid = isValid;
    this.message = message;
    this.response = response;
  }

  /**
   * Getter response
   * @return {any}
   */
  public getResponse(): Observable<AcctId> {
    return this.response;
  }

  /**
   * Setter response
   * @param {any} value
   */
  public setResponse(value: Observable<AcctId>) {
    this.response = value;
  }

  /**
   * Getter isValid
   * @return {boolean}
   */
  public getIsValid(): boolean {
    return this.isValid;
  }

  /**
   * Getter message
   * @return {string}
   */
  public getMessage(): string {
    return this.message;
  }

  /**
   * Setter isValid
   * @param {boolean} value
   */
  public setIsValid(value: boolean) {
    this.isValid = value;
  }

  /**
   * Setter message
   * @param {string} value
   */
  public setMessage(value: string) {
    this.message = value;
  }

  /**
   * sets isValid to false
   * @param msg
   */
  public setErrorMessage(msg: string) {
    this.isValid = false;
    this.errorMessage = msg;
  }
}
