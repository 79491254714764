import { Pipe, PipeTransform } from '@angular/core';
import { CommodityClassCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'commodityClass'
})
export class CommodityClassPipe implements PipeTransform {
  transform(value: string): string {
    if (value === CommodityClassCd.CLSS_775) {
      return '77.5';
    } else if (value === CommodityClassCd.CLSS_925) {
      return '92.5';
    } else {
      return value.replace('Clss', '');
    }
  }
}

export function toClassCd(classStringNbr: string): string {
  if (classStringNbr) {
    return `Clss${classStringNbr.replace('.', '')}`;
  } else {
    return '';
  }
}
