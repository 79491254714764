import { BehaviorSubject, Observable, PartialObserver, Subscription } from 'rxjs';

export class InspectionsBehaviorSubjectObservable<T> {
  protected behaviorSubject: BehaviorSubject<T>;

  public observable$: Observable<T>;

  constructor(defaultValue: T) {
    this.behaviorSubject = new BehaviorSubject(defaultValue);
    this.observable$ = this.behaviorSubject.asObservable();
  }

  next(val: T) {
    this.behaviorSubject.next(val);
  }

  subscribe(observer: PartialObserver<T>): Subscription {
    return this.behaviorSubject.subscribe(observer);
  }

  getValue(): T {
    return this.behaviorSubject.getValue();
  }
}
