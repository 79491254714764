import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { isEmpty as _isEmpty } from 'lodash';
import { ValidationError } from '../classes/error/validation-error';
import { ProNumber } from '../classes/pronumber';

@Injectable()
export class RequestValidator {
  static readonly ERROR_CANNOT_BE_EMPTY = 'cannot be empty.';
  static readonly ERROR_SHOULD_BE_POSITIVE_INT = 'should be a positive integer';
  static readonly PRO_NBR = 'PRO Number';
  static readonly INVALID = 'Invalid';
  static readonly CONTACT_SUPPORT = 'Use the Feedback button to contact support.';
  static readonly END_START_DATE_ERROR_MESSAGE = 'The end date must be equal to or after the start date.';

  constructor() {}

  public static validateStringNotNullOrEmpty(requestString: string, fieldName: string) {
    if (!requestString || requestString.trim().length === 0) {
      RequestValidator.throwError(`${RequestValidator.ERROR_CANNOT_BE_EMPTY}`, fieldName);
    }
  }

  public static validateObjectNotUndefinedOrEmpty(requestObject: Object, fieldName: string) {
    if (
      !requestObject ||
      Object.keys(requestObject).length === 0 ||
      Object.values(requestObject).every((value) => value === undefined)
    ) {
      RequestValidator.throwError(`${RequestValidator.ERROR_CANNOT_BE_EMPTY}`, fieldName);
    }
  }

  public static validateProNumber(requestProNumber: ProNumber) {
    this.validateObjectNotUndefinedOrEmpty(requestProNumber, `${RequestValidator.PRO_NBR}`);
    if (!requestProNumber?.isValid()) {
      RequestValidator.throwError(`${RequestValidator.INVALID} ${RequestValidator.PRO_NBR}.`);
    }
  }

  public static validateArrayOrSingleObjectNotEmpty(requestObject: Object | Array<Object>, fieldName: string) {
    if (_isEmpty(requestObject)) {
      RequestValidator.throwError(RequestValidator.ERROR_CANNOT_BE_EMPTY, fieldName);
    }
  }

  public static validateListNotUndefinedOrEmpty(requestObject: List<Object>, fieldName: string) {
    if (!requestObject || requestObject.isEmpty()) {
      RequestValidator.throwError(RequestValidator.ERROR_CANNOT_BE_EMPTY, fieldName);
    }
  }

  public static validateNumberIsPositiveOrZeroInteger(num: number, fieldName: string) {
    if (!Number.isInteger(num) || num < 0) {
      RequestValidator.throwError(RequestValidator.ERROR_SHOULD_BE_POSITIVE_INT + ' value:' + num + '.', fieldName);
    }
  }

  public static validateNumberIsPositiveInteger(num: number, fieldName: string) {
    if (!Number.isInteger(num) || num <= 0) {
      RequestValidator.throwError(RequestValidator.ERROR_SHOULD_BE_POSITIVE_INT + ' value:' + num + '.', fieldName);
    }
  }

  public static validateProNumberString(proNbrString: string) {
    this.validateStringNotNullOrEmpty(proNbrString, `${RequestValidator.PRO_NBR}`);

    if (!ProNumber.isValid(proNbrString)) {
      RequestValidator.throwError(`${RequestValidator.INVALID} ${RequestValidator.PRO_NBR}.`);
    }
  }

  static throwError(errorReason: string, fieldName?: string) {
    const errorMessage: string = RequestValidator.buildErrorMessage(errorReason, fieldName);
    throw new ValidationError(errorMessage);
  }

  static buildErrorMessage(errorReason: string, fieldName?: string): string {
    if (fieldName) {
      return `${fieldName} ${errorReason} ${RequestValidator.CONTACT_SUPPORT}`;
    } else {
      return `${errorReason} ${RequestValidator.CONTACT_SUPPORT}`;
    }
  }
}
