import { PhotoEvent } from '../photo-event';
import { Photo } from '../../photo';
import { ErrorPhotoEvent } from './error-photo-event';

export class FailedUploadedToDmsPhotoEvent extends ErrorPhotoEvent {
  constructor(msg: String, photo: Photo, createdDate: Date, error: Error) {
    super(msg, photo, createdDate, error);
  }

  getEventName(): string {
    return 'Upload Failed to DMS';
  }
}
