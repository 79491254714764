export enum SourceOfRecommendation {
  MANUAL_ADDITION = 'MAN',
  ALWAYS_INSPECT = 'REC',
  DENSITY = 'DEN',
  PROCESS_AS_DENSITY = 'DEN',
  ZIP_TO_ZIP = 'ZIP',
  ELS = 'ELS'
}

export function toDisplayModelFromSourceOfRecommendation(source: string) {
  source = SourceOfRecommendation[source];
  const rankNumber: string = getRankFromSourceOfRecommendation(source);
  if (rankNumber.trim().length > 0) {
    return `${rankNumber} - ${source}`;
  } else {
    return `${source}`;
  }
}

export function getRankFromSourceOfRecommendation(source: string): string {
  switch (source) {
    case SourceOfRecommendation.MANUAL_ADDITION:
    case SourceOfRecommendation.ALWAYS_INSPECT:
      return '1';
    case SourceOfRecommendation.DENSITY:
    case SourceOfRecommendation.PROCESS_AS_DENSITY:
      return '2';
    case SourceOfRecommendation.ZIP_TO_ZIP:
      return '3';
    case SourceOfRecommendation.ELS:
      return '4';
    default:
      return '';
  }
}
