import { ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarRef as MatSnackBarRef,
  LegacySimpleSnackBar as SimpleSnackBar
} from '@angular/material/legacy-snack-bar';
import { NotificationMessage, NotificationService, NotificationTypeEnum } from '@xpo-ltl/data-api';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataValidationError } from '@xpo-ltl/sdk-common';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements OnInit, OnDestroy {
  message: string | DataValidationError[];
  show$ = new BehaviorSubject<boolean>(false);

  @Input() showProgressSpinner = true;

  private unsubscriber$: Subject<void> = new Subject();
  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  constructor(private notificationService: NotificationService, private zone: NgZone, private snackBar: MatSnackBar, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.notificationService
      .getSubscriber()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((message: NotificationMessage) => {
        try {
          this.zone.run(() => {
            if (message.type !== NotificationTypeEnum.ShowSnackbar) {
              this.show$.next(message.type === NotificationTypeEnum.ShowOverlay);
              this.cd.detectChanges();
            } else {
              this.snackBarRef = this.snackBar.open(
                message.text as string,
                message.actionHandler?.actionLabel() ? message.actionHandler.actionLabel() : null,
                {
                  duration: message.durationInMillis
                }
              );
              if (message.actionHandler) {
                if (message.actionHandler.onAction() != null) {
                  this.snackBarRef.onAction().subscribe(message.actionHandler.onAction());
                }
                if (message.actionHandler.afterOpened() != null) {
                  this.snackBarRef.afterOpened().subscribe(message.actionHandler.afterOpened());
                }
                if (message.actionHandler.afterDismissed() != null) {
                  this.snackBarRef.afterDismissed().subscribe(message.actionHandler.afterDismissed());
                }
              }
            }
          });
        } catch (error) {
          // todo pg: do something with this error, we might not want to display that again not to run in an infinite loop
          console.error(error);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
