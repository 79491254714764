import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spacedStringFromTitleCase'
})
export class SpacedStringFromTitleCasePipe implements PipeTransform {
  transform(stringWithoutSpace: string): string {
    return stringWithoutSpace?.replace(/([a-z])([A-Z])/g, '$1 $2').trim();
  }
}
