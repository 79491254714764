export enum ListName {
  FLAGGED = 'Flagged',
  RECOMMENDED = 'Recommended',
  INSPECTED = 'Inspected',
  COMPLETED = 'Completed',
  DISMISSED = 'Dismissed',
  LOOKUP = 'Lookup',
  ADD_PROS = 'AddPros',
  RECOMMENDATION_RULES = 'Recommendation Rules',
  CUSTOMER_GUIDELINES = 'Customer Guidelines',
  BROADCAST_MESSAGES = 'Broadcast Messages',
  PICKUP_REQUESTS = 'Pickup Requests',
  AUTO_CORRECTION_APPROVAL = 'Auto Correction Approval',
  INSPECTION_CORRECTIONS = 'Inspection Corrections'
}

export enum AddFeatureName {
  RECOMMENDATION_RULE = 'Recommendation Rule',
  CUSTOMER_GUIDELINE = 'Customer Guideline',
  BROADCAST_MESSAGE = 'Broadcast Message'
}
