<div (window:resize)="onResize($event)">
  <ag-grid-angular
    #agGrid
    [style.width.px]="width"
    [style.height.px]="height"
    id="myGrid"
    class="ag-theme-material"
    [headerHeight]="56"
    (gridReady)="onGridReady($event)"
    [rowHeight]="48"
    [rowMultiSelectWithClick]="true"
    [domLayout]="'normal'"
    [gridOptions]="gridOptions"
    [rowData]="rowData"
  ></ag-grid-angular>
</div>
