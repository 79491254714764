<div class="inspect-shipment toolbar-inspect-fix">
  <app-toolbar
    [showBackButton]="true"
    (onBackButtonClicked)="onBackButtonClicked()"
    [title]="proNumber?.formatProNumber() + ' - ' + (status$ | async)"
    [actionButton1Label]="'SUBMIT'"
    (actionButton1Clicked)="onSubmitInspectionClicked()"
    [actionButton1IsMain]="true"
    [actionButton2Label]="'INSPECTED NOT CORRECTED'"
    (actionButton2Clicked)="onInspectedNotCorrectedClicked()"
    [actionButton2IsMain]="true"
  ></app-toolbar>
  <app-dimensions [inspectionDetails]="inspectionDetails$ | async" [dimensionErrors]="dimensionErrors"></app-dimensions>
  <app-indicators
    [inspectionDetails]="inspectionDetails$ | async"
    (selectedCorrectionIndChanged)="selectedCorrectionIndChanged($event)"
  ></app-indicators>
  <app-notes [inspectionDetails]="inspectionDetails$ | async"></app-notes>
  <app-document-list
    [shipmentDetails]="shipmentDetails"
    [title]="DocumentListTitles.PHOTOS"
    [docTypes]="photoDocTypes"
    [photos]="inspectionPhotos$ | async"
  ></app-document-list>
  <ig-image-gallery
    [inspectionDetails]="inspectionDetails$ | async"
    (numberOfPhotosChange)="setNumberOfPhotos($event)"
  ></ig-image-gallery>
</div>
