import { PhotoEvent } from './photo-event';
import { Photo } from '../photo';

export class LocallyStoredPhotoEvent extends PhotoEvent {
  constructor(msg: String, photo: Photo, createdDate: Date) {
    super(msg, photo, createdDate);
  }

  getEventName(): string {
    return 'Stored locally';
  }

  protected isProDisplayedInComponentText(): boolean {
    return false;
  }
}
