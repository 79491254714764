import { DataValidationError } from '@xpo-ltl/sdk-common';
import { InspectionShipment } from '@xpo-ltl/sdk-inspections';
import { List } from 'immutable';
import { PlanningListItem } from '../../../classes/planning-list-item';
import { InspectionState, toInspectionStateDisplayName } from '../../../enums/inspection-state.enum';

export class AddProOverview {
  addPros: List<AddPro> = List();
  private _dataValidationErrors: List<DataValidationError>;

  get dataValidationErrors() {
    return this._dataValidationErrors;
  }

  set dataValidationErrors(errors: List<DataValidationError>) {
    this._dataValidationErrors = errors;
  }
}

export class AddPro extends PlanningListItem {
  inspectionStatus: string;

  constructor(inspectionShipment: InspectionShipment) {
    super(inspectionShipment);
    this.inspectionStatus = toInspectionStateDisplayName(<InspectionState>inspectionShipment.inspectionStatusCd);
  }

  static mapInspectionShipments(inspectionShipments: InspectionShipment[]): AddPro[] {
    if (inspectionShipments) {
      return inspectionShipments.map((shipment) => new AddPro(shipment));
    }
    return [];
  }
}
