<xpo-shell class="app-container" [drawerClosedByDefault]="true">
  <!-- <xpo-app-switcher-popover *ngIf="(apps$ | async)?.length" [applications]="apps$ | async"></xpo-app-switcher-popover> -->
  <xpo-header-app-name> Edge</xpo-header-app-name>
  <xpo-header-sub-app-name>Inspections</xpo-header-sub-app-name>
  <xpo-header-navigation *ngIf="userService.isCurrentUserAuthorizedForInspectionsApp()">
    <ng-container *ngFor="let route of routes$ | async">
      <button
        [routerLink]="route.path"
        [queryParamsHandling]="route.queryParamsHandling"
        routerLinkActive="xpo-selected"
      >
        {{ route.label }}
      </button>
    </ng-container>
  </xpo-header-navigation>
  <xpo-header-utilities-actions>
    <!-- <xpo-account-popover *ngIf="accountPopoverConfig" [config]="accountPopoverConfig"> </xpo-account-popover> -->
    <xpo-auth-user-profile class="account" *ngIf="accountPopoverConfig" [config]="accountPopoverConfig">
      <xpo-auth-user-profile-links>
        <a [href]="AccountUrls.MY_ACCOUNT" target="_blank">My Account</a>
      </xpo-auth-user-profile-links>
    </xpo-auth-user-profile>
  </xpo-header-utilities-actions>

  <app-ins-context-header *ngIf="isAuthorizedUser"></app-ins-context-header>

  <div fxLayout="column" fxLayoutAlign="space-between center" fxFlex="" class="app-container-content">
    <div class="router-container">
      <app-loading-overlay></app-loading-overlay>
      <router-outlet></router-outlet>
    </div>
  </div>

  <xpo-footer [version]="build">
    <a xpo-regular-link color="accent" (click)="onFeedbackClick()">Feedback</a>
    <a [href]="releaseNotesLink" target="_blank"> Release Notes </a>
  </xpo-footer>
</xpo-shell>
