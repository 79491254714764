import { NgModule } from '@angular/core';
import { MaterialModule } from '../../material.module';
import { ListHeaderComponent } from './list-header.component';

@NgModule({
  imports: [MaterialModule],
  declarations: [ListHeaderComponent],
  exports: [ListHeaderComponent]
})
export class ListHeaderModule {}
