import { Pipe, PipeTransform } from '@angular/core';
import { getGeneralCorrectionStatusFromCorrectionStatusCd } from '../enums/general-correction-submission-message.enum';

@Pipe({
  name: 'toMessageFromCorrectionStatus'
})
export class ToMessageFromCorrectionStatus implements PipeTransform {
  transform(statusCd: string): string {
    return getGeneralCorrectionStatusFromCorrectionStatusCd(statusCd);
  }
}
