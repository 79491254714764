import { Injectable } from '@angular/core';
import { DataOptions } from '@xpo-ltl/data-api';
import {
  GetUserPreferencePath,
  GetUserPreferenceResp,
  InspectionsApiService,
  UpdateUserPreferenceRqst,
  UserPreference
} from '@xpo-ltl/sdk-inspections';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { GeneralPreferences } from '../classes/general-preferences';
import { SicShiftPreference } from '../classes/sic-shift-preference';
import { ErrorMessageActions } from '../enums/error-message-actions.enum';
import { ListName } from '../enums/list-name.enum';
import { UserPreferenceAppKeys } from '../enums/userPreference-appKeys.enum';
import { RetryStrategyService } from '../operators/retry-strategy.service';
import { RequestValidator } from '../validators/request.validator';
import { SnackBarHandlingService } from './snack-bar-handling.service';
import { UserService } from './user.service';
import { ApiUtil } from '../classes/utils/api-util';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {
  private static readonly DEFAULT_UPDATE_TIMEOUT = 60000;
  private static readonly DEFAULT_RETRY_DELAY = 1000;
  private static readonly DEFAULT_UPDATE_RETRY_COUNT = 0;
  private static readonly DEFAULT_GET_TIMEOUT = 15000;
  private static readonly DEFAULT_RETRY_COUNT = 4;
  private static readonly DEFAULT_LOADING_MESSAGE = `Loading. Please Wait...`;
  public static readonly SOURCE_NAME: string = `User Preferences`;

  // This will store the preferences loaded from the backend
  private generalPreferences: GeneralPreferences;
  private sicShiftPreference: SicShiftPreference = new SicShiftPreference();

  constructor(
    private inspectionsApiService: InspectionsApiService,
    private errorHandling: SnackBarHandlingService,
    private retryStrategy: RetryStrategyService,
    private userService: UserService
  ) {}

  public getGeneralPreferences(): GeneralPreferences {
    return this.generalPreferences;
  }

  protected setGeneralPreferences(preferences: GeneralPreferences): void {
    this.generalPreferences = preferences;
  }

  public saveGeneralUserPreferences(preferences: GeneralPreferences): void {
    // We update the field in the service
    this.setGeneralPreferences(preferences);

    // Then we save the user preferences in database
    const jsonString = preferences.toJson();
    this.updateUserPreference(UserPreferenceAppKeys.GENERAL_SETTINGS, jsonString);
  }

  public loadGeneralUserPreferences(): Observable<string> {
    return this.loadUserPreference(UserPreferenceAppKeys.GENERAL_SETTINGS).pipe(
      tap((preference: string) => {
        // Store the preferences in the service, for them to be available in other parts of the app
        if (preference) {
          // We parse the value of general user preferences
          const preferences = new GeneralPreferences();
          preferences.setPreferencesFromJson(preference);

          // And we set the generalPreferences
          this.generalPreferences = preferences;
        }
      })
    );
  }

  public saveListUserPreferences(listName: ListName, propertiesJsonString: string): void {
    const listNameKeyValue: string = Object.keys(ListName)[Object.values(ListName).indexOf(listName)];
    this.updateUserPreference(UserPreferenceAppKeys[listNameKeyValue], propertiesJsonString);
  }

  public loadListUserPreferences(listName: ListName): Observable<string> {
    const listNameKeyValue: string = Object.keys(ListName)[Object.values(ListName).indexOf(listName)];
    return this.loadUserPreference(UserPreferenceAppKeys[listNameKeyValue]);
  }

  public getSicShiftUserPreference(): SicShiftPreference {
    return this.sicShiftPreference;
  }

  public updateSicShiftUserPreference(aSicShiftPreference: SicShiftPreference): void {
    this.sicShiftPreference = aSicShiftPreference;
    return this.updateUserPreference(UserPreferenceAppKeys.SIC_SHIFT, this.sicShiftPreference.convertToJson());
  }

  public loadSicShiftUserPreference(): Observable<SicShiftPreference> {
    return this.loadUserPreference(UserPreferenceAppKeys.SIC_SHIFT).pipe(
      map((preference: string) => {
        if (preference) {
          this.sicShiftPreference.setPreferencesFromJson(preference);
        }
        return this.sicShiftPreference;
      })
    );
  }

  private updateUserPreference(key: string, preference: string): void {
    RequestValidator.validateStringNotNullOrEmpty(key, 'App Key');
    RequestValidator.validateStringNotNullOrEmpty(this.userService.currentUserEmployeeId, 'Employee ID');

    const request = new UpdateUserPreferenceRqst();
    request.appKey = key;
    request.employeeId = this.userService.currentUserEmployeeId;
    request.userPreference = new UserPreference();
    request.userPreference.appName = 'INSPECTION';
    request.userPreference.preference = preference;

    this.inspectionsApiService
      .updateUserPreference(request, ApiUtil.DEFAULT_DATA_OPTIONS)
      .pipe(
        this.retryStrategy.retryStrategy(
          UserPreferencesService.DEFAULT_UPDATE_TIMEOUT,
          UserPreferencesService.DEFAULT_RETRY_DELAY,
          UserPreferencesService.DEFAULT_UPDATE_RETRY_COUNT,
          null,
          'UPDATE-USER-PREFERENCE'
        ),
        take(1),
        catchError((error) => {
          this.errorHandling.handleResponseError(
            error,
            ErrorMessageActions.UPDATING,
            UserPreferencesService.SOURCE_NAME
          );

          return EMPTY;
        })
      )
      .subscribe(() => {
        // ONLY FOR TEST ENVIRONMENT: Refresh the page after deleting Sic and Shift User Preference
        if (request.appKey === UserPreferenceAppKeys.SIC_SHIFT && request.userPreference.preference === '{}') {
          location.reload();
        }
      });
  }

  private loadUserPreference(key: string): Observable<string> {
    RequestValidator.validateStringNotNullOrEmpty(key, 'App Key');

    return this.userService.isUserLoggedIn$.pipe(
      switchMap(() => {
        RequestValidator.validateStringNotNullOrEmpty(this.userService.currentUserEmployeeId, 'Employee ID');

        const path = new GetUserPreferencePath();
        path.appKey = key;
        path.employeeId = this.userService.currentUserEmployeeId;

        return this.inspectionsApiService.getUserPreference(path, ApiUtil.DEFAULT_DATA_OPTIONS).pipe(
          this.retryStrategy.retryStrategy(
            UserPreferencesService.DEFAULT_GET_TIMEOUT,
            UserPreferencesService.DEFAULT_RETRY_DELAY,
            UserPreferencesService.DEFAULT_RETRY_COUNT,
            UserPreferencesService.DEFAULT_LOADING_MESSAGE,
            'GET-USER-PREFERENCE'
          ),
          take(1),
          map((response: GetUserPreferenceResp) => {
            if (response?.userPreference?.preference?.trim().length > 0) {
              return response.userPreference.preference.trim();
            } else {
              return null;
            }
          })
        );
      })
    );
  }
}
