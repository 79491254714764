import {
  AcctId,
  ActionCd,
  Address,
  AuditInfo,
  Comment,
  CorrectionRequestResetReasonCd,
  InspectionCorrectionTypeCd,
  ShipmentId
} from '@xpo-ltl/sdk-common';
import {
  Correction,
  CorrectionRequest,
  CorrectionRequestsForShipment,
  InspectionCommodityLine,
  InspectionContext,
  InspectionCustGuidelines,
  InspectionHandlingUnit,
  InspectionShipment,
  ModelScore,
  PieceDimensions
} from '@xpo-ltl/sdk-inspections';
import { List } from 'immutable';

export class CorrectionDetails {
  correctionId: number;
  shipmentInstId: number;
  correctionTypeCd: string;
  inspectedInd: boolean;
  requestorEmployeeId: string;
  statusCd: string;
  corCorrectionRequestId: number;
  dimensionSourceCd: string;
  certificateId: string;
  correlationId: string;
  insEventId: number;
  originalChargeAmount: number;
  correctedChargeAmount: number;
  revenueImpactAmount: number;
  sicCd: string;
  piecesCount: number;
  lengthNbr: number;
  widthNbr: number;
  heightNbr: number;
  proNbr: string;
  errorDetail: string;
  auditInfo: AuditInfo;
  parentCorrectionRequestId: number;
  requestorFullName: string;
  autoCorrectionInd: string; // Display Yes if its true and don’t display anything if its false/undefined/null.
  shortDescription: string;
}

export class CorrectionRequestDetails {
  correctionRequestId: number;
  inspectionId: number;
  correctionId: number;
  proNbr: string;
  statusCd: string;
  correctionTypeCd: string;
  dimensionSourceCd: string;
  palletCount: number;
  requestorEmplid: string;
  requestorComment: string;
  website: string;
  autoInd: boolean;
  autoIndYesNoString: string;
  forceAuditInd: boolean;
  previewStatusCd: string;
  originalBolInformation: string;
  resetReasonCd: CorrectionRequestResetReasonCd;
  listActionCd: ActionCd;
  auditInfo: AuditInfo;
  parentShipmentInstId: number;
  parentInspectionSicCd: string;
  requestorFullName: string;
  description: string;
  shortDescription: string;
}

export class InspectionShipmentDetails {
  sourceOfRecommendation: string;
  rankOfRecommendation: number;
  shipmentId: ShipmentId;
  chargeTypeCd: string;
  billStatusCd: string;
  originSic: string;
  destSic: string;
  totPieceCnt: number;
  totGrossWeight: number;
  volumeProfileCalcMethodCd: string;
  volumeProfileTypeCd: string;
  volumeProfileCalcDateTime: Date;
  inspectorPieceDimensions: List<PieceDimensions>;
  totGrossVolume: number;
  totDensity: number;
  inspectionStatusCd: string;
  inspectionDateTime: Date;
  shipmentLocation: string;
  inspectionContext: InspectionContext;
  inspectionNote: Comment;
  shipperAcctId: AcctId;
  shipperAddr: Address;
  shipperIsDebtorInd: boolean;
  consigneeAcctId: AcctId;
  consigneeAddr: Address;
  consigneeIsDebtorInd: boolean;
  thirdPartyAcctId: AcctId;
  thirdPartyAddr: Address;
  thirdPartyIsDebtorInd: boolean;
  bil2AcctId: AcctId;
  bil2Addr: Address;
  bil2IsDebtorInd: boolean;
  commodity: List<InspectionCommodityLine>;
  appliedAgreementId: string;
  appliedRulesetNbr: number;
  agreementFakText: List<string>;
  item15ExemptInd: boolean;
  elsExemptInd: string;
  linealFootEligibilityInd: boolean;
  linealFootRanges: List<string>;
  specialCapacityRuleInd: boolean;
  loadDestSic: string;
  dockLocation: string;
  loadDoor: string;
  breakDoor: string;
  etaTmst: Date;
  trailerNbr: string;
  billClassCd: string;
  accessorialChargeCodes: List<string>;
  totChargeAmt: number;
  loosePiecesCnt: number;
  motorizedPiecesCnt: number;
  adminStatusCd: string;
  deliveryStatusCd: string;
  cshInd: boolean;
  remarks: List<Comment>;
  custSpecificInspGuidelines: List<InspectionCustGuidelines>;
  removedSinceRefresh: boolean;
  addedSinceRefresh: boolean;
  lastUpdateDateTime: Date;
  spotQuoteId: number;
  totPalletCnt: number;
  ratedPalletPricingInd: boolean;
  ratingTariffId: string;
  inspectionHandlingUnits: List<InspectionHandlingUnit>;
  inspectionHandlingUnitExemptionInd: boolean;
  currentLocation: string;
  hazmatInd: boolean;
  guaranteedInd: boolean;
  freezableInd: boolean;
  headloadInd: boolean;
  foodInd: boolean;
  pupVolumePercentage: number;
  corrections: List<CorrectionDetails>;
  modelScores: List<ModelScore>;
  trailerStatusCd: string;
  scheduleStatusCd: string;
  inspectionCorrectionTypeCd: InspectionCorrectionTypeCd;
  trailerLoadSequenceNbr: number;
  inspectionId: number;
  correctionRequestsForShipment: CorrectionRequestsForShipment;
  isRated: boolean;
  correctionRequests: List<CorrectionRequestDetails>;

  constructor(inspectionShipment: InspectionShipment) {
    if (!inspectionShipment) {
      return;
    } else {
      this.sourceOfRecommendation = inspectionShipment.sourceOfRecommendation;
      this.rankOfRecommendation = inspectionShipment.rankOfRecommendation;
      this.shipmentId = inspectionShipment.shipmentId;
      this.chargeTypeCd = inspectionShipment.chargeTypeCd;
      this.billStatusCd = inspectionShipment.billStatusCd;
      this.originSic = inspectionShipment.originSic;
      this.destSic = inspectionShipment.destSic;
      this.totPieceCnt = inspectionShipment.totPieceCnt;
      this.totGrossWeight = inspectionShipment.totGrossWeight;
      this.volumeProfileCalcMethodCd = inspectionShipment.volumeProfileCalcMethodCd;
      this.volumeProfileTypeCd = inspectionShipment.volumeProfileTypeCd;
      this.volumeProfileCalcDateTime = inspectionShipment.volumeProfileCalcDateTime;
      this.inspectorPieceDimensions = List(inspectionShipment.inspectorPieceDimensions);
      this.totGrossVolume = inspectionShipment.totGrossVolume;
      this.totDensity = inspectionShipment.totDensity;
      this.inspectionStatusCd = inspectionShipment.inspectionStatusCd;
      this.inspectionDateTime = inspectionShipment.inspectionDateTime;
      this.shipmentLocation = inspectionShipment.shipmentLocation;
      this.inspectionContext = inspectionShipment.inspectionContext;
      this.inspectionNote = inspectionShipment.inspectionNote;
      this.shipperAcctId = inspectionShipment.shipperAcctId;
      this.shipperAddr = inspectionShipment.shipperAddr;
      this.shipperIsDebtorInd = inspectionShipment.shipperIsDebtorInd;
      this.consigneeAcctId = inspectionShipment.consigneeAcctId;
      this.consigneeAddr = inspectionShipment.consigneeAddr;
      this.consigneeIsDebtorInd = inspectionShipment.consigneeIsDebtorInd;
      this.thirdPartyAcctId = inspectionShipment.thirdPartyAcctId;
      this.thirdPartyAddr = inspectionShipment.thirdPartyAddr;
      this.thirdPartyIsDebtorInd = inspectionShipment.thirdPartyIsDebtorInd;
      this.bil2AcctId = inspectionShipment.bil2AcctId;
      this.bil2Addr = inspectionShipment.bil2Addr;
      this.bil2IsDebtorInd = inspectionShipment.bil2IsDebtorInd;
      this.commodity = List(inspectionShipment.commodity);
      this.appliedAgreementId = inspectionShipment.appliedAgreementId;
      this.appliedRulesetNbr = inspectionShipment.appliedRulesetNbr;
      this.agreementFakText = List(inspectionShipment.agreementFakText);
      this.item15ExemptInd = inspectionShipment.item15ExemptInd;
      this.elsExemptInd = inspectionShipment.elsExemptInd;
      this.linealFootEligibilityInd = inspectionShipment.linealFootEligibilityInd;
      this.linealFootRanges = List(inspectionShipment.linealFootRanges);
      this.specialCapacityRuleInd = inspectionShipment.specialCapacityRuleInd;
      this.loadDestSic = inspectionShipment.loadDestSic;
      this.dockLocation = inspectionShipment.dockLocation;
      this.loadDoor = inspectionShipment.loadDoor;
      this.breakDoor = inspectionShipment.breakDoor;
      this.etaTmst = inspectionShipment.etaTmst;
      this.trailerNbr = inspectionShipment.trailerNbr;
      this.billClassCd = inspectionShipment.billClassCd;
      this.accessorialChargeCodes = List(inspectionShipment.accessorialChargeCodes);
      this.totChargeAmt = inspectionShipment.totChargeAmt;
      this.loosePiecesCnt = inspectionShipment.loosePiecesCnt;
      this.motorizedPiecesCnt = inspectionShipment.motorizedPiecesCnt;
      this.adminStatusCd = inspectionShipment.adminStatusCd;
      this.deliveryStatusCd = inspectionShipment.deliveryStatusCd;
      this.cshInd = inspectionShipment.cshInd;
      this.remarks = List(inspectionShipment.remarks);
      this.custSpecificInspGuidelines = List(inspectionShipment.custSpecificInspGuidelines);
      this.removedSinceRefresh = inspectionShipment.removedSinceRefresh;
      this.addedSinceRefresh = inspectionShipment.addedSinceRefresh;
      this.lastUpdateDateTime = inspectionShipment.lastUpdateDateTime;
      this.spotQuoteId = inspectionShipment.spotQuoteId;
      this.totPalletCnt = inspectionShipment.totPalletCnt;
      this.ratedPalletPricingInd = inspectionShipment.ratedPalletPricingInd;
      this.ratingTariffId = inspectionShipment.ratingTariffId;
      this.inspectionHandlingUnits = List(inspectionShipment.inspectionHandlingUnits);
      this.inspectionHandlingUnitExemptionInd = inspectionShipment.inspectionHandlingUnitExemptionInd;
      this.currentLocation = inspectionShipment.currentLocation;
      this.hazmatInd = inspectionShipment.hazmatInd;
      this.guaranteedInd = inspectionShipment.guaranteedInd;
      this.freezableInd = inspectionShipment.freezableInd;
      this.headloadInd = inspectionShipment.headloadInd;
      this.foodInd = inspectionShipment.foodInd;
      this.pupVolumePercentage = inspectionShipment.pupVolumePercentage;
      this.modelScores = List(inspectionShipment.modelScores);
      this.trailerStatusCd = inspectionShipment.trailerStatusCd;
      this.scheduleStatusCd = inspectionShipment.scheduleStatusCd;
      this.inspectionCorrectionTypeCd = inspectionShipment.inspectionCorrectionTypeCd;
      this.trailerLoadSequenceNbr = inspectionShipment.trailerLoadSequenceNbr;
      this.inspectionId = inspectionShipment.inspectionId;
      this.correctionRequestsForShipment = inspectionShipment.correctionRequestsForShipment;
      this.isRated = this.billStatusCd === 'Rated';

      this.corrections = List();
      inspectionShipment.corrections?.forEach((correction: Correction) => {
        this.corrections = this.corrections.push(this.buildCorrectionDetails(correction));
      });

      this.correctionRequests = List();
      inspectionShipment.correctionRequests?.forEach((correctionRequest: CorrectionRequest) => {
        this.correctionRequests = this.correctionRequests.push(this.buildCorrectionRequestDetails(correctionRequest));
      });
    }
  }

  public static fixClass(value: string): string {
    if (!value) {
      return '';
    } else if (value === '925') {
      return '92.5';
    } else if (value === '775') {
      return '77.5';
    } else {
      return value;
    }
  }

  private buildCorrectionDetails(originalCorrection: Correction): CorrectionDetails {
    const result: CorrectionDetails = new CorrectionDetails();

    result.correctionId = originalCorrection.correctionId;
    result.shipmentInstId = originalCorrection.shipmentInstId;
    result.correctionTypeCd = originalCorrection.correctionTypeCd;
    result.inspectedInd = originalCorrection.inspectedInd;
    result.requestorEmployeeId = originalCorrection.requestorEmployeeId;
    result.requestorFullName = originalCorrection.requestorFullName;
    result.statusCd = originalCorrection.statusCd;
    result.corCorrectionRequestId = originalCorrection.corCorrectionRequestId;
    result.dimensionSourceCd = originalCorrection.dimensionSourceCd;
    result.certificateId = originalCorrection.certificateId;
    result.correlationId = originalCorrection.correlationId;
    result.insEventId = originalCorrection.insEventId;
    result.originalChargeAmount = originalCorrection.originalChargeAmount;
    result.correctedChargeAmount = originalCorrection.correctedChargeAmount;
    result.revenueImpactAmount = result.correctedChargeAmount - result.originalChargeAmount;
    result.sicCd = originalCorrection.sicCd;
    result.piecesCount = originalCorrection.piecesCount;
    result.lengthNbr = originalCorrection.lengthNbr;
    result.widthNbr = originalCorrection.widthNbr;
    result.heightNbr = originalCorrection.heightNbr;
    result.proNbr = originalCorrection.proNbr;
    result.errorDetail = originalCorrection.errorDetail;
    result.autoCorrectionInd = originalCorrection.autoCorrectionInd ? 'Yes' : '';
    result.auditInfo = originalCorrection.auditInfo;
    result.parentCorrectionRequestId = originalCorrection.parentCorrectionRequestId;
    result.shortDescription = originalCorrection.shortDescription;

    return result;
  }

  private buildCorrectionRequestDetails(correctionRequests: CorrectionRequest): CorrectionRequestDetails {
    const result: CorrectionRequestDetails = new CorrectionRequestDetails();

    result.auditInfo = correctionRequests.auditInfo;
    result.autoInd = correctionRequests.autoInd;
    result.autoIndYesNoString = correctionRequests.autoInd ? 'Yes' : '';
    result.correctionId = correctionRequests.correctionId;
    result.correctionRequestId = correctionRequests.correctionRequestId;
    result.correctionTypeCd = correctionRequests.correctionTypeCd;
    result.description = correctionRequests.description;
    result.dimensionSourceCd = correctionRequests.dimensionSourceCd;
    result.forceAuditInd = correctionRequests.forceAuditInd;
    result.inspectionId = correctionRequests.inspectionId;
    result.listActionCd = correctionRequests.listActionCd;
    result.originalBolInformation = correctionRequests.originalBolInformation;
    result.palletCount = correctionRequests.palletCount;
    result.parentInspectionSicCd = correctionRequests.parentInspectionSicCd;
    result.parentShipmentInstId = correctionRequests.parentShipmentInstId;
    result.previewStatusCd = correctionRequests.previewStatusCd;
    result.proNbr = correctionRequests.proNbr;
    result.requestorComment = correctionRequests.requestorComment;
    result.requestorEmplid = correctionRequests.requestorEmplid;
    result.requestorFullName = correctionRequests.requestorFullName;
    result.resetReasonCd = correctionRequests.resetReasonCd;
    result.shortDescription = correctionRequests.shortDescription;
    result.statusCd = correctionRequests.statusCd;
    result.website = correctionRequests.website;

    return result;
  }
}
