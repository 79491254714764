<mat-card>
  <div>
    <p>Fill out all the information bellow. All fields required unless noted optional.</p>
  </div>
  <br />
  <div class="form-rule">
    <div>
      <div class="label">
        <label>MAD Code </label>
      </div>
      <mat-form-field floatLabel="never" class="mad-code-input">
        <input
          oninput="this.value = this.value.toUpperCase()"
          matInput
          (focusout)="validateInputMadCode()"
          [(ngModel)]="madCode"
          (ngModelChange)="searchMadCode()"
          placeholder="Enter MAD Code"
          maxlength="11"
          [disabled]="!isNewRule"
        />
        <mat-hint>{{ getAccountName() }}</mat-hint>
      </mat-form-field>

      <div *ngIf="!this.accountValidation.getIsValid()" class="mat-error">
        {{ this.accountValidation.getMessage() }}
      </div>
    </div>

    <div>
      <div class="label">
        <label>Recommendation </label>
      </div>
      <mat-button-toggle-group
        name="recommendation"
        aria-label="Font Style"
        [ngModel]="getRecommendation()"
        (ngModelChange)="setRecommendation($event)"
      >
        <mat-button-toggle value="Always" data-testid="type-code-always"> Always </mat-button-toggle>
        <br />
        <mat-button-toggle value="Never" data-testid="type-code-never"> Never </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div>
      <div class="label">
        <label>Party </label>
      </div>

      <mat-button-toggle-group
        name="addressSection"
        aria-label="Font Style"
        [ngModel]="getPartyRole()"
        (ngModelChange)="validatePartyRole($event)"
      >
        <mat-button-toggle value="{{ partyRole.Shipper }}" data-testid="shipper"> Shipper </mat-button-toggle>
        <br />
        <mat-button-toggle value="{{ partyRole.Consignee }}" data-testid="consignee"> Consignee </mat-button-toggle>
        <br />
        <mat-button-toggle value="{{ partyRole.BillTo }}" data-testid="bill-to"> Bill To </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
</mat-card>
