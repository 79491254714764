import { ColDef } from 'ag-grid-community';
import { ColumnType } from '../../enums/column-type.enum';

export class GridUtils {
  static readonly TIMESTAMP_COLUMN_WIDTH = 250;
  static readonly PRONUMBER_COLUMN_WIDTH = 200;
  static readonly STATUS_COLUMN_WIDTH = 250;
  static readonly YES_COLUMN_WIDTH = 105;
  static readonly SIC_COLUMN_WIDTH = 120;
  static readonly AUTO_MIN_WIDTH = 200;

  static buildTimestampColumn(params: {headerName:string, field: string}): ColDef {
    return {
      field: params.field,
      headerName: params.headerName,
      width: this.TIMESTAMP_COLUMN_WIDTH,
      refData: { columnType: ColumnType.TIMESTAMP }
    };
  }
  
  static buildStatusColumn(params: {headerName:string, field: string}): ColDef {
    return {
      field: params.field,
      headerName: params.headerName,
      width: this.STATUS_COLUMN_WIDTH,
      refData: { columnType: ColumnType.AUTO_CORRECTION_STATUS },
    };
  }

  static buildYesBooleanColumn(params: {headerName:string, field: string}): ColDef {
    return {
      field: params.field,
      headerName: params.headerName,
      width: this.YES_COLUMN_WIDTH,
      refData: { columnType: ColumnType.YES_BOOLEAN },
    };
  }
  
  static buildProNumberColumn(params: {headerName?:string, field: string}): ColDef {
    return {
      field: params.field,
      headerName: params.headerName ?? 'PRO',
      width: this.PRONUMBER_COLUMN_WIDTH
    };
  }
  
  static buildSicColumn(params: {headerName?:string, field: string}): ColDef {
    return {
      field: params.field,
      headerName: params.headerName ?? 'SIC',
      width: this.SIC_COLUMN_WIDTH
    };
  }
  
  static buildAutoWidthColumn(params: {headerName:string, field: string}): ColDef {
    return {
      field: params.field,
      headerName: params.headerName,
      minWidth: this.AUTO_MIN_WIDTH,
      flex: 1, 
    };
  }
}
