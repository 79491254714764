<xpo-card type="data" color="accent">
  <xpo-card-header>
    <xpo-card-title>General Information </xpo-card-title>
  </xpo-card-header>

  <xpo-card-content>
    <div class="normal_height_row">
      <div class="labels">Corrections History</div>
      <div class="values">
        <a class="normal_height_row" xpo-regular-link color="accent" (click)="onOpenCorrectionsApp($event)"
          >Open Edge Corrections</a
        >
      </div>
    </div>

    <div *ngIf="hasCustomerGuidelines" class="normal_height_row">
      <div class="labels">Customer Guidelines</div>
      <div class="values">
        <a
          *ngIf="hasCustomerGuidelines"
          class="normal_height_row"
          xpo-regular-link
          color="accent"
          (click)="onOpenCustomerGuidelines($event)"
          >Open Customer Guidelines</a
        >
      </div>
    </div>

    <div *ngIf="!isReadOnlyView" class="normal_height_row">
      <div class="labels">Inspection Details</div>
      <div class="values">
        <a class="normal_height_row" xpo-regular-link color="accent" (click)="onOpenInspectionDetails($event)"
          >Open Inspection Details</a
        >
      </div>
    </div>

    <div class="normal_height_row">
      <div class="labels">Bill Status</div>
      <div class="values">
        <div [ngClass]="isBillStatusRated ? 'readonly_value' : 'normal_height_row__bold_red'">
          {{ shipmentDetailsResponse?.shipmentDetails?.billStatusCd }}
        </div>
      </div>
    </div>

    <div *ngIf="!isBillStatusRated" class="normal_height_row__bold_red_message">
      <div class="labels"></div>
      <div class="values">
        <div class="normal_height_row__bold_red_message">
          {{ BILL_STATUS_NOT_RATED }}
        </div>
      </div>
    </div>

    <div class="normal_height_row">
      <div class="labels">FAK</div>
      <div class="readonly_value">
        <div *ngIf="shipmentDetailsResponse?.shipmentDetails?.agreementFakText">
          <div *ngFor="let fakText of shipmentDetailsResponse?.shipmentDetails?.agreementFakText" class="normal_height_row">
            {{ fakText }}
          </div>
        </div>
      </div>
    </div>
  </xpo-card-content>
</xpo-card>
