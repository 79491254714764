import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { List } from 'immutable';
import { ErrorPhotoEvent } from '../../classes/photos/photoevent/error/error-photo-event';
import { FailedUploadedToDmsPhotoEvent } from '../../classes/photos/photoevent/error/failed-uploaded-to-dms-photo-event';
import { Photo } from '../../classes/photos/photo';
import { UploadedToDmsPhotoEvent } from '../../classes/photos/photoevent/uploaded-to-dms-photo-event';
import { FailedGetNextPhotoEvent } from '../../classes/photos/photoevent/error/failed-get-next-photo-event';
import { ProNumber } from '../../classes/pronumber';
import { PhotoId } from '../../classes/photos/photo-id';
import { DeletedFromLocalStoragePhotoEvent } from '../../classes/photos/photoevent/deleted-from-local-storage-photo-event';
import { LocallyStoredPhotoEvent } from '../../classes/photos/photoevent/locally-stored-photo-event';
import { InspectionsBehaviorSubjectObservable } from '../../classes/inspections-behavior-subject-observable';
import { FailedStoreLocalStorageEvent } from '../../classes/photos/photoevent/error/failed-store-local-storage-event';
import { FailedLoadLocalStorage } from '../../classes/photos/photoevent/error/failed-load-local-storage';
import { IDBSpace } from '../app-storage.service';
import { InspectionError } from '../../classes/error/inspection-error';
import { FailedDeleteLocalStorageEvent } from '../../classes/photos/photoevent/error/failed-delete-local-storage-event';
import { InspectionNotificationService } from '../inspection-notification.service';
import { LocalStoragePhotoDeletedTooOld } from '../../classes/inspectionnotification/local-storage-photo-deleted-too-old';

/**
 * Contains all desired events regarding photos
 */
@Injectable({
  providedIn: 'root'
})
export class PhotoEventService {
  //error events
  protected photoErrorsEventsBs: InspectionsBehaviorSubjectObservable<List<ErrorPhotoEvent>> =
    new InspectionsBehaviorSubjectObservable<List<ErrorPhotoEvent>>(List());
  photoErrorEvents$: Observable<List<ErrorPhotoEvent>> = this.photoErrorsEventsBs.observable$;
  //Upload to dms
  protected photoUploadedToDmsEventsSubject: InspectionsBehaviorSubjectObservable<List<UploadedToDmsPhotoEvent>> =
    new InspectionsBehaviorSubjectObservable<List<UploadedToDmsPhotoEvent>>(List());
  photoUploadedToDmsEvents$: Observable<List<UploadedToDmsPhotoEvent>> =
    this.photoUploadedToDmsEventsSubject.observable$;
  //Stored to Local storage
  protected photosStoredLsEventsBs: InspectionsBehaviorSubjectObservable<List<LocallyStoredPhotoEvent>> =
    new InspectionsBehaviorSubjectObservable<List<LocallyStoredPhotoEvent>>(List());
  photosStoredLsEvents$: Observable<List<LocallyStoredPhotoEvent>> = this.photosStoredLsEventsBs.observable$;

  //Deleted from Local storage
  protected photoDeletedEventsBs: InspectionsBehaviorSubjectObservable<List<DeletedFromLocalStoragePhotoEvent>> =
    new InspectionsBehaviorSubjectObservable<List<DeletedFromLocalStoragePhotoEvent>>(List());
  photoDeletedFromLsEvents$: Observable<List<DeletedFromLocalStoragePhotoEvent>> =
    this.photoDeletedEventsBs.observable$;

  //Space from Local storage
  protected photoStorageSpaceBs: InspectionsBehaviorSubjectObservable<IDBSpace> =
    new InspectionsBehaviorSubjectObservable<IDBSpace>(undefined);
  photoStorageSpace$: Observable<IDBSpace> = this.photoStorageSpaceBs.observable$;

  //counts
  totalLocalStoragePhotosCountBs: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  totalLocalStoragePhotosCount$: Observable<number> = this.totalLocalStoragePhotosCountBs.asObservable();

  constructor() {}

  addFailedToUploadToDms(msg: String, photo: Photo, createdDate: Date, error: Error) {
    const aPhotoUploadError: ErrorPhotoEvent = new FailedUploadedToDmsPhotoEvent(msg, photo, createdDate, error);
    this.addPhotoError(aPhotoUploadError);
  }

  addUploadedSuccess(msg: string, photo: Photo, createdDate: Date) {
    const aPhotoUploadError: UploadedToDmsPhotoEvent = new UploadedToDmsPhotoEvent(msg, photo, createdDate);
    let lNext: List<UploadedToDmsPhotoEvent>;
    const lCurrentVal = this.photoUploadedToDmsEventsSubject.getValue();
    if (!lCurrentVal) {
      lNext = List([aPhotoUploadError]);
    } else {
      lNext = lCurrentVal.push(aPhotoUploadError);
    }
    this.photoUploadedToDmsEventsSubject.next(lNext);
  }

  addFailedToGetNextPhoto(msg: string, photo: Photo, createdDate: Date, error: Error) {
    const aPhotoUploadError: FailedGetNextPhotoEvent = new FailedGetNextPhotoEvent(msg, photo, createdDate, error);
    this.addPhotoError(aPhotoUploadError);
  }

  updateTotalPhotosCountInLocalStorage(photosCount: number) {
    this.totalLocalStoragePhotosCountBs.next(photosCount);
  }

  addUpdatedPhoto(proNumber: ProNumber) {}

  addDeletedFromLocalStoragePhoto(aPhotoId: PhotoId | undefined, createdDate: Date) {
    const aDeletedPhotoEvnt: DeletedFromLocalStoragePhotoEvent = new DeletedFromLocalStoragePhotoEvent(
      aPhotoId,
      createdDate
    );
    let lNext: List<DeletedFromLocalStoragePhotoEvent>;
    const lCurrentVal = this.photoDeletedEventsBs.getValue();
    if (!lCurrentVal) {
      lNext = List([aDeletedPhotoEvnt]);
    } else {
      lNext = lCurrentVal.push(aDeletedPhotoEvnt);
    }
    this.photoDeletedEventsBs.next(lNext);

    if (aPhotoId && aPhotoId?.id) {
      //delete from photoStoredLsBs
      const lLocallyStoredPhotosEvts: List<LocallyStoredPhotoEvent> = this.photosStoredLsEventsBs.getValue();
      const lToRemoveIndex: number = lLocallyStoredPhotosEvts.findIndex((lValue: LocallyStoredPhotoEvent) => {
        const lCurrentPhotoIdFromEvt: string = lValue.photo?.id?.id;
        const lArgPhotoId: string = aPhotoId.id;
        return lCurrentPhotoIdFromEvt === lArgPhotoId;
      });
      if (lToRemoveIndex == -1) {
        // do nothing, we might have multiple delete events comming, if we dont find
        // the corresponding storedEvent it might have been deleted already
      } else {
        const newLocallyStoredPhotoEvent: List<LocallyStoredPhotoEvent> = lLocallyStoredPhotosEvts.filter(
          (_, i) => i !== lToRemoveIndex
        );
        this.photosStoredLsEventsBs.next(newLocallyStoredPhotoEvent);
      }
    }
  }

  addFailedLoadLocalStorage(error: Error, date: Date, proNumber: ProNumber) {
    const aPhotoUploadError: FailedLoadLocalStorage = new FailedLoadLocalStorage(undefined, error, date);
    this.addPhotoError(aPhotoUploadError);
  }

  addStoredPhoto(photo: Photo, date: Date) {
    const lEvent: LocallyStoredPhotoEvent = new LocallyStoredPhotoEvent(undefined, photo, date);
    const lCurrentVal = this.photosStoredLsEventsBs.getValue();
    let lNext: List<LocallyStoredPhotoEvent>;
    if (!lCurrentVal) {
      lNext = List([lEvent]);
    } else {
      lNext = lCurrentVal.push(lEvent);
    }
    this.photosStoredLsEventsBs.next(lNext);
  }

  addFailedStoredToLocalStorage(photo: Photo, error: any, date: Date) {
    const aPhotoUploadError: FailedStoreLocalStorageEvent = new FailedStoreLocalStorageEvent(photo, error, date);
    this.addPhotoError(aPhotoUploadError);
  }

  private addPhotoError(aPhotoUploadError: ErrorPhotoEvent) {
    let lNext: List<ErrorPhotoEvent>;
    const lCurrentVal = this.photoErrorsEventsBs.getValue();
    if (!lCurrentVal) {
      lNext = List([aPhotoUploadError]);
    } else {
      lNext = lCurrentVal.push(aPhotoUploadError);
    }
    this.photoErrorsEventsBs.next(lNext);
  }

  updatePhotoDbSpace(idbSpace: IDBSpace) {
    this.photoStorageSpaceBs.next(idbSpace);
  }

  setStoredPhotos(newPhotos: Photo[]) {
    let lList: List<LocallyStoredPhotoEvent> = List();
    const lPhotoEvts: LocallyStoredPhotoEvent[] = [];
    for (let i = 0; i < newPhotos.length; i++) {
      const newPhoto: Photo = newPhotos[i];
      if (newPhoto) {
        lPhotoEvts.push(new LocallyStoredPhotoEvent(undefined, newPhoto, newPhoto.createDate));
      }
    }
    if (lPhotoEvts?.length > 0) {
      lList = List(lPhotoEvts);
    }
    this.photosStoredLsEventsBs.next(lList);
  }

  addFailedToDelete(photo: Photo, date: Date, error: any) {
    const aPhotoUploadError: FailedDeleteLocalStorageEvent = new FailedDeleteLocalStorageEvent(photo, error, date);
    this.addPhotoError(aPhotoUploadError);
  }

  addDeletedFromLocalStorageCauseTooOldPhoto(photo: Photo, evtCreatedDate: Date) {
    this.addDeletedFromLocalStoragePhoto(photo.id, evtCreatedDate);
    const evt: LocalStoragePhotoDeletedTooOld = new LocalStoragePhotoDeletedTooOld(photo, evtCreatedDate, undefined);
    InspectionNotificationService.appInstance?.addEvent(evt);
    // TODO: we should think the ways to notify user before removing locally stored photos
  }
}
