import { Component, OnDestroy, OnInit } from '@angular/core';
import { AcctId } from '@xpo-ltl/sdk-common';
import { GetInspectionShipmentDetailsResp, InspectionCustGuidelines } from '@xpo-ltl/sdk-inspections';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { ResponseValidation } from '../../../classes/ResponseValidation';
import { CustomerGuidelineWithAcct } from '../../../classes/customer-guideline-with-acct';
import { DialogAction } from '../../../enums/dialog-actions.enum';
import { ErrorMessageActions } from '../../../enums/error-message-actions.enum';
import { InspectionShipmentDetailsService } from '../../../services/api/inspection-shipment-details.service';
import { CustomerAccountService } from '../../../services/customer-account.service';
import { SnackBarHandlingService } from '../../../services/snack-bar-handling.service';
import { CustomerGuidelinesService } from '../services/customer-guidelines.service';

@Component({
  selector: 'app-customer-guidelines-form',
  templateUrl: './customer-guidelines-form.component.html',
  styleUrls: ['./customer-guidelines-form.component.scss']
})
export class CustomerGuidelinesFormComponent implements OnInit, OnDestroy {
  private unsubscriber$: Subject<void> = new Subject();

  madCodeResponseValidation: ResponseValidation;
  account: AcctId;

  madCodeTxt: string;
  guideLineTxt: string;
  GUIDELINE_MAX_LENGTH: number = CustomerGuidelineWithAcct.GUIDELINE_TXT_LENGTH_MAX;
  isManagerActionsAvailable$: Observable<boolean>;

  constructor(
    public customerGuidelinesService: CustomerGuidelinesService,
    private customerAccountService: CustomerAccountService,
    private inspectionShipmentDetailsService: InspectionShipmentDetailsService,
    private errorHandling: SnackBarHandlingService
  ) {
    this.updateMadCodeAndGuidelineFromService();
  }

  ngOnInit() {
    this.isManagerActionsAvailable$ = this.customerGuidelinesService.isManagerActionsAvailable$;
    this.madCodeResponseValidation = new ResponseValidation(true, '');
    this.customerGuidelinesService.updatedSubject$?.subscribe(() => {
      this.updateMadCodeAndGuidelineFromService();
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  setAccountName(accountName: string): void {
    this.customerGuidelinesService.setAccountName(accountName);
  }

  getAccountName(): string {
    return this.customerGuidelinesService.getAccountName();
  }

  setMadCode(madCode: string): void {
    this.customerGuidelinesService.setMadCode(madCode);
  }

  getMadCode(): string {
    return this.customerGuidelinesService.getMadCode();
  }

  setGuideline(guideline: string): void {
    this.customerGuidelinesService.setGuidelineTxt(guideline);
  }

  setAccountId(accountId: string): void {
    this.customerGuidelinesService.setAccountId(accountId);
  }

  getAccountId(): string {
    return this.customerGuidelinesService.getAccountId();
  }

  onGuidelineTxtChanged(): void {
    this.setMadCode(CustomerAccountService.formatMadCodeAccount(this.madCodeTxt.trim()));
    this.madCodeResponseValidation = this.customerAccountService.getAccountResponseValidation(this.madCodeTxt.trim());

    this.madCodeResponseValidation
      .getResponse()
      ?.pipe(
        take(1),
        catchError((error) => {
          //set as reused by the HTML
          this.madCodeResponseValidation.setIsValid(false);
          this.madCodeResponseValidation.setMessage('Account not found');
          this.madCodeResponseValidation.setResponse(undefined);
          this.setAccountId(undefined);
          this.setAccountName(undefined);

          const errorMessage: string = this.errorHandling.buildDisplayErrorMessage(
            ErrorMessageActions.GETTING,
            `Customer account`
          );
          console.error(this.errorHandling.buildResponseErrorMessage(errorMessage, error));

          return EMPTY;
        })
      )
      .subscribe((acct) => {
        console.debug('account is valid received:', acct);
        this.account = acct as AcctId;
        this.setAccountName(this.account.acctName);
        this.setAccountId(this.account.acctInstId);
        this.setCustomerGuidelinesServiceFromShipmentDetails();
      });
  }

  setCustomerGuidelinesServiceFromShipmentDetails() {
    this.inspectionShipmentDetailsService.inspectionShipmentDetails$
      .pipe(
        takeUntil(this.unsubscriber$),
        catchError((error) => {
          this.errorHandling.handleResponseError(error, ErrorMessageActions.GETTING, `Customer guidelines`);

          return EMPTY;
        })
      )
      .subscribe((shipmentDetails: GetInspectionShipmentDetailsResp) => {
        if (shipmentDetails?.currentInspectionDetails?.custSpecificInspGuidelines?.length > 0) {
          this.customerGuidelinesService.setCustomerGuideline(
            this.transformToCustomerGuidelineWithAcct(
              shipmentDetails.currentInspectionDetails.custSpecificInspGuidelines[0]
            )
          );
          this.customerGuidelinesService.setAction(DialogAction.EDIT);
        } else {
          this.customerGuidelinesService.setCustomerGuideline(this.buildCustomerGuidelineWithAcct());
          this.customerGuidelinesService.setAction(DialogAction.CREATE);
          this.madCodeResponseValidation.setIsValid(true);
        }
      });
  }

  private buildCustomerGuidelineWithAcct(): CustomerGuidelineWithAcct {
    const customerGuidelineNew = new CustomerGuidelineWithAcct();
    customerGuidelineNew.acctInstId = this.getAccountId();
    customerGuidelineNew.acctName = this.getAccountName();
    customerGuidelineNew.acctMadCd = this.getMadCode();
    customerGuidelineNew.guideline = '';
    return customerGuidelineNew;
  }

  private transformToCustomerGuidelineWithAcct(customerGuideline: InspectionCustGuidelines): CustomerGuidelineWithAcct {
    const customerGuidelineNew = new CustomerGuidelineWithAcct();
    customerGuidelineNew.acctInstId = customerGuideline.customerId.acctInstId;
    customerGuidelineNew.acctName = customerGuideline.customerId.acctName;
    customerGuidelineNew.acctMadCd = customerGuideline.customerId.acctMadCd;
    customerGuidelineNew.guideline = '';
    return customerGuidelineNew;
  }

  validateInputMadCode() {
    if (!this.madCodeTxt) {
      this.madCodeResponseValidation.setMessage('The MAD Code cannot be empty');
    } else if (this.madCodeTxt.length !== 11) {
      this.madCodeResponseValidation.setMessage('MAD Code must be 11 characters');
    }
  }

  private updateMadCodeAndGuidelineFromService() {
    this.madCodeTxt = this.customerGuidelinesService.getMadCode();
    this.guideLineTxt = this.customerGuidelinesService.getGuidelineTxt();
  }
}
