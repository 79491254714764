import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListType } from '../enums/list-type.enum';
import { AppNavigationService } from '../services/app-navigation.service';
import { UserService } from '../services/user.service';
import { DialogWrapperService } from '../services/dialog-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class NotAuthorizedRedirectGuard implements CanActivate {
  constructor(
    private appNavigation: AppNavigationService,
    private userService: UserService,
    private dialogManager: DialogWrapperService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.userService.isUserLoggedIn$.pipe(
      map(() => {
        const isAuthorized: boolean = this.userService.isCurrentUserAuthorizedToAccess();
        if (isAuthorized) {
          this.appNavigation.navigateToList(ListType.SHIPMENT);
        } else {
          this.userService.logNotAuthorizedUser();
          this.dialogManager.showNotAuthorizedDialog();
        }

        return isAuthorized;
      })
    );
  }
}
