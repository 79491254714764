import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import {
  GetInspectionForCorrectionRequestResp,
  GetInspectionShipmentDetailsResp,
  InspectionContext,
  InspectionCustGuidelines
} from '@xpo-ltl/sdk-inspections';
import { List } from 'immutable';
import { BehaviorSubject, Observable, Subject, take, takeUntil } from 'rxjs';
import { MultipleNotificationData } from '../../../classes/multiple-notification-data';
import { NotificationData } from '../../../classes/notification-data';
import { CustomerInstructionsAndPalletPricingRatedDialogComponent } from '../../../dialogs/customer-instructions-dialog/customer-instructions-dialog.component';
import { DialogHeader } from '../../../enums/dialog-header.enum';
import { AppConstantsService } from '../../../services/app-constants.service';
import { ExternalAppAccessService } from '../../../services/externalAppAccess.service';
import { RequestValidator } from '../../../validators/request.validator';
import { CustomerGuidelinesService } from '../../customer-guidelines/services/customer-guidelines.service';
import { PdfViewerDialogComponent } from '../../pdf-viewer-dialog/pdf-viewer-dialog.component';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class GeneralInfoComponent implements OnChanges {
  @HostBinding('class') hostClass = 'ins-correction-general-info';

  @Input()
  formattedProNumberString: string;
  @Input()
  inspectionForCorrectionRequestResp: GetInspectionForCorrectionRequestResp;
  @Input()
  shipmentDetailsResponse: GetInspectionShipmentDetailsResp;
  @Input()
  isReadOnlyView: boolean;

  @Output()
  onUpdateButtonState = new EventEmitter<boolean>();

  hasCustomerGuidelines: boolean;
  customerGuidelineList: List<NotificationData> = List();
  generalInfoFormGroup: FormGroup;
  private documentsDialogRef;
  isBillStatusRated: boolean = true;
  appliedRulesetUrl: string;

  readonly BILL_STATUS_NOT_RATED: string =
    'Bill is not rated. A Correction Request can be Created, Saved or Cancelled but Preview and Submit are not available until the bill is rated.';
  private readonly PRO_NUMBER: string = 'PRO number';

  private isGeneralInfoFormControlInitializedSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    undefined
  );
  isGeneralInfoFormControlInitialized$: Observable<boolean> =
    this.isGeneralInfoFormControlInitializedSubject$.asObservable();

  constructor(
    private appConstantsService: AppConstantsService,
    private externalAppAccessService: ExternalAppAccessService,
    private customerGuidelinesService: CustomerGuidelinesService,
    private matDialog: MatDialog,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.inspectionForCorrectionRequestResp?.currentValue) {
      this.getGeneralInfoData();
    }
  }

  getFormGroup() {
    return this.generalInfoFormGroup;
  }

  onOpenCorrectionsApp(mouseEvent: MouseEvent) {
    mouseEvent.stopPropagation();
    RequestValidator.validateStringNotNullOrEmpty(this.formattedProNumberString, this.PRO_NUMBER);

    const shipmentManagementAppBaseUrl: string = this.externalAppAccessService.buildCorrectionsAppUrl(
      this.formattedProNumberString
    );
    window.open(shipmentManagementAppBaseUrl);
  }

  onOpenCustomerGuidelines(mouseEvent: MouseEvent) {
    mouseEvent.stopPropagation();
    this.openCustomerGuidelines();
  }

  onOpenInspectionDetails(mouseEvent: MouseEvent) {
    mouseEvent.stopPropagation();
    if (this.documentsDialogRef) {
      // there's an existing one open.
      this.documentsDialogRef.close();
    }

    const dialogClosed = new Subject<void>();
    this.documentsDialogRef = this.matDialog.open(PdfViewerDialogComponent, {
      position: {
        top: '100px',
        left: '100px',
      },
      height: '880px',
      maxHeight: '1200px',

      hasBackdrop: false,
      data: { pro: this.formattedProNumberString},
      panelClass: 'pdf-mat-dialog-panel',
    });

    this.documentsDialogRef
      .keydownEvents()
      .pipe(takeUntil(dialogClosed))
      .subscribe((e: KeyboardEvent) => {
        if (e.code === 'Escape') {
          e.preventDefault();
          e.stopPropagation();
          this.documentsDialogRef.close();
        }
      });

    this.documentsDialogRef.afterClosed().subscribe(() => {
      dialogClosed.next();
      dialogClosed.complete();
    });
  }

  openCustomerGuidelines() {
    const multipleNotificationData: MultipleNotificationData = new MultipleNotificationData(this.customerGuidelineList);
    const dialogConfig: MatDialogConfig = <MatDialogConfig>{
      data: multipleNotificationData
    };
    this.matDialog.open(CustomerInstructionsAndPalletPricingRatedDialogComponent, dialogConfig);
  }

  private getGeneralInfoData() {
    this.isBillStatusRated = this.shipmentDetailsResponse?.shipmentDetails?.billStatusCd === 'Rated';
    this.appConstantsService.inspectionContext$.pipe(take(1)).subscribe((context: InspectionContext) => {
      this.hasCustomerGuidelines = this.inspectionForCorrectionRequestResp?.inspectionCustGuidelines?.length > 0;
      if (this.hasCustomerGuidelines) {
        this.setCustomerGuidelineList();
      }
    });
  }

  private setCustomerGuidelineList() {
    const customerGuidelines: InspectionCustGuidelines[] =
      this.inspectionForCorrectionRequestResp?.inspectionCustGuidelines;

    if (customerGuidelines?.length > 0) {
      const guideLineMessageList: List<string> = this.customerGuidelinesService.buildCustomerGuidelineMessagesList(
        this.inspectionForCorrectionRequestResp?.inspectionCustGuidelines
      );
      this.customerGuidelineList = this.customerGuidelineList.push(
        this.customerGuidelinesService.buildNotificationData(
          DialogHeader.CUSTOMER_GUIDELINE_HEADER,
          guideLineMessageList
        )
      );
    }
  }
}
