import { Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { InspectionCorrectionTypeCd } from '@xpo-ltl/sdk-common';
import { InspectionShipment } from '@xpo-ltl/sdk-inspections';
import { CorrectionIndicatorName } from '../../../../enums/correction-indicator.enum';

@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.scss'],
  host: { class: 'add_indicators' },
  encapsulation: ViewEncapsulation.None
})
export class IndicatorsComponent implements OnChanges {
  indicatorLabels: string[] = Object.values(CorrectionIndicatorName);
  inspectionCorrectionTypeCd: InspectionCorrectionTypeCd[];
  indicatorsForm: FormGroup;

  @Input()
  inspectionDetails: InspectionShipment;

  @Output()
  selectedCorrectionIndChanged = new EventEmitter<number>();

  ngOnChanges() {
    this.getInspectionCorrectionTypeCd();
    this.initIndicatorsForm();
  }

  getInspectionCorrectionTypeCd() {
    this.inspectionCorrectionTypeCd = [this.inspectionDetails?.inspectionCorrectionTypeCd];
  }

  onIndicatorChanged(indicator) {
    this.selectedCorrectionIndChanged.emit(indicator.value);
  }

  private getForDefaultCheckboxValue(formControlName: string) {
    return this.inspectionCorrectionTypeCd?.includes(formControlName as InspectionCorrectionTypeCd);
  }

  private initIndicatorsForm() {
    this.indicatorsForm = new FormGroup({
      [this.indicatorLabels?.[0]]: new FormControl(this.getForDefaultCheckboxValue(this.indicatorLabels?.[0]))
    });
  }
}
