import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionCd, CorrectionRequestResetReasonCd, DimensionSourceTypeCd } from '@xpo-ltl/sdk-common';
import { NmfcItemSearchRecord } from '@xpo-ltl/sdk-elasticsearch';
import {
  CorrectionRequest,
  CorrectionRequestCommodity,
  CorrectionRequestDimension,
  CreateInspectionCorrectionResp,
  GetCorrectionRequestRatePreviewResp,
  GetCorrectionRequestResp,
  GetInspectionForCorrectionRequestResp,
  GetInspectionResp,
  GetInspectionShipmentDetailsResp,
  InspectionContext,
  InspectionCorrectionReason,
  InspectionCorrectionRequest,
  InspectionCustGuidelines,
  InspectionDimension,
  UpdateInspectionStatusResp,
  UpsertInspectionCorrectionRequestResp
} from '@xpo-ltl/sdk-inspections';
import { List } from 'immutable';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  forkJoin,
  of,
  switchMap,
  take,
  tap
} from 'rxjs';
import { InspectionDetailService } from '../../services/api/inspection-detail.service';
import { MultipleNotificationData } from '../../classes/multiple-notification-data';
import { NotificationData } from '../../classes/notification-data';
import { PhotoId } from '../../classes/photos/photo-id';
import { ProNumber } from '../../classes/pronumber';
import { CustomerInstructionsAndPalletPricingRatedDialogComponent } from '../../dialogs/customer-instructions-dialog/customer-instructions-dialog.component';
import { CorrectionRequestStatus } from '../../enums/correction-request-status.enum';
import { InsCreateCorrectionStatusCdEnum } from '../../enums/create-correction-status-code.enum';
import { DialogHeader } from '../../enums/dialog-header.enum';
import { ErrorMessageActions } from '../../enums/error-message-actions.enum';
import {
  GeneralCorrectionSubmissionMessageEnum,
  getGeneralCorrectionSubmissionMessageFromCorrectionStatusCd,
  isStatusError
} from '../../enums/general-correction-submission-message.enum';
import { InspectionState } from '../../enums/inspection-state.enum';
import { ListType } from '../../enums/list-type.enum';
import { RouterUriComponents } from '../../enums/router-uri-components.enum';
import { CanDeactivateType } from '../../interfaces/deactivate-guard.interface';
import { toClassCd } from '../../pipes/commodity-class-pipe';
import { InspectionShipmentDetailsService } from '../../services/api/inspection-shipment-details.service';
import { ShipmentDetailsService } from '../../services/api/shipment-details.service';
import { AppConstantsService } from '../../services/app-constants.service';
import { AppNavigationService } from '../../services/app-navigation.service';
import { DialogWrapperService } from '../../services/dialog-wrapper.service';
import { MobileWebBrowserService } from '../../services/hardware/mobile-web-browser-service';
import { InspectionsApiWrapperService } from '../../services/inspections/inspections-api-wrapper.service';
import { PhotoDownloadService } from '../../services/photos/photo-download.service';
import { SnackBarHandlingService } from '../../services/snack-bar-handling.service';
import { RequestValidator } from '../../validators/request.validator';
import { CustomerGuidelinesService } from '../customer-guidelines/services/customer-guidelines.service';
import { LoadingOverlayService } from '../loading-overlay/service/loading-overlay.service';
import { CommoditiesComponent } from './commodities/commodities.component';
import { CorrectionRequestInfoComponent } from './correction-request-info/correction-request-info.component';
import { InspectionInfoComponent } from './inspection-info/inspection-info.component';
import { ElasticSearchApiWrapperService } from './service/elastic-search-api-wrapper.service';
import { InspectionCorrectionService } from './service/inspection-correction.service';
@Component({
  selector: 'app-inspection-corrections',
  templateUrl: './inspection-corrections.component.html',
  styleUrls: ['./inspection-corrections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class InspectionCorrectionsComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClass = 'ins-correction';

  @ViewChild(CorrectionRequestInfoComponent, { static: true })
  public correctionRequestInfoComponent: CorrectionRequestInfoComponent;

  @ViewChild(InspectionInfoComponent, { static: true })
  public inspectionInfoComponent: InspectionInfoComponent;

  @ViewChild(CommoditiesComponent, { static: true })
  public commoditiesComponent: CommoditiesComponent;

  private readonly MARK_AS_NOT_CORRECTED_CONFIRMATION_MESSAGE =
    'Do you want to mark this inspection as <b>Inspected Not Corrected?</b>';
  private readonly REPLACE_STRING: string = '{replaceString}';
  private readonly SUCCEEDED_MESSAGE: string = `The Inspection Correction Request was ${this.REPLACE_STRING} successfully.`;
  private readonly COMMODITY_RESET_MESSAGE: string =
    'Commodity data for this PRO has changed. The dimensions mapped to the commodities have been reset.';
  private readonly DIMENSION_RESET_MESSAGE: string =
    'Dimension data for the Inspection supporting this correction has changed. The dimensions mapped to the commodities have been reset.';
  private readonly COMMODITY_AND_DIMENSION_RESET_MESSAGE: string =
    'Both commodity data for this Pro and dimension data for the Inspection supporting this correction have changed. The dimensions mapped to the commodities have been reset.';

  private readonly RATE_PREVIEW_STATUS_CODE_YES: string = 'Y';
  private readonly RATE_PREVIEW_STATUS_CODE_NO: string = 'N';
  private readonly RATE_PREVIEW_STATUS_CODE_PARTIAL: string = 'P';
  private readonly RATE_PREVIEW_NOT_AVAILABLE_MESSAGE: string = `This shipment cannot be ${this.REPLACE_STRING} auto-rated, Rate Preview is not available.`;
  private readonly FULL: string = 'fully';

  private unsubscriber$: Subject<void> = new Subject();
  private shouldConfirmBeforeLeaveThisPage: boolean = true;
  private isSaveCancelPreviewOrSubmitButtonClicked: boolean = false;
  private isCommodityResetNeeded: boolean;
  private isDimensionResetNeeded: boolean;
  private isDimsConnected: boolean;
  private isBillStatusRated: boolean;

  formattedProNumberString: string;
  inspectionForCorrectionRequestResp: GetInspectionForCorrectionRequestResp;
  shipmentDetails: GetInspectionShipmentDetailsResp;
  isReadOnlyView: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private inspectionsApiWrapperService: InspectionsApiWrapperService,
    private appConstantsService: AppConstantsService,
    private changeDetector: ChangeDetectorRef,
    private dialogWrapperService: DialogWrapperService,
    private inspectionShipmentDetailsService: InspectionShipmentDetailsService,
    private inspectionDetailService: InspectionDetailService,
    private appNavigationService: AppNavigationService,
    private shipmentDetailsService: ShipmentDetailsService,
    private snackBarHandlingService: SnackBarHandlingService,
    public inspectionCorrectionService: InspectionCorrectionService,
    private customerGuidelinesService: CustomerGuidelinesService,
    public loadingOverlayService: LoadingOverlayService,
    private matDialog: MatDialog,
    private router: Router,
    private photoDownloadService: PhotoDownloadService,
    private mobileWebBrowserService: MobileWebBrowserService,
    private elasticSearchApiWrapperService: ElasticSearchApiWrapperService
  ) {}

  ngOnInit(): void {
    this.getCorrectionsData();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  canDeactivate(): CanDeactivateType {
    this.setShouldConfirmBeforeLeaveThisPageFromFromControls();

    if (this.shouldConfirmBeforeLeaveThisPage) {
      const messageBody: string =
        'Inspection Correction request is not saved, do you want to continue exit without saving?';
      return this.openConfirmCancelDialog(messageBody);
    } else {
      return true;
    }
  }

  onBackButtonClicked() {
    this.navigateToPreviousPage();
  }

  private getCorrectionsData() {
    setTimeout(() => {
      this.loadingOverlayService.setIsLoading(true);
    });
    if (this.inspectionCorrectionService.getIsInspectionCorrectionsComponentReadOnly) {
      const correctionRequestId: number = this.inspectionCorrectionService.getCorrectionRequestId;
      this.inspectionsApiWrapperService
        .getCorrectionRequest(correctionRequestId)
        .pipe(
          take(1),
          switchMap((correctionRequestResp: GetCorrectionRequestResp) => {
            this.inspectionForCorrectionRequestResp = {
              ...new GetInspectionForCorrectionRequestResp(),
              inspectionCorrectionRequest: correctionRequestResp.inspectionCorrectionRequest,
            };
            this.inspectionCorrectionService.setGetInspectionForCorrectionRequestResp(
              this.inspectionForCorrectionRequestResp
            );
            // if we have correctionRequestId, its called from inspection correction tab and we only need readonly view
            this.isReadOnlyView = true;
            const notFormattedProNbrString: string =
              this.inspectionForCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequest?.proNbr;
            RequestValidator.validateProNumberString(notFormattedProNbrString);
            const proNbr: ProNumber = new ProNumber(notFormattedProNbrString);
            const inspectionId = correctionRequestResp?.inspectionCorrectionRequest?.correctionRequest?.inspectionId;
            this.formattedProNumberString = proNbr.formatProNumber();
            this.photoDownloadService.loadPhotoIdsFromDms(
              proNbr,
              this.shipmentDetails?.shipmentDetails?.inspectionHandlingUnits
            );

            const sources: any[] = [this.inspectionShipmentDetailsService.getInspectionShipmentDetails(proNbr)];
            if (inspectionId){
              sources.push(this.inspectionDetailService.getInspection(inspectionId))
            } 
            return forkJoin(...sources);
          }),
          catchError((error) => {
            this.loadingOverlayService.setIsLoading(false);
            throw error;
          })  
        )
        .subscribe(([shipmentDetailsResp, inspectionResp]: [GetInspectionShipmentDetailsResp, GetInspectionResp]) => {
          this.onShipmentDetailsComplete(shipmentDetailsResp);
          this.inspectionForCorrectionRequestResp.inspectionNote = inspectionResp?.inspection?.inspectionNote;
      });
    } else {
      this.shipmentDetails = this.inspectionShipmentDetailsService.inspectionShipmentDetails;

      combineLatest([this.appConstantsService.inspectionContext$, this.activatedRoute.params])
        .pipe(
          filter(([inspectionContext, activeRouteParam]) => !!inspectionContext && !!activeRouteParam),
          distinctUntilChanged(),
          take(1),
          switchMap(([inspectionContext, activeRouteParam]) => {
            const notFormattedProNbrString: string = activeRouteParam[RouterUriComponents.INSPECTION_CORRECTIONS];
            RequestValidator.validateProNumberString(notFormattedProNbrString);
            const currentProNbr: ProNumber = new ProNumber(notFormattedProNbrString);
            const savedProNbr: ProNumber = new ProNumber(this.shipmentDetails?.shipmentDetails?.shipmentId?.proNumber);
            this.formattedProNumberString = currentProNbr.formatProNumber();

            this.photoDownloadService.loadPhotoIdsFromDms(
              currentProNbr,
              this.shipmentDetails?.shipmentDetails?.inspectionHandlingUnits
            );

            if (savedProNbr.getRawProNumber() === currentProNbr.getRawProNumber()) {
              return combineLatest([
                this.inspectionsApiWrapperService.getInspectionForCorrectionRequest(
                  this.formattedProNumberString,
                  inspectionContext.inspectionSic
                ),
                of(this.shipmentDetails)
              ]);
            } else {
              return combineLatest([
                this.inspectionsApiWrapperService.getInspectionForCorrectionRequest(
                  this.formattedProNumberString,
                  inspectionContext.inspectionSic
                ),
                this.inspectionShipmentDetailsService.getInspectionShipmentDetails(currentProNbr)
              ]);
            }
          }),
          catchError((error) => {
            this.loadingOverlayService.setIsLoading(false);
            throw error;
          })
        )
        .subscribe(
          ([inspectionForCorrectionReqResp, shipmentDetailsResp]: [
            GetInspectionForCorrectionRequestResp,
            GetInspectionShipmentDetailsResp
          ]) => {
            if (!inspectionForCorrectionReqResp?.inspectionCorrectionRequest) {
              inspectionForCorrectionReqResp.inspectionCorrectionRequest = new InspectionCorrectionRequest();
              inspectionForCorrectionReqResp.inspectionCorrectionRequest.correctionRequest = new CorrectionRequest();
              inspectionForCorrectionReqResp.inspectionCorrectionRequest.correctionRequestCommodities = [];
              inspectionForCorrectionReqResp.inspectionCorrectionRequest.correctionRequestDimensions = [];
            }

            inspectionForCorrectionReqResp?.inspectionCorrectionReasons?.sort(
              (reason1: InspectionCorrectionReason, reason2: InspectionCorrectionReason) => {
                if (reason1?.description > reason2?.description) {
                  return 1;
                }

                if (reason1?.description < reason2?.description) {
                  return -1;
                }

                return 0;
              }
            );

            this.onShipmentDetailsComplete(shipmentDetailsResp);
            this.isBillStatusRated = this.shipmentDetails?.shipmentDetails?.billStatusCd === 'Rated';
            this.inspectionForCorrectionRequestResp = inspectionForCorrectionReqResp;
            this.inspectionCorrectionService.setGetInspectionForCorrectionRequestResp(
              this.inspectionForCorrectionRequestResp
            );
            this.isReadOnlyView = false;
            this.displayDialogIfAny();
          }
        );
    }
  }

  onShipmentDetailsComplete(shipmentDetailsResp) {
    this.shipmentDetails = shipmentDetailsResp;
    this.loadingOverlayService.setIsLoading(false);
    this.changeDetector.markForCheck();
  }

  onSaveButtonClicked(isClicked: boolean) {
    const messageBody: string = this.SUCCEEDED_MESSAGE.replace(this.REPLACE_STRING, 'saved');
    this.setIsSaveCancelPreviewOrSubmitButtonClicked(isClicked);

    if (this.isSaveCancelPreviewOrSubmitButtonClicked) {
      this.loadingOverlayService.setIsLoading(true);
      this.mobileWebBrowserService.submitInspectionPhotos(new ProNumber(this.formattedProNumberString));

      this.buildAndUpsertCorrectionRequest(
        CorrectionRequestStatus.IN_PROGRESS,
        this.inspectionForCorrectionRequestResp.inspectionCorrectionRequest
      ).subscribe((resp: UpsertInspectionCorrectionRequestResp) => {
        this.loadingOverlayService.setIsLoading(false);
        this.displaySuccessMessageOnSnackBar(messageBody);
        this.navigateToPreviousPage();
      });
    }
  }

  onCancelButtonClicked(isClicked: boolean) {
    const messageBody: string = this.SUCCEEDED_MESSAGE.replace(this.REPLACE_STRING, 'cancelled');
    this.setIsSaveCancelPreviewOrSubmitButtonClicked(isClicked);

    if (this.isSaveCancelPreviewOrSubmitButtonClicked) {
      this.loadingOverlayService.setIsLoading(true);

      this.openConfirmCancelDialog(this.MARK_AS_NOT_CORRECTED_CONFIRMATION_MESSAGE)
        .pipe(
          take(1),
          switchMap((shouldMarkAsNotCorrected: boolean) => {
            return this.buildAndUpsertCorrectionRequest(
              CorrectionRequestStatus.CANCELLED,
              this.inspectionForCorrectionRequestResp.inspectionCorrectionRequest
            ).pipe(
              take(1),
              switchMap((upsertResp: UpsertInspectionCorrectionRequestResp) => {
                if (shouldMarkAsNotCorrected) {
                  return this.shipmentDetailsService.updateInspectionStatus(
                    new ProNumber(this.formattedProNumberString),
                    InspectionState.INSPECTED_NOT_CORRECTED
                  );
                } else {
                  return of(new UpdateInspectionStatusResp());
                }
              }),
              catchError((error) => {
                this.loadingOverlayService.setIsLoading(false);
                throw error;
              })
            );
          })
        )
        .subscribe((resp: UpdateInspectionStatusResp) => {
          this.loadingOverlayService.setIsLoading(false);
          this.displaySuccessMessageOnSnackBar(messageBody);
          this.navigateToPreviousPage();
        });
    }
  }

  private removePhotoFromLocalStorage() {
    this.mobileWebBrowserService
      .listPhotoIds(new ProNumber(this.formattedProNumberString))
      .pipe(
        take(1),
        switchMap((photoIds: PhotoId[]) => {
          return forkJoin(
            photoIds.map((photoId: PhotoId) => {
              return this.mobileWebBrowserService.deletePhoto(photoId);
            })
          );
        })
      )
      .subscribe((resp: boolean[]) => {
        if (resp.every((value: boolean) => value === true)) {
          // delete photo completed. do nothing.
        } else {
          // recursive call to delete left out photos
          this.removePhotoFromLocalStorage();
        }
      });
  }

  onPreviewButtonClicked(isClicked: boolean) {
    this.setIsSaveCancelPreviewOrSubmitButtonClicked(isClicked);
    let correctionRequestId: number;

    /**
     * update classType
     *
     * if user didn't select new nmfc from the dropdown(but had only one dropdown option),
     * classType is not updated because the classType update method will be triggered after this method is called.
     * and we can't change the trigger timing due to Angular Lifecycle so we'll manually update the classType here.
     */
    const lastLoadedNmfcItems: NmfcItemSearchRecord[] = this.elasticSearchApiWrapperService.getLastLoadedNmfcItems();
    if (lastLoadedNmfcItems?.length === 1) {
      const nmfcItemCd: NmfcItemSearchRecord = lastLoadedNmfcItems[0];
      this.commoditiesComponent.commodityFormArray?.controls?.forEach((abstractControl: AbstractControl) => {
        if (abstractControl.value?.nmfcItemCd === nmfcItemCd.id && nmfcItemCd.classType !== '0') {
          abstractControl.get('classType').setValue(nmfcItemCd.classType);
          abstractControl.markAsDirty();
        }
      });
    }

    if (this.isSaveCancelPreviewOrSubmitButtonClicked) {
      this.loadingOverlayService.setIsLoading(true);

      this.buildInspectionCorrectionRequest(CorrectionRequestStatus.IN_PROGRESS);
      this.inspectionsApiWrapperService
        .upsertInspectionCorrectionRequest(this.inspectionForCorrectionRequestResp.inspectionCorrectionRequest)
        .pipe(
          take(1),
          switchMap((requestResp: UpsertInspectionCorrectionRequestResp) => {
            correctionRequestId = requestResp.inspectionCorrectionRequest?.correctionRequest?.correctionRequestId;
            this.inspectionCorrectionService.setUpsertInspectionCorrectionRequestResp(requestResp);
            return this.inspectionsApiWrapperService.getCorrectionRequestRatePreview(correctionRequestId);
          }),
          catchError((error) => {
            this.loadingOverlayService.setIsLoading(false);
            throw error;
          })
        )
        .subscribe((previewResp: GetCorrectionRequestRatePreviewResp) => {
          this.loadingOverlayService.setIsLoading(false);
          if (previewResp.ratePreviewStatusCd === this.RATE_PREVIEW_STATUS_CODE_YES) {
            this.inspectionCorrectionService.setAutoRatedPreview(previewResp);
            this.navigateToPreviewPage(correctionRequestId);
          } else {
            const messageBody: string = this.getMessageForAutoRatedPreviewNotAvailable(previewResp.ratePreviewStatusCd);
            this.displayWarningMessageOnSnackBar(messageBody);
          }
        });
    }
  }

  private getMessageForAutoRatedPreviewNotAvailable(ratePreviewStatusCd: string) {
    if (ratePreviewStatusCd === this.RATE_PREVIEW_STATUS_CODE_PARTIAL) {
      return this.RATE_PREVIEW_NOT_AVAILABLE_MESSAGE.replace(this.REPLACE_STRING, this.FULL);
    } else if (ratePreviewStatusCd === this.RATE_PREVIEW_STATUS_CODE_NO) {
      return this.RATE_PREVIEW_NOT_AVAILABLE_MESSAGE.replace(this.REPLACE_STRING, '');
    } else {
      // should not get here, but if we get here, we want to know the value.
      return `Rate Preview Status Code: ${ratePreviewStatusCd}`;
    }
  }

  onSubmitButtonClicked(isClicked: boolean) {
    const correctionTypeCd: string = this.correctionRequestInfoComponent
      .getFormGroup()
      .get('inspectionCorrectionTypeCd').value;
    const inspectionContext: InspectionContext = this.appConstantsService.inspectionContext;
    this.setIsSaveCancelPreviewOrSubmitButtonClicked(isClicked);

    if (this.isSaveCancelPreviewOrSubmitButtonClicked) {
      this.loadingOverlayService.setIsLoading(true);
      this.mobileWebBrowserService.submitInspectionPhotos(new ProNumber(this.formattedProNumberString));

      this.buildAndUpsertCorrectionRequest(
        CorrectionRequestStatus.IN_PROGRESS,
        this.inspectionForCorrectionRequestResp.inspectionCorrectionRequest
      )
        .pipe(
          take(1),
          switchMap((upsertInspectionCorrectionRequestResp: UpsertInspectionCorrectionRequestResp) => {
            const correctionRequestId: number =
              upsertInspectionCorrectionRequestResp.inspectionCorrectionRequest.correctionRequest.correctionRequestId;

            return this.inspectionsApiWrapperService
              .createInspectionCorrection(
                this.formattedProNumberString,
                inspectionContext,
                correctionRequestId,
                correctionTypeCd
              )
              .pipe(
                take(1),
                catchError((error) => {
                  let errorMessage: string = '';
                  if (error.code === '500') {
                    errorMessage = GeneralCorrectionSubmissionMessageEnum.ERROR_500;
                  } else {
                    errorMessage = error;
                  }

                  this.snackBarHandlingService.handleResponseError(
                    errorMessage,
                    ErrorMessageActions.CREATING,
                    `Inspection Correction`
                  );

                  this.loadingOverlayService.setIsLoading(false);

                  return EMPTY; // returning EMPTY because error is handled above
                })
              );
          })
        )
        .subscribe((createInspectionCorrectionResp: CreateInspectionCorrectionResp) => {
          this.loadingOverlayService.setIsLoading(false);
          if (
            createInspectionCorrectionResp.statusCd === InsCreateCorrectionStatusCdEnum.ERROR_BILL_CORRECTION_SYSTEM &&
            createInspectionCorrectionResp?.correctionSystemErrorMessage?.trim().length > 0
          ) {
            this.displayStatusCdOnSnackBar(
              createInspectionCorrectionResp.correctionSystemErrorMessage,
              isStatusError(createInspectionCorrectionResp?.statusCd)
            );
          } else {
            this.displayStatusCdOnSnackBar(
              getGeneralCorrectionSubmissionMessageFromCorrectionStatusCd(createInspectionCorrectionResp?.statusCd),
              isStatusError(createInspectionCorrectionResp?.statusCd)
            );
          }

          this.navigateToPreviousPage();
        });
    }
  }

  onUpdateButtonState(isChanged: boolean) {
    if (isChanged) {
      this.inspectionCorrectionService.setButtonSates(
        this.correctionRequestInfoComponent.getFormGroup(),
        this.inspectionInfoComponent.getFormGroup(),
        this.commoditiesComponent.getFormGroup(),
        this.shipmentDetails
      );
    }
  }

  private buildAndUpsertCorrectionRequest(
    reqStatus: CorrectionRequestStatus,
    inspectionCorrectionRequest: InspectionCorrectionRequest,
    resetReasonCd?: CorrectionRequestResetReasonCd
  ): Observable<UpsertInspectionCorrectionRequestResp> {
    this.buildInspectionCorrectionRequest(reqStatus, resetReasonCd);
    return this.inspectionsApiWrapperService.upsertInspectionCorrectionRequest(inspectionCorrectionRequest).pipe(
      take(1),
      catchError((error) => {
        this.loadingOverlayService.setIsLoading(false);
        throw error;
      })
    );
  }

  private buildInspectionCorrectionRequest(
    statusCd: CorrectionRequestStatus,
    resetReasonCd?: CorrectionRequestResetReasonCd
  ) {
    let inspectionCorrectionRequest: InspectionCorrectionRequest =
      this.inspectionForCorrectionRequestResp.inspectionCorrectionRequest;
    if (resetReasonCd) {
      inspectionCorrectionRequest.correctionRequest.resetReasonCd = resetReasonCd;
    } else {
      // no need to update
    }

    this.setCorrectionRequest(inspectionCorrectionRequest.correctionRequest, statusCd);
    this.setCorrectionRequestCommodities(inspectionCorrectionRequest.correctionRequestCommodities);
    this.setCorrectionRequestDimensions(inspectionCorrectionRequest.correctionRequestDimensions);
  }

  private displayStatusCdOnSnackBar(message: string, isErrorMessage: boolean): void {
    if (isErrorMessage) {
      this.snackBarHandlingService.error(message);
    } else {
      this.snackBarHandlingService.info(message);
    }
  }

  private displaySuccessMessageOnSnackBar(message: string): void {
    this.snackBarHandlingService.success(message);
  }

  private displayWarningMessageOnSnackBar(message: string): void {
    this.snackBarHandlingService.warning(message);
  }

  private navigateToPreviousPage() {
    this.appNavigationService.navigateToList(ListType.SHIPMENT, null, null, true);
  }

  private navigateToPreviewPage(correctionRequestId: number) {
    this.router.navigate([RouterUriComponents.AUTO_RATED_PREVIEW, correctionRequestId]);
  }

  private openConfirmCancelDialog(messageBody: string): Observable<boolean> {
    return this.dialogWrapperService.showConfirmCancelDialog(messageBody).pipe(
      take(1),
      tap((resp: boolean) => {
        if (resp) {
          this.removePhotoFromLocalStorage();
        }
      })
    );
  }

  private setCorrectionRequest(correctionRequest: CorrectionRequest, statusCd: CorrectionRequestStatus) {
    const correctionReqInfoFormGroup: FormGroup = this.correctionRequestInfoComponent.getFormGroup();
    const inspectionInfoFormGroup: FormGroup = this.inspectionInfoComponent.getFormGroup();

    correctionRequest.autoInd = false;
    if (correctionRequest.correctionRequestId > 0) {
      correctionRequest.listActionCd = ActionCd.UPDATE;
    } else {
      correctionRequest.listActionCd = ActionCd.ADD;
    }

    correctionRequest.correctionTypeCd = correctionReqInfoFormGroup.get('inspectionCorrectionTypeCd').value;
    correctionRequest.dimensionSourceCd = DimensionSourceTypeCd.INSPECTION;
    correctionRequest.forceAuditInd = !!correctionReqInfoFormGroup.get('forceAuditor').value;
    correctionRequest.originalBolInformation = inspectionInfoFormGroup.get('originalBolInfo').value;
    correctionRequest.palletCount = this.shipmentDetails?.shipmentDetails?.totPalletCnt;
    correctionRequest.parentInspectionSicCd = this.appConstantsService.inspectionContext?.inspectionSic; // using sic from app constant service because we won't have InspectionContext in ShipmentDetails when we load cloned PROs
    correctionRequest.parentShipmentInstId = +this.shipmentDetails.shipmentDetails.shipmentId.shipmentInstId;
    correctionRequest.proNbr = this.formattedProNumberString;
    correctionRequest.requestorComment = inspectionInfoFormGroup.get('requesterComment').value;
    correctionRequest.requestorEmplid = correctionReqInfoFormGroup.get('userId').value;
    correctionRequest.website = inspectionInfoFormGroup.get('website').value;
    correctionRequest.statusCd = statusCd;
    correctionRequest.inspectionId = this.shipmentDetails.shipmentDetails.inspectionId;
    correctionRequest.description = correctionReqInfoFormGroup.get('description').value;
    correctionRequest.shortDescription = correctionReqInfoFormGroup.get('shortDescription').value;
  }

  private setCorrectionRequestCommodities(correctionRequestCommodity: CorrectionRequestCommodity[]) {
    correctionRequestCommodity?.forEach((correctionRequestCommodity: CorrectionRequestCommodity, index: number) => {
      const commodityFromFormArray = this.commoditiesComponent.commodityFormArray?.controls?.[index]?.value;

      if (commodityFromFormArray) {
        correctionRequestCommodity.classTypeCd = toClassCd(commodityFromFormArray?.classType);
        correctionRequestCommodity.volumeCubicFeet = +commodityFromFormArray?.cubicFeet?.toString()?.replace(/,/g, '');
        correctionRequestCommodity.densityWeightLbs = +commodityFromFormArray?.density?.toString()?.replace(/,/g, '');
        correctionRequestCommodity.description = commodityFromFormArray?.description;
        correctionRequestCommodity.hazmatInd = commodityFromFormArray?.hazardousMtInd;
        correctionRequestCommodity.nmfcItemCd = commodityFromFormArray?.nmfcItemCd;
        correctionRequestCommodity.listActionCd = ActionCd.UPDATE;
        correctionRequestCommodity.packageCd = commodityFromFormArray?.packageCd;
        correctionRequestCommodity.pieceCount = +commodityFromFormArray?.piecesCount;
        correctionRequestCommodity.weightLbs = +commodityFromFormArray?.weightLbs?.toString()?.replace(/,/g, '');
      } else {
        // no need to update
      }
    });
  }

  private setCorrectionRequestDimensions(dimensions: CorrectionRequestDimension[]) {
    if (dimensions?.length > 0) {
      this.setCorrectionRequestDimensionsActionCd(ActionCd.ADD);
    } else {
      this.setCorrectionRequestDimensionsActionCd(ActionCd.UPDATE);
    }

    if (!this.inspectionForCorrectionRequestResp.dimensionsMatchInd) {
      // first time opening Correction Request or changes from Inspection UI or outside of Inspection
      this.inspectionForCorrectionRequestResp.inspectionCorrectionRequest.correctionRequestDimensions.forEach(
        (requestDimension: CorrectionRequestDimension, index: number) => {
          const inspectionDimension: InspectionDimension =
            this.inspectionForCorrectionRequestResp?.inspectionDimensions?.[index];
          requestDimension.correctionRequestId =
            this.inspectionForCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequest?.correctionRequestId;
          requestDimension.dimensionSequenceNbr = inspectionDimension?.dimensionSequenceNbr;
          requestDimension.heightNbr = inspectionDimension?.dimension?.height;
          requestDimension.lengthNbr = inspectionDimension?.dimension?.length;
          requestDimension.widthNbr = inspectionDimension?.dimension?.width;
          requestDimension.pieceCount = inspectionDimension?.pieceCount;
          requestDimension.shipmentInstId =
            this.inspectionForCorrectionRequestResp?.inspectedShipmentCommodities?.[index]?.shipmentInstId;
        }
      );
    } else {
      // changes from Inspection UI: do nothing
    }
  }

  private setCorrectionRequestDimensionsActionCd(actionCd: ActionCd) {
    this.inspectionForCorrectionRequestResp.inspectionCorrectionRequest.correctionRequestDimensions?.forEach(
      (dim: CorrectionRequestDimension) => {
        dim.listActionCd = actionCd;
      }
    );
  }

  private setShouldConfirmBeforeLeaveThisPageFromFromControls() {
    const isFormControlDirty: boolean =
      this.correctionRequestInfoComponent.getFormGroup()?.dirty ||
      this.inspectionInfoComponent.getFormGroup()?.dirty ||
      this.commoditiesComponent.getFormGroup()?.dirty;

    this.shouldConfirmBeforeLeaveThisPage = !this.isSaveCancelPreviewOrSubmitButtonClicked && isFormControlDirty;
  }

  private setIsSaveCancelPreviewOrSubmitButtonClicked(newValue: boolean) {
    this.isSaveCancelPreviewOrSubmitButtonClicked = newValue;
  }

  private displayDialogIfAny() {
    const isCorrectionRequestExist: boolean =
      !!this.inspectionForCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequest?.correctionRequestId;
    this.isDimsConnected =
      !!this.inspectionForCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequestDimensions?.find(
        (crDimension: CorrectionRequestDimension) => crDimension.parentCommoditySequenceNbr > 0
      );
    this.isCommodityResetNeeded =
      !this.inspectionForCorrectionRequestResp?.commoditiesMatchInd && isCorrectionRequestExist;
    this.isDimensionResetNeeded =
      !this.inspectionForCorrectionRequestResp?.dimensionsMatchInd && isCorrectionRequestExist;

    let reasonCd: CorrectionRequestResetReasonCd;
    let resetMessage: string;
    if (this.isDimsConnected) {
      // reset message is only needed when dims are connected
      if (this.isCommodityResetNeeded && this.isDimensionResetNeeded) {
        reasonCd = CorrectionRequestResetReasonCd.BOTH;
        resetMessage = this.COMMODITY_AND_DIMENSION_RESET_MESSAGE;
      } else if (this.isCommodityResetNeeded) {
        reasonCd = CorrectionRequestResetReasonCd.COMMODITY;
        resetMessage = this.COMMODITY_RESET_MESSAGE;
      } else if (this.isDimensionResetNeeded) {
        reasonCd = CorrectionRequestResetReasonCd.DIMENSION;
        resetMessage = this.DIMENSION_RESET_MESSAGE;
      } else {
        // reasonCd should stay undefined
      }
    }

    let notificationDataList: List<NotificationData> = List();

    if (reasonCd) {
      notificationDataList = notificationDataList.push(
        this.customerGuidelinesService.buildNotificationData(
          DialogHeader.CORRECTION_REQUEST_RESET_WARNING_HEADER,
          List([resetMessage])
        )
      );
    }

    if (!this.isBillStatusRated) {
      notificationDataList = notificationDataList.push(
        this.customerGuidelinesService.buildNotificationData(
          DialogHeader.BILL_STATUS_NOT_RATED,
          List([this.correctionRequestInfoComponent.BILL_STATUS_NOT_RATED])
        )
      );
    }

    const customerGuidelines: InspectionCustGuidelines[] =
      this.inspectionForCorrectionRequestResp?.inspectionCustGuidelines;
    if (customerGuidelines?.length > 0) {
      const guideLineMessageList: List<string> =
        this.customerGuidelinesService.buildCustomerGuidelineMessagesList(customerGuidelines);
      notificationDataList = notificationDataList.push(
        this.customerGuidelinesService.buildNotificationData(
          DialogHeader.CUSTOMER_GUIDELINE_HEADER,
          guideLineMessageList
        )
      );
    }

    if (this.isCommodityResetNeeded || this.isDimensionResetNeeded) {
      /**
       * if correction request reset is needed, we have to wait until all the formControls to be initialized.
       * once all initialized, then we can create an upsert request object based on those formControls.
       */
      combineLatest([
        this.correctionRequestInfoComponent.isCorrectionRequestInfoFormControlInitialized$,
        this.inspectionInfoComponent.isInspectionInfoFormControlInitialized$,
        this.commoditiesComponent.isCommodityFormControlInitialized$
      ])
        .pipe(
          filter(
            ([generalInfo, inspectionInfo, commodityInfo]: [boolean, boolean, boolean]) =>
              !!generalInfo && !!inspectionInfo && !!commodityInfo
          ),
          take(1),
          switchMap(() => {
            return this.buildAndUpsertCorrectionRequest(
              CorrectionRequestStatus.IN_PROGRESS,
              this.inspectionForCorrectionRequestResp.inspectionCorrectionRequest,
              reasonCd
            );
          })
        )
        .subscribe(() => {
          if (notificationDataList.size > 0) {
            this.openDialog(notificationDataList);
          }
        });
    } else {
      if (notificationDataList.size > 0) {
        this.openDialog(notificationDataList);
      }
    }
  }

  openDialog(notificationDataList: List<NotificationData>) {
    const multipleNotificationData: MultipleNotificationData = new MultipleNotificationData(notificationDataList);
    const dialogConfig: MatDialogConfig = <MatDialogConfig>{
      data: multipleNotificationData
    };
    this.matDialog.open(CustomerInstructionsAndPalletPricingRatedDialogComponent, dialogConfig);
  }
}
