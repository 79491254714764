import { Injectable } from '@angular/core';
import { XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { XpoAccountPopoverConfig } from '@xpo-ltl/ngx-ltl-core/account-popover';
import { User } from '@xpo-ltl/sdk-common';
import { invoke as _invoke, isEmpty as _isEmpty } from 'lodash';
import { BehaviorSubject, Observable, interval } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, skipWhile, take, tap } from 'rxjs/operators';
import { AccountUrls } from '../enums/account-urls.enum';
import { AutoCorrectionsRoles, ManagerRoles } from '../enums/roles.enum';
import { AppConfigManagerService } from './app-config-manager.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  // ONLY USED IN TEST ENVIRONMENT -----------------
  originalUserRoles: string[];
  // end ONLY USED IN TEST ENVIRONMENT -----------------

  isUserLoggedIn$: Observable<boolean>;

  private _currentUser: User;
  private _isCurrentUserManager: boolean;
  private _isCurrentUserAutoCorrectionReviewer: boolean;

  // keeping this observable since we can change our roles while testing in
  // testing regions to switch Manager ver and Inspections ver
  private userRoleChangedSubject = new BehaviorSubject(<boolean>true);
  userRoleChanged$ = this.userRoleChangedSubject.asObservable();

  constructor(
    private appConfigManagerService: AppConfigManagerService,
    private xpoLtlLoggedInUserService: XpoLtlLoggedInUserService
  ) {
    this.isUserLoggedIn$ = this.xpoLtlLoggedInUserService
      .getLoggedInUser(appConfigManagerService.getLoggedInUser())
      .pipe(
        distinctUntilChanged(),
        shareReplay(1),
        map((user: User) => {
          this._currentUser = user;
          this._setIsCurrentUserManager();
          this._setIsCurrentUserAutoCorrectionReviewer();

          return !!user;
        })
      );
  }

  get currentUserEmailAddress(): string {
    return this._currentUser.emailAddress;
  }

  get currentUserEmployeeId(): string {
    return this._currentUser.employeeId;
  }

  get currentUserFirstName(): string {
    return this._currentUser.givenName;
  }

  get currentUserLastName(): string {
    return this._currentUser.lastName;
  }

  get currentUserFullName(): string {
    return `${this._currentUser.givenName} ${this._currentUser.lastName}`;
  }

  get isCurrentUserManager(): boolean {
    return this._isCurrentUserManager;
  }
  
  get isCurrentUserAutoCorrectionReviewer(): boolean {
    return this._isCurrentUserAutoCorrectionReviewer;
  }

  isCurrentUserAuthorizedToAccess(checkManagerRole?: boolean, checkAutoCorrectionReviewerRole = false): boolean {
    if (this.isCurrentUserAuthorizedForInspectionsApp() && !checkManagerRole && !checkAutoCorrectionReviewerRole) {
      // user is Inspector
      return true;
    } else if (this.isCurrentUserAuthorizedForInspectionsApp() && checkManagerRole && this.isCurrentUserManager) {
      // user is Manager
      return true;
    } else if (this.isCurrentUserAuthorizedForInspectionsApp() && checkAutoCorrectionReviewerRole && this.isCurrentUserAutoCorrectionReviewer) {
      // user is Auto Correction Reviewer
      return true;
    } else {
      return false;
    }
  }

  /**
   * This must used only in DevTestingService
   * @param isManagerRolesRemoved
   */
  devOnlyChangeManagerRole(isManagerRolesRemoved: boolean): void {
    if (isManagerRolesRemoved) {
      this.originalUserRoles = this._currentUser.roles;
      this._currentUser.roles = this._currentUser.roles.filter(
        (userRole) => !userRole.includes(ManagerRoles.CUSTOMER_GUIDELINES) && !userRole.includes(ManagerRoles.MESSAGES)
      );
    } else {
      this._currentUser.roles = this.originalUserRoles;
    }

    // reset if user has manager roles after updating user roles.
    this._setIsCurrentUserManager();
    this._setIsCurrentUserAutoCorrectionReviewer();
    this.userRoleChangedSubject.next(true);
  }

  logNotAuthorizedUser() {
    console.error(`Unauthorized User: ${JSON.stringify(this._currentUser)}`);
  }

  setAccountPopover(): XpoAccountPopoverConfig {
    const popoverConfig: XpoAccountPopoverConfig = {
      imageUri: `${AccountUrls.SWITCH_API_URL}${this._currentUser.emailAddress}${AccountUrls.PICTURE_URL}`,
      name: `${this.currentUserFullName}`,
      links: [{ title: `My Account`, url: AccountUrls.MY_ACCOUNT }]
    };

    return popoverConfig;
  }

  setDynatraceUserIdentity(): void {
    const setUser = (): void =>
      _invoke(
        window['dtrum'],
        'identifyUser',
        !_isEmpty(this._currentUser.emailAddress) ? this._currentUser.emailAddress : this._currentUser.userId
      );
    if ((window['dtrum'] || {}).identifyUser) {
      setUser();
    } else {
      let retryCount: number = 0;
      interval(1000)
        .pipe(
          tap(() => retryCount++),
          skipWhile(() => !(window['dtrum'] || {}).identifyUser && retryCount <= 60),
          take(1)
        )
        .subscribe(() => {
          setUser();
        });
    }
  }

  /**
   * Check if the logged-in XPO user is also authorized to access Inspections app
   * Not all XPO employee is authorized to access to Inspections app
   */
  isCurrentUserAuthorizedForInspectionsApp(): boolean {
    // Let anyone in if we're in develop and staging regions
    if (!this.appConfigManagerService.isProduction() && !this.appConfigManagerService.isPreProd()) {
      return true;
    } else {
      return this._hasInspectorRoles() || this._hasAccessJobRole() || this._hasAccessUserId();
    }
  }

  isManagerActionsAvailable(): boolean {
    return this.isCurrentUserManager;
  }
  
  isAutoCorrectionsReviewActionsAvailable(): boolean {
    return this.isCurrentUserManager;
  }

  protected getAccessRoles(): Set<string> {
    const validAccessRoles = new Set<string>();
    const accessRoles: string = this.appConfigManagerService.getAccessRoles();
    if (accessRoles) {
      accessRoles.split(',').forEach((role) => {
        validAccessRoles.add(role.trim());
      });
    }
    return validAccessRoles;
  }

  protected getAccessEmployeeIds(): Set<string> {
    const validAccessEmployeeIds = new Set<string>();
    const accessEmployeeUserIds: string = this.appConfigManagerService.getAccessEmployeeIds();
    if (accessEmployeeUserIds) {
      accessEmployeeUserIds.split(',').forEach((employeeId) => {
        validAccessEmployeeIds.add(employeeId.trim());
      });
    }
    return validAccessEmployeeIds;
  }

  protected getAccessJobRoleCodes(): Set<string> {
    const validAccessJobRoleCodes = new Set<string>();
    const accessJobRoleCodes: string = this.appConfigManagerService.getAccessJobRoleCodes();
    if (accessJobRoleCodes) {
      accessJobRoleCodes.split(',').forEach((jobRoleCode) => {
        validAccessJobRoleCodes.add(jobRoleCode.trim());
      });
    }
    return validAccessJobRoleCodes;
  }

  /**
   * Check for Access Roles
   */
  private _hasInspectorRoles(): boolean {
    let hasAccessRoles: boolean = false;
    const accessRoles: Set<string> = this.getAccessRoles();
    if (accessRoles?.size > 0 && this._currentUser?.roles) {
      for (let i = 0; i < this._currentUser.roles.length; i++) {
        let role = this._currentUser.roles[i];
        if (role) {
          const slashPos = role.indexOf('/');
          if (slashPos >= 0) {
            role = role.substring(slashPos + 1);
          }

          hasAccessRoles = accessRoles.has(role);
          if (hasAccessRoles) {
            break;
          }
        }
      }
    }
    return hasAccessRoles;
  }

  /**
   * Check for JobRoleCode
   */
  private _hasAccessJobRole(): boolean {
    const accessJobRoleCodes: Set<string> = this.getAccessJobRoleCodes();
    return (
      accessJobRoleCodes?.size > 0 &&
      this._currentUser?.jobRoleCode &&
      accessJobRoleCodes.has(this._currentUser.jobRoleCode)
    );
  }

  /**
   * Check for Specific UserIds
   */
  private _hasAccessUserId(): boolean {
    const accessUserIds: Set<string> = this.getAccessEmployeeIds();
    return accessUserIds?.size > 0 && this._currentUser?.employeeId && accessUserIds.has(this._currentUser.employeeId);
  }

  /**
   * set true if user is Manager
   * Only Manager can access to the Broadcast message tab and add/update/delete Customer Guidelines
   */
  private _setIsCurrentUserManager() {
    for (let i: number = 0; i < this._currentUser?.roles?.length; i++) {
      this._isCurrentUserManager = this._hasManagerRole(this._currentUser?.roles[i]);
      if (this._isCurrentUserManager) {
        break;
      }
    }
  }

  /**
   * Check if user is Manager or Inspector
   * Only Manager can access to the Broadcast message tab and add/update/delete Customer Guidelines
   * @param roles
   */
  private _hasManagerRole(role: string): boolean {
    return (
      Object.values(ManagerRoles).findIndex((managerRole) => role?.toLowerCase().includes(managerRole.toLowerCase())) >=
      0
    );
  }
  
  /**
   * set true if user is Auto Correction Reviewer
   * Only Auto Correction Reviewer can access to the Auto Correction Review tab
   */
  private _setIsCurrentUserAutoCorrectionReviewer() {
    for (let i: number = 0; i < this._currentUser?.roles?.length; i++) {
      this._isCurrentUserAutoCorrectionReviewer = this._hasAutoCorrectionReviewerRole(this._currentUser?.roles[i]);
      if (this._isCurrentUserAutoCorrectionReviewer) {
        break;
      }
    }
  }

  /**
   * Check if user is Auto Correction Reviewer
   * Only Auto Correction Reviewer can access to the Auto Correction Review tab
   * @param roles
   */
  private _hasAutoCorrectionReviewerRole(role: string): boolean {
    return (
      Object.values(AutoCorrectionsRoles).findIndex((autoCorrectionRole) => role?.toLowerCase().includes(autoCorrectionRole.toLowerCase())) >=
      0
    );
  }
}
