import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CustomerGuidelineDialogComponent } from '../../dialogs/customer-guideline-dialog/customer-guideline-dialog.component';
import { DialogAction } from '../../enums/dialog-actions.enum';
import { AddFeatureName } from '../../enums/list-name.enum';
import { RouteLabels } from '../../enums/router-uri-components.enum';
import { GridSettingsService } from '../../services/grid-settings/grid-settings.service';
import { RefreshDatesService } from '../../services/refresh-dates.service';
import { ListHeaderComponent } from '../list-header/list-header.component';
import { ListCustomerGuidelinesComponent } from './list-customer-guidelines/list-customer-guidelines.component';
import { CustomerGuidelinesService } from './services/customer-guidelines.service';

@Component({
  selector: 'app-customer-guidelines',
  templateUrl: './customer-guidelines.component.html',
  styleUrls: ['./customer-guidelines.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomerGuidelinesComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClass = 'customerGuidelines';

  selectedListName = undefined;
  selectedCount = 0;
  columnsSorted: boolean;
  columnsFiltered: boolean;
  title: string;
  addingObjectName: string;

  @ViewChild(ListCustomerGuidelinesComponent, { static: true })
  public customerGuidelinesList: ListCustomerGuidelinesComponent;

  @ViewChild(ListHeaderComponent, { static: true })
  public listHeaderComponent: ListHeaderComponent;

  public toolbarTitle: string;
  private unsubscriber$: Subject<void> = new Subject();

  constructor(
    public router: Router,
    private gridSettings: GridSettingsService,
    private changeDetector: ChangeDetectorRef,
    public customerGuidelinesService: CustomerGuidelinesService,
    private dialog: MatDialog,
    public refreshDatesService: RefreshDatesService
  ) {}

  ngOnInit() {
    this.selectedListName = RouteLabels.CUSTOMER_GUIDELINES;
    this.columnsFiltered = this.gridSettings.hasFilterGridSettings(this.selectedListName);
    this.columnsSorted = this.gridSettings.hasSortGridSettings(this.selectedListName);
    this.title = this.selectedListName;
    this.addingObjectName = AddFeatureName.CUSTOMER_GUIDELINE;

    // NOTE: This timeout is to force the table to resize correctly when rendered (after becoming visible).
    setTimeout(() => {
      this.resetRowHeights();
    });
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  gridSettingsChanged() {
    this.columnsFiltered = this.gridSettings.hasFilterGridSettings(this.selectedListName);
    this.columnsSorted = this.gridSettings.hasSortGridSettings(this.selectedListName);
    this.updateGridSettingForListHeader();
    this.changeDetector.detectChanges();
  }

  onGridFilterCleared() {
    this.gridSettings.clearFilterGridSettings(this.selectedListName, this.customerGuidelinesList.gridOptions);
  }

  onGridSortCleared() {
    this.gridSettings.clearSortGridSettings(this.selectedListName, this.customerGuidelinesList.gridOptions);
  }

  onGridSettingsReset() {
    this.gridSettings.resetGridSettings(this.selectedListName, this.customerGuidelinesList.gridOptions);
  }

  onGridSettingsLoaded() {
    this.gridSettings.loadListGridSettings(this.selectedListName, this.customerGuidelinesList.gridOptions);
  }

  onGridSettingsSaved() {
    const list = this.getSelectedList();
    if (list?.gridOptions) {
      this.gridSettings.saveListGridSettings(this.selectedListName);
    }
  }

  getSelectedList(): any {
    return this.customerGuidelinesList;
  }

  resetRowHeights() {
    const list = this.getSelectedList();
    if (list && list.gridOptions && list.gridOptions.api) {
      list.gridOptions.api.resetRowHeights();
    }
  }

  onRefreshTriggered() {
    this.customerGuidelinesList.setCustomerGuidelines();
  }

  onAddButtonClicked() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.autoFocus = true;
    matDialogConfig.disableClose = false;
    matDialogConfig.minWidth = '90%';
    matDialogConfig.minHeight = '90%';
    matDialogConfig.data = {
      title: 'Add Customer Guideline',
      action: DialogAction.CREATE
    };
    const matDialogRef = this.dialog.open(CustomerGuidelineDialogComponent, matDialogConfig);
    matDialogRef.afterClosed().subscribe((reload) => {
      if (reload) {
        this.customerGuidelinesList.clearList();
        this.customerGuidelinesList.setCustomerGuidelines();
      }
      this.customerGuidelinesService.clear();
    });

    matDialogRef.backdropClick().subscribe(() => {
      this.customerGuidelinesService.clear();
    });
  }

  updateGridSettingForListHeader() {
    this.listHeaderComponent.gridSettingsChanged();
  }
}
