<div class="overall">
  <div class="close">
    <button
      (click)="close()"
      class="close"
      matTooltip="Click to close or Escape or Click outside the dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <h1>Photos Informations</h1>
  <div class="content">
    <!--  general info-->
    <h2>General infos</h2>
    <p>On browser reload, those informations are lost (not your local photos). Note that after {{photoCleanupStrategyDays}} days, your photos are
      deleted from your device to free up space.</p>
    <p><strong>Locally stored photos:</strong> {{totalLocalStoragePhotosCount$|async}}</p>
    <p><strong>Space:</strong> {{(photoSpace$|async)?.usedKB / 1024| number : '1.0-2'}}
      /{{(photoSpace$|async)?.availableKB / 1024| number : '1.0-2'}} Mb</p>

    <!--  Stored Photos -->
    <h2>Locally not submitted photos ({{(photoStoredLs$|async)?.size}})</h2>
    <div class="events info">
      <p *ngFor="let photoStored of (photoStoredLs$|async)" class="event">
        {{photoStored.getComponentText()}}
        <a (click)="onProLinkToInspectionClicked(photoStored.getProNumber())"
           [matTooltip]="'Navigate to '+photoStored.getProNumberTxt()+' inspection details'">
          Pro: {{photoStored.getProNumberTxt()}}</a>
      </p>
    </div>

    <!--  Photo upload-->
    <h2>Uploaded Photos to DMS ({{(photoUploaded$|async)?.size}})</h2>
    <div class="events info">
      <p *ngFor="let photoUlEvent of (photoUploaded$|async)" class="event">
        {{photoUlEvent.getComponentText()}}
        <a (click)="onProLinkToShipmentClicked(photoUlEvent.getProNumber())"
           [matTooltip]="'Navigate to '+photoUlEvent.getProNumberTxt()+' shipment details'">
          Pro: {{photoUlEvent.getProNumberTxt()}}</a>
      </p>
    </div>

    <!--  Photo deleted-->
    <h2>Deleted Photos from Local Storage ({{(photoDeletedFromLs$|async)?.size}})</h2>
    <div class="events info">
      <p *ngFor="let photosDeletedEvent of (photoDeletedFromLs$|async)" class="event">
        {{photosDeletedEvent.getComponentText()}}
        <a (click)="onProLinkToInspectionClicked(photosDeletedEvent.getProNumber())"
           [matTooltip]="'Navigate to '+photosDeletedEvent.getProNumberTxt()+' inspection details'">
          Pro: {{photosDeletedEvent.getProNumberTxt()}}</a>
      </p>
    </div>

    <!--Photo errors  -->
    <h2>Photo errors ({{(photoError$|async)?.size}})</h2>
    <div class="events errors">
      <p *ngFor="let photoError of (photoError$|async)" class="event">
        {{photoError.getComponentText()}}
        <a (click)="onProLinkClicked(photoError)"
           [matTooltip]="'Navigate to '+photoError.getProNumberTxt()">
          Pro: {{photoError.getProNumberTxt()}}</a>
        Photo status:{{photoError.getPhotoUploadStatusTxt()}}
      </p>
    </div>
  </div>
</div>
