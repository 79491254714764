import { InspectionError } from '../inspection-error';

export class ApiError extends InspectionError {
  constructor(aMessage: string, aCause?: any) {
    super(aMessage, aCause); // call the parent constructor
    this.name = 'ApiError'; // set the name property
  }

  isCause404(): boolean {
    return this.cause?.error?.code === '404';
  }

  isCauseMsgContains(msg: string) {
    return this.cause?.error?.error?.message?.includes(msg);
  }
}
