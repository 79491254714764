import { Injectable } from '@angular/core';
import {
  GetInspectionDetailsPath,
  GetInspectionDetailsResp,
  GetInspectionPath,
  GetInspectionResp,
  InspectionsApiService
} from '@xpo-ltl/sdk-inspections';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ApiError } from '../../classes/error/api/api-error';
import { ProNumber } from '../../classes/pronumber';
import { ApiUtil } from '../../classes/utils/api-util';
import { ErrorMessageActions } from '../../enums/error-message-actions.enum';
import { RetryStrategyService } from '../../operators/retry-strategy.service';
import { RequestValidator } from '../../validators/request.validator';
import { AppConstantsService } from '../app-constants.service';
import { ShipmentDetailsService } from './shipment-details.service';

@Injectable({
  providedIn: 'root'
})
export class InspectionDetailService {
  private inspectionDetailsSubject$: BehaviorSubject<GetInspectionResp> = new BehaviorSubject<GetInspectionResp>(
    undefined
  );
  inspectionDetails$: Observable<GetInspectionResp> = this.inspectionDetailsSubject$.asObservable();

  constructor(
    private constants: AppConstantsService,
    private inspectionsApiService: InspectionsApiService,
    private retryStrategy: RetryStrategyService
  ) {}

  getInspection(inspectionId: number): Observable<GetInspectionResp> {
    const pathParams: GetInspectionPath = new GetInspectionPath();
    pathParams.inspectionId = inspectionId;

    return this.inspectionsApiService.getInspection(pathParams).pipe(
      catchError((error) => {
        throw new ApiError(`Error ${ErrorMessageActions.GETTING} Inspection Details`, error);
      })
    );
  }

  getInspectionDetails(proNumber: string): Observable<GetInspectionDetailsResp> {
    RequestValidator.validateStringNotNullOrEmpty(this.constants?.inspectionContext?.inspectionSic, 'Inspection Sic');
    RequestValidator.validateObjectNotUndefinedOrEmpty(new ProNumber(proNumber), RequestValidator.PRO_NBR);
    RequestValidator.validateProNumber(new ProNumber(proNumber));

    const pathParam: GetInspectionDetailsPath = new GetInspectionDetailsPath();
    pathParam.inspectionSic = this.constants.inspectionContext?.inspectionSic;
    pathParam.proNumber = new ProNumber(proNumber).formatProNumber();

    return this.inspectionsApiService
      .getInspectionDetails(pathParam, ApiUtil.DEFAULT_DATA_OPTIONS)
      .pipe(
        this.retryStrategy.retryStrategy(
          ShipmentDetailsService.DEFAULT_GET_TIMEOUT,
          ShipmentDetailsService.DEFAULT_RETRY_DELAY,
          ShipmentDetailsService.DEFAULT_RETRY_COUNT,
          ShipmentDetailsService.DEFAULT_LOADING_MESSAGE,
          'GET-INSPECTION-DETAILS'
        ),
        take(1)
      );
  }

  setInspectionDetails(getInspectionDetailsResp: GetInspectionResp) {
    this.inspectionDetailsSubject$.next(getInspectionDetailsResp);
  }
}
