import { EventEmitter, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { List } from 'immutable';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';
import { InspectionError } from '../../classes/error/inspection-error';
import { Photo } from '../../classes/photos/photo';
import { PhotoId } from '../../classes/photos/photo-id';
import { PhotoUtils } from '../../classes/photos/photo-utils';
import { SnackBarHandlingService } from '../../services/snack-bar-handling.service';
import { PhotoGalleryData } from './photo-gallery-data';
import { PhotoGalleryDataIF } from './photo-gallery-data-if';
import { PhotoGalleryComponent } from './photo-gallery.component';

@Injectable({
  providedIn: 'root'
})
export class PhotoGalleryService {
  constructor(private matDialog: MatDialog, private snackBarHandlingService: SnackBarHandlingService) {}

  onClearThumbnail = new EventEmitter();

  private photoGalleryData: PhotoGalleryData;
  private dialogRef: any;
  private docTypes: string[];
  private dataInterface: PhotoGalleryDataIF;
  private photos: List<Photo> = List();
  private static readonly STILL_LOADING_MESSAGE: string = 'Loading photo, please wait.';

  private photoGalleryChangedSubject: Subject<void> = new Subject<void>();
  photoGalleryChanged$: Observable<void> = this.photoGalleryChangedSubject.asObservable();

  private loadedPhotosForCurrentPro: BehaviorSubject<List<Photo>> = new BehaviorSubject<List<Photo>>(List());
  loadedPhotos$: Observable<List<Photo>> = this.loadedPhotosForCurrentPro.asObservable();

  private previousPhoto$: Observable<Photo> = of();
  private nextPhoto$: Observable<Photo> = of();

  openPhotoGalleryDialog(
    dataInterface: PhotoGalleryDataIF,
    docTypes: string[],
    currentPhotoId: PhotoId,
    photos?: List<Photo>
  ): Observable<any | undefined> {
    this.dataInterface = dataInterface;
    this.photoGalleryData = new PhotoGalleryData();
    this.photoGalleryData.serviceRef = this;
    this.docTypes = docTypes;
    this.photos = photos;
    this.photoGalleryData.currentPhoto = new Photo(currentPhotoId);
    this.photoGalleryData.currentPhotoId = currentPhotoId;
    this.updatePhotoGalleryData(this.photoGalleryData.currentPhotoId);

    this.dialogRef = this.matDialog.open(PhotoGalleryComponent, {
      data: this.photoGalleryData,
      maxWidth: '100vw',
      panelClass: 'photoGalleryPanel'
    });
    return this.dialogRef.afterClosed();
  }

  canDeletePhoto() {
    return this.dataInterface.canDeletePhoto();
  }

  // this is called from template by clicking '>' arrow
  nextPhoto() {
    this.processUpdatingPhotoGalleryData(this.nextPhoto$, this.photoGalleryData.nextPhoto);
  }

  // this is called from template by clicking '<' arrow
  previousPhoto() {
    this.processUpdatingPhotoGalleryData(this.previousPhoto$, this.photoGalleryData.previousPhoto);
  }

  /**
   * the prev/next photos are triggered to load when the current photo is loaded.
   *  if base64data has value: prev/next photo is loaded. continue updatePhotoGalleryData()
   *  else: prev/next photo is still loading. wait the response and then continue updatePhotoGalleryData()
   *        (note: those observables are shareReplay(1) so it will not make additional calls from here.)
   */
  private processUpdatingPhotoGalleryData(prevOrNextPhoto$: Observable<Photo>, prevOrNextPhoto: Photo) {
    if (prevOrNextPhoto?.getBase64data()) {
      this.updatePhotoGalleryData(prevOrNextPhoto.id);
    } else {
      const message: string = PhotoGalleryService.STILL_LOADING_MESSAGE;
      this.snackBarHandlingService.warning(message);
      prevOrNextPhoto$.subscribe((loadedPhoto: Photo) => {
        this.updatePhotoGalleryData(prevOrNextPhoto.id);
      });
    }
  }

  updatePhotoGalleryData(currentPhotoId: PhotoId): void {
    if (!this.photos) {
      // load photos before submitting inspection, which is stored in hardware
      this.loadListOfPhotoIdFromHardware(currentPhotoId);
    } else {
      // load photos that retrieved from DB
      this.loadPhotoFromDb(currentPhotoId);
    }
  }

  private loadListOfPhotoIdFromHardware(photoId: PhotoId): void {
    this.dataInterface
      .listPhotoIds(photoId.proNumber, this.docTypes)
      .pipe(take(1))
      .subscribe((photoIds: PhotoId[]) => {
        this.photos = PhotoUtils.toPhotosFromPhotoIds(List(photoIds));
        this.loadPhotoFromDb(photoId);
      });
  }

  /**
   * load current, prev, and next photos
   *  so navigating prev/next photo will be faster
   */
  private loadPhotoFromDb(aCurrentPhotoId: PhotoId) {
    const isLoadingOverlayEnabled: boolean = true;

    this.setPreviousCurrentNextPhotos(aCurrentPhotoId);

    // load current photo
    if (!this.photoGalleryData.currentPhoto?.getBase64data()) {
      this.dataInterface
        .loadPhoto(this.photoGalleryData.currentPhotoId, isLoadingOverlayEnabled)
        .pipe(take(1))
        .subscribe((aCurrentPhoto: Photo) => {
          // we could run into a problem where the user spams left and right before the image is loaded.
          // if so, only update the current photo if the currentPhotoId matches the photo returned in the call
          if (this.photoGalleryData.currentPhotoId.id === aCurrentPhotoId.id) {
            this.photoGalleryData.currentPhoto = aCurrentPhoto;
            this.loadedPhotosForCurrentPro.next(this.loadedPhotosForCurrentPro.getValue().concat(aCurrentPhoto));
          }
        });
    }

    // start loading prev/next photos in the background
    this.loadPrevOrNextPhotoFromDB(this.previousPhoto$, this.photoGalleryData.previousPhoto);
    this.loadPrevOrNextPhotoFromDB(this.nextPhoto$, this.photoGalleryData.nextPhoto);
  }

  private loadPrevOrNextPhotoFromDB(prevOrNextPhoto$: Observable<Photo> | undefined, prevOrNextPhoto: Photo) {
    // loading prev/next photos will be handled in background so overlay should be disabled
    const isLoadingOverlayEnabled: boolean = false;

    if (prevOrNextPhoto && !prevOrNextPhoto?.getBase64data()) {
      // assigning an observable here so we can subscribe in multiple places with shareReplay(1)
      prevOrNextPhoto$ = this.dataInterface.loadPhoto(prevOrNextPhoto.id, isLoadingOverlayEnabled).pipe(shareReplay(1));

      prevOrNextPhoto$.subscribe((aLoadedPhoto: Photo) => {
        if (this.photoGalleryData.nextPhoto?.id === prevOrNextPhoto.id) {
          this.photoGalleryData.nextPhoto = aLoadedPhoto;
        } else {
          this.photoGalleryData.previousPhoto = aLoadedPhoto;
        }
        this.loadedPhotosForCurrentPro.next(this.loadedPhotosForCurrentPro.getValue().concat(aLoadedPhoto));
      });
    }
  }

  private setPreviousCurrentNextPhotos(aCurrentPhotoId: PhotoId): void {
    if (!this.photos) {
      const msg: string = 'no existing photos, closing this dialog';
      throw new InspectionError(msg);
      this.dialogRef.close();
    } else {
      this.photoGalleryData.photoCount = this.photos?.size;
      //set this.photoGalleryData from Id
      let i: number = 0;
      this.photos.every((currentPhoto) => {
        if (currentPhoto.id.id === aCurrentPhotoId.id) {
          // set previous photo
          if (i > 0) {
            const previousPhoto: Photo = new Photo(this.photos.get(i - 1).id);
            this.photoGalleryData.previousPhoto = previousPhoto;
            this.updatePhotoWithBase64dataIfAny(previousPhoto);
          } else {
            // no previous photo
            this.photoGalleryData.previousPhoto = undefined;
          }

          // set next photo
          if (i < this.photos.size - 1) {
            const nextPhoto: Photo = new Photo(this.photos.get(i + 1).id);
            this.photoGalleryData.nextPhoto = nextPhoto;
            this.updatePhotoWithBase64dataIfAny(nextPhoto);
          } else {
            // no next photo
            this.photoGalleryData.nextPhoto = undefined;
          }

          // set current photo
          this.photoGalleryData.currentPhoto = currentPhoto;
          this.updatePhotoWithBase64dataIfAny(currentPhoto);

          this.photoGalleryData.photoNumber = i + 1;
          this.photoGalleryData.currentPhotoId = currentPhoto.id;

          return false; //break the loop
        }
        i++;

        return true; //to continue loop
      });

      this.photoGalleryChangedSubject.next();
    }
  }

  private updatePhotoWithBase64dataIfAny(photoWithoutBase64: Photo) {
    const photoWithBase64 = this.loadedPhotosForCurrentPro.value.find(
      (loadedPhoto) => loadedPhoto.id.id === photoWithoutBase64.id.id
    );

    if (photoWithBase64) {
      if (this.photoGalleryData.previousPhoto?.id === photoWithoutBase64.id) {
        this.photoGalleryData.previousPhoto = photoWithBase64;
      } else if (this.photoGalleryData.nextPhoto?.id === photoWithoutBase64.id) {
        this.photoGalleryData.nextPhoto = photoWithBase64;
      } else {
        this.photoGalleryData.currentPhoto = photoWithBase64;
      }
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  deletePhoto(): void {
    const deletePhotoId: PhotoId = this.photoGalleryData.currentPhoto.id;
    this.photos = this.photos.filter((aPhoto: Photo) => aPhoto.id.id !== deletePhotoId.id);

    this.dataInterface
      .deletePhoto(deletePhotoId)
      .pipe(take(1))
      .subscribe(() => {
        if (this.photoGalleryData.nextPhoto) {
          /**
           * we need to clear the previous photo data
           * we'll update the next photo as the current photo and update the prev/next photos based on the new current photo
           */
          this.photoGalleryData.previousPhoto = undefined;
          this.nextPhoto();
        } else if (this.photoGalleryData.previousPhoto) {
          this.previousPhoto();
        } else {
          // no more archived-photos, close the dialog box
          this.onDeleteLastPhoto();
          this.dialogRef.close();
        }
        this.onDeleteLastPhoto();
      });
  }

  onDeleteLastPhoto(): void {
    this.onClearThumbnail.emit(null);
  }

  setLoadedPhotosForCurrentPRO(newLoadedPhotos: List<Photo>) {
    this.loadedPhotosForCurrentPro.next(newLoadedPhotos);
  }
}
