import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import {
  GetInspectionShipmentDetailsResp,
  InspectionCommodityLine,
  InspectionShipment,
  PieceDimensions
} from '@xpo-ltl/sdk-inspections';
import { AppConstantsService } from '../../../../services/app-constants.service';
import { InspectionState } from '../../../../enums/inspection-state.enum';
import { InspectionsDateUtils } from '../../../../classes/utils/inspections-date-utils';

@Component({
  selector: 'app-previous-inspection',
  templateUrl: './previous-inspection.component.html',
  styleUrls: ['./previous-inspection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviousInspectionComponent implements OnChanges {
  @Input()
  inspectionShipmentDetails: GetInspectionShipmentDetailsResp;

  previousInspections: InspectionShipment[];
  displayedColumns: string[] = ['pieceCnt', 'length', 'width', 'height'];
  isExpanded = false;

  constructor() {}

  ngOnChanges() {
    if (this.inspectionShipmentDetails?.previousInspectionDetails) {
      this.previousInspections = this.inspectionShipmentDetails.previousInspectionDetails.filter(
        (prev: InspectionShipment) => {
          return (
            prev.inspectionStatusCd !== InspectionState.IN_PROGRESS &&
            prev.inspectionStatusCd !== InspectionState.EDITING &&
            ((prev.inspectorPieceDimensions && prev.inspectorPieceDimensions.length > 0) ||
              (prev.inspectionNote && prev.inspectionNote.note && prev.inspectionNote.note.length > 0))
          );
        }
      );
    }
    this.isExpanded = false;
  }

  calculateWeight(prev: InspectionShipment): number {
    if (prev.inspectorPieceDimensions && prev.inspectorPieceDimensions.length > 0) {
      const sumFn = (x, y) => x + y;
      const weight = prev.commodity.map((line: InspectionCommodityLine) => line.totGrossWeight).reduce(sumFn);
      if (!weight) {
        return 0;
      }
      return parseFloat(weight.toFixed(2));
    } else {
      return 0;
    }
  }

  calculateCuFt(prev: InspectionShipment): number {
    if (prev.inspectorPieceDimensions && prev.inspectorPieceDimensions.length > 0) {
      const sumFn = (x, y) => x + y;
      const volume = prev.inspectorPieceDimensions
        .map(
          (item: PieceDimensions) =>
            item.pieceCnt * (item.dimensions.length / 12) * (item.dimensions.width / 12) * (item.dimensions.height / 12)
        )
        .reduce(sumFn);
      return parseFloat(volume.toFixed(2));
    } else {
      return 0;
    }
  }

  calculatePCF(prev: InspectionShipment): number {
    if (prev.inspectorPieceDimensions && prev.inspectorPieceDimensions.length > 0) {
      const totWeight = this.calculateWeight(prev);
      const totCuFt: number = this.calculateCuFt(prev);

      return AppConstantsService.calculateDensity(totWeight, totCuFt);
    } else {
      return 0;
    }
  }
}
