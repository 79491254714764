import { Pipe, PipeTransform } from '@angular/core';
import { getGeneralCorrectionStatusFromCorrectionStatusCdForInspectionCardTooltip } from '../enums/general-correction-submission-message.enum';

@Pipe({
  name: 'toTooltipMessageFromCorrectionStatus'
})
export class ToTooltipMessageFromCorrectionStatus implements PipeTransform {
  transform(statusCd: string): string {
    return getGeneralCorrectionStatusFromCorrectionStatusCdForInspectionCardTooltip(statusCd);
  }
}
