export enum InspectionStatusChangeMessage {
  INSPECT = 'INSPECT',
  DISMISS = 'DISMISS',
  KEEP = 'KEEP',
  MARK_CORRECTED = 'MARK CORRECTED',
  INSPECTED_NOT_CORRECTED = 'INSPECTED NOT CORRECTED',
  SEND_TO_RECOMMENDED_LIST = 'SEND TO RECOMMENDED LIST',
  SEND_TO_FLAGGED_LIST = 'SEND TO FLAGGED LIST',
  EDIT_INSPECTION = 'EDIT INSPECTION'
}
