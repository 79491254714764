// NOTE: app key's maximum length is 40.
export enum UserPreferenceAppKeys {
  FLAGGED = 'INS-FLAGGED-LIST-GRID-SETTINGS',
  RECOMMENDED = 'INS-RECOMMENDED-LIST-GRID-SETTINGS',
  INSPECTED = 'INS-INSPECTED-LIST-GRID-SETTINGS',
  COMPLETED = 'INS-COMPLETED-LIST-GRID-SETTINGS',
  DISMISSED = 'INS-DISMISSED-LIST-GRID-SETTINGS',
  LOOKUP = 'INS-LOOKUP-LIST-GRID-SETTINGS',
  ADD_PROS = 'INS-ADD-PROS-LIST-GRID-SETTINGS',
  RECOMMENDATION_RULES = 'INS-RECOMMENDED-RULES-LIST-GRID-SETTINGS',
  CUSTOMER_GUIDELINES = 'INS-CUST-GUIDELINES-LIST-GRID-SETTINGS',
  BROADCAST_MESSAGES = 'INS-BROADCAST-MESSAGE-LIST-GRID-SETTINGS',
  PICKUP_REQUESTS = 'INS-PICKUP-REQUESTS-LIST-GRID-SETTINGS',
  AUTO_CORRECTION_APPROVAL = 'INS-AUTO-CORRECTION-LIST-GRID-SETTINGS',
  INSPECTION_CORRECTIONS = 'INS-CORRECTIONS-LIST-GRID-SETTINGS',
  GENERAL_SETTINGS = 'INS-GENERAL-SETTINGS',
  SIC_SHIFT = 'INS-SIC-SHIFT'
}
