export enum InspectionState {
  NO_STATUS = '',
  FLAGGED = 'R',
  RECOMMENDED = 'Y',
  DISMISSED = 'E',
  IN_PROGRESS = 'P',
  INSPECTED = 'I',
  INSPECTED_NOT_CORRECTED = 'N',
  CORRECTION_SUBMITTED = 'T',
  MANUALLY_ADDED = 'M', // Used for History
  // The following aren't normal states, but reserved for the future
  EXCLUDED = 'E',
  EDITING = 'K',
  CORRECTION_IN_PROGRESS = 'C'
}

export function toInspectionStateDisplayName(inspectionState: InspectionState): string {
  switch (inspectionState) {
    case InspectionState.RECOMMENDED:
      return 'Recommended';
    case InspectionState.FLAGGED:
      return 'Flagged';
    case InspectionState.IN_PROGRESS:
      return 'In Progress';
    case InspectionState.INSPECTED:
      return 'Inspected';
    case InspectionState.DISMISSED:
      return 'Dismissed';
    case InspectionState.INSPECTED_NOT_CORRECTED:
      return 'Inspected Not Corrected';
    case InspectionState.CORRECTION_SUBMITTED:
      return 'Correction Submitted';
    case InspectionState.EXCLUDED:
      return 'Excluded';
    case InspectionState.MANUALLY_ADDED:
      return 'Manually Added';
    case InspectionState.CORRECTION_IN_PROGRESS:
      return 'Correction In Progress';
    case InspectionState.EDITING:
      return 'In Edit';
    case InspectionState.NO_STATUS:
    case undefined:
      return 'No Status';
  }
  return '';
}
