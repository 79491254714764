import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { InspectionListItemComponent } from '../../../classes/inspection-list-item-component';
import { InspectionState } from '../../../enums/inspection-state.enum';
import { ListName } from '../../../enums/list-name.enum';
import { ShipmentDetailsService } from '../../../services/api/shipment-details.service';
import { AppNavigationService } from '../../../services/app-navigation.service';
import { DialogWrapperService } from '../../../services/dialog-wrapper.service';
import { GridSettingsService } from '../../../services/grid-settings/grid-settings.service';
import { HardwareService } from '../../../services/hardware/hardware-service';
import { LocationService } from '../../../services/location/location.service';
import { ColDef} from 'ag-grid-community';

@Component({
  selector: 'app-list-complete-inspections',
  templateUrl: './list-completed-inspections.component.html',
  styleUrls: ['./list-completed-inspections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ListCompleteInspectionsComponent extends InspectionListItemComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClass = 'listCompletedShipments';

  @Output()
  selectedCountChanged = new EventEmitter<number>();

  @Output()
  listChanged = new EventEmitter<boolean>();

  @Output()
  gridSettingsChanged = new EventEmitter<boolean>();

  private columnDefsSubject$ = new BehaviorSubject<ColDef[]>(undefined);
  columnDefs$ = this.columnDefsSubject$.asObservable();

  constructor(
    private shipmentDetailsService: ShipmentDetailsService,
    private gridSettings: GridSettingsService,
    private dialogManager: DialogWrapperService,
    protected appNavigation: AppNavigationService,
    protected changeDetector: ChangeDetectorRef,
    private locationService: LocationService
  ) {
    super(changeDetector, appNavigation);

    this.gridOptions.defaultColDef = this.getDefaultColDef();

    this.gridOptions.columnDefs = this.gridSettings.buildDefaultCompletedListColumnDefs();

    this.gridOptions.rowSelection = 'multiple';
    this.gridOptions.suppressMenuHide = HardwareService.isTouchScreenCapable();
    this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.gridOptions.onCellDoubleClicked = this.onCellDoubleClicked.bind(this);
    this.gridOptions.onColumnMoved = this.columnsChanged.bind(this);
    this.gridOptions.onColumnPinned = this.columnsChanged.bind(this);
    this.gridOptions.onColumnResized = this.columnsChanged.bind(this);
    this.gridOptions.onFilterChanged = this.columnsChanged.bind(this);
    this.gridOptions.onColumnVisible = this.columnsChanged.bind(this);
    this.gridOptions.onSortChanged = this.columnsChanged.bind(this);
    this.gridOptions.onRowDataChanged = this.rowDataChanged.bind(this);
    this.gridOptions.icons = GridSettingsService.buildGridIcons();
    this.gridOptions.enableCellTextSelection = true;
    this.gridOptions.ensureDomOrder = true;
    this.gridOptions.onGridReady = () => {
      this.setCurrentWidthAndHeight();
    };
  }

  private subscription = new Subscription();

  public ngOnInit() {
    this.subscription.add(
      this.shipmentDetailsService.completedListData$.subscribe((planningListItems) => {
        this.updateRowData(planningListItems);
      })
    );

    this.subscription.add(
      this.locationService.isCorrectionsFeatureAvailable$
        .pipe(filter((isCorrectionsFeatureAvailable: boolean) => isCorrectionsFeatureAvailable !== undefined))
        .subscribe((isCorrectionsFeatureAvailable: boolean) => {
          this.columnDefsSubject$.next(this.gridSettings.buildDefaultCompletedListColumnDefs());
        })
    );

    // TODO: remove this temporary approach. This should be done by Angular automatically.
    this.setCurrentWidthAndHeight();
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private rowDataChanged($event) {
    this.gridSettings.restoreGridState(ListName.COMPLETED, this.gridOptions);
    this.setCurrentWidthAndHeight();
  }

  private columnsChanged($event) {
    this.gridSettings.storeGridState(ListName.COMPLETED, this.gridOptions);
    this.gridSettingsChanged.emit(true);
  }

  onResize($event) {
    this.setCurrentWidthAndHeight();
  }

  onSelectionChanged() {
    if (this.gridOptions.api) {
      const selectedRows = this.gridOptions.api.getSelectedRows();
      if (selectedRows) {
        this.selectedCountChanged.emit(selectedRows.length);
      }
    }
  }


  public moveShipmentsToInspectionList() {
    const proNumbers = this.getSelectedShipments();
    this.shipmentDetailsService
      .updateInspectionStatus(proNumbers, InspectionState.FLAGGED)
      .pipe(take(1))
      .subscribe((response) => {
        this.dialogManager.showStatusChangeDialog(response);
        this.listChanged.emit(true);
      });
  }

  public moveShipmentsToPlanningList() {
    const proNumbers = this.getSelectedShipments();
    this.shipmentDetailsService
      .updateInspectionStatus(proNumbers, InspectionState.RECOMMENDED)
      .pipe(take(1))
      .subscribe((response) => {
        this.dialogManager.showStatusChangeDialog(response);
        this.listChanged.emit(true);
      });
  }
}
