<div class="change-location-container">
  <div class="change-location-container__content" fxLayout="column" fxLayoutAlign="space-between center">
    <div fxLayout="row">
      <form [formGroup]="sicShiftFromGroup" class="change-location-container__content-formGroup">
        <mat-form-field appearance="fill">
          <mat-label>SIC Code / City</mat-label>
          <input
            #sicInput
            type="text"
            placeholder="Select one"
            matInput
            required
            formControlName="inputSicFormControl"
            [matAutocomplete]="sicAutoComplete"
          />
          <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="onSicClearClicked($event)">
            <mat-icon>close</mat-icon>
          </button>
          <mat-autocomplete autoActiveFirstOption #sicAutoComplete="matAutocomplete" (closed)="handleFormGroupValues()">
            <mat-option *ngFor="let filteredSic of filteredSicLocations$ | async" [value]="filteredSic">
              {{ filteredSic }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Shift</mat-label>
          <input
            #shiftInput
            type="text"
            placeholder="Select one"
            matInput
            required
            formControlName="inputShiftFormControl"
            [matAutocomplete]="shiftAutoComplete"
          />
          <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="onShiftClearClicked($event)">
            <mat-icon>close</mat-icon>
          </button>
          <mat-autocomplete
            autoActiveFirstOption
            #shiftAutoComplete="matAutocomplete"
            (closed)="handleFormGroupValues()"
          >
            <mat-option *ngFor="let key of filteredShifts$ | async" [value]="key">
              {{ key | uppercase }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>

    <div *ngIf="errorMessage" class="mat-error" fxLayout="row" fxFlexAlign="start">
      <div>{{ errorMessage }}</div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end end" fxFlex="" class="change-location-container__action-buttons">
      <button mat-button color="primary" (click)="onOkClicked()" [disabled]="!isEveryValueValid">Ok</button>
      <button *ngIf="constants.inspectionContext$ | async" mat-button color="primary" [mat-dialog-close]="true">
        Cancel
      </button>
    </div>
  </div>
</div>
