export enum ConfigManagerProperties {
  ACCESS_EMPLOYEE_IDS = 'accessEmployeeIds',
  ACCESS_JOB_ROLE_CODES = 'accessJobRoleCodes',
  ACCESS_ROLES = 'accessRoles',
  API_URL = 'apiUrl',
  APP_METADATA_ENDPOINT = 'appMetadataEndpoint',
  APP_NAME = 'appName',
  BOL_API_ENDPOINT = 'bolApiEndpoint',
  BUILD_VERSION = 'buildVersion',
  CS_SHIPMENT_LIST_REGION_URL = 'csShipmentListRegionUrl',
  CORRECTIONS_APP_REGION_URL = 'correctionsAppRegionUrl',
  ELASTIC_SEARCH_API_ENDPOINT = 'elasticSearchApiEndpoint',
  ERROR_TOAST_DURATION = 'errorToastDuration',
  FEEDBACK_SUBJECT = 'feedbackSubject',
  FEEDBACK_TO_ADDRESS = 'feedbackToAddress',
  FREIGHT_FLOW_API_ENDPOINT = 'freightFlowApiEndpoint',
  FREIGHT_MOVEMENT_API_ENDPOINT = 'freightmovementApiEndpoint',
  IMAGEVIEWER_AUTH_APP_ROOT_URL = 'imageviewerAuthAppRootUri',
  INFRASTRUCTURE_API_ENDPOINT = 'infrastructureApiEndpoint',
  INVOICE_API_ENDPOINT = 'invoiceApiEndpoint',
  IS_ELS_AUTOMATED_CORRECTION_AVAILABLE = 'isElsAutomatedCorrectionAvailable',
  ITEM_680_NOTE_2B_VERSION = 'item680note2bVersion',
  LOCATION_API_ENDPOINT = 'locationApiEndpoint',
  LOCATION_V2_API_ENDPOINT = 'locationV2ApiEndpoint',
  LOGGED_IN_USER_ROOT = 'loggedInUserRoot',
  LOGGING_API_ENDPOINT = 'loggingApiEndpoint',
  MEMBERSHIP_API_ENDPOINT = 'membershipApiEndpoint',
  PHOTO_CLEANUP_STRATEGY_DAYS = 'photoCleanupStrategyDays',
  PRICING_API_ENDPOINT = 'pricingApiEndpoint',
  PRODUCTION = 'production',
  RECOMMENDED_RULES_ENDPOINT = 'recommendedRulesEndpoint',
  REGION = 'region',
  RELEASE_NOTE_LINK = 'releaseNotesLink',
  SCOPE_OPTIONS = 'scopeOptions',
  SECRET_TOKEN = 'secretToken',
  SHIPMENT_API_ENDPOINT = 'shipmentApiEndpoint',
  SHIPMENT_V2_API_ENDPOINT = 'shipmentV2ApiEndpoint',
  SUCCESS_TOAST_DURATION = 'successToastDuration',
  USER_CONTEXT_PATH = 'userContextPath',
  DMS_CORP_CODE = 'imageCorpCode'
}
