import { DmsDocType } from '../../enums/dms-doc-type.enum';
import { IllegalArgumentError } from '../error/illegal-argument-error';
import { DocumentTypeIconName } from '../../enums/document-type-icon-name';
import { InspectionNotificationService } from '../../services/inspection-notification.service';

export class DmsUtil {
  static getSvgIconValueByDocType(aDocType: string): string {
    if (!aDocType) {
      throw new IllegalArgumentError('aDocType should not be null');
    }

    let result: string = DocumentTypeIconName.UNKNOWN;
    let lFound: boolean = false;
    for (const lImgType in DmsDocType) {
      const lValue = DmsDocType[lImgType];
      if (typeof lValue === 'string' && lValue.endsWith(aDocType)) {
        const lDocumentTypeIconName: string = 'document-type-' + lValue;
        if (DmsUtil.documentTypeIconNameHas(lDocumentTypeIconName)) {
          result = lDocumentTypeIconName;
        }
        lFound = true;
        break;
      }
    }
    if (!lFound) {
      InspectionNotificationService.appInstance?.logShouldBeFixed(
        'Document type -' + aDocType + '- has no corresponding DocumentTypeIconName, a default one will be displayed'
      );
    }
    // console.debug('getSvgIconValueByDocType->input:' + aDocType + ' res:' + result);

    return result;
  }

  static getDoctypeDescriptionByDocType(aDocType: string): string {
    const result: string = DmsUtil.DMS_DOC_TYPES_DESC_MAP.get(aDocType);
    if (!result) {
      InspectionNotificationService.appInstance?.logShouldBeFixed(
        'getDoctypeDescriptionByDocType-> docType:' + aDocType + 'not found into map'
      );
    }
    return result;
  }

  static documentTypeIconNameHas(enumName: string): boolean {
    let result: boolean = false;
    for (const lImgType in DocumentTypeIconName) {
      const lValue = DocumentTypeIconName[lImgType];
      if (lValue === enumName) {
        result = true;
        break;
      }
    }

    return result;
  }

  /**
   * Grabbed from a java class with bash ->
   * `while read line;do foo=$(echo "$line"|sed 's/(/[/g'|sed 's/)/]/g'|cut -d'[' -f2-);echo "[$foo";done < hitme.txt`
   *
   */

  public static readonly DMS_DOC_TYPES_DESC_MAP: Map<string, string> = new Map<string, string>([
    ['A265', 'F265 Adjustments'],
    ['ACSM', 'Customer ACSM Summary reports'],
    ['ACTL', 'Activity Log/History'],
    ['APIN', 'AP INVOICE'],
    ['APLS', 'Benefits Appeals'],
    ['APMP', 'AP Manual Payment'],
    ['APND', 'Appendix'],
    ['APNT', 'AP INVOICE NOTE'],
    ['APPO', 'AP Purchase Order'],
    ['APPR', 'AP Invoice Approval'],
    ['ARDC', 'Archive Deletion'],
    ['ARIN', 'AR Invoice'],
    ['AUDT', 'Audit Agreement'],
    ['BL', 'BILL OF LADING'],
    ['BLAT', 'B/L ATTATCHMENT'],
    ['BLCA', 'BL Customer Provided Attachment'],
    ['BNKR', 'AR Bank Report'],
    ['BNTE', 'Benefits Notes,references,calcs,formulas'],
    ['BOTH', 'Benefits Other Document'],
    ['BPSL', 'Backup Pickup Stop List'],
    ['BVER', 'Benefits Verification Document'],
    ['BWS', 'Benefits Work Sheet-Snapshot As Is'],
    ['C265', 'F265 Control Data File'],
    ['CCTL', 'Company Car Tax Liability'],
    ['CDF', 'OCR Control Data File'],
    ['CHCP', 'HR Certificate Health Care Provider'],
    ['CHEK', 'CHECKS'],
    ['CHKS', 'Check Sheet'],
    ['CINM', 'Customer Increase Monthly report'],
    ['CINS', 'Customer Increase Summary Sheet'],
    ['CKAT', 'CHECK ATTACHMENT'],
    ['CKRF', 'CHECK REFUNDS'],
    ['CLAT', 'Claim Attachment'],
    ['CLM', 'Claim Form'],
    ['CLMH', 'Closed Claim Folder'],
    ['CLMN', 'Claim Notes'],
    ['CLOA', 'CHECK LETTER OF AUTH'],
    ['CM', 'CREDIT MEMO'],
    ['COND', 'Draft Contract'],
    ['CPCF', 'Customer Profile - Consignee Photo'],
    ['CPOR', 'Customs Proof of Report Border Crossing'],
    ['CPRF', 'Customer Profile'],
    ['CPSF', 'Customer Profile - Shipper Photo'],
    ['CSTA', 'Customer Costing Actual'],
    ['CSTP', 'Customer Costing Projected'],
    ['CTRT', 'Contract'],
    ['CULA', 'Consignee Unload Agreement'],
    ['CUPD', 'Customer Provided Docs'],
    ['CUSR', 'Customs Released Documents'],
    ['CUST', 'CUSTOMS DOCUMENT'],
    ['DCMD', 'DriveCam Video Metadata File'],
    ['DCVE', 'DriveCam Video File- Proprietary Format [DCE]'],
    ['DCVW', 'DriveCam Video File- Windows Format [WMV]'],
    ['DDA', 'Direct Dep. Advice'],
    ['DEXF', 'DIRECT excp freight'],
    ['DFLE', 'DEAD FILE'],
    ['DLOG', 'Driver Logs'],
    ['DM', 'DEBIT MEMO'],
    ['DMAN', 'Delivery Manifest'],
    ['DMNR', 'In Motion Dimensioner Photos'],
    ['DNEW', 'DIRECT new shipment'],
    ['DR', 'DELIVERY RECEIPT'],
    ['DRAT', 'D/R ATTATCHMENT'],
    ['DRMN', 'DIRECT manifest'],
    ['DXCF', 'Dock Exception Foto'],
    ['DXFO', 'Delivery Exception Photo'],
    ['DXFR', 'DXFO - Report'],
    ['DXPP', 'Dock Exception Poorly Packaged'],
    ['E265', 'F265 Scan Stat Record'],
    ['EBCF', 'Employee Benefits Change Form'],
    ['EBEF', 'Employee Benefits Enrollment form'],
    ['EBIL', 'Employee Benefits Billing Disability'],
    ['ECOR', 'Employee Correspondence Letters from Benefits'],
    ['EHIS', 'Employee History'],
    ['EIFO', 'Employee ID Photo'],
    ['EIWO', 'Employee Withholding Court Order'],
    ['ELOA', 'Employee Benefits Leave of Absence Election'],
    ['EMSN', 'National Medical Support Notices'],
    ['EVOB', 'Verification Employee Benefits Forms'],
    ['EWEB', 'Benefits Employee Web Work Sheet-Snapshot'],
    ['EXRR', 'Expense Report Receipts'],
    ['F265', 'TIME CARD'],
    ['F701', 'DRIVER MILEAGE'],
    ['FAI', 'Federal Annual Inspection'],
    ['FLCT', 'Forklift Certification'],
    ['FLWO', 'Forklift Work Order'],
    ['FLYS', 'Fly Sheet'],
    ['H265', 'Handheld Control Data File'],
    ['HRNT', 'HR Note reference or comment'],
    ['ICHK', 'Customer Incentive Check'],
    ['ICP', 'ICP Detail Letter'],
    ['IR', 'INSPECTION REPORT'],
    ['IRAT', 'IR Attachment'],
    ['IRFO', 'IR Photo'],
    ['JE', 'Journal Entry'],
    ['JE30', 'Journal Entry'],
    ['LBIL', 'Benefits Billing Forms'],
    ['LCOR', 'Letters and Correspondence'],
    ['LDFO', 'Loading Door Photo'],
    ['LMAN', 'Load Manifest'],
    ['LOA', 'Letter of Authority'],
    ['LOAC', 'Employee Leave of Absence Claim-HR'],
    ['M265', 'F265 Mileage Transmit File'],
    ['MEDR', 'Misc. Property EDR'],
    ['MEML', 'Misc. Property Email'],
    ['MEXR', 'Misc Property Expenditure Requests'],
    ['MI', 'MISC. INVOICE'],
    ['MIAT', 'MISC. INV. ATTCHMNT'],
    ['MINV', 'Misc Property Invoices'],
    ['MJE', 'Misc Property Journal Entry Documents'],
    ['MMAK', 'Match Mail Acknowledgement'],
    ['MOTH', 'Misc. Property Other Documents'],
    ['MPO', 'Misc. Property Purchase Order'],
    ['MRCP', 'Misc. Property Recap Sheet'],
    ['NCIC', 'NMFC Classification Inspection Certificate'],
    ['NOTE', 'Notes,references,calcs,formulas '],
    ['O265', 'Driver Pay Form Bitonal'],
    ['OE', 'Completed Open Enrollment Form'],
    ['OECL', 'Open Enrollment Employee Confirm Letter'],
    ['OECR', 'Open Enrollment Correspondence'],
    ['OENT', 'Open Enrollment Note'],
    ['OEPP', 'Pre-Populated Open Enrollment Form'],
    ['OIAP', 'AP Remittance Advice'],
    ['OLFX', 'Misc online fax'],
    ['OSDF', 'Over Short or Damage Photo'],
    ['PDFO', 'Pickup Dimensioner Photo'],
    ['PDMN', 'P and D Manifest'],
    ['PMTL', 'PM Task List'],
    ['PMWU', 'PM Write Up'],
    ['PPIX', 'Property Pictures CTS'],
    ['PPPP', 'Poorly Packaged at Pickup'],
    ['PRCC', 'Pricing Contracts'],
    ['PRIC', 'Pricing'],
    ['PROP', 'Customer Proposal'],
    ['PUFO', 'PickUp Photo'],
    ['PURG', 'Purged Document'],
    ['PXFC', 'Claims Production Unknown'],
    ['R401', 'Retiree HSA Reimbursement Request'],
    ['RBRD', 'Real Property Board Approvals'],
    ['RCLO', 'Real Property Closing Documents'],
    ['REML', 'Real Property Emails'],
    ['REXR', 'Real Property Expenditure Request'],
    ['RFP', 'Request for Pricing'],
    ['RFPC', 'Request for Pricing Checklist'],
    ['RIMG', 'Real Property Photos and Images'],
    ['RINV', 'Real Property Invoices'],
    ['RJE', 'Real Property Journal Entry Documents'],
    ['RMED', 'Retiree Medical and Billing Forms'],
    ['RMSR', 'Medicare Supplement Reimbursement'],
    ['ROTH', 'Real Property Other Documents'],
    ['RRCP', 'Real Property Recap Sheet'],
    ['RTAX', 'Real Property Tax Allocation'],
    ['RTSC', 'Customer Rate Scale'],
    ['SIFO', 'Site Install Photo'],
    ['SLCA', 'Shipper Load Count Agreement'],
    ['SMFO', 'Scanner Maintenance Photo'],
    ['SPLG', 'Subpoena Request Log Files'],
    ['SSCF', 'Substitute Service Control Form BL'],
    ['SSFO', 'Site Survey Photo'],
    ['T265', 'TEMP TIME CARD'],
    ['TARF', 'Tarrif'],
    ['TBCF', 'Test Employee Benefits Change form'],
    ['TBD', 'Unknown, Misc, To Be Determined '],
    ['TBEF', 'Test Employee Benefits Enrollment form'],
    ['TCFO', 'Trailer Closing Photo'],
    ['TCLM', 'Test Claim Form'],
    ['TCTL', 'Test Activity Log/History'],
    ['TENT', 'Open Enrollment Note'],
    ['TEST', 'CTS doc class for WinTerm testing'],
    ['THIS', 'Test Employee History'],
    ['THKS', 'Test Check Sheet'],
    ['TLAT', 'Test Claim Attachment'],
    ['TLFO', 'Trailer Load Close Photo'],
    ['TLFT', 'Trailer Load Close Photo Thumbnail'],
    ['TLMH', 'Test Claim Folder BF Archive'],
    ['TLMN', 'Test Claim Notes'],
    ['TLOA', 'Test Employee Benefits Leave of Absence Election'],
    ['TLYS', 'Test Fly Sheet'],
    ['TOE', 'Test Open Enrollment form'],
    ['TOFO', 'Trailer Open Photo'],
    ['TOFT', 'Trailer Open Photo Thumbnail'],
    ['TOTH', 'Benefits Other Document'],
    ['TOTR', 'Total Rewards Statement'],
    ['TPFO', 'Progressive full'],
    ['TPFT', 'Progressive thumb'],
    ['TPIN', 'Test AP Invoice'],
    ['TPMP', 'Test A/P Manual Payment'],
    ['TPNT', 'Test AP Notes'],
    ['TPPO', 'Test AP Purchase Order'],
    ['TPPR', 'Test AP Invoice Approval'],
    ['TRDT', 'TRAILER DETENTION'],
    ['TRFO', 'Trailer Photo'],
    ['TT', 'Pricing and Tariff Transit Time Files'],
    ['TUFO', 'Trailer Unload Photo'],
    ['TUPD', 'Test Customer Provided Docs'],
    ['TVER', 'Benefits Verification Document'],
    ['TXFC', 'Test Claims Production Unknown'],
    ['UMAN', 'Unload Manifest'],
    ['VREG', 'Vehicle Registration'],
    ['VRGP', 'Tractor Registrations'],
    ['W2', 'W2'],
    ['WI', 'WT. AND INSP. CERT.'],
    ['WRFO', 'WT. & Research Photo'],
    ['X265', 'F265 Payroll Transmit File']
  ]);
}
