import { PlanningListItem } from './planning-list-item';
import { ChangeDetectorRef } from '@angular/core';
import { AppNavigationService } from '../services/app-navigation.service';
import { ShipmentListItemComponent } from './shipment-list-item-component';

export abstract class PlanningListItemsComponent extends ShipmentListItemComponent<PlanningListItem> {
  constructor(protected changeDetector: ChangeDetectorRef, protected appNavigation: AppNavigationService) {
    super(changeDetector, appNavigation);
  }
}
