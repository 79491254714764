<div class="rule-filter">
  <div>
    <button mat-button color="primary" (click)="removeFilter()" data-testid="close">
      X
    </button>
  </div>
  <div class="filter-text"><span class="text-bold">AND</span> the</div>
  <div>
    <mat-form-field hideRequiredMarker="true">
      <mat-label *ngIf="filter.getName() == ''"> - select filter - </mat-label>
      <mat-select [ngModel]="filter.getName()" (ngModelChange)="selectFilter($event)" matNativeControl required data-testid="filter-dropdown">
        <mat-option *ngFor="let filter of filters" value="{{ filter.getName() }}">
          {{ filter.getDisplayName() }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="!filter.getName() && !filter.getIsValid() && !this.filterValidation.getIsValid()" class="mat-error">
      Invalid Filter
    </div>
  </div>
  <div *ngIf="filter && (filter.getType() === 'options' || filter.getType() === 'MADCode')" class="filter-text">
    is
  </div>
  <div class="madCodeSearch" *ngIf="filter && filter.getType() === 'MADCode'">
    <mat-form-field floatLabel="never">
      <input data-testid="filter-mad-code"
        matInput
        placeholder="Enter MAD Code"
        (keydown.enter)="madCodeChanged()"
        [ngModel]="getMadCode()"
        (ngModelChange)="setMadCode($event); madCodeChanged()"
        maxlength="11"
      />
      <mat-hint>{{ getAccountName() }}</mat-hint>
      <mat-icon (click)="madCodeChanged()" matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <div *ngIf="filter && filter.getType() === 'options'">
    <mat-button-toggle-group
      name="filterOptions"
      [ngModel]="filter.getValue()"
      (ngModelChange)="filter.setValue($event)"
    >
      <mat-button-toggle
        *ngFor="let option of filter.getOptions()"
        value="{{ option.toUpperCase() }}"
        (change)="validateFilter()"
      >
        {{ option }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div *ngIf="!this.filterValidation.getIsValid()" class="mat-error">
    {{ displayFilterErrorMessageByFilterType() }}
  </div>
</div>
<br />
