import { PhotoEvent } from '../photo-event';
import { Photo } from '../../photo';

export abstract class ErrorPhotoEvent extends PhotoEvent {
  private _error: Error;

  constructor(msg: String, photo: Photo, createdDate: Date, error: Error) {
    super(msg, photo, createdDate);
    this._error = error;
  }

  get error(): Error {
    return this._error;
  }

  getPhotoUploadStatusTxt(): string | undefined {
    return this.photo?.getPhotoUploadStatusTxt();
  }
}
