import {ChangeDetectorRef, inject} from '@angular/core';
import {ColDef, GridApi, GridOptions, GridReadyEvent} from 'ag-grid-community';
import {List} from 'immutable';
import {AppNavigationService} from '../services/app-navigation.service';
import {PlanningListItem} from './planning-list-item';
import {ProNumber} from './pronumber';
import {AgGridHelper} from './helpers/ag-grid-helper';
import { InspectionLocalStorageService } from '../services/inspection-local-storage.service';
import { ListName } from '../enums/list-name.enum';

export abstract class ShipmentListItemComponent<T> {

  gridApi: GridApi;
  localStorageService = inject(InspectionLocalStorageService);
  /**
   * we want to use an immutable list here so that we make sure that the reference is changed when its updated to have
   * the changeDetection mecanism from ng that detects that changes have been done to the data
   */
  public rowData: List<T> = List();

  public gridOptions: GridOptions;
  public totalCount: number = 0;
  //grid height
  height: number;
  //grid width
  width: number;

  constructor(protected changeDetector: ChangeDetectorRef, protected appNavigation: AppNavigationService) {
    this.gridOptions = <GridOptions>{};
  }

  protected unselectAllRows() {
    if (this.gridOptions.api) {
      this.localStorageService.setLastProIndexClick(undefined);
      this.gridOptions.api.deselectAll();
      this.onSelectionChanged();
    }
  }

  protected updateRowData(planningListItems: T[]) {
    if (planningListItems) {
      this.unselectAllRows();
      this.changeDetector.markForCheck();
      this.rowData = List(planningListItems);
      this.totalCount = planningListItems.length;
    }
  }

  protected getSelectedShipments(): ProNumber[] {
    const selectedShipments: ProNumber[] = [];
    if (this.gridOptions.api) {
      const selectedRows = this.gridOptions.api.getSelectedRows();
      if (selectedRows && selectedRows.length > 0) {
        selectedRows.forEach((row) => {
          selectedShipments.push(new ProNumber(row.proNumber));
        });
        this.unselectAllRows();
      }
    }
    return selectedShipments;
  }

  protected navigateToShipmentDetails(data: PlanningListItem) {
    this.appNavigation.navigateToShipmentDetails(new ProNumber(data.proNumber));
  }

  protected setCurrentWidthAndHeight() {
    this.width = document.getElementsByClassName('app-container-content')[0].clientWidth;
    this.height = document.getElementsByClassName('app-container-content')[0].clientHeight - 50;
  }

  protected getDefaultColDef(): ColDef {
    return AgGridHelper.getDefaultColDef();
  }

  protected onCellDoubleClicked($event) {
    this.unselectAllRows();
    this.localStorageService.setLastProIndexClick($event.rowIndex);
    this.navigateToShipmentDetails($event.data);
  }

  onGridReady(event: GridReadyEvent): void {
    const lastVisitedListName: ListName = this.localStorageService.getLastVisitedList();
    this.gridApi = event.api;
    const lastpro: number = parseInt(this.localStorageService.getlastProIndex());
    const rowNode = this.gridApi.getDisplayedRowAtIndex(lastpro);
    const thisClass = this as any;
    if (rowNode && thisClass?.hostClass.includes(lastVisitedListName)) {
      this.gridOptions.getRowStyle = (params) => { 
        if (params.node.rowIndex === lastpro) {
            return { background: '#f0f0f0' }
        }
      }
      rowNode.setHighlighted("above");
      this.gridApi.ensureIndexVisible(lastpro, 'middle');
    }
  }

  abstract onSelectionChanged();
}
