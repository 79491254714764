import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BroadcastMessage } from '../../../classes/broadcast-messages/broadcast-message';
import { BroadcastMessageItemStatus } from '../../../enums/broadcast-messages/broadcast-message-item-status.enum';

@Component({
  selector: 'app-unread-broadcast-message-item',
  templateUrl: './unread-broadcast-message-item.component.html',
  styleUrls: ['./unread-broadcast-message-item.component.scss']
})
export class UnreadBroadcastMessageItemComponent {
  @Input()
  unreadMessage: BroadcastMessage;
  @Input()
  employeeId: string;
  @Input()
  isAcknowledgeLaterButtonDisplayed: boolean;

  @Output()
  responseMessage = new EventEmitter<BroadcastMessage>();

  constructor() {}

  private createAcknowledgeResponseMessage(): BroadcastMessage {
    if (!this.unreadMessage) {
      return;
    }
    this.unreadMessage.response = BroadcastMessageItemStatus.ACKNOWLEDGED;
    return this.unreadMessage;
  }

  onAcknowledgeResponseMessage() {
    const messageResponse = this.createAcknowledgeResponseMessage();
    this.responseMessage.emit(messageResponse);
  }
}
