import { Injectable } from '@angular/core';
import { DeliveryNotification, NotificationHist } from '@xpo-ltl/sdk-dataservices';
import { get as _get } from 'lodash';
import { PDFDocument } from 'pdf-lib';
import { decode } from 'typescript-base64-arraybuffer';

export type AppointmentType = DeliveryNotification | NotificationHist;

@Injectable()
export class DocumentUtilsService {
  /**
   * Merge multiple PDF documents into one.
   *
   * @param string Encoded byte data of a pdf file
   */
  async mergePdfs(documentData: string[]): Promise<Uint8Array> {
    if (documentData?.length === 0) {
      return undefined;
    }
    const mergedPdf = await PDFDocument.create();
    for (const document of documentData) {
      const pdfBytes = decode(document);
      const pdf = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
    }
    const mergedPdfFile = await mergedPdf.save();
    return mergedPdfFile;
  }
}
