<div class="bill-details">
  <mat-expansion-panel [(expanded)]="isExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title class="section-label"> Bill Details </mat-panel-title>
    </mat-expansion-panel-header>

    <div fxLayout="row" fxLayoutAlign="start stretch">
      <div fxLayout="column" fxFlex="60">
        <div class="bill-details__shipper" fxLayout="row" fxLayoutAlign="space-between stretch">
          <div fxLayout="column" fxFlex="50">
            <div class="label">Shipper</div>
            <div fxLayout="row">
              <div class="value">
                {{ shipmentDetails?.shipperAcctId?.acctName }}
              </div>
              <button
                class="bill-details__expand-collapse"
                mat-button
                mat-icon-button
                aria-label="Expand Collapse Shipper"
                (click)="expandCollapse(NameAddressSection.SHIPPER)"
              >
                <mat-icon *ngIf="!shipperSectionExpanded" matSuffix>keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="shipperSectionExpanded" matSuffix>keyboard_arrow_up</mat-icon>
              </button>
            </div>
            <div *ngIf="shipperSectionExpanded">
              <div class="bill-details__address" [innerHTML]="shipmentDetails?.shipperAddr | nameAddressPipe"></div>
            </div>
          </div>
          <div fxLayout="column" fxFlex="50">
            <div class="label">Shipper MAD Code</div>
            <div class="value">
              {{ shipmentDetails?.shipperAcctId?.acctMadCd }}
            </div>
          </div>
        </div>
        <div class="bill-details__consignee" fxLayout="row" fxLayoutAlign="start stretch">
          <div fxLayout="column" fxFlex="50">
            <div class="label">Consignee</div>
            <div fxLayout="row">
              <div class="value">
                {{ shipmentDetails?.consigneeAcctId?.acctName }}
              </div>
              <button
                class="bill-details__expand-collapse"
                mat-button
                mat-icon-button
                aria-label="Expand Collapse Consignee"
                (click)="expandCollapse(NameAddressSection.CONSIGNEE)"
              >
                <mat-icon *ngIf="!consigneeSectionExpanded" matSuffix>keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="consigneeSectionExpanded" matSuffix>keyboard_arrow_up</mat-icon>
              </button>
            </div>
            <div *ngIf="consigneeSectionExpanded">
              <div class="bill-details__address" [innerHTML]="shipmentDetails?.consigneeAddr | nameAddressPipe"></div>
            </div>
          </div>
          <div fxLayout="column" fxFlex="50">
            <div class="label">Consignee MAD Code</div>
            <div class="value">
              {{ shipmentDetails?.consigneeAcctId?.acctMadCd }}
            </div>
          </div>
        </div>
        <div class="bill-details__billto" fxLayout="row" fxLayoutAlign="start stretch">
          <div fxLayout="column" fxFlex="50">
            <div class="label">Bill To</div>
            <div fxLayout="row">
              <div class="value">
                {{ shipmentDetails?.bil2AcctId?.acctName }}
              </div>
              <button
                class="bill-details__expand-collapse"
                mat-button
                mat-icon-button
                aria-label="Expand Collapse Consignee"
                (click)="expandCollapse(NameAddressSection.BILL_TO)"
              >
                <mat-icon *ngIf="!billtoSectionExpanded" matSuffix>keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="billtoSectionExpanded" matSuffix>keyboard_arrow_up</mat-icon>
              </button>
            </div>
            <div *ngIf="billtoSectionExpanded">
              <div class="bill-details__address" [innerHTML]="shipmentDetails?.bil2Addr | nameAddressPipe"></div>
            </div>
          </div>
          <div fxLayout="column" fxFlex="50">
            <div class="label">Bill To MAD Code</div>
            <div class="value">
              {{ shipmentDetails?.bil2AcctId?.acctMadCd }}
            </div>
          </div>
        </div>

        <!-- VERSION 2 -->
        <!--
          <div class="bill-details__create-rule">
            <button class="pricing__rulesets-button"
                    mat-button
                    color="primary"
                    (click)="createRule()">Create Recommendation Rule</button>
          </div>
        -->
      </div>
      <div class="horizontal-divider" style="width: 2%"></div>
      <div fxLayout="column" fxFlex="38">
        <div class="label bill-details__driver-collect">Driver Collect</div>
        <div class="value" data-testid="driver-collect">
          {{ shipmentDetails?.shipperIsDebtorInd | yesNoPipe }}
        </div>
        <div class="label bill-details__total-charge">Total Charges</div>
        <div class="value" data-testid="total-charges">
          {{ shipmentDetails?.totChargeAmt | currency }}
        </div>
        <div class="label bill-details__accessorials">Applied Accessorials</div>
        <div
          class="value"
          data-testid="applied-accessorials"
          *ngFor="let accessorial of shipmentDetails?.accessorialChargeCodes"
        >
          {{ accessorial }}
        </div>
        <div class="label bill-details__spot-quote">Spot Quote</div>
        <div *ngIf="shipmentDetails?.spotQuoteId > 0" class="value" data-testid="spot-quote">
          {{ shipmentDetails?.spotQuoteId }}
        </div>
        <div *ngIf="shipmentDetails?.spotQuoteId == 0" class="value">N/A</div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
