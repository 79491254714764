import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';

export class InspectionsDateUtils {
  static readonly DATE_TIME_FORMAT_STRING: string = 'M/d/yy h:mm:ss a';
  static readonly DATE_TIME_MILITARY_FORMAT_STRING: string = 'MM/DD/YY HH:mm:ss';
  static readonly DEFAULT_TIMEZONE: string = 'America/Los_Angeles'; // PST PDT
  static readonly locale: string = 'en-US';

  /**
   * Format date, based on default application timezone
   * @default format = 'MM-DD-YYYY'
   */
  static getFormatDate(date: Date, format: string = 'MM-DD-YYYY'): string {
    return moment(date).format(format);
  }

  static getDayBefore(date: Date): Date {
    const dayBefore = new Date(date);
    dayBefore.setDate(dayBefore.getDate() - 1);
    return dayBefore;
  }

  /**
   * Return today date, but without time
   */
  static getToday(): Date {
    const todayDateTime = new Date();
    const currentYear = todayDateTime.getFullYear();
    const currentMonth = todayDateTime.getMonth();
    const currentDate = todayDateTime.getDate();
    const todayDate = new Date(currentYear, currentMonth, currentDate);
    return todayDate;
  }

  static addDays(date: Date, daysToAdd): Date {
    const dateToReturn = new Date(date);
    dateToReturn.setDate(dateToReturn.getDate() + daysToAdd);
    return dateToReturn;
  }

  static addMinutes(date: Date, minutesToAdd: number): Date {
    const dateMs = date.getTime();
    const msToAdd = minutesToAdd * 6000;
    const newDateMs = dateMs + msToAdd;
    return new Date(newDateMs);
  }

  static addMilliseconds(date: Date, msToAdd: number): Date {
    const dateMs = date.getTime();
    const newDateMs = dateMs + msToAdd;
    return new Date(newDateMs);
  }

  static getDifferenceInMinutes(dateFrom: Date, dateTo: Date): number {
    const dateFromMs = dateFrom.getTime();
    const dateToMs = dateTo.getTime();
    const differenceMs = dateToMs - dateFromMs;
    if (!differenceMs || differenceMs <= 0) {
      return 0;
    }
    return Math.round(differenceMs / 6000);
  }

  static getFormattedDateTime(dateTime: Date): string {
    const formattedDateTime = new DatePipe(InspectionsDateUtils.locale).transform(
      dateTime,
      this.DATE_TIME_FORMAT_STRING
    );
    return formattedDateTime;
  }

  static getCurrentFormattedDateTime(): string {
    return this.getFormattedDateTime(new Date());
  }

  static getPassedTimeStringSince(aDate: Date): string {
    if (!aDate) {
      throw Error('given aDate should not be null');
    } else {
      const now: Date = new Date();
      const endDateMs: number = now.getTime();
      const aDateMs: number = aDate.getTime();
      const dateDiffMs: number = endDateMs - aDateMs;
      const result: string = InspectionsDateUtils.msToTimeString(dateDiffMs);

      return result;
    }
  }

  static msToTimeString(milliseconds: number): string {
    //src: https://bobbyhadz.com/blog/typescript-calculate-time-between-dates
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    // console.debug('msToTimesString->s:' + milliseconds / 1000);

    let result: string = '';
    if (hours > 0) {
      result += `${InspectionsDateUtils.padTo2Digits(hours)}h `;
    }
    if (minutes > 0) {
      result += `${InspectionsDateUtils.padTo2Digits(minutes)}m `;
    }
    result += `${InspectionsDateUtils.padTo2Digits(seconds)}s`;

    return result;
  }

  public static isDate(src: any): boolean {
    //https://stackoverflow.com/a/643827/2118777
    return typeof src?.getMonth === 'function';
  }

  public static toDateIfNumberOrDate(src: any): Date {
    let result: Date;
    if (!isNaN(src)) {
      result = new Date(src);
    } else {
      result = src as Date;
    }
    return result;
  }

  static padTo2Digits(num: number): string {
    return num.toString().padStart(2, '0');
  }

  getMilitaryTime(dateTimeInMs: number): string {
    return InspectionsDateUtils.getFormatDate(
      new Date(dateTimeInMs),
      InspectionsDateUtils.DATE_TIME_MILITARY_FORMAT_STRING
    );
  }
}
