import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MatLegacySelectChange } from '@angular/material/legacy-select';
import { ActionCd, Commodity, CommodityClassCd, CommodityPackageCd, DimensionMeasurement } from '@xpo-ltl/sdk-common';
import { NmfcItemSearchRecord, XrtNmfcItemSearchResp } from '@xpo-ltl/sdk-elasticsearch';
import {
  CorrectionRequest,
  CorrectionRequestCommodity,
  CorrectionRequestDimension,
  GetInspectionForCorrectionRequestResp,
  GetInspectionShipmentDetailsResp,
  InspectionCorrectionRequest,
  InspectionDimension
} from '@xpo-ltl/sdk-inspections';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { CommodityClassPipe } from '../../../pipes/commodity-class-pipe';
import { ToFormattedDensityPipe } from '../../../pipes/to-formatted-density.pipe';
import { ToFormattedVolumePipe } from '../../../pipes/to-formatted-volume.pipe';
import { AppConstantsService } from '../../../services/app-constants.service';
import { DialogWrapperService } from '../../../services/dialog-wrapper.service';
import { RequestValidator } from '../../../validators/request.validator';
import { ElasticSearchApiWrapperService } from '../service/elastic-search-api-wrapper.service';

export class DimensionCardData {
  value: string;
  data: CorrectionRequestDimension;
}

export class DimensionCard {
  id: string;
  dimensions: DimensionCardData[];
  connectedList: string[];
  cubicFeet: string;
  density: string;
}

export class CommodityLine {
  id: string;
  dimensions: DimensionCardData[];
  data: Commodity | CorrectionRequestCommodity;
  connectedList: string[];
}

@Component({
  selector: 'app-commodities',
  templateUrl: './commodities.component.html',
  styleUrls: ['./commodities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CommoditiesComponent implements OnChanges {
  @HostBinding('class') hostClass = 'ins-correction-commodities';

  @Input()
  inspectionForCorrectionRequestResp: GetInspectionForCorrectionRequestResp;
  @Input()
  shipmentDetails: GetInspectionShipmentDetailsResp;
  @Input()
  isReadOnlyView: boolean;

  @Output()
  onUpdateButtonState = new EventEmitter<boolean>();

  readonly CUBIC_INCH_IN_CUBIC_FEET: number = 1728;
  readonly CUBIC_FEET: string = 'cubicFeet';
  readonly DENSITY: string = 'density';
  readonly PIECE_COUNT: string = 'piecesCount';
  readonly PACKAGE_CODE: string = 'packageCd';
  readonly HAZMAT_IND: string = 'hazardousMtInd';
  readonly DESCRIPTION: string = 'description';
  readonly WEIGHT_LBS: string = 'weightLbs';
  readonly NMFC_ITEM_CODE: string = 'nmfcItemCd';
  readonly CLASS_TYPE: string = 'classType';
  readonly DIMENSIONS_DRAG_LIST_NAME: string = 'dimensions';
  readonly NMFC_DROPDOWN: string = 'nmfcDropdownValues';
  readonly NMFC_INVALID_INPUT_ERROR_MESSAGE: string = 'NMFC valid value is number or number with hyphen.';

  copyIconMatTooltipMessage: string = 'Copy NMFC and Class from line #1';
  commodityFormGroup: FormGroup;
  commodityWithDimensions: CommodityLine[] = [];
  dimensions: DimensionCard;
  dragAndDropIds: string[] = [];
  dimensionCardDataArray: DimensionCardData[] = [];
  maxCharForNmfc: number = 9;
  isDimensionAssociated: boolean; // use this in readonly view

  private isCommodityResetNeeded: boolean;
  private isDimensionResetNeeded: boolean;
  private hasOnlyOneCommodity: boolean;
  private isFirstTimeOpeningCorrectionRequest: boolean;
  private isAutomaticConnectNeeded: boolean;
  private isConnectionResetNeeded: boolean;
  private lastSearchedNmfcClass: string = '';

  private isCommodityFormControlInitializedSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  isCommodityFormControlInitialized$: Observable<boolean> =
    this.isCommodityFormControlInitializedSubject$.asObservable();

  private nmfcDropdownChangedIndexNumberSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  nmfcDropdownChangedIndexNumber$: Observable<number> = this.nmfcDropdownChangedIndexNumberSubject$.asObservable();

  constructor(
    private formBuilder: UntypedFormBuilder,
    protected changeDetector: ChangeDetectorRef,
    private elasticSearchApiWrapperService: ElasticSearchApiWrapperService,
    private appConstantsService: AppConstantsService,
    private dialogWrapperService: DialogWrapperService
  ) {}

  get commodityFormArray(): FormArray {
    return <FormArray>this.commodityFormGroup.get('commodityFormArray');
  }

  get dimensionsFormControl(): FormControl {
    return <FormControl>this.commodityFormGroup.get('dimensionsFormControl');
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event?.previousContainer === event?.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex); // method provided by Angular
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex); // method provided by Angular
    }

    this.updateTotalCuFtDensityForPrevAndMoveTo(event.previousContainer.id, event.container.id);
    this.emitUpdateButtonStateForCommodity();
  }

  getFormGroup() {
    return this.commodityFormGroup;
  }

  getInitialDimensionArray(): InspectionDimension[] {
    return this.inspectionForCorrectionRequestResp?.inspectionDimensions;
  }

  getTotalWeightFromInspectedShipmentCommodity(): string {
    let totalWeight: number = 0;
    this.inspectionForCorrectionRequestResp?.inspectedShipmentCommodities?.forEach((commodity: Commodity) => {
      totalWeight += commodity.weightLbs;
    });

    return totalWeight.toString();
  }

  isClassTypeEditable(commodityFormControl: AbstractControl): boolean {
    const nmfcItemCd: string = commodityFormControl.get(this.NMFC_ITEM_CODE).value?.trim();
    const hasClassTypeZero: boolean =
      commodityFormControl.get(this.NMFC_DROPDOWN)?.value?.length === 1 &&
      commodityFormControl.get(this.NMFC_DROPDOWN)?.value[0]?.classType === '0';

    return hasClassTypeZero || !nmfcItemCd;
  }

  isUndoShouldDisplayed(index: number, value: string) {
    const nmfcFromDb: string =
      this.inspectionForCorrectionRequestResp?.inspectedShipmentCommodities?.[index]?.nmfcItemCd;
    return !(value === nmfcFromDb || (!value && !nmfcFromDb));
  }

  onClassTypeSelect(nmfcRecord: MatLegacySelectChange) {
    this.commodityFormArray.markAsDirty();
    this.emitUpdateButtonStateForCommodity();
  }

  onNmfcAndClassCopyClicked() {
    const firstCommodityFormControl: AbstractControl = this.commodityFormArray?.controls?.[0];
    RequestValidator.validateObjectNotUndefinedOrEmpty(firstCommodityFormControl, 'First commodity');

    const nmfcFirstLine: string = firstCommodityFormControl.get(this.NMFC_ITEM_CODE).value;
    const classFirstLine: string = firstCommodityFormControl.get(this.CLASS_TYPE).value;

    this.commodityFormArray?.controls?.forEach((control: AbstractControl) => {
      this.setStringFromFormControl(control, this.NMFC_ITEM_CODE, nmfcFirstLine);
      this.setStringFromFormControl(control, this.CLASS_TYPE, classFirstLine);
    });
    this.commodityFormArray.markAsDirty();
  }

  onNmfcDropdownClosed(commodityControl: AbstractControl, index: number) {
    const pipe: CommodityClassPipe = new CommodityClassPipe();
    const nmfcDropDownValues: NmfcItemSearchRecord[] = commodityControl.get(this.NMFC_DROPDOWN).value;
    if (nmfcDropDownValues?.length === 1) {
      commodityControl.get(this.NMFC_ITEM_CODE).setValue(nmfcDropDownValues[0].id);
      const modifiedCommodityFormControl: AbstractControl = this.commodityFormArray?.controls?.[index];
      const classType: string =
        nmfcDropDownValues[0].classType !== '0'
          ? nmfcDropDownValues[0].classType
          : modifiedCommodityFormControl.get(this.CLASS_TYPE).value;
      commodityControl.get(this.CLASS_TYPE).setValue(classType);
      this.commodityFormArray.markAsDirty();
    } else {
      commodityControl.get(this.NMFC_ITEM_CODE).setValue(undefined);
      // reset class type value to the original
      commodityControl
        .get(this.CLASS_TYPE)
        .setValue(
          pipe.transform(this.inspectionForCorrectionRequestResp?.inspectedShipmentCommodities?.[index]?.classType)
        );
      this.commodityFormArray.markAsDirty();
    }
  }

  onNmfcSelect(nmfcRecord: NmfcItemSearchRecord, commodityControl: AbstractControl, index: number) {
    const classType: string =
      nmfcRecord.classType !== '0' ? nmfcRecord.classType : commodityControl.get(this.CLASS_TYPE).value;

    commodityControl.get(this.NMFC_ITEM_CODE).setValue(nmfcRecord.id);
    commodityControl.get(this.CLASS_TYPE).setValue(classType);
    commodityControl.get(this.NMFC_DROPDOWN).setValue([nmfcRecord]);
    this.commodityFormArray.markAsDirty();
  }

  onNmfcUndoClicked(commodityControl: AbstractControl, index: number) {
    const pipe: CommodityClassPipe = new CommodityClassPipe();
    const originalNmfc: string =
      this.inspectionForCorrectionRequestResp?.inspectedShipmentCommodities?.[index]?.nmfcItemCd;
    const originalClassType: string =
      this.inspectionForCorrectionRequestResp?.inspectedShipmentCommodities?.[index]?.classType;
    commodityControl.get(this.NMFC_ITEM_CODE).setValue(originalNmfc);
    commodityControl.get(this.CLASS_TYPE).setValue(pipe.transform(originalClassType));
    this.commodityFormArray.markAsDirty();
    this.emitUpdateButtonStateForCommodity();
  }

  onOpenNmfcLinkClicked(nmfcIdString: string) {
    const nmfcSearchUrl = this.appConstantsService.getNmftaSearchUrl(nmfcIdString);

    this.dialogWrapperService.openExternalWindow(nmfcSearchUrl, 'NMFC Search');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.inspectionForCorrectionRequestResp?.currentValue) {
      this.getCommoditiesData();
    }
  }

  // keep the data building order, each build uses the data that was built previous lines
  private getCommoditiesData() {
    this.isFirstTimeOpeningCorrectionRequest =
      !this.inspectionForCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequest?.correctionRequestId;
    this.hasOnlyOneCommodity = this.inspectionForCorrectionRequestResp?.inspectedShipmentCommodities?.length === 1;
    this.isCommodityResetNeeded = this.isReadOnlyView
      ? false
      : !this.inspectionForCorrectionRequestResp?.commoditiesMatchInd;
    this.isDimensionResetNeeded = this.isReadOnlyView
      ? false
      : !this.inspectionForCorrectionRequestResp?.dimensionsMatchInd;
    this.isAutomaticConnectNeeded = this.isFirstTimeOpeningCorrectionRequest && this.hasOnlyOneCommodity;
    this.isConnectionResetNeeded =
      !this.isAutomaticConnectNeeded && (this.isCommodityResetNeeded || this.isDimensionResetNeeded);

    if (this.isReadOnlyView) {
      // when readonly view is opened, create inspection data from correction request info
      this.setInspectionForCorrectionRequestRespFromInspectionCorrectionRequest();
    }

    this.setDragAndDropIds(); // IDs are used for drag and drop connectedList

    if (this.isFirstTimeOpeningCorrectionRequest) {
      // build inspectionCorrectionRequest from inspectedShipmentCommodities and inspectionDimensions
      this.buildInspectionCorrectionRequestFromInspectionData();
    }

    if (this.isConnectionResetNeeded) {
      this.resetCommodityAndDimensions();
    } else {
      this.setCommodityWithConnectedDimensions();
    }

    this.setDimensionCards();
    this.isDimensionAssociated = this.dimensionCardDataArray.length === 0;
    this.setCommodityFormGroup();
  }

  private setInspectionForCorrectionRequestRespFromInspectionCorrectionRequest() {
    const inspectionCorrectionRequest: InspectionCorrectionRequest =
      this.inspectionForCorrectionRequestResp?.inspectionCorrectionRequest;

    this.setInspectedShipmentCommoditiesFromInspectionCorrectionRequest(inspectionCorrectionRequest);
    this.setInspectionDimensionsFromInspectionCorrectionRequest(inspectionCorrectionRequest);
  }

  private setInspectedShipmentCommoditiesFromInspectionCorrectionRequest(
    inspectionCorrectionRequest: InspectionCorrectionRequest
  ) {
    this.inspectionForCorrectionRequestResp.inspectedShipmentCommodities = [];
    inspectionCorrectionRequest.correctionRequestCommodities?.forEach((crCommodity: CorrectionRequestCommodity) => {
      const commodity: Commodity = new Commodity();
      commodity.sequenceNbr = crCommodity.commoditySequenceNbr.toString();
      commodity.shipmentInstId = crCommodity.shipmentInstId;
      commodity.classType = crCommodity.classTypeCd as CommodityClassCd;
      commodity.nmfcItemCd = crCommodity.nmfcItemCd;
      commodity.packageCd = crCommodity.packageCd as CommodityPackageCd;
      commodity.piecesCount = crCommodity.pieceCount;
      commodity.volumeCubicFeet = crCommodity.volumeCubicFeet;
      commodity.weightLbs = crCommodity.weightLbs;
      commodity.description = crCommodity.description;

      this.inspectionForCorrectionRequestResp.inspectedShipmentCommodities.push(commodity);
    });
  }

  private setInspectionDimensionsFromInspectionCorrectionRequest(
    inspectionCorrectionRequest: InspectionCorrectionRequest
  ) {
    this.inspectionForCorrectionRequestResp.inspectionDimensions = [];
    inspectionCorrectionRequest.correctionRequestDimensions?.forEach((crDimension: CorrectionRequestDimension) => {
      const inspectionDimension: InspectionDimension = new InspectionDimension();
      inspectionDimension.dimensionSequenceNbr = crDimension.dimensionSequenceNbr;
      inspectionDimension.commoditySequenceNbr = crDimension.parentCommoditySequenceNbr;
      inspectionDimension.pieceCount = crDimension.pieceCount;
      inspectionDimension.dimension = new DimensionMeasurement();
      inspectionDimension.dimension.length = crDimension.lengthNbr;
      inspectionDimension.dimension.height = crDimension.heightNbr;
      inspectionDimension.dimension.width = crDimension.widthNbr;

      this.inspectionForCorrectionRequestResp.inspectionDimensions.push(inspectionDimension);
    });
  }

  private setDragAndDropIds() {
    this.inspectionForCorrectionRequestResp?.inspectedShipmentCommodities?.forEach((commodity: Commodity, index) => {
      this.dragAndDropIds.push(`dimsForCommodity_${index}`);
    });
    this.dragAndDropIds.push(this.DIMENSIONS_DRAG_LIST_NAME);
  }

  private buildInspectionCorrectionRequestFromInspectionData() {
    this.inspectionForCorrectionRequestResp.inspectionCorrectionRequest = new InspectionCorrectionRequest();
    this.inspectionForCorrectionRequestResp.inspectionCorrectionRequest.correctionRequestDimensions = [];
    this.inspectionForCorrectionRequestResp.inspectionCorrectionRequest.correctionRequestCommodities = [];
    this.inspectionForCorrectionRequestResp.inspectionCorrectionRequest.correctionRequest = new CorrectionRequest();

    this.inspectionForCorrectionRequestResp?.inspectionDimensions?.forEach((dimension: InspectionDimension) => {
      const newCorrectionRequestDimension: CorrectionRequestDimension =
        this.buildCorrectionRequestDimensionFromInspectionDimension(dimension);
      this.inspectionForCorrectionRequestResp.inspectionCorrectionRequest.correctionRequestDimensions.push(
        newCorrectionRequestDimension
      );
    });

    this.inspectionForCorrectionRequestResp?.inspectedShipmentCommodities?.forEach((commodity: Commodity) => {
      const newCorrectionRequestCommodity: CorrectionRequestCommodity =
        this.buildCorrectionRequestCommodityFromCommodity(commodity);
      this.inspectionForCorrectionRequestResp.inspectionCorrectionRequest.correctionRequestCommodities.push(
        newCorrectionRequestCommodity
      );
    });
  }

  private buildCorrectionRequestCommodityFromCommodity(commodity: Commodity): CorrectionRequestCommodity {
    const newCorrectionRequestCommodity: CorrectionRequestCommodity = new CorrectionRequestCommodity();
    newCorrectionRequestCommodity.chargeAmount = commodity.amount;
    newCorrectionRequestCommodity.classTypeCd = commodity.classType;
    newCorrectionRequestCommodity.commoditySequenceNbr = +commodity.sequenceNbr;
    newCorrectionRequestCommodity.densityWeightLbs = commodity.weightLbs;
    newCorrectionRequestCommodity.description = commodity.description;
    newCorrectionRequestCommodity.freezableInd = commodity.freezableInd;
    newCorrectionRequestCommodity.hazmatInd = commodity.hazardousMtInd;
    newCorrectionRequestCommodity.inspectionId = this.shipmentDetails.shipmentDetails.inspectionId;
    newCorrectionRequestCommodity.listActionCd = ActionCd.ADD;
    newCorrectionRequestCommodity.minimumChargeInd = commodity.minimumChargeInd;
    newCorrectionRequestCommodity.nmfcItemCd = commodity.nmfcItemCd;
    newCorrectionRequestCommodity.packageCd = commodity.packageCd;
    newCorrectionRequestCommodity.pieceCount = commodity.piecesCount;
    newCorrectionRequestCommodity.shipmentInstId = commodity.shipmentInstId;
    newCorrectionRequestCommodity.tariffRate = commodity.tariffsRate;
    newCorrectionRequestCommodity.volumeCubicFeet = commodity.volumeCubicFeet;
    newCorrectionRequestCommodity.weightLbs = commodity.weightLbs;

    return newCorrectionRequestCommodity;
  }

  private buildCorrectionRequestDimensionFromInspectionDimension(
    dimension: InspectionDimension
  ): CorrectionRequestDimension {
    const correctionRequestDimension: CorrectionRequestDimension = new CorrectionRequestDimension();
    correctionRequestDimension.dimensionSequenceNbr = dimension.dimensionSequenceNbr;
    correctionRequestDimension.heightNbr = dimension.dimension?.height;
    correctionRequestDimension.lengthNbr = dimension.dimension?.length;
    correctionRequestDimension.widthNbr = dimension.dimension?.width;

    correctionRequestDimension.listActionCd = !this.inspectionForCorrectionRequestResp?.inspectionCorrectionRequest
      ? ActionCd.ADD
      : ActionCd.UPDATE;
    correctionRequestDimension.pieceCount = dimension.pieceCount;
    correctionRequestDimension.shipmentInstId =
      this.inspectionForCorrectionRequestResp?.inspectedShipmentCommodities?.[0]?.shipmentInstId;

    if (this.hasOnlyOneCommodity) {
      // if there is only one commodity, automatically associate dim to the commodity
      // NOTE: sequenceNbr could start from more than 1, so we should not manually assign parentCommoditySequenceNbr to 1
      correctionRequestDimension.parentCommoditySequenceNbr =
        +this.inspectionForCorrectionRequestResp?.inspectedShipmentCommodities?.[0]?.sequenceNbr;
    }

    return correctionRequestDimension;
  }

  private buildDimensionCardsForConnectedCommodity(commoditySeqNbr: number): DimensionCardData[] {
    const dimensionCard: DimensionCardData[] = [];
    let connectedDimensionDataArray: CorrectionRequestDimension[] = [];

    if (this.isAutomaticConnectNeeded) {
      connectedDimensionDataArray =
        this.inspectionForCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequestDimensions;
    } else {
      connectedDimensionDataArray =
        this.inspectionForCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequestDimensions?.filter(
          (dimension: CorrectionRequestDimension) => dimension.parentCommoditySequenceNbr === commoditySeqNbr
        );
    }

    connectedDimensionDataArray?.forEach((connectedDims: CorrectionRequestDimension) => {
      const cardContent: string = `${connectedDims.pieceCount} pc - ${connectedDims.lengthNbr} x ${connectedDims.widthNbr} x ${connectedDims.heightNbr}`;
      dimensionCard.push({
        value: cardContent,
        data: connectedDims
      });
    });

    return dimensionCard;
  }

  private setCommodityWithConnectedDimensions() {
    this.dragAndDropIds?.forEach((id: string, index) => {
      if (id !== this.DIMENSIONS_DRAG_LIST_NAME) {
        const dataForCommodityWithDimensions: Commodity =
          this.inspectionForCorrectionRequestResp?.inspectedShipmentCommodities?.[index];
        const parentCommoditySequenceNbr: number = +dataForCommodityWithDimensions.sequenceNbr;

        const dimensionCard: DimensionCardData[] =
          this.buildDimensionCardsForConnectedCommodity(parentCommoditySequenceNbr);

        const connectedListForCommodityWithDimensions: string[] = this.getFilteredArrayBy(this.dragAndDropIds, id);

        if (dataForCommodityWithDimensions && connectedListForCommodityWithDimensions) {
          this.commodityWithDimensions?.push({
            data: dataForCommodityWithDimensions,
            dimensions: dimensionCard,
            id: id,
            connectedList: connectedListForCommodityWithDimensions
          });
        }
      }
    });
  }

  private resetCommodityAndDimensions() {
    this.inspectionForCorrectionRequestResp.inspectionCorrectionRequest.correctionRequestCommodities = [];
    this.dragAndDropIds?.forEach((name: string, index: number) => {
      if (name !== this.DIMENSIONS_DRAG_LIST_NAME) {
        const updatedCorrectionRequestCommodities = this.buildCorrectionRequestCommodityFromCommodity(
          this.inspectionForCorrectionRequestResp?.inspectedShipmentCommodities?.[index]
        );
        updatedCorrectionRequestCommodities.correctionRequestId =
          this.inspectionForCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequest?.correctionRequestId;
        updatedCorrectionRequestCommodities.listActionCd = ActionCd.UPDATE;
        this.inspectionForCorrectionRequestResp.inspectionCorrectionRequest.correctionRequestCommodities.push(
          updatedCorrectionRequestCommodities
        );

        const connectedListForCommodityWithDimensions: string[] = this.getFilteredArrayBy(this.dragAndDropIds, name);

        if (updatedCorrectionRequestCommodities && connectedListForCommodityWithDimensions) {
          this.commodityWithDimensions?.push({
            data: updatedCorrectionRequestCommodities,
            dimensions: [], // empty dimensions for reset
            id: name,
            connectedList: connectedListForCommodityWithDimensions
          });
        }
      }
    });

    this.inspectionForCorrectionRequestResp.inspectionCorrectionRequest.correctionRequestDimensions = [];
    this.inspectionForCorrectionRequestResp?.inspectionDimensions?.forEach((insDimension: InspectionDimension) => {
      const newCorrectionRequestDimension: CorrectionRequestDimension =
        this.buildCorrectionRequestDimensionFromInspectionDimension(insDimension);
      newCorrectionRequestDimension.correctionRequestId =
        this.inspectionForCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequest?.correctionRequestId;
      newCorrectionRequestDimension.listActionCd = ActionCd.UPDATE;
      this.inspectionForCorrectionRequestResp.inspectionCorrectionRequest.correctionRequestDimensions.push(
        newCorrectionRequestDimension
      );
    });
  }

  private setStringFromFormControl(control: AbstractControl, formControlName: string, valueString: string) {
    control.get(formControlName).setValue(valueString);
  }

  private setDimensionCards() {
    if (this.inspectionForCorrectionRequestResp?.inspectionDimensions) {
      this.setDimensionCardDataArray();

      const correctionRequests: CorrectionRequestDimension[] = [];
      this.inspectionForCorrectionRequestResp?.inspectionDimensions?.forEach((dim: InspectionDimension) => {
        correctionRequests.push(this.buildCorrectionRequestDimensionFromInspectionDimension(dim));
      });
      const cuFt: string = this.getCubicFtString(correctionRequests);
      const totalWeight: string = this.getTotalWeightFromInspectedShipmentCommodity();
      const cuFtNumber: number = this.getCubicFtNumber(correctionRequests);

      this.dimensions = {
        id: this.DIMENSIONS_DRAG_LIST_NAME,
        dimensions: this.dimensionCardDataArray,
        connectedList: this.getFilteredArrayBy(this.dragAndDropIds, this.DIMENSIONS_DRAG_LIST_NAME),
        cubicFeet: cuFt,
        density: this.getDensityString(totalWeight, cuFtNumber.toString())
      };
    } else {
      // do nothing
    }
  }

  private setDimensionCardDataArray() {
    RequestValidator.validateNumberIsPositiveInteger(
      +this.shipmentDetails?.shipmentDetails?.shipmentId?.shipmentInstId,
      'Shipment Instance ID'
    );

    this.dimensionCardDataArray = [];
    const shipmentInstId: number = +this.shipmentDetails.shipmentDetails.shipmentId.shipmentInstId;

    if (this.isAutomaticConnectNeeded) {
      // do nothing, keep dimensionCardDataArray empty
    } else {
      // reset dims connection
      this.inspectionForCorrectionRequestResp?.inspectionDimensions?.forEach(
        (inspectionDimension: InspectionDimension, index: number) => {
          const newCorrectionRequestDimension: CorrectionRequestDimension =
            this.buildCorrectionRequestDimensionFromInspectionDimension(inspectionDimension);
          newCorrectionRequestDimension.shipmentInstId = shipmentInstId;

          if (this.isConnectionResetNeeded) {
            const cardContent: string = `${inspectionDimension.pieceCount} pc - ${inspectionDimension.dimension.length} x ${inspectionDimension.dimension.width} x ${inspectionDimension.dimension.height}`;
            this.dimensionCardDataArray.push({
              value: cardContent,
              data: newCorrectionRequestDimension
            });
          } else {
            newCorrectionRequestDimension.parentCommoditySequenceNbr =
              this.inspectionForCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequestDimensions?.[
                index
              ]?.parentCommoditySequenceNbr;

            // if parentCommoditySequenceNbr is 0, null or undefined means this is not assigned to any commodity
            if (!newCorrectionRequestDimension.parentCommoditySequenceNbr) {
              const cardContent: string = `${newCorrectionRequestDimension.pieceCount} pc - ${newCorrectionRequestDimension.lengthNbr} x ${newCorrectionRequestDimension.widthNbr} x ${newCorrectionRequestDimension.heightNbr}`;
              this.dimensionCardDataArray.push({
                value: cardContent,
                data: newCorrectionRequestDimension
              });
            }
          }
        }
      );
    }
  }

  private setCommodityFormGroup() {
    if (!this.commodityFormGroup) {
      const pipe: CommodityClassPipe = new CommodityClassPipe();
      this.commodityFormGroup = this.formBuilder.group({
        commodityFormArray: this.formBuilder.array([]),
        dimensionsFormControl: this.formBuilder.control(this.dimensions)
      });

      this.inspectionForCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequestCommodities?.forEach(
        (commodity: CorrectionRequestCommodity, index: number) => {
          const cuFt: string = this.getCubicFtString(this.commodityWithDimensions?.[index]?.dimensions);
          const cuFtNumber: number = this.getCubicFtNumber(this.commodityWithDimensions?.[index]?.dimensions);

          this.commodityFormArray.controls.push(
            this.formBuilder.group({
              [this.PIECE_COUNT]: this.formBuilder.control(commodity.pieceCount),
              [this.PACKAGE_CODE]: this.formBuilder.control(commodity.packageCd),
              [this.HAZMAT_IND]: this.formBuilder.control(commodity.hazmatInd),
              [this.DESCRIPTION]: this.formBuilder.control(commodity.description),
              [this.WEIGHT_LBS]: this.formBuilder.control(commodity.weightLbs),
              [this.NMFC_ITEM_CODE]: this.formBuilder.control(commodity.nmfcItemCd, {
                validators: [this.customValidatorForNmfcInvalidInput(index), Validators.maxLength(this.maxCharForNmfc)],
                updateOn: 'change'
              }),
              [this.NMFC_DROPDOWN]: this.formBuilder.control([commodity.nmfcItemCd], {
                updateOn: 'change'
              }),
              [this.CLASS_TYPE]: this.formBuilder.control(pipe.transform(commodity.classTypeCd), [Validators.required]),
              [this.DIMENSIONS_DRAG_LIST_NAME]: this.formBuilder.control(this.commodityWithDimensions[index]),
              [this.CUBIC_FEET]: this.formBuilder.control(cuFt),
              [this.DENSITY]: this.formBuilder.control(
                this.getDensityString(commodity.weightLbs.toString(), cuFtNumber.toString())
              )
            })
          );
        }
      );
    }
    this.isCommodityFormControlInitializedSubject$.next(true);
  }

  private getFilteredArrayBy(srcArray: string[], removingString: string): string[] {
    let filteredArray: string[] = [];
    filteredArray = srcArray.filter((content: string) => content !== removingString);

    return filteredArray;
  }

  private getCubicFtString(dimList: any[]): string {
    const pipe: ToFormattedVolumePipe = new ToFormattedVolumePipe();
    let cubicFeetTotal: number = 0;
    if (dimList) {
      dimList.forEach((dims) => {
        const dimsData = dims?.data ? dims.data : dims;
        if (dimsData?.lengthNbr && dimsData?.widthNbr && dimsData?.heightNbr && dimsData?.pieceCount) {
          let cubicFeet: number =
            (dimsData.lengthNbr * dimsData.widthNbr * dimsData.heightNbr) / this.CUBIC_INCH_IN_CUBIC_FEET;

          cubicFeet = cubicFeet * dimsData.pieceCount;
          cubicFeetTotal += cubicFeet;
        }
      });
    }

    return pipe.transform(cubicFeetTotal);
  }

  private getCubicFtNumber(dimList: any[]): number {
    let cubicFeetTotal: number = 0;
    if (dimList) {
      dimList.forEach((dims) => {
        const dimsData = dims?.data ? dims.data : dims;
        if (dimsData?.lengthNbr && dimsData?.widthNbr && dimsData?.heightNbr && dimsData?.pieceCount) {
          let cubicFeet: number =
            (dimsData.lengthNbr * dimsData.widthNbr * dimsData.heightNbr) / this.CUBIC_INCH_IN_CUBIC_FEET;

          cubicFeet = cubicFeet * dimsData.pieceCount;
          cubicFeetTotal += cubicFeet;
        }
      });
    }

    return cubicFeetTotal;
  }

  private getDensityString(weight: string, cuFt: string): string {
    const pipe: ToFormattedDensityPipe = new ToFormattedDensityPipe();
    let density: number = 0;
    if (+weight > 0 && +cuFt > 0) {
      density = +weight / +cuFt;
    }

    return pipe.transform(density);
  }

  private setNmfcDropdownValues(nmfcString: string, index: number) {
    if (nmfcString?.trim().length > 0) {
      this.elasticSearchApiWrapperService
        .xrtNmfcItemSearch(nmfcString)
        .pipe(take(1))
        .subscribe((xrtNmfcItemSearchResp: XrtNmfcItemSearchResp) => {
          const hasOnlyOneDropdownOption: boolean =
            this.commodityFormArray?.controls?.[index]?.get(this.NMFC_DROPDOWN)?.value?.length === 1;
          let needToUpdateDropdown: boolean = true;

          if (xrtNmfcItemSearchResp.result.length === 1) {
            this.lastSearchedNmfcClass = xrtNmfcItemSearchResp.result[0].classType;
            if (this.lastSearchedNmfcClass !== '0') {
              this.commodityFormArray?.controls?.[index]?.get(this.CLASS_TYPE)?.setValue(this.lastSearchedNmfcClass);
            }
          }

          if (hasOnlyOneDropdownOption) {
            const isDropDownMatchesCurrentNmfcId: boolean =
              this.commodityFormArray?.controls?.[index]?.get(this.NMFC_DROPDOWN)?.value?.[0]?.id ===
              this.commodityFormArray?.controls?.[index]?.get(this.NMFC_ITEM_CODE)?.value;
            needToUpdateDropdown = !(isDropDownMatchesCurrentNmfcId && hasOnlyOneDropdownOption);
          }
          if (needToUpdateDropdown) {
            this.commodityFormArray?.controls?.[index]?.get(this.NMFC_DROPDOWN)?.setValue(xrtNmfcItemSearchResp.result);
            this.nmfcDropdownChangedIndexNumberSubject$.next(index);
          }
          this.emitUpdateButtonStateForCommodity();
        });
    } else {
      this.commodityFormArray?.controls?.[index]?.get(this.NMFC_DROPDOWN)?.setValue(undefined);
      this.nmfcDropdownChangedIndexNumberSubject$.next(undefined);
    }
  }

  private customValidatorForNmfcInvalidInput(index: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.isReadOnlyView) {
        const value = control.value;

        if (!value) {
          this.setErrors(undefined);
          this.setNmfcDropdownValues(value, index);
          this.emitUpdateButtonStateForCommodity();
          return null;
        }

        const hasOnlyNumberOrHyphen: boolean = /^[0-9-]*$/.test(value);

        if (hasOnlyNumberOrHyphen) {
          this.setErrors(undefined);
          this.setNmfcDropdownValues(value, index);
          this.emitUpdateButtonStateForCommodity();
          return null;
        } else {
          this.setErrors({ invalidNmfcForm: true });
          this.emitUpdateButtonStateForCommodity();
          control.markAsTouched();
          return { invalidNmfcForm: true };
        }
      }
    };
  }

  private emitUpdateButtonStateForCommodity() {
    this.onUpdateButtonState.emit(true);
  }

  private updateTotalCuFtDensityForPrevAndMoveTo(previousListId, moveToListId) {
    this.updateCommodityInfoForId(previousListId);
    this.updateCommodityInfoForId(moveToListId);
    this.commodityFormArray.markAsDirty();
  }

  private updateCommodityInfoForId(formControlId: string) {
    if (formControlId === this.DIMENSIONS_DRAG_LIST_NAME) {
      // update dimension cards formControl
      this.dimensionCardDataArray?.forEach((dim) => {
        this.updateParentCommoditySequenceNbrForCorrectionRequestDimensions(dim.data?.dimensionSequenceNbr, undefined);
      });
      this.dimensions = {
        id: this.DIMENSIONS_DRAG_LIST_NAME,
        dimensions: this.dimensionCardDataArray,
        connectedList: this.dimensionsFormControl.value.connectedList,
        cubicFeet: this.dimensionsFormControl.value.cubicFeet,
        density: this.dimensionsFormControl.value.density
      };

      this.dimensionsFormControl.setValue(this.dimensions);
    } else {
      const index: number = this.commodityFormArray.controls.findIndex(
        (control: AbstractControl) => control.value?.dimensions?.id === formControlId
      );
      const updatingFormControl = this.commodityFormArray.controls.find(
        (control: AbstractControl) => control.value?.dimensions?.id === formControlId
      );

      const cubicFeet: string = this.getCubicFtString(updatingFormControl.value.dimensions.dimensions);
      const weight: string = updatingFormControl.get(this.WEIGHT_LBS).value;
      const cuFtNumber: number = this.getCubicFtNumber(updatingFormControl.value.dimensions.dimensions);

      updatingFormControl.get(this.CUBIC_FEET).setValue(cubicFeet);
      updatingFormControl.get(this.DENSITY).setValue(this.getDensityString(weight, cuFtNumber.toString()));

      const commodityElementPositionInInspectedShipmentCommodities: number = +formControlId.split('_')[1];
      const commoditySeqNbr: number =
        +this.inspectionForCorrectionRequestResp?.inspectedShipmentCommodities?.[
          commodityElementPositionInInspectedShipmentCommodities
        ]?.sequenceNbr;
      const updatingDimension: DimensionCardData = updatingFormControl
        .get(this.DIMENSIONS_DRAG_LIST_NAME)
        .value.dimensions.find((dim) => dim.data.parentCommoditySequenceNbr !== commoditySeqNbr);

      if (updatingDimension) {
        updatingDimension.data.parentCommoditySequenceNbr = commoditySeqNbr;
        this.updateParentCommoditySequenceNbrForCorrectionRequestDimensions(
          updatingDimension.data.dimensionSequenceNbr,
          commoditySeqNbr
        );
      }
      this.commodityFormArray.value[index] = updatingFormControl?.value;
      this.commodityFormArray.markAsDirty();
    }
  }

  private updateParentCommoditySequenceNbrForCorrectionRequestDimensions(
    dimensionSeqNbr: number,
    sequenceNbr: number | undefined
  ) {
    const updateDim: CorrectionRequestDimension =
      this.inspectionForCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequestDimensions?.find(
        (dim: CorrectionRequestDimension) => dim.dimensionSequenceNbr === dimensionSeqNbr
      );
    updateDim.parentCommoditySequenceNbr = sequenceNbr;
  }

  private setErrors(errors: ValidationErrors) {
    this.commodityFormGroup.setErrors(errors);
  }
}
