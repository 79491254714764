import { ProNumber } from '../../classes/pronumber';
import { InspectionState } from '../../enums/inspection-state.enum';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Photo } from '../../classes/photos/photo';
import { PhotoId } from '../../classes/photos/photo-id';
import { PhotoEventService } from '../photos/photo-event.service';

/**
 *
 */
export abstract class HardwareService {
  private photosUpdatedSubject = new Subject<void>();
  public photosUpdated$ = this.photosUpdatedSubject.asObservable();

  private pendingPhotoCountSubject = new BehaviorSubject<number>(0); // Default to the recommended list
  public pendingPhotoCount$ = this.pendingPhotoCountSubject.asObservable();

  constructor(protected photoEventService: PhotoEventService) {}

  // code from: https://stackoverflow.com/questions/7838680/detecting-that-the-browser-has-no-mouse-and-is-touch-only
  public static isTouchScreenCapable(): boolean {
    return (
      'ontouchstart' in window || // html5 browsers
      navigator.maxTouchPoints > 0 || // future IE
      navigator.maxTouchPoints > 0
    ); // current IE10
  }

  public photosUpdated(proNumber: ProNumber | undefined) {
    this.photoEventService.addUpdatedPhoto(proNumber);
    this.photosUpdatedSubject.next();
  }

  public setPendingPhotosCount(count: number) {
    this.pendingPhotoCountSubject.next(count);
    // this.photoEventService.updatePendingPhotosToUpload(count);//want that from local storage
  }

  public getPendingPhotoCount(): number {
    return this.pendingPhotoCountSubject.value;
  }

  abstract addPhoto(proNumber: ProNumber, photoBytes: string, filename: string);

  abstract isGetac(): boolean;

  abstract showPendingPhotosDialogBox(): boolean;

  abstract exitApplication(): void;

  abstract launchCamera(proNumber: ProNumber, inspectionState: InspectionState): void;

  abstract listThumbnails(proNumber: ProNumber): Observable<Photo[]>;

  abstract getImage(photoId: PhotoId): Observable<Photo>;

  abstract listPhotoIds(proNumber: ProNumber): Observable<PhotoId[]>;

  abstract deletePhoto(photoId: PhotoId): Observable<boolean>;

  abstract submitInspectionPhotos(proNumber: ProNumber);

  abstract updatePendingPhotoCount();

  abstract getPendingPhotoIdsToUpload(): Observable<PhotoId[]>;

  abstract showPhotoGallery(proNumber: ProNumber, photoId: PhotoId): Observable<boolean>;

  // this is only for the WebVersion, not the Getac
  // This is to clear the list of taken photos when a new inspection is started
  abstract removePendingPhotos();

  // This is to minimize the app. Getac Version only
  abstract showDesktop();

  abstract canSetZoomLevel(): boolean;

  abstract setZoomLevel(zoomLevel: number);

  abstract getPhotoCountByPro(proNumber: ProNumber, photoStatus: string);

  abstract getStorageSpace();
}
