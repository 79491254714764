<button mat-button color="primary" (click)="onDeleteSicShiftUserPreferenceClicked()">Delete Sic/Shift</button>
<mat-divider></mat-divider>

<mat-slide-toggle [(ngModel)]="devTestingService.isManagerRolesChecked" (change)="onManagerRolesChanged($event)"
  >Remove Manager Roles
</mat-slide-toggle>
<mat-divider></mat-divider>

<mat-slide-toggle
  [(ngModel)]="devTestingService.isBeaconApiThrowingError"
  (change)="onBeaconApiThrowErrorChanged($event)"
  >Throw error for getUnreadMessages Beacon API
</mat-slide-toggle>
<mat-divider></mat-divider>

<div class="shipment-Inst-Id-lookup">
  <div class="shipment-Inst-Id-lookup__left">
    <mat-form-field>
      <input
        #shipmentInstIdSearchInput
        matInput
        placeholder="Enter PRO"
        [formControl]="proInputFormControl"
        (keyup.enter)="onShipmentInstIdLookup()"
        type="text"
        pattern="[a-zA-Z0-9\-]+"
      />
      <mat-icon matSuffix class="search_icon" (click)="onShipmentInstIdLookup()">search</mat-icon>
    </mat-form-field>
  </div>

  <div class="shipment-Inst-Id-lookup__right">Shipment Inst ID: {{ shipmentInstId }}</div>
  <mat-divider></mat-divider>
</div>
