import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { MaterialModule } from '../../material.module';
import { ListHeaderModule } from '../list-header/list-header.module';
import { ListRecommendedRulesComponent } from './list-recommended-rules/list-recommended-rules.component';
import { RecommendationsRulesComponent } from './recommendation-rules.component';
import { RecommendedRulesFiltersComponent } from './recommended-rules-filters/recommended-rules-filters.component';
import { RuleFilterComponent } from './rule-filter/rule-filter.component';

@NgModule({
  declarations: [
    RecommendationsRulesComponent,
    ListRecommendedRulesComponent,
    RuleFilterComponent,
    RecommendedRulesFiltersComponent
  ],
  imports: [CommonModule, MaterialModule, ListHeaderModule, AgGridModule.withComponents([])],
  exports: [
    RecommendationsRulesComponent,
    ListRecommendedRulesComponent,
    RuleFilterComponent,
    RecommendedRulesFiltersComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RecommendationRulesModule {}
