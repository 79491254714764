<div class="pricing">
  <mat-expansion-panel [(expanded)]="isExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title class="section-label"> Pricing </mat-panel-title>
    </mat-expansion-panel-header>

    <div fxLayout="column" fxLayoutAlign="start stretch">
      <div fxLayout="row" fxLayoutAlign="stretch start">
        <div fxLayout="column" fxFlex="25">
          <div class="label">Item 15 Eligibility</div>
          <div class="value" data-testid="item-15">
            {{ shipmentDetails?.item15ExemptInd | invertedYesNoPipe : 'Yes' }}
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div class="label">ELS Eligibility</div>
          <div class="value" data-testid="els-eligibility">
            {{ shipmentDetails?.elsExemptInd | invertedYesNoPipe : 'Yes' }}
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div class="label">Lineal Foot Eligibility</div>
          <div class="value" data-testid="lineal-foot-eligibility">
            {{ shipmentDetails?.linealFootEligibilityInd | yesNoPipe : 'Yes' }}
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div class="label">Lineal Foot Range</div>
          <div class="value" data-testid="lineal-foot-range">
            {{ shipmentDetails?.linealFootRanges?.toArray() }}
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div class="label">Special lineal foot or capacity rules</div>
          <div class="value" data-testid="special-lineal-foot-rules">
            {{ shipmentDetails?.specialCapacityRuleInd | yesNoPipe : 'No' }}
          </div>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="start stretch"
        class="pricing__rulesets"
        data-testid="applied-rulesets-link"
        *ngIf="appliedRulesetUrl"
      >
        <button
          class="pricing__rulesets-button"
          mat-button
          color="primary"
          (click)="onOpenRulesetsClicked()"
          matTooltip="Click to open Applied Rulesets in a new page"
        >
          View Applied Rulesets
        </button>
      </div>
    </div>
  </mat-expansion-panel>
</div>
