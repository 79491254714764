<div class="customerGuidelines_container">
  <app-list-header
    [title]="title"
    [isAddEnabled]="customerGuidelinesService.isManagerActionsAvailable$ | async"
    [addingObjectName]="addingObjectName"
    [refreshTime]="refreshDatesService.customerGuidelineListRefreshTimestamp.stringValue$ | async"
    (refreshTriggered)="onRefreshTriggered()"
    (addButtonClicked)="onAddButtonClicked()"
    (gridFilterCleared)="onGridFilterCleared()"
    (gridSortCleared)="onGridSortCleared()"
    (gridSettingsSaved)="onGridSettingsSaved()"
    (gridSettingsLoaded)="onGridSettingsLoaded()"
    (gridSettingsReset)="onGridSettingsReset()"
  ></app-list-header>

  <ig-list-customer-guidelines
    (listChanged)="onRefreshTriggered()"
    (gridSettingsChanged)="gridSettingsChanged()"
  ></ig-list-customer-guidelines>
</div>
