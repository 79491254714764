<div *ngIf="show$ | async" class="notification">
  <div
    class="notification__content"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <mat-progress-spinner
      *ngIf="showProgressSpinner"
      mode="indeterminate"
      [strokeWidth]="10"
      [diameter]="120"
      class="notification__spinner"
    >
    </mat-progress-spinner>
  </div>
</div>
