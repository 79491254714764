import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../enums/config-manager-properties.enum';
import { CorrectionsApiWrapperService } from './corrections-api-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalAppAccessService {
  constructor(private configManagerService: ConfigManagerService) {}

  buildShipmentManagementAppUrl(shipmentInstId: string): string {
    let csShipmentListRegionUrl = this.configManagerService.getSetting<string>(
      ConfigManagerProperties.CS_SHIPMENT_LIST_REGION_URL
    );
    if (csShipmentListRegionUrl && csShipmentListRegionUrl[csShipmentListRegionUrl.length - 1] !== '/') {
      csShipmentListRegionUrl += '/';
    }
    return `${csShipmentListRegionUrl}shipment-details/${shipmentInstId}`;
  }

  buildCorrectionsAppUrl(proNbrString: string): string {
    let correctionsAppRegionUrl: string = this.configManagerService.getSetting<string>(
      ConfigManagerProperties.CORRECTIONS_APP_REGION_URL
    );
    if (correctionsAppRegionUrl && correctionsAppRegionUrl[correctionsAppRegionUrl.length - 1] !== '/') {
      correctionsAppRegionUrl += '/';
    }
    const billDetailsUrl: string = CorrectionsApiWrapperService.BILL_DETAILS;
    return `${correctionsAppRegionUrl}${billDetailsUrl}?proNbr=${proNbrString}`;
  }
}
