export enum ShipmentStatusEnum {
  APPOINTMENT = 'APPT',
  ARRIVED = 'ARIV',
  AVAILABLE_FOR_LINEHAUL = 'AVAL',
  AVAILABLE_FOR_CITY = 'CITY',
  CITY_ARRIVE = 'CARV',
  CITY_LOAD = 'CLDK',
  CLOSE = 'CLOS',
  CLOSE_FOR_DELIVERY = 'CLDV',
  DELAYED = 'DLYD',
  DROP = 'DROP',
  EMPTY = 'EMTY',
  ENROUTE = 'ENRT',
  FINAL_DELIVERED = 'FINL',
  LOADING_AT_CUSTOMER = 'LDCU',
  LOADING_AT_DECK = 'LDDK',
  OUT_OF_SERVICE = 'OSVC',
  OVERHEAD = 'OVHD',
  PND_ROUTE = 'PDRT',
  TRAP = 'TRAP',
  UNLOADING_AT_CUSTOMER = 'UNLC',
  UNLOADING_AT_DECK = 'UNLD'
}
