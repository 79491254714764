import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { XpoLtlDialogTitleModule, XpoLtlDocumentService} from '@xpo-ltl/ngx-ltl';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { GetDocumentResp, SearchDmsDocumentResp } from '@xpo-ltl/sdk-documentmanagement';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { DocumentUtilsService } from '../../services/document-utils.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { XpoResponsiveLoadingSpinnerModule } from '@xpo-ltl/ngx-ltl-core/responsive-loading-spinner';
import { ShipmentDetailsComponent } from '../shipment-details/shipment-details.component';


@Component({
  selector: 'xpo-pdf-viewer-dialog',
  templateUrl: './pdf-viewer-dialog.component.html',
  styleUrls: ['./pdf-viewer-dialog.component.scss'],
  standalone: true,
  imports: [PdfViewerModule, CommonModule, XpoLtlDialogTitleModule, DragDropModule, MatIconModule, MatButtonModule, XpoResponsiveLoadingSpinnerModule],
})
export class PdfViewerDialogComponent {
  combinedPdf: Uint8Array;
  zoom: number = 1.0;
  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<PdfViewerDialogComponent>,
    private documentService: XpoLtlDocumentService,
    private snackBar: XpoSnackBar,
    private documentUtilsService: DocumentUtilsService,
  ) {
    this.loadDocuments(data.pro, data.explicitDocuments);
  }

  loadDocuments(proNumber: string, explicitDocuments?: string[]) {
    this.loadingSubject.next(true);
    this.documentService
      .listAvailableDocuments(proNumber)
      .pipe(take(1))
      .subscribe(
        (searchDmsDocumentResp: SearchDmsDocumentResp) => {
          let filteredDocuments = [];
          if (explicitDocuments){
            filteredDocuments = searchDmsDocumentResp?.documentInfo.filter((document) => {
              return explicitDocuments.includes(document.cdt.timestamp);
            });
          } else {
             filteredDocuments = searchDmsDocumentResp?.documentInfo.filter((document) => {
              if (document?.cdt?.docClass){
                const allDocumentTypes = ShipmentDetailsComponent.DOCUMENT_DOC_TYPES.concat(ShipmentDetailsComponent.INSPECTIONS_PHOTOS_DOC_TYPES.concat(ShipmentDetailsComponent.OPERATIONS_PHOTOS_DOC_TYPES));
                return allDocumentTypes.includes(document?.cdt?.docClass as any);
              }
              return false;
            });
          }
          if (filteredDocuments?.length > 0) {
            this.processDocuments(filteredDocuments);
          } else {
            this.snackBar.error('No documents found.');
            this.loadingSubject.next(false);
            this.dialogRef.close();
          }
        },
        (err) => {
          this.snackBar.error('Unexpected error.');
          this.loadingSubject.next(false);
          this.dialogRef.close();
        }
      );
  }

  processDocuments(documents) {
    let documentStrings: string[] = [];
    const resultArray: { name: string; value: string }[] = [];
    const documentsLength = documents.length;
    documents.forEach((document) => {
      if (document?.cdt) {
        this.documentService
          .getDocument(document.cdt?.timestamp, document.cdt?.docClass, document.cdt?.corpCode)
          .pipe(take(1))
          .subscribe(
            (result: GetDocumentResp) => {
              resultArray.push({
                name: result.fileName.substring(0, result.fileName.length - 4), // ignore extension
                value: result?.documentData,
              });
              if (resultArray.length >= documentsLength) {
                if (resultArray.length > 1) {
                  //sort them
                  resultArray.sort(function(a, b) {
                    return parseInt(a.name) - parseInt(b.name);
                  });
                }
                resultArray.forEach((result) => {
                  documentStrings.push(result.value);
                });
                this.loadingSubject.next(false);
                this.documentUtilsService.mergePdfs(documentStrings).then((data) => (this.combinedPdf = data));
              } 
            },
            (err) => {
              this.snackBar.error('Unexpected error.');
              this.loadingSubject.next(false);
              this.dialogRef.close();
            }
          );
      }
    });
  }

  incrementZoom(amount: number) {
    this.zoom += amount;
  }
}

