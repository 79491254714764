import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InspectionShipmentDetails } from '../../../../classes/inspection-shipment-details';
import { ProNumber } from '../../../../classes/pronumber';
import { AppConstantsService } from '../../../../services/app-constants.service';
import { DialogWrapperService } from '../../../../services/dialog-wrapper.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricingComponent implements OnChanges {
  @Input()
  shipmentDetails: InspectionShipmentDetails;

  appliedRulesetUrl: string;
  isExpanded: boolean = false;

  constructor(
    public dialog: MatDialog,
    public xpoDialog: DialogWrapperService,
    public appConstantsService: AppConstantsService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.appliedRulesetUrl = undefined;
    if (changes['shipmentDetails']) {
      if (this.shipmentDetails) {
        const proNumber = new ProNumber(this.shipmentDetails?.shipmentId?.proNumber).formatProNumber();
        const pickupDate = this.shipmentDetails?.shipmentId?.pickupDate;
        const appliedRulesetNbr = this.shipmentDetails?.appliedRulesetNbr;
        const appliedAgreementId = this.shipmentDetails?.appliedAgreementId;
        if (proNumber && pickupDate && appliedRulesetNbr && appliedAgreementId) {
          const queryParams = [];
          queryParams.push('proId=' + proNumber);
          queryParams.push('shipmentPkupDt=' + pickupDate);
          queryParams.push('rsSeq=' + appliedRulesetNbr);
          queryParams.push('docId=' + appliedAgreementId);
          queryParams.push('popUp=Y');
          this.appliedRulesetUrl = this.appConstantsService.getPricingAppEndpoint(['initRulesetView.do'], queryParams);
        }
      }
    }
  }

  public onOpenRulesetsClicked() {
    if (this.appliedRulesetUrl) {
      this.xpoDialog.openExternalWindow(this.appliedRulesetUrl, 'PricingRuleSet');
    }
  }
}
